import Accordion from "../../components/common/accordion";
import { faqFile } from "../../data/faqList";

function FaqSection() {
  // filter only general sales questions
  const specificQuestions = faqFile.contents.filter(
    (faq) => faq.places && faq.places.includes("landing")
  );

  return (
    <div className="new-yellow-background-color">
      {/* F. A. Q. */}
      <div
        className="exact-page d-flex flex-column justify-content-center align-items-center"
        style={{
          paddingInline: "30px",
        }}
      >
        <h2
          className="font-title0-Osw seafoam-selection"
          style={{
            textAlign: "center",
            paddingBlock: "30px",
          }}
        >
          F.A.Q.
        </h2>
        <br />
        <div
          className="cream-background-color"
          style={{
            maxWidth: "700px",
            width: "100%",
            marginBottom: "30px",
            borderRadius: "20px",
          }}
        >
          <Accordion file={specificQuestions} color="white" />
        </div>
        <br />
      </div>
    </div>
  );
}

export default FaqSection;
