import wheel from "../../assets/icons/wheel.svg";
import ReactPixel from "../../services/facebookPixel";

// THIS BUTTON IS USED ONLY ON THE LANDING PAGE CTAs
function WheelSecondaryButton({
  text,
  clickHandler,
  active = true,
  customEventName = "unregistered-wheelSecondary",
}) {
  const handleClick = () => {
    clickHandler();
    if (customEventName !== "unregistered-wheelSecondary") {
      ReactPixel.trackCustom(customEventName);
    }
  };

  return (
    <button
      className="wheelBtn wheelBtnSecondary d-flex align-items-center"
      disabled={active ? false : true}
      onClick={handleClick}
    >
      <img
        className="wheel wheel-reduced"
        src={wheel}
        alt=""
        style={{ marginRight: "1em" }}
      />
      {text}
    </button>
  );
}

export default WheelSecondaryButton;
