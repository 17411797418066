import React, { useState } from "react";
import { useFormspark } from "@formspark/use-formspark";

const FORMSPARK_FORM_ID = "RRUofDiY6";

export const FeedbackInput = () => {
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [message, setMessage] = useState("");
  const active = true;

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit({ message });
    alert("Feedback inviato. Grazie! :)");
  };

  return (
    <form onSubmit={onSubmit} style={{ width: "90%" }}>
      <div className="d-flex flex-column align-items-center">
        <textarea
          className="form-control dedication dedication-textarea phone-dedication"
          style={{
            backgroundColor: "#F1E8D5",
            resize: "none",
            height: "300px",
          }}
          placeholder="Lascia qui i tuoi feedback su Feelbox"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <br />
        <br className="no-phone" />
        <button
          className={`primaryBtnUncolored ${
            active ? "primaryBtnColor" : "primaryBtnColorInactive"
          }`}
          type="submit"
          disabled={submitting}
        >
          Invia
        </button>
      </div>
    </form>
  );
};
