import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import OfferBig from "../../components/common/offerBig";
import PrimaryButton from "./../../components/common/primaryButton";
import SecondaryButton from "../../components/common/secondaryButton";
import { useState } from "react";

//THIS POPUP, BEING DIMENSIONALLY UNIQUE, IT'S REBUILT FROM MUI MATERUAL AND NOT UPON THE SQUARED POPUP800 (simple) COMPONENT
function PopOffer({ giftSize, onExplore, onLifestyle, onThanks }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [selected, setSelected] = useState(false);
  const [whichOffer, setWhichOffer] = useState("");

  return (
    // we recognize classes for "phone", "mid", "midphone" corrections, or "all" when the style is declined in the stylesheet for all sizes
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogContent
          className="phone-pop-offer mid-pop-offer"
          style={{
            width: "1600px",
            height: "800px",
            backgroundColor: "#FCE868",
            padding: "0px",
          }}
        >
          <div
            className="phone-pop-offer-small mid-pop-offer-small d-flex flex-column align-items-center flex-grow"
            style={{
              paddingTop: "84px",
            }}
          >
            <h1 className="font-title0-Osw" style={{ marginBottom: "25px" }}>
              Ti è piaciuto?
            </h1>
            <h2
              className="font-subT1-Osw all-offer-text1-space"
              style={{ textAlign: "center" }}
            >
              Regalare e pensare agli altri, in un mondo individualista, è{" "}
              <br className="no-phone" />
              un'esperienza che ci fa essere più noi stessi:{" "}
              <span
                className="white-text-background"
                // style={{ color: "#FFFCEB" }}
              >
                esseri relazionali!{" "}
              </span>
            </h2>
            <h5
              className="font-subtitle3-WS midphone-offer-text2-space"
              style={{
                fontFamily: "Work Sans",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "29px",
                marginBottom: "55px",
                textAlign: "center",
              }}
            >
              Ti proponiamo allora uno sconto per impegnarti con più frequenza{" "}
              <br className="no-phone" /> in questa bella esperienza di{" "}
              <span
                className="white-text-background"
                // style={{ color: "#FFFCEB" }}
              >
                altruismo e vicinanza.{" "}
              </span>
            </h5>

            <div className="d-flex flex-row flex-wrap">
              <OfferBig
                form={whichOffer == "E" ? "selected" : ""}
                claim="GIFTING EXPLORER"
                row1="Un altro regalo nei"
                row2="prossimi 2 mesi"
                price={giftSize == "M" ? "16,99" : "10,99"}
                saving={giftSize == "M" ? "3" : "1"}
                gifts="2"
                onClickAdd={() => {
                  onExplore();
                  setWhichOffer("E");
                  setSelected(true);
                }}
                onClickRmv={() => {
                  onThanks();
                  setWhichOffer("");
                  setSelected(false);
                }}
              />
              <span style={{ width: "25px" }} />
              <OfferBig
                form={whichOffer == "L" ? "selected" : ""}
                claim="LIFESTYLE GIFTER"
                row1="Altri 3 regali nei"
                row2="prossimi 4 mesi"
                price={giftSize == "M" ? "29,99" : "19,99"}
                saving={giftSize == "M" ? "10" : "4"}
                gifts="4"
                onClickAdd={() => {
                  onLifestyle();
                  setWhichOffer("L");
                  setSelected(true);
                }}
                onClickRmv={() => {
                  onThanks();
                  setWhichOffer("");
                  setSelected(false);
                }}
              />
            </div>

            <div className="d-flex flex-column flex-end align-items-center">
              <span
                className="phone-popup-less-margin"
                style={{ marginTop: "25px" }}
              />
              <PrimaryButton
                text="AGGIUNGI"
                active={selected}
                clickHandler={handleClose}
              />
              <span className="phone-pop-less-H" style={{ height: "20px" }} />
              <SecondaryButton
                text="NO GRAZIE"
                clickHandler={() => {
                  handleClose();
                  onThanks();
                }}
              />
              <p className="font-subtitle3-WS">
                Un unico regalo a {giftSize == "M" ? "9.99 " : "5.99 "}€
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PopOffer;
