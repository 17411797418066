import { useEffect } from "react";
import { FeedbackInput } from "../components/common/feedbackInput";

function FeedbackPage({ setScene }) {
  useEffect(() => {
    setScene("light");
  }, []);

  return (
    <>
      <div
        className="exact-page-net-header d-flex flex-column justify-content-center align-items-center cream-background-color"
        style={{ paddingInline: "30px" }}
      >
        <FeedbackInput />
        {/* <br />
        <br />
        <br />

        <h3
          className="font-subtitle-OswMed seafoam-selection"
          style={{ textAlign: "center" }}
        >
          Oppure scrivi all'indirizzo
          <br />
          <span className="yellow-selection">connect@feelbox.gift</span>
        </h3> */}
        <br />
      </div>
    </>
  );
}

export default FeedbackPage;
