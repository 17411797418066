import { React } from "react";

const MiniSongBar = ({ data, onPlay, onPause, playingSong }) => {
  const {
    Title: title,
    Stage_Name: artist,
    Length: length,
    // ID_Song: iD_Song, //not needed
  } = data;

  const resultBarShadow =
    playingSong === data.Storage_Preview ? "#FCE868" : "transparent";

  const TextPart = () => {
    return (
      <div
        className="mini-song-text"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <span translate="no">{title}</span>
        <span
          style={{
            opacity: 0.7,
            fontWeight: 400,
            fontStyle: "italic",
          }}
          translate="no"
        >
          <span className="no-phone"> - </span> <br className="phone" />{" "}
          {artist}
        </span>
      </div>
    );
  };

  return (
    <div
      className="mini-songbar"
      style={{ boxShadow: `0 0 0 2px ${resultBarShadow}` }}
    >
      <a
        className="list-group-item active px-2 border-1 d-flex align-items-center "
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (playingSong === data.Storage_Preview) {
            onPause();
          } else {
            onPlay(data);
          }
        }}
      >
        <div className="d-flex align-items-center">
          <TextPart />
        </div>
      </a>
    </div>
  );
};

export default MiniSongBar;
