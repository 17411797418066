//This code exports two functions: createImage and getCroppedImg.

//Helper function that takes a url as its input and returns a Promise that
//resolves with an Image object when the image is loaded, or rejects with an error if the image fails to load. 
export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

  
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc, //the source URL of the image to be cropped
  pixelCrop, //an object that specifies the bounding box of the cropped image
) {
  const image = await createImage(imageSrc) // creates a new Image object using the createImage helper function. 
  const canvas = document.createElement('canvas') // creates a new canvas
  const ctx = canvas.getContext('2d') //and a 2D context for the canvas

  if (!ctx) { 
    return null
  }
    
      // set canvas size to match the bounding box
      canvas.width = image.width
      canvas.height = image.height
    
      // translate canvas context to a central location to allow rotating and flipping around the center
      ctx.translate(image.width / 2, image.height / 2)
      ctx.translate(-image.width / 2, -image.height / 2)
    
      // draw rotated image
      ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}
