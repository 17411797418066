import { useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import WheelPrimaryButton from "../../components/common/wheelPrimaryButton";
import WheelSecondaryButton from "../../components/common/wheelSecondaryButton";
import { isMobile } from "../../utils/isMobile";

function HERO({ handleClickOpen, callRedeem }) {
  const { scrollY } = useScroll();

  const deviceMobile = isMobile();
  const [PCFactor, setPCFactor] = useState([1, 1]);
  useEffect(() => {
    if (!deviceMobile) {
      setPCFactor([1.5, 0]);
    }
  }, [deviceMobile]);

  const wpH =
    parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue("--vh")
    ) * 100;

  useEffect(() => {
    listenerTranslateY.set([0, 30 * PCFactor[1]]);
    listenerTranslateX.set([0, -15 * PCFactor[0]]);
    handTranslateY.set([0, -55 * PCFactor[0]]);
    handTranslateX.set([0, -18 * PCFactor[0]]);
  }, [PCFactor]);

  const listenerTranslateY = useTransform(
    scrollY,
    [0, wpH],
    [0, 30 * PCFactor[1]]
  );
  const listenerTranslateX = useTransform(
    scrollY,
    [0, wpH],
    [0, -15 * PCFactor[0]]
  );
  const handTranslateY = useTransform(
    scrollY,
    [0, wpH],
    [0, -55 * PCFactor[0]]
  );
  const handTranslateX = useTransform(
    scrollY,
    [0, wpH],
    [0, -18 * PCFactor[0]]
  );

  return (
    <div
      className="exact-page-net-header clear-background-color right-margin-phone"
      style={{
        width: "100%",
        position: "relative",
        paddingLeft: "6.32%", //TODO change css?
        overflow: "hidden",
        zIndex: "10",
      }}
    >
      <motion.div
        className="hero-img-2"
        style={{
          translateY: listenerTranslateY,
          translateX: listenerTranslateX,
        }}
      />
      <motion.div
        className="hero-img-22"
        style={{ translateY: handTranslateY, translateX: handTranslateX }}
      />
      <h1 className="font-titleHome-Osw">
        CREA UN REGALO UNICO,
        <br />
        CON LA MUSICA
      </h1>
      {/* <div className="subtitle-hero-row phone-column">
      </div> */}
      <h2 className="font-subT3-WS">
        Per emozionare, stupire e
        <br /> rimanere vicino a chi vuoi bene
      </h2>

      <div style={{ zIndex: 20 }}>
        <WheelPrimaryButton
          text="REGALA"
          clickHandler={handleClickOpen}
          customEventName="cta1"
        />
        <p className="smaller-br-span-phone" />
        <WheelSecondaryButton
          text="RICEVI IN APP"
          clickHandler={callRedeem}
          customEventName="cta-redeem"
        />
      </div>
    </div>
  );
}

export default HERO;
