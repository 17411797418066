import { IoCart } from "react-icons/io5";
import ReactPixel from "../../services/facebookPixel";

function CartButton({
  text,
  clickHandler,
  active = true,
  customEventName = "unregistered-cartBtn",
}) {
  const handleClick = () => {
    clickHandler();
    if (customEventName !== "unregistered-cartBtn") {
      ReactPixel.trackCustom(customEventName);
    }
  };

  return (
    <button
      className="wheelBtn wheelBtnSecondary cartBtn d-flex align-items-center"
      disabled={active ? false : true}
      onClick={handleClick}
    >
      <IoCart
        className="cart wheel-reduced"
        alt=""
        style={{ marginRight: "1em" }}
      />
      Acquista
    </button>
  );
}

export default CartButton;
