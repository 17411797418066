import React from "react";

// Reusable Step component
const StepDesktop = ({ number, title, text }) => (
  <div className="how-step ">
    <div className="how-number">{number}</div>
    <br />
    <span className="how-title">{title}</span>
    <p className="how-text">{text}</p>
  </div>
);

const StepPhone = ({ number, title, text }) => (
  <div className="how-step ">
    <div className="how-number">{number}</div>
    <div className="d-flex flex-column justify-content-start align-items-start">
      <span className="how-title">{title}</span>
      <p className="how-text">{text}</p>
    </div>
  </div>
);

// Data constant
const stepsData = [
  {
    number: 1,
    title: "CREA",
    text: (
      <span>
        Da questo <span className="strong600">sito</span> scegli dei brani e
        personalizza il regalo in ogni sua parte
      </span>
    ),
  },
  {
    number: 2,
    title: "REGALA",
    text: (
      <span>
        Acquista il regalo e comunica al destinatario il codice per riceverlo
      </span>
    ),
  },
  {
    number: 3,
    title: "RESTA VICINO",
    text: (
      <span>
        Chi riceve il tuo regalo potrà viverlo dalla nostra{" "}
        <span className="strong600">app</span>, senza limiti e distrazioni
      </span>
    ),
  },
];

function HowTunehey() {
  return (
    <div className="exact-page page-padding page-padding-rb cream-background-color phone-how-flow">
      <div>
        <h1
          className="font-title1-Osw midphone-boost-ft1o-2"
          style={{ textAlign: "center", fontWeight: "500" }}
        >
          <span className="yellow-text-background">COME FUNZIONA</span>
        </h1>
        <h2
          className="font-subT1-WS home-space-1"
          style={{ fontWeight: "500", textAlign: "center" }}
        >
          Ogni tuo pensiero e gesto <br className="phone" /> renderanno il
          regalo più prezioso!
        </h2>
      </div>

      <div className="no-phone">
        <div className="how-steps">
          <div className="how-line" />
          {stepsData.map((stepData) => (
            <StepDesktop
              key={stepData.number}
              number={stepData.number}
              title={stepData.title}
              text={stepData.text}
            />
          ))}
        </div>
      </div>

      <div className="phone">
        <div className="how-steps">
          <div className="how-line" />
          {stepsData.map((stepData) => (
            <StepPhone
              key={stepData.number}
              number={stepData.number}
              title={stepData.title}
              text={stepData.text}
            />
          ))}
        </div>
      </div>

      <div className="phone" style={{ height: "20px" }} />
    </div>
  );
}

export default HowTunehey;
