import axios from "axios";
import { Buffer } from 'buffer';

const testUrl = "https://ubbyy93ys8.execute-api.eu-west-1.amazonaws.com/default/";
const prodUrl = "https://vd9h4kcs81.execute-api.eu-west-1.amazonaws.com/default/";
const url = (window.location.hostname == "www.tunehey.com" || window.location.hostname == "tunehey.com")  ? prodUrl : testUrl;

/**
 * Requests cookies to be sent to CloudFront in order to listen to songs
 * 
 * @returns {Object} - A JSON containing the three cookies to set
 */
export function requestSongCookie() {
  return axios.post(url + "MP3Streaming"
  // , { headers: {'Access-Control-Allow-Origin': "*"}}
  );
}

/**
 * Requests a signed URL to upload an image to S3.
 * 
 * @param {string} filename the name of the file to be saved with the extension, preceded by the desired destination folder (e.g. "Avatar/img.jpg")
 * @returns {string} containing the signed URL
 */
export function getImageUrl(filename) {
  try {
    const result = axios.get(url + `uploadImage?filename=${filename}`);
    return result;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Performs the upload of cover and user images.
 * 
 * @param {string} signedUrl the signed url to upload the image to S3
 * @param {ArrayBuffer} img the image to upload
 */
export async function uploadImage(signedUrl, img) {
  const buffer = Buffer.from(img);
  try {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/jpeg',
        'Access-Control-Allow-Origin': "*"
      },
      body: buffer,
    });
    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Upload error:', error);
    throw error;
  }
}


/**
 * Send an email with attachment to the user after the gift or gift plus credit purchase
 * 
 * @param {string} code - The code to redeem the gift purchased
 * @param {string} email - The contact email for the user that purchased the gift
 * @param {string} name - The person name of the receiver, that identifies the gift
 * @param {string} songs - A comma separated list of dash separated Title Artist couples of the chosed songs
 * @returns {Object} an object with a "message" property that indicates if "Email processed siccesfully" or not
 */
export function sendPurchaseEmail(code, email, name, songs) {
  try {
    return axios.get(url + `sendPurchaseEmail?code=${code}&email=${email}&name=${name}&songs=${songs}`);
  } catch (error) {
    console.error(error);
  }
}


/**
 * Send an email with attachment to the email after the credit purchase
 * 
 * @param {string} email - The contact email for the user that purchased the gift
 * @returns {Object} an object with a "message" property that indicates if "Email processed siccesfully" or not
 */
export function sendCreditPurchaseEmail(email) {
  try {
    return axios.get(url + `sendCreditPurchaseEmail?email=${email}`);
  } catch (error) {
    console.error(error);
  }
}