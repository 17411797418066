import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import YellowDialog from "../../layouts/popup800";
import { UserContext } from "../../contexts/userContext";
import { Card } from "@mui/material";
import ResumeCard from "../../components/common/resumeCard";
import { DecodeLineBreak } from "../../utils/manageLines";
import { ParseDate } from "./../../utils/parseDate";
import { moments } from "../customize/moment";
import { coverArtOrdered as CoverNames } from "../customize/cover";

// THIS POPUP IS THE ENTRY PAGE FOR THE USER SESSION
// THE USER CAN VIEW AND CHOSE PREVIOUS GIFT
// TODO the delete option
// AND RESTART FROM THEM OR START FROM SCRATCH
function PopGift({
  setName,
  setDedication,
  setMomentID,
  setOriginalMoment,
  setMoment,
  setCollection,
  open,
  onClose,
  sceneChange,
  pageChange,
  setSongs,
  setIcon,
  setCover,
  setFakeCover,
  setGiftId,
  setFilenameIcon,
  setFilenameCover,
  giftList,
  setGiftList,
  userId,
  setGiverName,
  setGiftSize,
}) {
  const navigate = useNavigate();
  const { userAvatar } = useContext(UserContext);

  const [expanded, setExpanded] = useState(false);

  const handleClick = (gift) => {
    setIcon(
      gift.Filename_Avatar && gift.Filename_Avatar !== null
        ? "https://d1ytrwzciudool.cloudfront.net/Avatar/" + gift.Filename_Avatar
        : //note that we apply default user avatar also to previous gift (without avatar)
        userAvatar
        ? "https://d1ytrwzciudool.cloudfront.net/Avatar/" + userAvatar
        : //only if we don't have a saved image or a user avatar we initialize with blank state
          ""
    );
    if (CoverNames.includes(gift.Filename_Coverart)) {
      setCover(gift.Filename_Coverart); //it's one of the proposed default images
      setFakeCover(gift.Filename_Coverart); //we always select it graphycally with this state
    } else {
      setCover(
        gift.Filename_Coverart
          ? "https://d1ytrwzciudool.cloudfront.net/Coverart/" +
              gift.Filename_Coverart
          : ""
      );
      setFakeCover(
        gift.Filename_Coverart
          ? "https://d1ytrwzciudool.cloudfront.net/Coverart/" +
              gift.Filename_Coverart
          : ""
      );
    }
    setFilenameIcon(gift.Filename_Avatar !== null ? gift.Filename_Avatar : "");
    setFilenameCover(gift.Filename_Coverart);
    setGiftId(gift.ID_Gift);
    setName(DecodeLineBreak(gift.Name));
    setGiverName(gift.Giver_Name);
    setDedication(DecodeLineBreak(gift.Dedication));
    setCollection(gift.ID_Collection); // TODO when using editorial or album, check if is one of them & update state
    setMoment(gift.Moment); //we don't DecodeLineBreak here because doesn't have to be written directly - the original one (moment) is
    const res = moments.find(({ text, id }) => text === gift.Moment);
    if (res) {
      setMomentID(res.id);
      setOriginalMoment("");
    } else {
      setMomentID("Custom");
      setOriginalMoment(DecodeLineBreak(gift.Moment));
    }
    setGiftSize(gift.Size);
    navigate("/create");
    pageChange("create/playlist");
    sceneChange("light");
    onClose();
  };

  const inputRef = useRef(null);

  const handleClickNew = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  // local state. we will then update the real state with "playName" prop
  const [thisName, setThisName] = useState("");

  // manages the svg shadow filter of the play button
  const [activeFilter, setActiveFilter] = useState(false);

  const handleNew = () => {
    setName(thisName);
    setDedication("");
    setMoment("");
    setMomentID("");
    setOriginalMoment("");
    setGiftId("");
    setCollection("");
    setSongs([]);
    setIcon(
      userAvatar
        ? "https://d1ytrwzciudool.cloudfront.net/Avatar/" + userAvatar
        : ""
    );
    setCover("");
    setFakeCover("");
    setFilenameIcon(userAvatar);
    setFilenameCover("");
    setGiftSize("");
    navigate("/size");
    pageChange("size");
    sceneChange("land");
    onClose();
  };

  const handleRemoveGift = (index) => {
    const updatedGiftList = [...giftList];
    updatedGiftList.splice(index, 1);
    setGiftList(updatedGiftList);
  };

  return (
    <YellowDialog opened={open} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          className="font-title2-Osw first-up-elem-spacer"
          style={{
            textAlign: "center",
            letterSpacing: "0.02em",
          }}
        >
          Tutto inizia da qui,
          <br />e ogni regalo da un nome
        </h1>

        {/* Render cards when giftList is not empty */}
        <AnimatePresence initial={false}>
          {giftList.length > 0 &&
            giftList.map((v, index) => (
              <motion.button
                key={v.ID_Gift}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2, delay: index * 0.08 }}
                className="transparentBtn transparentBtn-conditionalHv"
                onClick={() => handleClick(v)}
              >
                <ResumeCard
                  name={v.Name}
                  date={ParseDate(v.Modification_Date)}
                  thumb={v.Filename_Coverart}
                  size={v.Size}
                  userId={userId}
                  giftId={v.ID_Gift}
                  giftListIndex={index}
                  handleRemoveGift={handleRemoveGift}
                />
              </motion.button>
            ))}

          <motion.button
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="newGiftBtn"
            onClick={handleClickNew}
          >
            <span>
              <Card
                className={`pop-up-card-space-down ${
                  expanded ? "pop-card-expanded" : ""
                }`}
              >
                {expanded ? (
                  <>
                    <h1 className="new-gift-font1">Chi riceverà il regalo?</h1>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder=""
                          className="cream-background-color name-form"
                          style={{
                            border: "solid",
                            borderColor: "#2C2B26",
                            borderWidth: "0.25px",
                            borderRadius: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginRight: "10px",
                            marginLeft: "30px",
                            lineHeight: "normal",
                            color: "#F98050",
                          }}
                          value={thisName}
                          onChange={(e) => setThisName(e.currentTarget.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleNew();
                            }
                            if (e.key === " ") {
                              e.preventDefault();
                              setThisName((prevName) => prevName + " ");
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <button
                          className="transparentBtn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNew();
                          }}
                        >
                          {/* ioPlay from React Icons decomposition for filter application */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`ionicon ${
                              activeFilter ? "active-filter" : ""
                            } svg-play-name`}
                            viewBox="0 0 512 512"
                          >
                            <filter id="inset-shadow">
                              <feOffset dx="0" dy="0" />
                              <feGaussianBlur
                                stdDeviation="3"
                                result="offset-blur"
                              />
                              <feComposite
                                operator="out"
                                in="SourceGraphic"
                                in2="offset-blur"
                                result="inverse"
                              />
                              <feFlood
                                floodColor="#2C2B26"
                                floodOpacity=".8"
                                result="color"
                              />
                              <feComposite
                                operator="in"
                                in="color"
                                in2="inverse"
                                result="shadow"
                              />
                              <feComposite
                                operator="over"
                                in="shadow"
                                in2="SourceGraphic"
                              />
                            </filter>
                            <path
                              d="M133 440a35.37 35.37 0 01-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0135.77.45l247.85 148.36a36 36 0 010 61l-247.89 148.4A35.5 35.5 0 01133 440z"
                              fill="#F98050"
                            />
                          </svg>
                        </button>
                      </div>
                      <span className="resume-card-subtitle">
                        <br />
                        Se non lo sai scrivi pure "prova". È il nome
                        <br /> della bozza e potrai tornare a modificarlo
                      </span>
                    </div>
                  </>
                ) : (
                  <h1 className="new-gift-font1">Realizza un nuovo regalo!</h1>
                )}
              </Card>
            </span>
          </motion.button>
        </AnimatePresence>
      </div>
    </YellowDialog>
  );
}

export default PopGift;
