import { useState, useEffect, useContext, useMemo } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import md5 from "md5";
import { RequireAuth } from "./contexts/requireAuth";

import Header from "./layouts/header";
import Home from "./pages/home";
import ErrorPage from "./pages/errorPage";
import SignIn from "./pages/signin";
import Email from "./pages/email";
import Purchase from "./pages/purchase";
import Thanks from "./pages/thanks";
import Redeem from "./pages/redeem";
import Faq from "./pages/faq";
import {
  StyleOne,
  StyleCommit,
  StyleLive,
  StylePresale,
} from "./pages/payment";

import { coverArtOrdered as CoverNames } from "./pages/customize/cover";
import {
  newCollection,
  modifyCollection,
  newGift,
  modifyGift,
  getSongsData,
} from "./services/contentApi";
import { getImageUrl, uploadImage } from "./services/elementsApi";
import PrivacyPolicy from "./pages/legals/privacyPolicy";
import CookiePolicy from "./pages/legals/cookiePolicy";
import TermsAndConditions from "./pages/legals/termsAndConditions";
import Create from "./pages/create";
import SelectSize from "./pages/size";
import FeedbackPage from "./pages/feedback";

function Gift(props) {
  const [Page, setPage] = useState(
    localStorage.getItem("progress") !== null
      ? localStorage.getItem("progress")
      : ""
  );

  const [scene, setScene] = useState(
    localStorage.getItem("scene") !== null
      ? localStorage.getItem("scene")
      : "light"
  );

  const [ID_Gift, setGiftId] = useState(
    localStorage.getItem("giftId") !== null
      ? localStorage.getItem("giftId")
      : ""
  );

  const [Name, setName] = useState(
    localStorage.getItem("name") !== null ? localStorage.getItem("name") : ""
  );

  const [collectionType, setCollectionType] = useState("C"); // C = Custom, E = Editorial, A = Album / for now it's always C

  const [Songs, setSongs] = useState(
    localStorage.getItem("selection") !== null
      ? JSON.parse(localStorage.getItem("selection"))
      : []
  );

  const [ID_Collection, setCollection] = useState(
    localStorage.getItem("collection") !== null
      ? localStorage.getItem("collection")
      : ""
  );

  const [Dedication, setDedication] = useState(
    localStorage.getItem("dedication") !== null
      ? localStorage.getItem("dedication")
      : ""
  );

  const [MomentID, setMomentID] = useState(
    localStorage.getItem("momentID") !== null
      ? localStorage.getItem("momentID")
      : ""
  );

  //TEST_HELPER: to view the Purchase page fail massage after a refresh that don't happen in Customize
  // invert commented and not commented lines in this declaration
  const [Moment, setMoment] = useState(
    localStorage.getItem("moment") !== null
      ? localStorage.getItem("moment")
      : ""
    // ""
  );

  const [OriginalMoment, setOriginalMoment] = useState(
    localStorage.getItem("originalMoment") !== null
      ? localStorage.getItem("originalMoment")
      : ""
  );

  const [Cover, setCover] = useState(
    localStorage.getItem("coverLink") !== null
      ? localStorage.getItem("coverLink")
      : ""
  );

  const [FakeCover, setFakeCover] = useState(
    localStorage.getItem("coverLink") !== null &&
      localStorage.getItem("coverLink") !== ""
      ? localStorage.getItem("coverLink")
      : ""
  );

  const [FilenameCover, setFilenameCover] = useState(
    localStorage.getItem("coverName") !== null
      ? localStorage.getItem("coverName")
      : ""
  );

  const [moveToFirstSlide, setMoveToFirstSlide] = useState(false);

  const [Icon, setIcon] = useState(
    localStorage.getItem("iconLink") !== null
      ? localStorage.getItem("iconLink")
      : ""
  );

  const [FilenameIcon, setFilenameIcon] = useState(
    localStorage.getItem("iconName") !== null
      ? localStorage.getItem("iconName")
      : ""
  );

  const {
    user,
    ID_User,
    setUserAvatar,
    newStdAvatar,
    requestRefresh,
    userCredits,
    creditsExp,
    creditsSize,
  } = useContext(UserContext);

  const [GiverName, setGiverName] = useState(
    localStorage.getItem("giverName") || ""
  );

  const [signPage, setSignPage] = useState("");

  const [idBundle, setIdBundle] = useState(
    localStorage.getItem("idBundle") !== null
      ? localStorage.getItem("idBundle")
      : "0"
  );

  const [ChosenEmail, setChosenEmail] = useState(
    localStorage.getItem("chosenEmail") || ""
  );
  useEffect(() => {
    // console.log("user is", user);
    // console.log("ID_User is", ID_User);
    const email = user?.email || "";
    setChosenEmail(email);
    const giver = user?.given_name ?? user?.name ?? "";
    setGiverName(giver);
  }, [user, ID_User]);

  const [GiftSize, setGiftSize] = useState(
    localStorage.getItem("size") !== null && localStorage.getItem("size") !== ""
      ? localStorage.getItem("size")
      : "S"
  );

  const [selectMin, setSelectMin] = useState(3);
  const [selectMax, setSelectMax] = useState(10);

  const possibleDiscountedPrice = 7.99;

  // useEffect(() => {
  //   console.log("c.l.i.n.:", document.cookie.length);
  // }, []);

  const location = useLocation();

  //Redirect hook after sign-in and refresh
  let navigate = useNavigate();
  const [popAskNow, setPopAskNow] = useState(false); // to ask gift size and also the name
  useEffect(() => {
    if (
      !["/feelbox-feedback", "/termsandconditions", "/privacypolicy"].includes(
        location.pathname
      )
    ) {
      if (props.cookies.enrolling === "true") {
        if (
          Page !== "undefined" &&
          Page !== "signin" &&
          Page !== "createLogin" &&
          !Page.startsWith("create/")
        ) {
          let path = "/" + Page;
          navigate(path);
          setPage(path.slice(1)); // or in the meantime Page becames ""
        } else if (Page == "createLogin") {
          setName("(prova)");
          setPopAskNow(true);
          navigate("/create");
          setPage("create/playlist");
        } else if (Page.startsWith("create/")) {
          navigate("/create");
          setPage("create/playlist");
        } else {
          navigate("/");
        }
        setTimeout(() => {
          props.setCookie("enrolling", false, [
            {
              path: "/",
              expires: new Date(Date.now() + 60 * 60 * 24 * 60 * 1000 * 2),
            },
          ]);
        }, 2000);
      } else {
        if (Page.startsWith("create/")) {
          navigate("/create");
        } else if (
          // the following pages are the only ones, exept the root, to be accessible from direct url
          !["/faq", "/create", "/feelbox-feedback"].includes(location.pathname)
        ) {
          navigate("/");
        }
      }
    }
  }, []);

  // state used to redirect to thankspage after payment flow
  const [greet, setGreet] = useState(false);
  useEffect(() => {
    if (greet === true) {
      navigate("/thanks");
      setScene("light");
      setPage("thanks");
      setGreet(false); //note that it ritrigger the useEffect but not it's condition
    }
  }, [greet]);

  // LOCAL STORES UPDATES SECTION
  useEffect(() => {
    localStorage.setItem("progress", Page);
    localStorage.setItem("scene", scene);
  }, [Page, scene]);

  useEffect(() => {
    localStorage.setItem("selection", JSON.stringify(Songs));
  }, [Songs]);

  useEffect(() => {
    localStorage.setItem("giftId", ID_Gift);
  }, [ID_Gift]);

  useEffect(() => {
    localStorage.setItem("name", Name);
  }, [Name]);

  useEffect(() => {
    localStorage.setItem("collection", ID_Collection);
  }, [ID_Collection]);

  useEffect(() => {
    localStorage.setItem("dedication", Dedication);
  }, [Dedication]);

  useEffect(() => {
    localStorage.setItem("momentID", MomentID);
  }, [MomentID]);

  useEffect(() => {
    localStorage.setItem("moment", Moment);
  }, [Moment]);

  useEffect(() => {
    localStorage.setItem("originalMoment", OriginalMoment);
  }, [OriginalMoment]);

  useEffect(() => {
    localStorage.setItem("iconLink", Icon);
    localStorage.setItem("iconName", FilenameIcon);
  }, [Icon, FilenameIcon]);

  useEffect(() => {
    localStorage.setItem("coverLink", Cover);
    localStorage.setItem("coverName", FilenameCover);
  }, [Cover, FilenameCover]);

  useEffect(() => {
    localStorage.setItem("idBundle", idBundle);
  }, [idBundle]);

  useEffect(() => {
    localStorage.setItem("chosenEmail", ChosenEmail);
  }, [ChosenEmail]);

  useEffect(() => {
    localStorage.setItem("giverName", GiverName);
  }, [GiverName]);

  useEffect(() => {
    localStorage.setItem("size", GiftSize);
  }, [GiftSize]);

  // GIFT MANAGEMENT SECTION
  useEffect(() => {
    if (ID_Collection) {
      getSongsData(ID_Collection).then((res) => {
        setSongs(res.data.contacts);
      });
    }
  }, [ID_Collection]);

  useEffect(() => {
    if (Cover && !Cover.startsWith("http")) {
      if (CoverNames.includes(Cover)) {
        setFilenameCover(Cover);
      } else {
        blobUrlToBlobFile(Cover).then((newblob) => {
          const newCoverName =
            md5(new Date().getTime() + ID_User) +
            "." +
            newblob.type.split("/")[1];
          callUploadImage("Coverart", newCoverName, newblob).then((success) => {
            if (success) {
              // every time a new image is succesful uploaded on S3, we update the Cover Value and auto-save the Gift on the DB
              setFilenameCover(newCoverName);
              setCover(
                "https://d1ytrwzciudool.cloudfront.net/Coverart/" + newCoverName
              );
              setFakeCover(
                "https://d1ytrwzciudool.cloudfront.net/Coverart/" + newCoverName
              );
              setMoveToFirstSlide(true);
              if (ID_Gift) {
                callModifyGift(newCoverName, FilenameIcon);
              } else if (ID_User) {
                callNewGift(newCoverName, FilenameIcon);
              }
            } else {
              console.log("Upload failed"); //url is invalid or offline
              setCover(""); //so the user can't go forward
              setFakeCover(""); //so that the blob image became not selected and user could reselect it until an auto-save succeeds
              // note that the filename doesn't change, so that, if previously present, don't overwrites himself and can be reopened
            }
          });
        });
      }
    }
  }, [Cover]);

  useEffect(() => {
    if (Icon && !Icon.startsWith("http")) {
      blobUrlToBlobFile(Icon).then((newblob) => {
        const newIconName =
          md5(new Date().getTime() + ID_User) +
          "." +
          newblob.type.split("/")[1];
        callUploadImage("Avatar", newIconName, newblob).then((success) => {
          if (success) {
            // every time a new image is succesful uploaded on S3, we update the Icon Value and auto-save the Gift on the DB
            setFilenameIcon(newIconName);
            setIcon(
              "https://d1ytrwzciudool.cloudfront.net/Avatar/" + newIconName
            );
            if (ID_Gift) {
              callModifyGift(FilenameCover, newIconName);
            } else if (ID_User) {
              callNewGift(FilenameCover, newIconName);
            }
          } else {
            console.log("Upload failed"); //url is invalid or offline
            setIcon(""); //so the user can't go forward
            // note that the filename doesn't change, so that, if previously present, don't overwrites himself and can be reopened
          }
        });
      });
    }
  }, [Icon]);

  useEffect(() => {
    if (GiftSize == "S") {
      setSelectMin(3);
      setSelectMax(5);
    } else if (GiftSize == "M") {
      setSelectMin(6);
      setSelectMax(10);
    }
  }, [GiftSize]);

  const [isHandlingSave, setIsHandlingSave] = useState(false);
  const [saveFeedback, setSaveFeedback] = useState(null);

  const callModifyGift = async (nameCover, nameIcon) => {
    try {
      if (collectionType === "C") {
        // console.log("trying modifyCollection");
        // FIXME we re-upload the collection songs even if they don't change
        await modifyCollection(
          ID_Collection,
          Songs.map((i) => i.ID_Song).toString()
        );
      } else {
        // TODO pure Editorial (maybe) or pure Album case...the modified versions have to go on "C" case
        // this shoud be the case where we modifyt the ID_Collection but not the "pure" songs
        // but with the Editorial it's an Editorial responsibility to don't use same index for modified editorial playlists
        // think about it, or always go on case "C"
      }

      // console.log("trying modifyGift");
      var res = await modifyGift(
        ID_Gift,
        ID_User,
        Name,
        ID_Collection,
        GiftSize,
        Moment,
        Dedication,
        nameCover,
        nameIcon,
        GiverName,
        ChosenEmail,
        newStdAvatar
      );

      if (newStdAvatar === true) {
        setUserAvatar(nameIcon);
      }
    } finally {
      setIsHandlingSave(false);
      // console.log(res?.status, res);
      res?.status === 200
        ? setSaveFeedback("correct")
        : setSaveFeedback("error");
      setTimeout(() => {
        setSaveFeedback(null);
      }, 500);
    }
  };

  const callNewGift = async (nameCover, nameIcon) => {
    try {
      // console.log("trying CreateCollection", ID_Collection);
      var collection = await newCollection(
        Songs.map((i) => i.ID_Song).toString()
      );
      setCollection(collection.data);
      // console.log("trying CreateGift");
      var id = await newGift(
        ID_User,
        Name,
        collection.data,
        GiftSize,
        Moment,
        Dedication,
        nameCover,
        nameIcon,
        GiverName,
        ChosenEmail,
        newStdAvatar
      );
      // console.log("id.data is, ", id.data);
      setGiftId(id.data);
    } finally {
      setIsHandlingSave(false);
      id?.status === 200
        ? setSaveFeedback("correct")
        : setSaveFeedback("error");
      setTimeout(() => {
        setSaveFeedback(null);
      }, 1000);
    }
  };

  const callUploadImage = async (type, newName, blob) => {
    try {
      // console.log("Uploading " + type, newName);
      const coverUrl = await getImageUrl(type + "/" + newName);
      await uploadImage(coverUrl.data.presigned_url, await blob.arrayBuffer());
      return true;
    } catch (error) {
      return false;
    }
  };

  // SAVING FUNCTION
  const handleSave = async () => {
    if (isHandlingSave) {
      return;
    }

    setIsHandlingSave(true);

    if (ID_Gift) {
      await callModifyGift(FilenameCover, FilenameIcon);
    } else {
      await callNewGift(FilenameCover, FilenameIcon);
    }
  };

  const blobUrlToBlobFile = async (blobUrl) =>
    await fetch(blobUrl).then((r) => r.blob());

  //Activate the button to proceed in Customize
  const [customized, setCustomized] = useState(false);
  useMemo(() => {
    setCustomized(
      ID_User !== "" &&
        ID_User !== null &&
        ID_Gift !== "" &&
        Songs.length + 1 > selectMin &&
        Dedication !== "" &&
        Moment !== "" &&
        Icon !== "" &&
        Cover !== ""
    );
  }, [Songs, Dedication, Moment, Icon, Cover, selectMin]);

  //clear states after purchase
  const clearGift = () => {
    setName("");
    setDedication("");
    setMomentID("");
    setOriginalMoment("");
    setIcon("");
    setCover("");
    setFakeCover("");
    setFilenameIcon("");
    setFilenameCover("");
    setGiftId("");
  };

  //management of the acquisition message for active but not acquired users (shows from header)
  const [isOpenAcquisitionMsg, setIsOpenAcquisitionMsg] = useState(false);
  const [acquisitionMsg, setAcquisitionMsg] = useState("");

  const showAcquisitionMsg = () => {
    setIsOpenAcquisitionMsg(true);
    setTimeout(() => {
      setIsOpenAcquisitionMsg(false);
    }, 2700);
  };

  return (
    <>
      {/* <AnimatePresence mode="wait"> */}
      <Header
        onSave={handleSave}
        scene={scene}
        page={Page}
        setPage={setPage}
        setScene={setScene}
        signPage={signPage}
        setSignPage={setSignPage}
        fresh={requestRefresh}
        isHandlingSave={isHandlingSave}
        saveFeedback={saveFeedback}
        userCredits={userCredits}
        creditsExp={creditsExp}
        creditsSize={creditsSize}
        email={user?.email}
        canPay={customized}
        isOpenAcquisitionMsg={isOpenAcquisitionMsg}
        setIsOpenAcquisitionMsg={setIsOpenAcquisitionMsg}
        acquisitionMsg={acquisitionMsg}
        // if we want to clear it at logout, pass Gift fundamental states set methods
      />

      <Routes key={location.pathname} location={location}>
        <Route
          exact
          path="/signin"
          element={<SignIn sceneChange={setScene} position={Page} />}
        ></Route>
        {/* THIS IS THE REAL THANKSPAGE */}
        <Route exact path="/thanks" element={<Thanks />}></Route>
        <Route
          path="/"
          element={
            <Home
              setName={setName}
              setDedication={setDedication}
              setMomentID={setMomentID}
              setOriginalMoment={setOriginalMoment}
              setMoment={setMoment}
              setCollection={setCollection}
              sceneChange={setScene}
              pageChange={setPage}
              userId={ID_User}
              setSongs={setSongs}
              setIcon={setIcon}
              setCover={setCover}
              setFakeCover={setFakeCover}
              setGiftId={setGiftId}
              setFilenameIcon={setFilenameIcon}
              setFilenameCover={setFilenameCover}
              setGiverName={setGiverName}
              setGiftSize={setGiftSize}
            />
          }
        ></Route>
        <Route
          exact
          path="/size"
          element={
            <RequireAuth>
              <SelectSize
                setGiftSize={setGiftSize}
                sceneChange={setScene}
                pageChange={setPage}
              />
            </RequireAuth>
          }
        ></Route>
        <Route
          // exact
          path="/create"
          element={
            // <RequireAuth>
            <Create
              name={Name}
              dedication={Dedication}
              onDedication={setDedication}
              onMoment={setMoment}
              momentID={MomentID}
              onMomentID={setMomentID}
              originalMoment={OriginalMoment}
              onOriginalMoment={setOriginalMoment}
              sceneChange={setScene}
              cover={Cover}
              setCover={setCover}
              fakeCover={FakeCover}
              setFakeCover={setFakeCover}
              avatar={Icon}
              onAvatar={setIcon}
              complete={customized}
              page={Page}
              pageChange={setPage}
              onSave={handleSave}
              giverName={GiverName}
              setGiverName={setGiverName}
              moment={Moment}
              songs={Songs}
              setSongs={setSongs}
              setSignPage={setSignPage}
              setFilenameCover={setFilenameCover}
              userId={ID_User}
              showAcquisitionMsg={showAcquisitionMsg}
              setAcquisitionMsg={setAcquisitionMsg}
              giftSize={GiftSize}
              setGiftSize={setGiftSize}
              selectMin={selectMin}
              selectMax={selectMax}
              popAskNow={popAskNow}
              setPopAskNow={setPopAskNow}
              moveToFirstSlide={moveToFirstSlide}
              setMoveToFirstSlide={setMoveToFirstSlide}
            />
            // </RequireAuth>
          }
        ></Route>
        <Route
          exact
          path="/email"
          element={
            <RequireAuth>
              <Email
                sceneChange={setScene}
                name={Name}
                chosenEmail={ChosenEmail}
                setChosenEmail={setChosenEmail}
                pageChange={setPage}
                onNext={handleSave}
              />
            </RequireAuth>
          }
        ></Route>
        <Route
          exact
          path="/cart"
          element={
            <RequireAuth>
              <Purchase
                progress={Page}
                sceneChange={setScene}
                name={Name}
                thumbnail={Cover}
                user={ID_User}
                pageChange={setPage}
                setIdBundle={setIdBundle}
                gift={{
                  id: ID_Gift,
                  songs: Songs,
                  dedication: Dedication,
                  moment: Moment,
                  cover: Cover,
                  avatar: Icon,
                }}
                possibleDiscountedPrice={possibleDiscountedPrice}
                giftSize={GiftSize}
                chosenEmail={ChosenEmail}
              />
            </RequireAuth>
          }
        ></Route>
        <Route
          exact
          path="/thankspage/one/"
          element={
            <StyleOne
              idGift={ID_Gift}
              userId={ID_User}
              songs={Songs}
              setGreet={setGreet}
              clearGift={clearGift}
              email={ChosenEmail}
              name={Name}
              price={GiftSize == "M" ? 9.99 : 5.99}
              giftSize={GiftSize}
            />
          }
        ></Route>
        <Route
          exact
          path="/thankspage/E/"
          element={
            <StyleCommit
              idGift={ID_Gift}
              userId={ID_User}
              songs={Songs}
              setGreet={setGreet}
              clearGift={clearGift}
              email={ChosenEmail}
              name={Name}
              price={GiftSize == "M" ? 16.99 : 10.99}
              bundleType={"E"}
              giftSize={GiftSize}
            />
          }
        ></Route>
        <Route
          exact
          path="/thankspage/L/"
          element={
            <StyleCommit
              idGift={ID_Gift}
              userId={ID_User}
              songs={Songs}
              setGreet={setGreet}
              clearGift={clearGift}
              email={ChosenEmail}
              name={Name}
              price={GiftSize == "M" ? 29.99 : 19.99}
              bundleType={"L"}
              giftSize={GiftSize}
            />
          }
        ></Route>
        <Route
          exact
          path="/thankspage/pointfive/"
          // because less than "one"
          element={
            <StyleOne
              idGift={ID_Gift}
              userId={ID_User}
              songs={Songs}
              setGreet={setGreet}
              clearGift={clearGift}
              email={ChosenEmail}
              name={Name}
              price={possibleDiscountedPrice}
              giftSize={GiftSize}
            />
          }
        ></Route>
        <Route
          exact
          path="/thankspage/C"
          element={
            <StyleLive
              idGift={ID_Gift}
              userId={ID_User}
              giftSize={GiftSize}
              bundle={idBundle}
              songs={Songs}
              setGreet={setGreet}
              clearGift={clearGift}
              email={ChosenEmail}
              name={Name}
            />
          }
        ></Route>
        <Route
          exact
          path="/thankspage/PS"
          element={
            <StylePresale
              setGreet={setGreet}
              email={ChosenEmail}
              bundleType={"M"}
            />
          }
        ></Route>
        {/* <Route path="/*" element={<ErrorPage />}></Route> */}
        <Route
          exact
          path="/redeem"
          element={
            <RequireAuth>
              <Redeem setScene={setScene} />
            </RequireAuth>
          }
        ></Route>
        <Route exact path="/faq" element={<Faq setScene={setScene} />}></Route>
        <Route
          exact
          path="/feelbox-feedback"
          element={<FeedbackPage setScene={setScene} />}
        ></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/cookiepolicy" element={<CookiePolicy />}></Route>
        <Route
          path="/termsandconditions"
          element={<TermsAndConditions />}
        ></Route>
      </Routes>
      {/* </AnimatePresence> */}
    </>
  );
}

export default Gift;
