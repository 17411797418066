import { userFeedbacks } from "../../data/userFeedbacks";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/localStyle/feedbacksCarousel.css";
import "../../assets/localStyle/cursor.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function Feedbacks(props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex);
    // console.log(swiper.realIndex);
  };

  const showEndCharAt = userFeedbacks.length;

  return (
    <div
      className="yellow-band-section d-flex flex-column justify-content-center"
      style={{ position: "relative" }}
    >
      <div
        className="quote-marks quote-top"
        style={{
          opacity: activeSlideIndex === 0 ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
      >
        “
      </div>
      {/* <div
        className="quote-marks quote-bottom"
        style={{
          opacity: activeSlideIndex === showEndCharAt - 2 ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
      >
        ”
      </div> */}
      <div className="feedback-phone-margin">
        <Swiper
          slidesPerView={props.isMobile ? 1 : 2}
          spaceBetween={2}
          centeredSlidesBounds={true}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          slidesOffsetBefore={props.isMobile ? 30 : 220}
          slidesOffsetAfter={props.isMobile ? 0 : 80}
          onSlideChange={handleSlideChange}
        >
          {userFeedbacks.map((feedback) => (
            <SwiperSlide key={feedback.id}>
              <div className="feedback-slide">
                <div className="feedback-card">
                  <p className="font-feedback seafoam-selection">
                    {feedback.feedback}
                  </p>
                  <p className="font-feedback-who seafoam-selection">
                    {feedback.person}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <button className="transparentBtn">
            <IoIosArrowBack className="feedback-swiper swiper-button-prev" />
          </button>
          <button className="transparentBtn">
            <IoIosArrowForward className="feedback-swiper swiper-button-next" />
          </button>
        </Swiper>
      </div>
    </div>
  );
}

export default Feedbacks;
