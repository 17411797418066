import SizeChoice from "../../components/common/sizeChoice";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";

function PopSize({ open, setOpenPopSize, setPopAskNow, setGiftSize }) {
  const handleClick = (size) => {
    if (size == "5,99") {
      setGiftSize("S");
    } else setGiftSize("M");
    setOpenPopSize(false);
    setPopAskNow(false);
  };

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogContent
        className="phone-pop-offer mid-pop-offer d-flex flex-column justify-content-around align-items-center flex-wrap"
        style={{
          width: "1600px",
          height: "800px",
          backgroundColor: "#FCE868",
          padding: "0px",
          paddingTop: "44px",
        }}
      >
        <SizeChoice handleClick={handleClick} />
      </DialogContent>
    </Dialog>
  );
}

export default PopSize;
