import { useEffect, useState } from "react";
import { Switch } from "@mantine/core";
import { motion, animate } from "framer-motion";
import { useCookieState } from "use-cookie-state";

import Compose from "./customize/compose";
import Dedication from "./customize/dedication";
import Moment from "./customize/moment";
import Cover from "./customize/cover";
import Avatar from "./customize/avatar";
import Mockup from "../components/mockup/mockup";
import NavCheckBar from "../components/navCheckBar";
import { isMobile } from "./../utils/isMobile";
import PopSize from "./popups/popSize";

const Create = (props) => {
  const deviceMobile = isMobile();
  const [toggle, setToggle] = useState(!deviceMobile);
  const [openPopSize, setOpenPopSize] = useState(props.popAskNow);
  const [onTopActivity, setOnTopActivity] = useState(false);

  useEffect(() => {
    props.sceneChange("light");
  }, []);

  const handleNavBar = (page) => {
    props.pageChange("create/" + page);
    if (props.userId) {
      props.onSave();
    }
  };

  const [justAddedSong, setJustAddedSong] = useState(false);
  useEffect(() => {
    if (justAddedSong === true) {
      setTimeout(() => {
        setJustAddedSong(false);
      }, 1000);
    }
  }, [justAddedSong]);

  const playlistStarterPoint = -125;
  const songSlidingSpace = 27;
  const [songsSpace, setSongsSpace] = useState(
    props.songs.length * -songSlidingSpace
  );

  const roomTranslateY = -340;
  const roomOpacity = 0;
  const [tuneTranslateY, setTuneTranslateY] = useState(playlistStarterPoint);
  const [dedicationScale, setDedicationScale] = useState(1);
  const [dedicationOpacity, setDedicationOpacity] = useState(1);
  const [momentScale, setMomentScale] = useState(0);
  const [momentOpacity, setMomentOpacity] = useState(0);

  useEffect(() => {
    setSongsSpace(props.songs.length * -songSlidingSpace);
  }, [props.songs]);

  const playlistScroll = () => {
    setTuneTranslateY(playlistStarterPoint + songsSpace);
  };

  useEffect(() => {
    playlistScroll();
  }, [songsSpace]);

  useEffect(() => {
    // when going back history
    if (!props.page.startsWith("create")) {
      props.pageChange("create/playlist");
    }
  }, []);

  function useRemove(song) {
    var selected = props.songs.filter((m) => m.ID_Song !== song.ID_Song);
    props.setSongs(selected);
  }

  //HIGHER ORDER AUDIO MANAGEMENT
  const [currentSong, setCurrentSong] = useState("");
  const [volume, setVolume] = useState(75);

  //ACTIVE AUDIO MANAGEMENT (hire due to mockup higher order relative to compose)
  const [songUrl, setSongUrl] = useState("");
  const [songPlaying, setSongPlaying] = useState("");
  const [songStart, setSongStart] = useState(45);

  function handlePlay(item) {
    if (props.userId) {
      setSongUrl(
        "https://song.tunehey.com/songs/" + item.Storage_Preview + ".mp3"
      );
      setCurrentSong(item);
      setSongStart(item.Preview_Start);
      setSongPlaying(item.Storage_Preview);
    } else {
      props.setAcquisitionMsg("Accedi per poter ascoltare e salvare");
      props.showAcquisitionMsg(true);
    }
  }

  function handlePause() {
    setSongPlaying("");
    setSongUrl("");
    // this last line introduces a regression:
    // like in popList this will pause the music, but
    // reclicking play the song will restart from the original previewStart
    // and not from where it was interrupted
    // 1. Business think about the feature need (or not). 2. Manage the seconds of same song session.
  }
  // -----------------------

  // COMPOSE TOUR MANAGEMENT
  const [tuTourEd, setTuTourEd] = useCookieState("tuTourEd", false, {
    path: "/",
    expires: new Date(Date.now() + 60 * 60 * 24 * 60 * 1000 * 2), // Two months in ms
  });

  return (
    <div className="cream-background-color">
      <PopSize
        open={openPopSize}
        setOpenPopSize={setOpenPopSize}
        setPopAskNow={props.setPopAskNow}
        setGiftSize={props.setGiftSize}
      />
      {deviceMobile && toggle && (
        <div
          className="dark-overlay"
          onClick={() => setToggle(!toggle)}
          style={{ zIndex: 800 }}
        ></div>
      )}
      <div className="navCheckBar-container">
        <NavCheckBar
          handleNavBar={handleNavBar}
          steps={[
            props.songs.length + 1 > props.selectMin,
            props.dedication !== "",
            props.moment !== "" && props.momentID !== "",
            props.cover == !"" || props.fakeCover !== "",
            props.avatar !== "",
          ]}
          playlistScroll={playlistScroll}
          setTuneTranslateY={setTuneTranslateY}
          setDedicationScale={setDedicationScale}
          setDedicationOpacity={setDedicationOpacity}
          setMomentScale={setMomentScale}
          setMomentOpacity={setMomentOpacity}
        />
      </div>
      <div className="exact-page-net-header exact-page-net-header-navbar">
        {props.page === "create/playlist" && (
          <Compose
            sceneChange={props.sceneChange}
            songs={props.songs}
            setSongs={props.setSongs}
            setJustAddedSong={setJustAddedSong}
            currentSong={currentSong}
            setCurrentSong={setCurrentSong}
            volume={volume}
            setVolume={setVolume}
            songUrl={songUrl}
            songPlaying={songPlaying}
            songStart={songStart}
            handlePlay={handlePlay}
            handlePause={handlePause}
            giftSize={props.giftSize}
            setGiftSize={props.setGiftSize}
            selectMin={props.selectMin}
            selectMax={props.selectMax}
            showAcquisitionMsg={props.showAcquisitionMsg}
            setAcquisitionMsg={props.setAcquisitionMsg}
            tuTourEd={tuTourEd}
            setTuTourEd={setTuTourEd}
          />
        )}
        {props.page === "create/dedication" && (
          <Dedication
            name={props.name}
            dedication={props.dedication}
            onDedication={props.onDedication}
            isMobile={deviceMobile}
          />
        )}
        {props.page === "create/moment" && (
          <Moment
            onMoment={props.onMoment}
            momentID={props.momentID}
            onMomentID={props.onMomentID}
            originalMoment={props.originalMoment}
            onOriginalMoment={props.onOriginalMoment}
          />
        )}
        {props.page === "create/cover" && (
          <Cover
            cover={props.cover}
            setCover={props.setCover}
            fakeCover={props.fakeCover}
            setFakeCover={props.setFakeCover}
            setOnTopActivity={setOnTopActivity}
            deviceMobile={deviceMobile}
            moveToFirstSlide={props.moveToFirstSlide}
            setMoveToFirstSlide={props.setMoveToFirstSlide}
          />
        )}
        {props.page === "create/avatar" && (
          <Avatar
            avatar={props.avatar}
            onAvatar={props.onAvatar}
            giverName={props.giverName}
            onGiverName={props.setGiverName}
            setOnTopActivity={setOnTopActivity}
          />
        )}
      </div>

      <motion.div
        className="switch-container"
        drag // Enable dragging
        dragConstraints={{
          left: -window.innerWidth + 110, // Minimum distance from the left
          right: 10, // Maximum distance from the right
          top: -window.innerHeight + 150, // Minimum distance from the top
          bottom: 70, // Maximum distance from the bottom
        }}
        style={{
          bottom: 90,
          right: 30,
          // display: onTopActivity ? "none" : "block",
        }} // Initial position
      >
        <Switch
          className={`${deviceMobile && !toggle && justAddedSong ? "hey" : ""}
           ${
             props.songs.length === 10 && props.page === "create/playlist"
               ? "alt"
               : ""
           }`} //note that alt do nothing for now
          size="lg"
          color="#FCE868"
          checked={toggle}
          onChange={() => setToggle(!toggle)}
          thumbIcon={
            props.page === "create/playlist" ? (
              <span
                style={{
                  color: `${
                    toggle
                      ? "#FFFDEF"
                      : props.songs.length < props.selectMin
                      ? "#2C2B26"
                      : props.songs.length < props.selectMax
                      ? "#F98050"
                      : "#FFFDEF"
                  }`,
                  fontFamily: "Oswald",
                  fontWeight: "500",
                  fontSize: "12px",
                }}
              >
                {props.songs.length}
              </span>
            ) : (
              <></>
            )
          }
        />
      </motion.div>

      <motion.div
        className="preview-container"
        initial={{ opacity: toggle ? 1 : 0 }}
        animate={{ opacity: toggle ? 1 : 0 }}
        transition={{ duration: 0.12 }}
        style={{ pointerEvents: toggle ? "auto" : "none" }}
      >
        <Mockup
          roomOpacity={roomOpacity}
          dedicationOpacity={dedicationOpacity}
          momentOpacity={momentOpacity}
          dedicationScale={dedicationScale}
          momentScale={momentScale}
          roomTranslateY={roomTranslateY}
          tuneTranslateY={tuneTranslateY}
          giverName={props.giverName}
          moment={props.moment}
          dedication={props.dedication}
          avatar={props.avatar}
          cover={props.cover}
          songs={props.songs}
          setSongs={props.setSongs}
          removeSong={useRemove}
          isInteractive={true}
          smoothTargets={true}
          tuneFooterVisible={false}
          playingSong={songPlaying}
          onPlay={handlePlay}
          onPause={handlePause}
          isFrameHidden={deviceMobile}
        />
      </motion.div>

      {onTopActivity && (
        <motion.div
          style={{
            position: "fixed",
            top: "0",
            backgroundColor: "#FFFDEF",
            opacity: "0.6",
            zIndex: "2",
            height: "100%",
            width: "100%",
          }}
        />
      )}
    </div>
  );
};

export default Create;
