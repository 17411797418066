import React from "react";

function Dedication({ name, dedication, onDedication, isMobile }) {
  const maxChars = 230;

  return (
    <div className="custom-page-int-up custom-page-int-up-phone-corr custom-page-int-up-process">
      <br className="no-desktop" />
      {/* PRESENTATION AREA */}
      <div className="centered-title-phone centered-process-pd-right">
        <h1 className="font-title0-Osw">
          Aggiungi una <span className="yellow-text-background">dedica </span>
        </h1>
        <h2 className="font-subT1-Osw font-subT1-Osw-custom-phone">
          {/* <span className="green-text-background cream-color"> */}
          che resterà sempre accanto alla <br className="phone" />
          musica che hai scelto
        </h2>
      </div>
      <br className="desktop" />
      <br className="desktop" />
      <br />
      <div className="centered-group-phone centered-process-pd-right">
        {/* ACTION AREA */}
        <div className="dedication-feature-container">
          <textarea
            className="form-control dedication dedication-textarea phone-dedication "
            style={{
              backgroundColor: "#F1E8D5",
              resize: "none",
            }}
            placeholder="Scrivi qui il tuo messaggio"
            id="Dedica"
            rows={!isMobile ? "5" : "9"}
            maxLength={maxChars}
            onChange={(e) => onDedication(e.currentTarget.value)} //-->S.S.O.T.
            value={dedication} //S.S.O.T.
          />
          {/* counter */}
          <span
            className="counter-font"
            style={{
              color: `${
                dedication.length > maxChars - 100 ? "#2C2B26" : "#B8AA91"
              }`,
            }}
          >
            {dedication.length > 0 ? `${maxChars - dedication.length}` : ""}
          </span>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default React.memo(Dedication);
