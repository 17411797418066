import SizeCard from "./sizeCard";

function SizeChoice({ handleClick, onlyCards = false }) {
  return (
    <>
      {!onlyCards && (
        <div style={{ textAlign: "center" }}>
          <h2 className="font-title2-Osw">
            Puoi scegliere
            <br className="phone" /> tra 2 opzioni
          </h2>
        </div>
      )}
      <div className="d-flex flex-row flex-wrap justify-content-center">
        <SizeCard
          tuneType=""
          subtitle="Un pensiero semplice e completo"
          infosize="da 3 a 5 canzoni"
          price="5,99"
          clickHandler={handleClick}
          active={!onlyCards}
        />
        <span className="phone" style={{ width: "100%", height: "30px" }} />
        <SizeCard
          tuneType="Maxi"
          subtitle="Un regalo con più musica"
          infosize="da 6 a 10 canzoni"
          price="9,99"
          clickHandler={handleClick}
          active={!onlyCards}
        />
      </div>
      {!onlyCards && (
        <div>
          <br />
          <span className="phone" style={{ width: "100%", height: "5px" }} />
          <br />
        </div>
      )}
    </>
  );
}

export default SizeChoice;
