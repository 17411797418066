import React from "react";
import { default as Acc } from "react-bootstrap/Accordion";
import "../../assets/localStyle/accordion.css";

// THIS REACT-BOOTSTRAP PRIMITIVE INTERNALLY MANAGE THE OnSelect FUNCTION & THE ActiveEventKey
// we tried the defaultActiveKey prop but it didn't work. test it for other implementations
function Accordion({ file, color = "beije" }) {
  return (
    <Acc
      className={`orange-selection-all ${
        color === "beije" ? "accordion-beije" : "accordion-yellow"
      }`}
    >
      {file.map((item, index) => (
        <React.Fragment key={index}>
          {item.answer && (
            <Acc.Item style={{ fontFamily: "Work Sans" }} eventKey={index}>
              <Acc.Header
                className={`${
                  color === "beije"
                    ? "accordion-header-beije"
                    : "accordion-header-yellow"
                }`}
              >
                {item.question}
              </Acc.Header>
              <Acc.Body>{item.answer}</Acc.Body>
            </Acc.Item>
          )}
        </React.Fragment>
      ))}
    </Acc>
  );
}

export default Accordion;
