import { useEffect, useState } from "react";
import { Carousel } from "@mantine/carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function BoxesCarousel(props) {
  const [embla, setEmbla] = useState(null);

  const newItems = [...props.originals, ...props.items];

  useEffect(() => {
    if (props.moveToFirstSlide == true && embla) {
      embla.scrollTo(0);
      props.setMoveToFirstSlide(false);
    }
  }, [props.moveToFirstSlide]);

  return (
    <>
      <Carousel
        styles={{
          control: {
            "&[data-inactive]": {
              opacity: 0,
              cursor: "default",
            },
          },
        }}
        className="carousel-outer-size"
        slideSize={`${100 / props.numberOf}%`}
        slideGap="md"
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideGap: "0" },
        ]}
        skipSnaps={true}
        slidesToScroll={1}
        controlSize={40}
        // containScroll="keepSnaps"
        align={props.deviceMobile ? "center" : "start"}
        getEmblaApi={setEmbla}
      >
        {newItems.map((item) => (
          <Carousel.Slide key={item}>
            <button
              onClick={() => {
                props.onSelect(item);
                props.onFakeSelect(item);
              }}
              className="cover-slide-default"
              style={{
                borderColor:
                  item === props.fakeSelected ? "#F98050" : "transparent",
              }}
            >
              {item.startsWith("http") || item.startsWith("blob") ? (
                <LazyLoadImage
                  src={item}
                  alt=""
                  className="carousel-cover-inner-size"
                  style={{ borderRadius: 10 }}
                  threshold={500}
                />
              ) : (
                <img
                  src={require(`../../assets/cover/${item}`)}
                  alt=""
                  className="carousel-cover-inner-size"
                  style={{
                    borderRadius: 10,
                  }}
                />
              )}
            </button>
          </Carousel.Slide>
        ))}
      </Carousel>
      <br />
    </>
  );
}
