import React, { useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/system";
import {
  IoVolumeOffOutline,
  IoVolumeLowOutline,
  IoVolumeMediumOutline,
  IoVolumeHighOutline,
} from "react-icons/io5";
import { isMobile } from "../../utils/isMobile";

const VolumeBar = styled(Slider)({
  color: "#FFFCEB",
  height: 5,
  borderRadius: "2px",
  opacity: 1,
  position: "relative",
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#FCE868",
    borderRadius: "2px",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 4,
    borderRadius: "0",
    backgroundColor: "#fffdef",
    border: "1px solid currentColor",
    transition: "box-shadow 0.3s",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
});

const VolumeIcon = ({ volume }) => {
  if (volume === 0) {
    return <IoVolumeOffOutline color="#FFFDEF" fontSize={"24px"} />;
  } else if (volume < 30) {
    return <IoVolumeLowOutline color="#FFFDEF" fontSize={"24px"} />;
  } else if (volume < 70) {
    return <IoVolumeMediumOutline color="#FFFDEF" fontSize={"24px"} />;
  } else {
    return <IoVolumeHighOutline color="#FFFDEF" fontSize={"24px"} />;
  }
};

export default function VolumeControl({ volume, setVolume }) {
  const deviceMobile = isMobile();

  const handleChange = (event, newValue) => {
    setVolume(newValue);
    // Add logic to control the volume in your audio player
  };

  return (
    !deviceMobile && (
      <div className="d-flex flex-row align-items-center">
        <VolumeIcon volume={volume} />
        <div className="volume-control-container">
          <VolumeBar
            value={volume}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-label="pretto slider"
          />
        </div>
      </div>
    )
  );
}
