import HowTunehey from "./how";
import FaqSection from "./faqSection";
import Artists from "./artists";
import Pricing from "./pricing";

function SlidingSection() {
  return (
    <div
      className="sliding-section"
      style={{ zIndex: 100, position: "relative" }}
    >
      <HowTunehey />
      <Pricing />
      <FaqSection />
      <Artists />
    </div>
  );
}

export default SlidingSection;
