export function FindArtistSale(songs) {

    const ArtistSale = [
        // "Davide Scavolini",
    ]; //array of the current artist with active sales
    
    let found = false;
    for (let song of songs) {
        if (ArtistSale.includes(song.Stage_Name)) {
            found = true;
            break;
        }
    };

    return found;
}

export function FindArtistCredit(songs) {

    const ArtistCredit = ["Mani",]; //array of the current artist with credit related
    
    let found = true;
    for (let song of songs) {
        if (!ArtistCredit.includes(song.Stage_Name)) {
            found = false;
            break;
        }
    };

    return found;
}