const stripePL = {
    "links":[
      {"one":"https://buy.stripe.com/8wMdS437U77wgPS6oo", 
      "explorer":"https://buy.stripe.com/aEU9BO0ZM1Nc57a3cd", 
      "lifestyle":"https://buy.stripe.com/fZe29mdMy1NcczC6oq",
      "minione":"https://buy.stripe.com/6oE7tGbEq9fE1UY6ou", 
      "miniexplorer":"https://buy.stripe.com/3cs7tG7oa3Vkbvy6ov", 
      "minilifestyle":"https://buy.stripe.com/5kA8xK23Q9fEeHKaEM",
      "sale8":"https://buy.stripe.com/fZe01e6k63Vk7fi3cf",
      "sale7":"https://buy.stripe.com/3cs5lyfUGfE2bvy9AF",
    }
    ]
}

export default stripePL;