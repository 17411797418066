import React, { useState } from "react";
import { DropCrop } from "../../components/dragNCropCover";
import BoxesCarousel from "../../components/common/carousel";

function Cover({
  cover,
  setCover,
  fakeCover,
  setFakeCover,
  setOnTopActivity,
  deviceMobile,
  moveToFirstSlide,
  setMoveToFirstSlide,
}) {
  const [focusOtherAction, setFocusOtherAction] = useState(false);

  const handleFocusActionTop = (bool) => {
    setFocusOtherAction(bool);
    setOnTopActivity(bool);
  };

  //While Cover is the SSOT (Single Source of Truth), fakeCover "iterates" over the array composed
  //of localCovCrop and coverArtOrdered in the <BoxesCarousel /> to visually select the corresponding item.
  //Note that localCovCrop initializes with an original cover if present, but it will maintain a maximum of the last two (2 is okay?)
  //user-uploaded images as blob files due to the set method passed and applied with 2 local state concatenations in <DropCrop />.
  //These files can then always be matched by fakeCover as mentioned above.
  let [localCovCrop, setLocalCovCrop] = useState(
    coverArtOrdered.includes(cover) || cover === undefined || cover === ""
      ? []
      : [cover]
  );

  //Note: This implementation has a limitation that every time we select an original image (e.g., switching from the two in memory),
  //we upload a new image to the S3 bucket, even when selecting a previously deselected image.
  //However, this is a corner case of a corner case(uploading more than 1 photo), which is a limit but not a priority.
  //When an original image is passed to children as a CloudFront URL, it is instead immutable.

  return (
    <>
      <div className="custom-page-int-up custom-page-int-up-phone-corr custom-page-int-up-process min-short-page">
        <br className="no-desktop" />
        {!focusOtherAction ? (
          <div className="centered-title-phone centered-process-pd-right">
            <h1 className="font-title0-Osw">
              Scegli un'{" "}
              <span className="yellow-text-background">immagine</span>{" "}
              <br className="phone" />
              che vi rappresenta
            </h1>
            <h2 className="font-subT1-Osw font-subT1-Osw-custom-phone">
              per evocare ricordi piacevoli
            </h2>
            {/* <h5 className="font-subT2-Osw">
        (nota: assicurandoti che non sia protetta da copiright!)
      </h5> */}
            <br className="desktop midsize" />
            <span className="phone" style={{ height: "16px" }} />
          </div>
        ) : (
          <>
            <h2 className="font-subT1-Osw font-subT1-Osw-custom-phone top-action">
              Ritaglia l'immagine e clicca conferma
            </h2>
            <br className="" />
          </>
        )}

        <div className="dropcrop-cover-flexprop centered-process-pd-right dropcrop-avatar-outer-container top-action">
          <DropCrop
            shape="rect"
            grid={true}
            cta="Conferma"
            onCrop1={setLocalCovCrop}
            onCrop2={setCover}
            onCrop3={setFakeCover}
            makeSpaceForMe={handleFocusActionTop}
          />
        </div>
        <br />
      </div>
      <div style={{ paddingLeft: deviceMobile ? "0px" : "7vw" }}>
        <BoxesCarousel
          items={coverArtOrdered}
          originals={localCovCrop}
          onSelect={setCover}
          fakeSelected={fakeCover}
          onFakeSelect={setFakeCover}
          sides={200}
          numberOf={3}
          deviceMobile={deviceMobile}
          moveToFirstSlide={moveToFirstSlide}
          setMoveToFirstSlide={setMoveToFirstSlide}
        />
      </div>
    </>
  );
}

export default React.memo(Cover);

export const coverArtOrdered = [
  "mountains1.png",
  "birthday1.png",
  "wavesun.png",
  "mates.png",
  "couple.png",
  "road.png",
  "group.png",
  "trip.png",
  // "christmas1.png",
  // "valentine.png",
  "cat.png",
  "cloud.png",
  "lovemate2.png",
  // "christmas2.png",
  // "christmas3.png",
  "seaship.png",
  "world.png",
  // "lovemate1.png",
  "rings.png",
  "graduation.png",
  "baby.png",
];
