import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  purchaseGift,
  purchaseGiftWithCredit,
  purchaseCredit,
} from "../services/contentApi";
import {
  sendPurchaseEmail,
  sendCreditPurchaseEmail,
} from "../services/elementsApi";

// THIS COMPONENT IS A COLLLECTION OF FUNCTIONS THAT:
// ---AFTER THE PAYMENT REDIRECT, DO THE PURCHASE ON OUR BACKEND
// -------------FACT1: every TYPE of purchase has his function
// -------------FACT2: every purchase (inside his type) has it's own route from Gift, since with stripe links we can't pass parameter
// -------------(yes, we came here from stripe redirects, apart from credits made purchase. We will then upgrade with "Stripe Checkout")
// ---"REDIRECTS" USER TO THANKSPAGE
// -------------(actually is the AWS redirect that redirects user to Gift but there we prepare from here a Thanks redirect )

//CASE 1: the style with ONE SHOT purchase
//standard or with sale (from the "pointfive" route where we overwrite the price prop)
export const StyleOne = ({
  idGift,
  price,
  userId,
  giftSize,
  songs,
  setGreet,
  clearGift,
  email,
  name,
}) => {
  useEffect(() => {
    const idSongs = songs.map((i) => i.ID_Song).toString();
    const songString = songs
      .map((song) => `${song.Title} - ${song.Stage_Name}`)
      .join(",");
    purchaseGift(idGift, price, "EUR", userId, "", giftSize, idSongs).then(
      (res) => {
        if (res.data.length == 6) {
          sendPurchaseEmail(res.data, email, name, songString);
        }
      }
    );
    setGreet(true);
    clearGift();
  }, []);
  return <div className="exact-page-net-header cream-background-color"></div>;
};

//CASE2: The style of purchase to identify the COMMITMENT of the user to other gifts! (= a bundle offer is purchased)
export const StyleCommit = ({
  idGift,
  userId,
  songs,
  setGreet,
  clearGift,
  email,
  name,
  price,
  bundleType,
  giftSize,
}) => {
  useEffect(() => {
    const idSongs = songs.map((i) => i.ID_Song).toString();
    const songString = songs
      .map((song) => `${song.Title} - ${song.Stage_Name}`)
      .join(",");
    purchaseGift(
      idGift,
      price,
      "EUR",
      userId,
      bundleType,
      giftSize,
      idSongs
    ).then((res) => {
      if (res.data.length == 6) {
        sendPurchaseEmail(res.data, email, name, songString);
      }
    });
    setGreet(true);
    clearGift();
  }, []);
  return <div className="exact-page-net-header cream-background-color"></div>;
};

//CASE3: the style of purchase WITH CREDIT, where the user is LIVING his commitment to gift more!
export const StyleLive = ({
  idGift,
  userId,
  giftSize,
  bundle,
  songs,
  setGreet,
  clearGift,
  email,
  name,
}) => {
  let navigate = useNavigate();
  useEffect(() => {
    const idSongs = songs.map((i) => i.ID_Song).toString();
    const songString = songs
      .map((song) => `${song.Title} - ${song.Stage_Name}`)
      .join(",");
    purchaseGiftWithCredit(idGift, userId, bundle, giftSize, idSongs).then(
      (res) => {
        setGreet(res.data.length == 6);
        if (res.data.length == 6) {
          clearGift();
          sendPurchaseEmail(res.data, email, name, songString);
        }
      }
    );
    navigate("/");
  }, []);
  return <div className="exact-page-net-header cream-background-color"></div>;
};

//CASE4: The anticipated purchase without customizing any gift yet
export const StylePresale = ({ setGreet, email, bundleType }) => {
  useEffect(() => {
    purchaseCredit(email, bundleType).then((res) => {
      if (res.data == "credit added") {
        sendCreditPurchaseEmail(email);
      }
    });
    setGreet(true);
  }, []);
  return <div className="exact-page-net-header cream-background-color"></div>;
};
