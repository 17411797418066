import { Card } from "@mui/material";
import { IoAdd, IoCloseOutline } from "react-icons/io5";

//THIS COMPONENT IS A PRIMITIVE FOR OFFERS THAT DISPLAY THIS TYPE OF INFORMATION
//in a smaller shape
function Offer({ form, claim, row1, row2, price, saving, gifts, onClick }) {
  // all styles are for desktop and mid sizes, phone have all the classes
  return (
    <Card
      className="phone-offer cart-cards cart-cards-bottom lowmid-offer"
      variant="outlined"
      sx={{
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        padding: "15px",
        borderRadius: "10px",
        alignItems: "center",
      }}
      style={{
        backgroundColor: "#FFFCEB",
      }}
    >
      <span
        className="phone-tag lowmid-tag"
        style={{ marginLeft: "15px", width: "170px" }}
      >
        <h4 className="offer-claim lowmid-offer-claim">{claim}</h4>
        <p
          className="phone-rows lowmid-rows"
          style={{
            fontFamily: "Work Sans",
            fontSize: "14px",
            marginBlock: "0px",
            lineHeight: "16px",
            marginBottom: "2px",
          }}
        >
          {row1}
          <br />
          {row2}
        </p>
      </span>
      <span>
        <h6
          className="font-price-gift-value lowmid-font-price-gift-value"
          style={{
            fontFamily: "Oswald",
            fontWeight: "500",
            fontSize: "29px",
            lineHeight: "19.7px",
            color: "#F98050",
            margin: "4.5px",
          }}
        >
          {price} €
        </h6>
        <hr
          style={{
            height: "1px",
            borderWidth: "1px",
            opacity: "1",
            margin: "0px",
          }}
        />
        <h6
          className="phone-saving lowmid-saving"
          style={{
            fontFamily: "Oswald",
            fontWeight: "300",
            fontSize: "14px",
            lineHeight: "15px",
            margin: "0px",
          }}
        >
          (Risparmi {saving} euro)
        </h6>

        <h6
          className="phone-equal lowmid-equal"
          style={{
            fontFamily: "Oswald",
            fontWeight: "500",
            fontSize: "12pt",
            lineHeight: "17pt",
            color: "#F98050",
            margin: "0px",
          }}
        >
          ={gifts} regali
        </h6>
      </span>
      <button className="transparentBtn" onClick={onClick}>
        {form !== "selected" ? (
          <IoAdd fontSize={30} color="#F98050" />
        ) : (
          <IoCloseOutline fontSize={30} style={{ opacity: 0.5 }} />
        )}
      </button>
    </Card>
  );
}

export default Offer;
