import React from "react";
import { FileInput } from "@mantine/core";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { TbEditCircle } from "react-icons/tb";

// THIS COMPONENT IS A TURNAROUND TO HAVE CUSTOMIZED FILE UPLOAD BUTTONS
// here the original flow https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8
// we have deleted the second step of the turn-around onChange --> HANDLECHANGE (deleted) --> handleFile

const FileUploader = (props) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="transparentBtn"
        style={{ alignSelf: "start" }}
      >
        <TbEditCircle size={40} color={props.color} />
      </button>
      <FileInput
        accept={IMAGE_MIME_TYPE}
        ref={hiddenFileInput}
        onChange={props.handleFile}
        style={{ display: "none" }}
      />
    </>
  );
};
export default FileUploader;
