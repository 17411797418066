//TWIN of RadioButton
const CheckBox = ({ label, id, handler, checked }) => {
  return (
    <div
      className="form-check font-subT6-WS phone-checkbox-list"
      sx={{
        variant: "forms.checkBox",
        marginTop: "15px",
      }}
    >
      <input
        className="form-check-input check"
        style={{
          backgroundColor: "#F1E8D5",
        }}
        type="checkbox"
        name="momentCraft"
        id={id}
        onChange={handler}
        checked={checked}
        value={checked}
      />
      <label
        className="form-check-label "
        // style={{ textAlign: "center" }}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
