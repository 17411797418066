import { useEffect, useState } from "react";
import "../../assets/localStyle/iubenda.css";

const TermsAndConditions = () => {
  const [policyText, setPolicyText] = useState("");

  useEffect(() => {
    const fetchPolicy = async () => {
      const response = await fetch(
        "https://www.iubenda.com/api/termini-e-condizioni/47780912"
      );
      const data = await response.json();
      setPolicyText(data.content);
    };
    fetchPolicy();
  }, []);

  return (
    // <div
    //   className="exact-page-net-header"
    //   dangerouslySetInnerHTML={{ __html: policyText }}
    // />
    <div style={{ padding: "40px 40px" }}>
      <h1>Termini e Condizioni</h1>
      <p>
        Questi Termini disciplinano l’utilizzo di questa Applicazione e
        qualsiasi altro Accordo o rapporto giuridico con il Titolare in maniera
        vincolante...
      </p>
      <h2>CONDIZIONI D'USO</h2>
      <p>
        Salvo ove diversamente specificato, le condizioni d’uso di questa
        Applicazione esposte in questa sezione hanno validità generale...
      </p>
      <ul>
        <li>L’Utente deve avere almeno 13 anni;</li>
        <li>
          Non è possibile usufruire del Servizio senza aprire un account Utente.
        </li>
      </ul>
      <p>
        Per maggiori dettagli, contattare il Titolare all'indirizzo email
        fornito.
      </p>
      <p>
        <h1>Termini e Condizioni di www.tunehey.com</h1>
        <p>
          Questi Termini disciplinano
          <ul>
            <li>l’utilizzo di questa Applicazione e</li>
            <li>l'utilizzo delle applicazioni al dominio feelbox.gift e</li>
            <li>
              qualsiasi altro Accordo o rapporto giuridico con il Titolare
            </li>
          </ul>
          in maniera vincolante. Le espressioni con l’iniziale maiuscola sono
          definite nella relativa sezione di questo documento. L’Utente è
          pregato di leggere attentamente questo documento. Nonostante il
          rapporto contrattuale relativo all’acquisto di tali Prodotti sia
          concluso esclusivamente tra Titolare e Utenti, gli Utenti riconoscono
          e accettano che, qualora la fornitura di questa Applicazione sia
          avvenuta tramite l'Apple App Store, Apple potrebbe esercitare diritti
          derivanti da questi Termini in qualità di terzo beneficiario. Questa
          Applicazione è un servizio di: Tunehey srl sb - via Modigliani 23,
          Senigallia (AN), 60019 Indirizzo email del Titolare: hey@tunehey.com
          “Questa Applicazione” si riferisce a
          <ul>
            <li>
              questo sito, inclusi i relativi sottodomini ed ogni altro sito
              attraverso i quali il Titolare offre il Servizio;
            </li>
            <li>applicazioni per dispositivi mobili, tablet o simili;</li>
            <li>il Servizio;</li>
          </ul>
        </p>
        <h2>Da sapere a colpo d’occhio</h2>
        <ul>
          <li>
            Si fa presente che determinate disposizioni di questi Termini
            potrebbero essere solo applicabili ad alcune categorie di Utenti. In
            particolare, alcune disposizioni potrebbero applicarsi solo a
            Consumatori o solo a Utenti che non agiscono come Consumatori. Tali
            limitazioni sono sempre menzionate esplicitamente in ciascuna
            clausola interessata. In caso di mancata menzione, le clausole si
            applicano a tutti gli Utenti.
          </li>
          <li>
            L’accesso a questa Applicazione è consentito agli Utenti che
            soddisfano i requisiti di età specificati in questi Termini.
          </li>
          <li>
            Il diritto di recesso si applica solo nei confronti di Consumatori
            Europei.
          </li>
        </ul>
        <hr />

        <h2>CONDIZIONI D'USO</h2>
        <p>
          Salvo ove diversamente specificato, le condizioni d’uso di questa
          Applicazione esposte in questa sezione hanno validità generale.
          Ulteriori condizioni d’uso o d’accesso applicabili in particolari
          situazioni sono espressamente indicate in questo documento.
          Utilizzando questa Applicazione l’Utente dichiara di soddisfare i
          seguenti requisiti:
        </p>
        <ul>
          <li>
            Non ci sono restrizioni riferite agli Utenti rispetto al fatto che
            essi siano Consumatori o Utenti Professionisti;
          </li>
          <li>L’Utente deve avere almeno 13 anni;</li>
          <li>
            L’Utente non è situato in un paese soggetto ad embargo governativo
            da parte degli Stati Uniti d’America o in un paese inserito nella
            lista degli stati che sponsorizzano il terrorismo da parte del
            Governo degli Stati Uniti d’America;
          </li>
          <li>
            L’Utente non è inserito in alcuna lista di contraenti non ammessi da
            parte del Governo degli Stati Uniti d’America;
          </li>
        </ul>
        <h3>Registrazione</h3>
        <p>
          Per usufruire del Servizio l’Utente può aprire un account indicando
          tutti i dati e le informazioni richieste in maniera completa e
          veritiera. Non è possibile usufruire del Servizio senza aprire un
          account Utente.
        </p>
        <p>
          È responsabilità degli Utenti conservare le proprie credenziali
          d’accesso in modo sicuro e preservarne la confidenzialità. A tal fine,
          gli Utenti devono scegliere una password che corrisponda al più alto
          livello di sicurezza disponibile su questa Applicazione. Creando un
          account l’Utente accetta di essere pienamente responsabile di ogni
          attività posta in atto con le sue credenziali d’accesso. Gli Utenti
          sono tenuti a informare il Titolare immediatamente e univocamente
          tramite i recapiti indicati in questo documento qualora ritengano che
          le proprie informazioni personali, quali ad esempio l’account Utente,
          le credenziali d’accesso o dati personali, siano state violate,
          illecitamente diffuse o sottratte.
        </p>
        <h4>Chiusura account</h4>
        <p>
          Gli Utenti possono chiudere il proprio account e cessare l’utilizzo
          del Servizio alle condizioni e secondo le procedure specificate nella
          rispettiva sezione di questa Applicazione.
        </p>
        <h4>Sospensione e cancellazione account</h4>
        <p>
          Il Titolare si riserva il diritto di sospendere o cancellare l’account
          di un Utente in qualsiasi momento a propria discrezione e senza
          preavviso, qualora lo ritenga inopportuno, offensivo o contrario a
          questi Termini. La sospensione o cancellazione dell’account non da
          all’Utente alcun diritto di risarcimento, rimborso o indennizzo. La
          sospensione o cancellazione di un account per cause addebitabili
          all’Utente non esonera l’Utente dal pagamento dei compensi o prezzi
          eventualmente applicabili.
        </p>
        <h3>Contenuti su questa Applicazione</h3>
        <p>
          Salvo ove diversamente specificato o chiaramente riconoscibile, tutti
          i contenuti disponibili su questa Applicazione sono di proprietà di o
          forniti dal Titolare o dei/dai suoi licenzianti. Il Titolare adotta la
          massima cura affinché il contenuto disponibile su questa Applicazione
          non violi la normativa applicabile o diritti di terze parti. Tuttavia,
          non sempre è possibile raggiungere tale risultato. In tali casi, senza
          alcun pregiudizio ai diritti ed alle pretese legalmente esercitabili,
          gli Utenti sono pregati di indirizzare i relativi reclami ai recapiti
          specificati in questo documento.
        </p>
        <h4>Diritti sui contenuti di questa Applicazione</h4>
        <p>
          Il Titolare detiene e si riserva espressamente ogni diritto di
          proprietà intellettuale sui suddetti contenuti. Gli Utenti non sono
          autorizzati ad usare i contenuti in alcun modo che non sia necessario
          od implicito nel corretto utilizzo del Servizio. In particolare, ma
          senza esclusioni, è fatto divieto agli Utenti di copiare, scaricare,
          condividere oltre i limiti sotto specificati, modificare, tradurre,
          elaborare, pubblicare, trasmettere, vendere, concedere sottolicenze,
          trasformare, trasferire/alienare a terze parti o creare opere derivate
          a partire dal contenuto disponibile su questa Applicazione, di
          permettere a terze parti di intraprendere tali attività tramite il
          proprio account Utente o dispositivo, anche a propria insaputa. Ove
          espressamente indicato su questa Applicazione, l’Utente è autorizzato
          a scaricare, copiare e/o condividere determinati contenuti disponibili
          su questa Applicazione esclusivamente per scopi personali e non
          commerciali ed a condizione che sia osservata l’attribuzione della
          paternità dell’opera nonché l’indicazione di ogni altra circostanza
          rilevante richiesta dal Titolare. Restano ferme le limitazioni ed
          esclusioni previste dalla normativa sul diritto d’autore.
        </p>
        <h3>Contenuti forniti dagli Utenti</h3>
        <p>
          Il Titolare consente agli Utenti di caricare, condividere o offrire i
          propri contenuti su questa Applicazione. All’atto di fornire contenuti
          a questa Applicazione l’Utente dichiara di essere legalmente
          autorizzato a farlo e conferma che detti contenuti non violano norme
          di legge e/o diritti di terzi.
        </p>
        <h4>Diritti sui contenuti forniti dagli Utenti</h4>
        <p>
          L’Utente riconosce ed accetta che fornendo contenuti propri a questa
          Applicazione concede al Titolare a titolo gratuito il diritto non
          esclusivo di elaborare i contenuti con fini di operatività e
          manutenzione di questa Applicazione, così come contrattualmente
          previsto. Nei limiti di legge, l’Utente rinuncia all’esercizio di
          diritti morali in relazione al contenuto fornito a questa
          Applicazione. Gli Utenti riconoscono ed accettano che i contenuti da
          loro offerti tramite questa Applicazione saranno resi disponibili alle
          stesse condizioni applicabili ai contenuti di questa Applicazione.
        </p>
        <h4>Responsabilità per i contenuti forniti</h4>
        <p>
          L’Utente risponde in via esclusiva dei contenuti caricati, pubblicati,
          condivisi o comunque forniti a questa Applicazione. L’Utente riconosce
          ed accetta che{" "}
          <strong>il Titolare non filtra né modera tali contenuti.</strong>
          Ciononostante, il Titolare si riserva il diritto di rimuovere,
          cancellare o bloccare detti contenuti a propria discrezione e di
          negare senza preavviso all’Utente che li ha caricati l’accesso a
          questa Applicazione:
        </p>
        <ul>
          <li>
            nel momento in cui venisse a conoscenza di una (presunta) violazione
            di questi Termini, dei diritti di terzi o della legge applicabile,
            in relazione a tale contenuto;
          </li>
          <li>
            se ha ricevuto una notifica di violazione dei diritti di proprietà
            intellettuale;
          </li>
          <li>
            se ha ricevuto una notifica di violazione della riservatezza di
            terzi, compresa la loro sfera intima;
          </li>
          <li>per ordine dell’Autorità; o</li>
          <li>
            qualora sia stato fatto presente al Titolare che tali contenuti, se
            accessibili tramite questa Applicazione, possono rappresentare un
            rischio per gli Utenti, per i terzi o per la disponibilità del
            Servizio.
          </li>
        </ul>
        <p>
          La rimozione, cancellazione o il blocco dei contenuti non giustificano
          alcuna pretesa di risarcimento, rimborso o indennizzo in capo agli
          Utenti che hanno fornito tali contenuti. Gli Utenti accettano di
          mantenere indenne il Titolare da e contro ogni pretesa avanzata e/o
          danno sofferto a causa di contenuti da essi forniti a o offerti
          tramite questa Applicazione.
        </p>
        <h4>
          Rimozione di contenuti da parte di questa Applicazione disponibili
          tramite App Store
        </h4>
        <p>
          Se un contenuto oggetto di reclamo è considerato contestabile sarà
          rimosso entro 24 ore e all’Utente responsabile del caricamento sarà
          negato l’accesso al Servizio.
        </p>
        <h4>Accessibilità dei contenuti forniti</h4>
        <p>
          I contenuti forniti a questa Applicazione dagli Utenti sono messi a
          disposizione alle condizioni descritte in questa sezione.
        </p>
        <h5>Contenuti privati</h5>
        <p>
          I contenuti riservati forniti dall’Utente restano privati e non
          saranno condivisi con terze parti o con il Titolare senza il consenso
          espresso dell’Utente.
        </p>
        <h5>Contenuti riservati a un pubblico determinato</h5>
        <p>
          I contenuti destinati ad essere messi a disposizione di un pubblico
          determinato possono essere condivisi solo con le terze parti definite
          dall’Utente. Nessun dato personale, identificativo o altra
          informazione riferibile all’Utente ad eccezione di uno pseudonimo
          liberamente scelto (quali, ad esempio, un nickname o avatar) apparirà
          in collegamento con il contenuto, a meno che l’Utente non decida
          diversamente di propria iniziativa. Gli Utenti possono (e sono
          incoraggiati a) consultare questa Applicazione per scoprire chi può
          accedere ai contenuti da loro forniti.
        </p>
        <h3>Accesso a risorse esterne</h3>
        <p>
          Tramite questa Applicazione gli Utenti potrebbero avere accesso a
          risorse fornite da terzi. Gli Utenti riconoscono ed accettano che il
          Titolare non ha alcun controllo su tali risorse e che pertanto non
          risponde del loro contenuto e della loro disponibilità. Le condizioni
          applicabili alle risorse fornite da terzi, ivi incluse quelle
          applicabili a eventuali concessioni di diritti su contenuti, sono
          determinate dagli stessi terzi e regolate nei relativi termini e
          condizioni o, in loro assenza, dalla legge.
        </p>
        <h3>Uso ammesso</h3>
        <p>
          Questa Applicazione ed il Servizio possono essere utilizzati solo per
          gli scopi per i quali sono offerti, secondo questi Termini ed ai sensi
          della legge applicabile. È responsabilità esclusiva dell’Utente di far
          sì che l’uso di questa Applicazione e/o del Servizio non violi la
          legge, i regolamenti o i diritti di terzi. Pertanto,{" "}
          <strong>
            il Titolare si riserva il diritto di adottare ogni misura idonea a
            proteggere i propri interessi legittimi, ed in particolare di negare
            all’Utente l’accesso a questa Applicazione o al Servizio, risolvere
            contratti, denunciare ogni attività censurabile svolta tramite
            questa Applicazione o il Servizio alle autorità competenti – p. es.
            l’autorità giudiziaria o amministrativa – ogniqualvolta sussista il
            sospetto che l’Utente ponga in essere violazioni di legge,
            regolamenti, diritti di terzi e/o i Termini, in particolare, ma
            senza esclusioni, mettendo in atto una delle seguenti attività:
          </strong>
        </p>
        <h4>Regole di comportamento</h4>
        <ul>
          <li>
            fingere di soddisfare un qualsiasi requisito di accesso a questa
            Applicazione o di utilizzo del Servizio, come ad esempio la maggiore
            età o la qualifica di Consumatore;
          </li>
          <li>
            nascondere la propria identità, utilizzare l’identità altrui o
            fingere di agire in nome di un terzo, se non autorizzati da tale
            terzo;
          </li>
          <li>
            alterare identificativi per nascondere o camuffare l’origine dei
            propri messaggi o dei contenuti pubblicati;
          </li>
          <li>
            diffamare, minacciare, abusare, usare pratiche intimidatorie,
            minacciare o violare i diritti di terzi in qualsiasi altro modo;
          </li>
          <li>
            promuovere attività che possono mettere a rischio la vita propria o
            di qualsiasi altro Utente o causare danni fisici. Sono comprese in
            questa categoria, ma senza alcuna esclusione, la minaccia di o
            istigazione al suicidio, l’esaltazione di traumi fisici
            intenzionali, l’uso di droghe illegali, l’abuso di alcol. La
            pubblicazione di contenuti che promuovono, esaltano o illustrano
            atteggiamenti autodistruttivi o violenti su questa Applicazione non
            è tollerata in alcun caso;
          </li>
          <li>
            mettere alla prova, analizzare o testare la vulnerabilità di questa
            Applicazione, i servizi e le reti collegate al sito, violare la
            sicurezza o le procedure di autenticazione su questa Applicazione, i
            servizi e le reti collegate a questa Applicazione;
          </li>
          <li>
            installare, integrare, caricare o altrimenti incorporare malware in
            o tramite questa Applicazione;
          </li>
          <li>
            usare questa Applicazione o la relativa infrastruttura tecnologica
            in maniera abusiva, eccessiva o altrimenti inappropriata (ad
            esempio: a scopo di spam);
          </li>
          <li>
            tentare di disgregare o manomettere l’infrastruttura tecnologica in
            modo tale da causare un danno o un onere eccessivo a questa
            Applicazione o al Servizio;
          </li>
        </ul>
        <h4>Regole di contenuto</h4>
        <ul>
          <li>
            diffondere o pubblicare contenuti illeciti, osceni, illegittimi,
            diffamatori o inadeguati;
          </li>
          <li>
            pubblicare contenuti che direttamente o indirettamente promuovono
            odio, razzismo, discriminazione, pornografia o violenza;
          </li>
          <li>
            diffondere o pubblicare contenuti falsi o che possono provocare
            ingiustificato allarme;
          </li>
          <li>
            usare questa Applicazione per pubblicare, diffondere o altrimenti
            offrire contenuti tutelati dalla normativa sulla proprietà
            intellettuale, compresi, ma senza esclusione, brevetti, marchi e
            diritto d’autore, senza l’autorizzazione del titolare dei diritti;
          </li>
          <li>
            usare questa Applicazione per pubblicare, diffondere o altrimenti
            offrire contenuti che violano diritti di terzi, compresi, ma senza
            esclusione, segreti militari, commerciali, professionali o di stato
            e dati personali;
          </li>
          <li>
            pubblicare contenuti o svolgere attività che disgregano,
            interrompono, danneggiano o violano in ogni altro modo l’integrità
            di questa Applicazione o dei dispositivi di altri Utenti. Sono
            comprese tra tali attività: lo spamming, la diffusione illecita di
            pubblicità, il phishing, la frode a danno di terzi, la diffusione di
            malware o virus etc.;
          </li>
        </ul>
        <h4>Divieti d’uso commerciale</h4>
        <ul>
          <li>
            aprire un account o usare questa Applicazione per promuovere,
            vendere o pubblicizzare prodotti o servizi di qualsiasi tipo in
            qualsiasi modo;
          </li>
          <li>
            fingere o implicare in qualsiasi maniera di avere un rapporto con
            questa Applicazione, di godere del sostegno di questa Applicazione o
            che questa Applicazione sostenga i prodotti o servizi dell’Utente o
            di un terzo per qualsiasi scopo;
          </li>
        </ul>
        <h3>Licenza software</h3>
        <p>
          Qualsiasi diritto di proprietà intellettuale o industriale, così come
          ogni altro diritto esclusivo esistente sul software o sulla tecnologia
          integrata in o relativa a questa Applicazione è detenuto dal Titolare
          e/o dal suo licenziante. A condizione che l’Utente rispetti questi
          Termini e nonostante qualsiasi disposizione divergente in essi
          contenuta, il Titolare concede agli Utenti una licenza revocabile, non
          esclusiva non cedibile né concedibile di usare il software e/o la
          tecnologia integrata nel Servizio nel quadro e per gli scopi di questa
          Applicazione e del Servizio offerto. La licenza non comprende alcun
          diritto di accesso a, utilizzo o rivelazione del codice sorgente
          originale all’Utente. Le tecniche, gli algoritmi e le procedure
          contenute nel software e la relativa documentazione sono di proprietà
          esclusiva del Titolare o del suo licenziante. La concessione di
          diritti e licenze all’Utente cessa con effetti immediati in caso di
          risoluzione o scadenza dell’Accordo.
        </p>
        <br />
        <h2>TERMINI E CONDIZIONI DI VENDITA</h2>
        <h3>Prodotti a pagamento</h3>
        <p>
          Alcuni dei Prodotti offerti su questa Applicazione come parte del
          servizio sono a pagamento. Le tariffe, la durata e le condizioni
          applicabili alla vendita di tali Prodotti sono descritte di seguito e
          nelle rispettive sezioni di questa Applicazione. Per acquistare i
          Prodotti, l’Utente è tenuto a registrarsi o ad effettuare l’accesso a
          questa Applicazione.
        </p>
        <h3>Descrizione del prodotto</h3>
        <p>
          Prezzi, descrizioni e disponibilità dei Prodotti sono specificati
          nelle rispettive sezioni di questa Applicazione e sono soggetti a
          modifica senza preavviso. Sebbene i Prodotti su questa Applicazione
          siano presentati con la massima accuratezza tecnicamente possibile, la
          rappresentazione su questa Applicazione tramite qualsiasi mezzo
          (inclusi, a seconda dei casi, materiali grafici, immagini, colori,
          suoni) è da intendersi come mero riferimento e non implica alcuna
          garanzia in merito alle caratteristiche del Prodotto acquistato. Le
          caratteristiche del Prodotto selezionato saranno specificate durante
          la procedura d’acquisto.
        </p>
        <h3>Procedura d’acquisto</h3>
        <p>
          Ogni fase, dalla scelta del prodotto fino all’inoltro dell’ordine, fa
          parte della procedura d’acquisto. La procedura d’acquisto comprende i
          seguenti passaggi:
        </p>
        <ul>
          <li>
            Gli Utenti sono pregati di scegliere il Prodotto desiderato e di
            verificare la loro scelta d’acquisto.
          </li>
          <li>
            Dopo aver controllato le informazioni visibili nella scelta
            d’acquisto, gli Utenti possono effettuare l’ordine inoltrandolo.
          </li>
        </ul>
        <h3>Invio dell’ordine</h3>
        <p>L’invio dell’ordine comporta quanto segue:</p>
        <ul>
          <li>
            L’invio dell’ordine da parte dell’utente determina la conclusione
            del contratto e fa sorgere in capo all’Utente l’obbligo di pagare
            prezzo, tasse ed eventuali ulteriori oneri e spese, così come
            specificato nella pagina dell’ordine.
          </li>
          <li>
            Nel caso in cui il Prodotto acquistato richieda un'azione da parte
            dell’Utente, come la fornitura di informazioni o dati personali,
            specificazioni o richieste particolari, l’inoltro dell’ordine
            costituisce in capo all’Utente anche l’obbligo di collaborare di
            conseguenza.
          </li>
          <li>
            Una volta inoltrato l’ordine, agli Utenti sarà inviata una conferma
            di ricezione dell’ordine.
          </li>
          <li>
            Tutte le notifiche relative alla procedura d’acquisto sopra
            descritta saranno inviate all’indirizzo di posta elettronica fornito
            dall’Utente a tal fine.
          </li>
        </ul>
        <h3>Prezzi</h3>
        <p>
          Durante la procedura d’acquisto e prima dell’inoltro dell’ordine, gli
          Utenti vengono debitamente informati di tutte le commissioni, tasse e
          costi (comprese eventuali spese di spedizione) che saranno loro
          addebitati. I prezzi su questa Applicazione:
        </p>
        <ul>
          <li>includono tutte le commissioni, tasse e costi applicabili.</li>
        </ul>
        <h3>Promozioni e sconti</h3>
        <p>
          Il Titolare potrebbe offrire sconti o promozioni speciali per
          l’acquisto dei Prodotti. Tali promozioni o sconti sono sempre soggetti
          ai requisiti ed a termini e condizioni previste nella relativa sezione
          di questa Applicazione. Promozioni e offerte sono sempre concesse a
          sola discrezione del Titolare. Promozioni o sconti ripetuti o
          periodici non costituiscono alcuna pretesa o diritto azionabili dagli
          Utenti in futuro. A seconda dei casi, sconti e promozioni valgono per
          un determinato periodo di tempo o fino ad esaurimento scorte. Salvo
          ove diversamente specificato, le limitazioni di tempo di promozioni e
          sconti si intendono riferite al fuso orario della sede del Titolare,
          come indicata nei recapiti in questo documento.
        </p>
        <h3>Mezzi di pagamento</h3>
        <p>
          I dettagli relativi ai mezzi di pagamento accettati sono evidenziati
          durante la procedura d’acquisto. Alcuni mezzi di pagamento sono legati
          ad ulteriori condizioni o comportano costi aggiuntivi. Le informazioni
          dettagliate sono riportate nella relativa sezione di questa
          Applicazione. Tutti i pagamenti vengono gestiti autonomamente da
          servizi terzi. Pertanto, questa Applicazione non raccoglie dati
          relativi al pagamento – quali numeri di carta di credito – ma riceve
          una notifica una volta che il pagamento è andato a buon fine. Nel caso
          in cui il pagamento effettuato con uno dei mezzi disponibili fallisca
          o venga rifiutato dal fornitore di servizi di pagamento, il Titolare
          non è obbligato a eseguire l’ordine. Nel caso in cui il pagamento non
          vada a buon fine, il Titolare si riserva il diritto di richiedere
          all'Utente il rimborso di qualsiasi spesa o danno correlato.
        </p>
        <h3>Valuta Virtuale ad uso esclusivo su questa Applicazione</h3>
        <p>
          Su questa Applicazione è possible effettuare alcuni pagamenti
          utilizzando una Valuta Virtuale. Salvo ove diversamente specificato,
          tale Valuta Virtuale non è commerciabile, convertibile o rimborsabile
          con alcuna valuta tradizionale, valuta digitale, beni o altri valori.
          Acquistando la Valuta Virtuale, gli Utenti riconoscono ed accettano
          che essa potrà essere utilizzata solo nel contesto di questa
          Applicazione per gli scopi espressamenti autorizzati dal Titolare
          nell’ambito dei suoi Servizi. Gli Utenti inoltre riconoscono ed
          accettano che tale Valuta Virtuale non potrà essere trasferita,
          acquistata, venduta o scambiata al di fuori del Servizio. Di
          conseguenza, gli Utenti non possono concedere in sublicenza,
          scambiare, vendere o tentare di vendere la Valuta Virtuale in cambio
          di denaro o scambiare la Valuta Virtuale contro alcuntipo di valore al
          di fuori dell’apposita offerta del Titolare su questa Applicazione.
          Qualsiasi attività o transazione vietata sarà considerato nulla ed
          inefficace e potrebbe comportare azioni legali nei confronti
          dell’Utente. In caso di risoluzione del contratto o di chiusura
          dell’account per qualsiasi causa imputabile all’Utente, l’eventuale
          residuo di Valuta Virtuale non ancora utilizzata scadrà e non sarà
          rimborsata in alcun modo.
        </p>
        <h3>Acquisto su app store</h3>
        <p>
          Questa Applicazione o determinati Prodotti in vendita su questa
          Applicazione devono essere acquistati tramite un app store terzo. Per
          poter effettuare tali acquisti gli Utenti sono pregati di seguire le
          istruzioni nel relativo app store (ad esempio “Apple App Store” o
          “Google Play”). Tali informazioni possono variare a seconda dello
          specifico dispositivo utilizzato. Salvo ove diversamente specificato,
          gli acquisti effettuati tramite negozi online di terze parti sono
          soggetti anche ai termini e condizioni di tali terzi. Tali termini e
          condizioni prevalgono in ogni caso di incongruenza o conflitto
          rispetto ai presenti Termini. Pertanto, gli Utenti che effettuano
          acquisti attraverso tali negozi online di terzi sono pregati di
          leggere attentamente ed accettare i relativi termini e condizioni di
          vendita.
        </p>
        <h3>Riserva di proprietà</h3>
        <p>
          Fino alla ricezione del pagamento del prezzo integrale di acquisto da
          parte del Titolare, l’Utente non acquista la proprietà dei Prodotti
          ordinati.
        </p>
        <h3>Riserva dei diritti d’uso</h3>
        <p>
          Fino alla ricezione del pagamento del prezzo integrale di acquisto da
          parte del Titolare, l’Utente non acquista i diritti d’uso dei Prodotti
          ordinati.
        </p>
        <h3>Consegna</h3>
        <h4>Consegna di contenuti digitali</h4>
        <p>
          Salvo ove diversamente specificato, il contenuto digitale acquistato
          su questa Applicazione viene consegnato tramite download sul
          dispositivo o sui dispositivi scelti dall’Utente. Gli Utenti
          riconoscono ed accettano che, per scaricare e/o utilizzare il
          Prodotto, il dispositivo o i dispositivi prescelti ed il rispettivo
          software (compresi i sistemi operativi) debbano essere legali, d’uso
          comune, aggiornati e in linea con gli attuali standard di mercato. Gli
          Utenti riconoscono ed accettano che la possibilità di scaricare il
          Prodotto acquistato potrebbe essere limitata nel tempo e nello spazio.
        </p>
        <br />
        <h2>Diritti dell’Utente</h2>
        <h3>Diritto di recesso</h3>
        <p>
          A meno che non ricorra un’eccezione, l’Utente potrebbe godere del
          diritto di recedere dal contratto entro il termine sotto specificato
          (di norma 14 giorni) per qualsiasi motivo e senza giustificazione.
          L’Utente può trovare ulteriori informazioni sul diritto di recesso in
          questa sezione.
        </p>
        <h4>Chi gode del diritto di recesso</h4>
        <p>
          Ove non ricorra una delle eccezioni sotto elencate, gli Utenti che
          agiscono come Consumatori Europei godono per legge del diritto di
          recedere dai contratti conclusi online (contratti a distanza) entro il
          periodo di tempo specificato più avanti per qualsiasi motivo e senza
          bisogno di giustificazione. Gli Utenti che non soddisfano tali
          requisiti non godono dei diritti descritti in questa sezione.
        </p>
        <h4>Esercizio del diritto di recesso</h4>
        <p>
          Per esercitare il diritto di recesso l’Utente deve inviare al Titolare
          una comunicazione inequivocabile della propria intenzione di recedere
          dal contratto. A tal fine, l’Utente può utilizzare il modulo di
          recesso tipo reperibile nella sezione delle definizioni di questo
          documento. L’Utente è tuttavia libero di esprimere la propria
          intenzione di recedere dal contratto in ogni altra forma idonea. Al
          fine di rispettare il periodo entro il quale dev’essere esercitato il
          diritto, l’Utente deve inviare la dichiarazione di recesso prima che
          scada il termine di recesso. Quando scade il termine di recesso?
        </p>
        <ul>
          <li>
            In caso di contratti d’acquisto di contenuti digitali non forniti su
            supporto materiale il termine di recesso scade dopo 14 giorni dal
            giorno di conclusione del contratto, a meno che l’Utente abbia
            rinunciato al diritto di recesso.
          </li>
        </ul>
        <h4>Effetti del recesso</h4>
        <p>
          Il Titolare rimborsa tutti i pagamenti ricevuti compresi, se
          effettuati, quelli relativi alle spese di consegna agli Utenti che
          abbiano correttamente esercitato il diritto di recesso. Tuttavia, il
          maggior costo derivante dalla scelta di un particolare metodo di
          consegna diverso dalla consegna standard più economica offerta dal
          Titolare, resterà a carico dell’Utente. Il rimborso avviene senza
          indebito ritardo ed in ogni caso entro 14 giorni dal giorno in cui il
          Titolare è stato informato della decisione dell’Utente di recedere dal
          contratto. Salvo ove diversamente pattuito con l’Utente, il rimborso è
          effettuato utilizzando lo stesso mezzo di pagamento utilizzato per la
          transazione iniziale. L’Utente non deve sostenere alcun costo quale
          conseguenza del recesso.
        </p>
        <h4>Eccezioni al diritto di recesso</h4>
        <p>Non sussiste diritto di recesso da contratti:</p>
        <ul>
          <li>
            di fornitura di contenuti digitali su supporto non materiale, se il
            contratto impone al Consumatore l’obbligo di pagare e quando
            l’esecuzione è iniziata con il consenso espresso del Consumatore
            dopo l’accettazione di perdere il diritto di recesso, e tali
            circostanze sono state confermate dal Titolare;
          </li>
        </ul>
        <h3>Limitazione di responsabilità e manleva</h3>
        <h4>Manleva</h4>
        <p>
          L’Utente si impegna a manlevare e tenere indenne il Titolare e i suoi
          sottoposti, affiliati, funzionari, agenti, contitolari del marchio,
          partner e dipendenti nella misura di legge da qualsivoglia
          rivendicazione o pretesa – compresi, senza alcuna limitazione, oneri e
          spese legali – avanzata da terzi a causa di o in collegamento con
          comportamenti in violazione dei presenti Termini, di diritti di terzi
          o di legge, posti in essere in collegamento con l'utilizzo del
          Servizio e addebitabili all’Utente, ai suoi affiliati, funzionari,
          agenti, contitolari del marchio, partner e dipendenti, a titolo di
          colpa.
        </p>
        <h4>
          Limitazione della responsabilità per le attività dell’Utente su questa
          Applicazione
        </h4>
        <p>
          Salvo ove diversamente specificato e fatte salve le disposizioni di
          legge applicabili, è esclusa ogni pretesa risarcitoria nei confronti
          del Titolare (o di qualsiasi persona fisica o giuridica che agisca per
          suo conto). Quanto precede non limita la responsabilità del Titolare
          per morte, danno alla persona o all’integrità fisica o mentale, danni
          derivanti dalla violazione di obblighi contrattuali essenziali, quali
          gli obblighi strettamente necessari al raggiungimento della causa del
          contratto, e/o ai danni provocati con dolo o colpa grave, a condizione
          che l’utilizzo di questa Applicazione da parte dell’Utente sia stato
          idoneo e corretto. Salvo che i danni siano stati causati con dolo o
          colpa grave o incidano sulla vita e/o l’integrità personale, fisica o
          mentale, il Titolare risponde solo nella misura del danno tipico per
          il tipo di contratto e prevedibile al momento della conclusione.
        </p>
        <br />
        <h2>Disposizioni comuni</h2>
        <h3>Nessuna rinuncia implicita</h3>
        <p>
          Il mancato esercizio di diritti di legge o pretese derivanti da questi
          Termini da parte del Titolare non costituisce rinuncia agli stessi.
          Nessuna rinuncia può essere considerata definitiva in relazione ad uno
          specifico diritto o a qualsiasi altro diritto.
        </p>
        <h3>Interruzione del Servizio</h3>
        <p>
          Per garantire il miglior livello di servizio possibile, il Titolare si
          riserva di interrompere il Servizio per finalità di manutenzione,
          aggiornamenti di sistema o per qualsiasi altra modifica, dandone
          idonea notizia agli Utenti. Nei limiti di legge, il Titolare si
          riserva di sospendere o cessare completamente l'attività del Servizio.
          In caso di cessazione dell'attività del Servizio, il Titolare si
          adopererà affinché gli Utenti possano estrarre i propri Dati Personali
          e le informazioni e rispetterà i diritti degli Utenti relativi
          all’utilizzo continuato del prodotto e/o al risarcimento, secondo le
          disposizioni di legge. Inoltre, il Servizio potrebbe non essere
          disponibile per cause che si sottraggono al ragionevole controllo del
          Titolare, quali cause di forza maggiore (p. es. malfunzionamenti
          infrastrutturali, blackout etc.).
        </p>
        <h3>Rivendita del Servizio</h3>
        <p>
          Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare,
          vendere, rivendere o sfruttare questa Applicazione o il Servizio in
          toto o in parte senza previo consenso scritto del Titolare, espresso
          direttamente o attraverso un legittimo programma di rivendite.
        </p>
        <h3>Privacy policy</h3>
        <p>
          Le informazioni sul trattamento dei Dati Personali sono contenute
          nella privacy policy di questa Applicazione.
        </p>
        <h3>Proprietà intellettuale</h3>
        <p>
          Senza pregiudizio ad alcuna previsione più specifica contenuta nei
          Termini, i diritti di proprietà intellettuale ed industriale, quali ad
          esempio diritti d’autore, marchi, brevetti e modelli relativi a questa
          Applicazione sono detenuti in via esclusiva dal Titolare o dai suoi
          licenzianti e sono tutelati ai sensi della normativa e dei trattati
          internazionali applicabili alla proprietà intellettuale. Tutti i
          marchi – denominativi o figurativi – ed ogni altro segno distintivo,
          ditta, marchio di servizio, illustrazione, immagine o logo che
          appaiono in collegamento con questa Applicazione sono e restano di
          esclusiva proprietà del Titolare o dei suoi licenzianti e sono
          tutelati ai sensi della normativa e dei trattati internazionali
          applicabili alla proprietà intellettuale.
        </p>
        <h3>Modifiche dei Termini</h3>
      </p>
      <p>
        Il Titolare si riserva il diritto di modificare i Termini in ogni
        momento. In tal caso, il Titolare darà opportuna notizia delle modifiche
        agli Utenti. Le modifiche avranno effetto nel rapporto con l’Utente solo
        a partire dal momento comunicato all'Utente.
        <strong>
          L’utilizzo continuato del Servizio implica l’accettazione dell’Utente
          dei Termini aggiornati.
        </strong>{" "}
        Se l’Utente non desidera accettare le modifiche, deve cessare l’utilizzo
        del Servizio e può recedere dall'Accordo. La versione precedente
        continua a disciplinare il rapporto fino all’accettazione delle
        modifiche da parte dell’Utente. Tale versione può essere richiesta al
        Titolare. Se richiesto dalla legge, il Titolare comunicherà in anticipo
        agli Utenti la data di entrata in vigore dei Termini modificati.
      </p>
      <h3>Cessione del contratto</h3>
      <p>
        Il Titolare si riserva il diritto di trasferire, cedere, disporre di,
        novare o appaltare singoli o tutti i diritti e le obbligazioni secondo
        questi Termini, avendo riguardo per gli interessi legittimi degli
        Utenti. Si applicano le disposizioni relative alla modifica di questi
        Termini. L’Utente non è autorizzato a cedere o trasferire i propri
        diritti e le proprie obbligazioni secondo i Termini senza il consenso
        scritto del Titolare.
      </p>
      <h3>Contatti</h3>
      <p>
        Tutte le comunicazioni inerenti all’uso di questa Applicazione devono
        essere inviate ai recapiti indicati in questo documento.
      </p>
      <h3>Clausola di salvaguardia</h3>
      <p>
        Qualora alcuna delle disposizioni di questi Termini dovesse essere o
        divenire nulla o inefficace ai sensi della legge applicabile, la nullità
        o inefficacia di tale disposizione non provoca inefficacia delle
        restanti previsioni, che permangono pertanto valide ed efficaci.
      </p>
      <h4>Utenti europei</h4>
      <p>
        Qualora una disposizione di questi Termini dovesse essere o divenire
        nulla, invalida o inefficace, le parti si adopereranno per individuare
        in via amichevole una disposizione valida ed efficace sostitutiva di
        quella nulla, invalida o inefficace. In caso di mancato accordo nei
        termini predetti, se permesso o previsto dalla legge applicabile, la
        disposizione nulla, invalida o inefficace sarà sostituita dalla
        disciplina legale applicabile. Fermo restando quanto sopra, la nullità,
        invalidità o inefficacia di una specifica disposizione di questi Termini
        non comporta nullità dell’intero Accordo, a meno che le disposizioni
        nulle, invalidi o inefficaci nel quadro dell’Accordo siano essenziali o
        di tale importanza, che le parti non avrebbero concluso il contratto se
        avessero saputo che la disposizione sarebbe stata invalida, ovvero in
        casi in cui le disposizioni residue comporterebbero un onere eccessivo
        ed inaccettabile per una delle parti.
      </p>
      <h3>Legge applicabile</h3>
      <p>
        I Termini sono disciplinati dalla legge del luogo in cui è stabilito il
        Titolare, così come indicato nella relativa sezione di questo documento
        a prescindere dalle norme di conflitto.
      </p>
      <h4>Prevalenza della legge nazionale</h4>
      <p>
        Tuttavia, a prescindere da quanto precede, se la legge del paese in cui
        si trova l'Utente prevede un livello di tutela dei consumatori
        superiore, prevale tale superiore livello di tutela.
      </p>
      <h3>Foro competente</h3>
      <p>
        La competenza esclusiva a conoscere ogni controversia derivante da o in
        collegamento con i Termini spetta all’autorità giudiziaria del seguente
        paese: Tribunale di Ancona, AN, Italia
      </p>
      <h4>Eccezione per Consumatori in Europa</h4>
      <p>
        Quanto precede non si applica a Utenti che agiscono come Consumatori
        Europei o Consumatori situati nel Regno Unito, Svizzera, Norvegia o
        Islanda.
      </p>
      <h2>Risoluzione delle controversie</h2>
      <h3>Composizione amichevole delle controversie</h3>
      <p>
        Gli Utenti possono segnalare eventuali controversie al Titolare, che
        cercherà di risolvere in via amichevole. Per quanto resti impregiudicato
        il diritto degli Utenti di promuovere un’azione in giudizio, in caso di
        controversie inerenti all’uso di questa Applicazione o al Servizio, gli
        Utenti sono pregati di contattare il Titolare ai recapiti indicati in
        questo documento. L’Utente può indirizzare un reclamo all’indirizzo
        e-mail del Titolare indicato in questo documento, includendo una breve
        descrizione e, se del caso, i dettagli dell’ordine, acquisto o account
        interessato. Il Titolare provvederà ad evadere la richiesta senza
        indebito ritardo ed entro 3 giorni dalla sua ricezione.
      </p>
      <h3>Piattaforma di risoluzione delle controversie con i Consumatori</h3>
      <p>
        La Commissione Europea ha introdotto una piattaforma online per la
        risoluzione alternativa delle controversie che favorisce la composizione
        extragiudiziale di dispute relative a e derivanti da vendite e contratti
        di servizio online. Pertanto, ogni Consumatore Europeo o con sede in
        Norvegia, Islanda o Liechtenstein può servirsi di tale piattaforma per
        risolvere controversie derivanti da contratti conclusi online. La
        piattaforma è disponibile qui.
      </p>
      <h6>Definizioni e riferimenti legali</h6>
      <br />
      <br />
      <hr />
      <p>
        <strong>Questa Applicazione (o questa Applicazione)</strong>
        <br />
        La struttura che consente la fornitura del Servizio.
        <br />
        <strong>Accordo</strong>
        <br />
        Qualsiasi rapporto legalmente vincolante o contrattuale tra il Titolare
        <br />e l’Utente disciplinato dai Termini.
        <strong>Utente Commerciale</strong>
        <br />
        Qualsiasi Utente che non corrisponde alla definizione di Consumatore.
        <br />
        <strong>Europeo (o Europa)</strong>
        <br />
        Si applica quando l'Utente, a prescindere dalla nazionalità, si trova
        nell’Unione Europea.
        <br />
      </p>
      <br />
      <strong>Modulo di recesso tipo</strong>
      <br />
      Indirizzato a: Tunehey srl sb - via Modigliani 23, Senigallia (AN), 60019
      hey@tunehey.com Con la presente io/noi notifichiamo il recesso dal
      mio/nostro contratto di vendita dei seguenti beni/servizi:
      _____________________________________________ (inserire qui una
      descrizione dei beni/servizi dal cui acquisto si intende recedere)
      <ul>
        <li>
          Ordinato il: _____________________________________________ (inserire
          la data)
        </li>
        <li>
          Ricevuto il: _____________________________________________ (inserire
          la data)
        </li>
        <li>
          Nome del/dei
          consumatore/i:_____________________________________________
        </li>
        <li>
          Indirizzo del/dei
          consumatore/i:_____________________________________________
        </li>
        <li>Data: _____________________________________________</li>
      </ul>
      (firmare solamente se il presente modulo è notificato in versione
      cartacea)
      <br />
      <strong>Titolare (o Noi)</strong>
      <br />
      Indica la persona fisica o giuridica che fornisce questa Applicazione e/o
      offre il Servizio agli Utenti.
      <br />
      <strong>Prodotto</strong>
      <br />
      Un bene o servizio disponibile tramite questa Applicazione, come ad
      esempio un bene materiale, file digitali, software, servizi di
      prenotazione etc., e qualsiasi altro tipo di prodotto definito
      separatamente nel presente documento, come i Prodotti Digitali.
      <br />
      <strong>Servizio</strong>
      <br />
      Il servizio offerto tramite questa Applicazione così come descritto nei
      Termini e su questa Applicazione.
      <br />
      <strong>Termini</strong>
      <br />
      Tutte le condizioni applicabili all’utilizzo di questa Applicazione e/o
      alla fornitura del Servizio così come descritti in questo documento nonché
      in qualsiasi altro documento o accordo ad esso collegato, nella versione
      rispettivamente più aggiornata.
      <br />
      <strong>Utente (o Tu)</strong>
      <br />
      Indica qualsiasi persona fisica che utilizzi questa Applicazione.
      <br />
      <strong>Valuta Virtuale</strong>
      <br />
      Un valore non monetario con il quale l’Utente può acquistare specifici
      Prodotti offerti su questa Applicazione alle condizioni specificate dal
      Titolare. Tali valori possono essere rappresentati da codici, token,
      immagini digitali etc.
      <br />
      <strong>Consumatore</strong>
      <br />
      Vale come Consumatore qualsiasi Utente considerato tale ai sensi della
      legge applicabile.
      <br />
      <br />
      Ultima modifica: 18 gennaio 2024
    </div>
  );
};

export default TermsAndConditions;
