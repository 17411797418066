import { Card, CardActionArea } from "@mui/material";

function SizeCard({
  tuneType,
  subtitle,
  infosize,
  price,
  clickHandler,
  active = true,
}) {
  return (
    <Card
      variant="elevation"
      className="pop-size-card-outside"
      sx={{
        justifyContent: "space-between",
        borderRadius: "15px",
        alignItems: "center",
      }}
      style={{ backgroundColor: "#FFFCEB" }}
    >
      <CardActionArea
        disabled={!active}
        className="pop-size-card-inside"
        onClick={() => clickHandler(price)}
      >
        <h4 className="offer-claim big-offer-claim big-size-claim big-offer-claim-less-height-more-bottom">
          <span style={{ color: "#F98050", letterSpacing: "0.04em" }}>
            {tuneType && (
              <span
                style={{
                  // fontFamily: "Work Sans",
                  // fontStyle: "italic",
                  fontWeight: "400",
                  letterSpacing: "0.02em",
                  // fontSize: "0.6em",
                }}
              >
                {tuneType}{" "}
              </span>
            )}
            tune
          </span>
          <br />
          <span
            className="size-claim offer-claim big-offer-claim big-offer-claim-less-height-more-bottom"
            style={{ fontWeight: "400" }}
          >
            {subtitle}
          </span>
        </h4>
        <ul
          className="phone-rows phone-size-rows"
          style={{
            fontFamily: "Work Sans",
            fontSize: "25px",
            marginBlock: "0px",
            lineHeight: "27px",
          }}
        >
          <li>
            <span className="yellow-text-background">{infosize}</span>
          </li>
          <li>tutte le personalizzazioni</li>
        </ul>
        <div className="size-card-space" />
        <div className="d-flex flex-row justify-content-end">
          <h6
            className="font-price-gift-value offer-big-font-price-gift-value font-price-size"
            style={{
              fontFamily: "Oswald",
              fontWeight: "500",
              fontSize: "38px",
              lineHeight: "46px",
              color: "#F98050",
              margin: "4.5px",
            }}
          >
            {price}€{" "}
            {/* <h6
              className="phone-saving"
              style={{
                fontFamily: "Oswald",
                fontWeight: "300",
                fontSize: "24px",
                lineHeight: "32px",
                margin: "0px",
              }}
            >
              {" "}
              al carrello
            </h6> */}
          </h6>
        </div>
      </CardActionArea>
    </Card>
  );
}

export default SizeCard;
