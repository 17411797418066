import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";

// A PRIMITIVE FOR SQUARE YELLOW POPUOPS
export default function YellowDialog({ children, opened = true, onClose }) {
  return (
    <div>
      <Dialog open={opened} onClose={onClose} maxWidth={false}>
        <DialogContent
          className="popup800"
          style={{
            backgroundColor: "#FCE868",
            padding: "0px",
            border: "none",
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}
