import './App.css';
import React, { useEffect } from "react";
// import ComponentsNav from './layouts/componentsNav';
import Gift from './gift';
import { useCookies } from "react-cookie";
import _ from 'lodash';
import { isMobile } from './utils/isMobile';


 function App() {
  const [cookies, setCookie, removeCookie] = useCookies(
      "enrolling"
    );

  const deviceMobile = isMobile(); 

    useEffect(() => {
      const debouncedResizeHandler = _.debounce(() => {
        let vh = window.innerHeight * 0.01;
        if (!deviceMobile) {document.documentElement.style.setProperty('--vh', `${vh}px`)};
      }, 200);
  
      window.addEventListener('resize', debouncedResizeHandler);
  
      return () => {
        window.removeEventListener('resize', debouncedResizeHandler);
      };
    }, []);

  return (
    <>
      {/* <ComponentsNav/> */}
      <main className="containerPage seafoam-selection">
        <Gift cookies={cookies} setCookie={setCookie} removeCookie={removeCookie}/>
      </main>  
    </>
  );
}

export default App;
