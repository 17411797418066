/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

import "../assets/localStyle/cursor.css";
import { SENTIMENT_LIST as sentimentList } from "../constants";
import { GENRE_LIST as genreList } from "../constants";
import SearchBar from "./searchBar";
import SongBar from "./songBar";

import { paginate } from "../utils/paginate";
import { clearSearchBar } from "../utils/clearSearchBar";
import {
  fetchData,
  getSongsData,
  getUserCredits,
  purchaseCredit,
} from "../services/contentApi";
import { UserContext } from "../contexts/userContext";

// This component will search based on a text input
// (or from hashtag input that finally will update the text).
// The input will be scomposed on text search or hashtag or playlist code
// recognized by special symbols # and @
// The component also manage the UI of the list.
const SongList = ({
  songs,
  setSongs,
  selectMax,
  setIsSixth,
  setRequestPopSwitchSize,
  setAcquisitionMsg,
  showAcquisitionMsg,
  setJustAddedSong,
  setSelectedSentiment,
  setSelectedGenre,
  debouncedQuery,
  setDebouncedQuery,
  songPlaying,
  handlePlay,
  handlePause,
}) => {
  const baseLen = 100;
  const maxLen = 200;
  //of result list

  const { user, ID_User, userCredits, setUserCredits, creditsExp } =
    useContext(UserContext);

  const [searchQuery, setQuery] = useState("");
  // photographs every 150ms the raw text input and then, scomposed, will feed the search API

  const [playlistChosed, setPlaylistChosed] = useState(false);

  const onDebounce = _.debounce((query) => setQuery(query), 150);

  useEffect(() => {
    onDebounce(debouncedQuery);
    return () => onDebounce.cancel();
  }, [onDebounce, debouncedQuery]);

  const [response, setResponse] = useState([]); //"raw" query response
  const [playdat, setPlaydat] = useState(null); //"pure" response of playlist data (note: not his song)

  const validateTags = useCallback((query) => {
    const tags = query.match(/#[a-zà-ÿ-]+/gi);
    // console.log("tags are", tags);
    const tagsToString = tags ? tags.join(" ") : "";
    // console.log("to string are", tagsToString);
    let itaSentimentTags = [];
    let engSentimentTags = [];
    let itaGenreTags = [];
    let engGenreTags = [];
    for (let item in tags) {
      sentimentList.find((hashtag) => {
        if (hashtag.ita === tags[item]) {
          itaSentimentTags.push(hashtag.ita + " ");
          engSentimentTags.push(hashtag.eng.replace("#", ""));
        }
      });
      genreList.find((hashtag) => {
        if (hashtag.ita === tags[item]) {
          itaGenreTags.push(hashtag.ita + " ");
          engGenreTags.push(hashtag.eng.replace("#", ""));
        }
      });
    }
    setSelectedSentiment(itaSentimentTags.join(""));
    setSelectedGenre(itaGenreTags.join(""));
    return { engSentimentTags, engGenreTags, tagsToString };
  }, []);

  useEffect(() => {
    let querySpace = searchQuery + " "; //provides uniformity to the last text world

    //HASHTAG RECOGNIZE
    const { engSentimentTags, engGenreTags, tagsToString } =
      validateTags(querySpace);

    //PLAYLIST RECOGNIZE
    const playlist = querySpace.match(/@[a-zA-Z0-9À-ÿ]+/g);
    const plsCodeString = playlist ? playlist[0] : "";

    //PREPARATION AND CALL
    const dirtQuery = searchQuery.replaceAll(tagsToString, ""); //we don't research the hashtag as text
    // console.log("dirtQuery: ", dirtQuery);
    const query = dirtQuery === "" ? " " : dirtQuery;
    // %20 codifies space in URLs & %23 codifies #
    fetchData(
      query.replaceAll(" ", "%20").replaceAll("#", "%23"),
      engSentimentTags.join(),
      engGenreTags.join(),
      plsCodeString
    ).then((res) => {
      if (plsCodeString !== "" && res.data.number > 0) {
        // the API will return only the playlist data
        setPlaydat(res.data.contacts[0]);
        if (res.data.contacts[0].Offer == "credit" && userCredits < 1) {
          getUserCredits(ID_User).then((uc) => {
            if (uc.data.number < 1) {
              purchaseCredit(user.email, "B").then(() =>
                getUserCredits(ID_User).then((uc2) =>
                  setUserCredits(uc2.data.contacts[0].Credits_Owned)
                )
              );
            }
          });
        }
        getSongsData(res.data.contacts[0].ID_Collection).then((songs) => {
          setResponse(songs.data.contacts);
        });
      } else {
        // the API will return songs
        setResponse(res.data.contacts);
      }
    });
  }, [searchQuery, validateTags]);

  //UI MANAGEMENT SECTION
  const [other, setOther] = useState(false); //if "visualizza altro" has been clicked
  const [currentSize, setCurrSize] = useState(baseLen);
  function useExpand() {
    setOther(true);
    setCurrSize(maxLen);
  }

  function useSelect(song) {
    if (!full && songs.length < selectMax) {
      var selected = [...songs, song];
      //removes the words of the selected song's title from the search bar (note: only the title) --> then we'll use the API and query.
      const oldTrace = clearSearchBar(song, searchQuery);
      setSongs(selected);
      setJustAddedSong(true);
      setDebouncedQuery(oldTrace);
      setOther(false);
      setCurrSize(baseLen);
    } else if (selectMax == 5) {
      if (user !== null) {
        setIsSixth(true);
        setRequestPopSwitchSize(true);
      } else {
        setAcquisitionMsg("Accedi per selezionare più brani");
        showAcquisitionMsg(true);
      }
    }
  }

  const [full, setFull] = useState(false);
  // note610: when the songList will manage Albums will admit less than 6 or more than 10 songs, while not becoming a normal playlist
  useEffect(() => {
    if (songs.length >= selectMax) {
      setFull(true);
    } else {
      setFull(false);
    }
  }, [songs, selectMax]);

  const [results, setResults] = useState([]); //songs that can be displayed in the results
  const [display, setDisplay] = useState(false); //indicates whether there are potentially more results to show.
  useLayoutEffect(() => {
    //remove duplicate song IDs when there are featuring
    //note that now we display only one artist at a time
    const respo = _.uniqBy(response, (r) => r.ID_Song);
    //the we remove the songs already chosed:
    //it prevents bugs related to double song selection and warning about duplicate key but
    //TODO make more general
    const selectionIds = !Array.isArray(songs)
      ? []
      : songs.map((i) => {
          return i.ID_Song;
        });
    const depured = respo.filter((s) => !selectionIds.includes(s.ID_Song));

    //pagination and overload test
    const results = paginate(depured, 1, currentSize);
    let display = false;
    depured.length > baseLen ? (display = true) : (display = false);
    setResults(results);
    setDisplay(display);
  }, [songs, response, other, currentSize]);

  return (
    <div className="songlist-container-pre d-flex flex-column align-items-center">
      <div className="">
        {!playlistChosed ? (
          <SearchBar
            value={debouncedQuery}
            onChange={setDebouncedQuery}
            disabled={playlistChosed}
          />
        ) : null}
        <div className="songlist-container-post">
          <div className="song-results-container">
            {/* RESULTS */}
            {results.map((item) => (
              <SongBar
                key={item.ID_Song}
                chosed={false}
                data={item}
                onSelect={useSelect}
                onPlay={() => handlePlay(item)}
                onPause={handlePause}
                playingSong={songPlaying}
              />
            ))}
            {/* conditional render of the button - let see it if: display is true, it has not already been clicked in the same session, results lenght make sense for it */}
            {!other && results.length < currentSize + 1 && display && (
              <button
                type="other-button" //regola in app.css per text decoration underline on hover
                onClick={useExpand}
                className="btn btn-light-disabled-color border-white"
                style={{ paddingBottom: "18px" }}
              >
                Visualizza Altro
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SongList;
