import PrimaryButton from "../../components/common/primaryButton";
import YellowDialog from "../../layouts/popup800";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function PopThanks({ opened, onRecLater }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/thanks");
    //it's not necessary to also change the Page state, and would leads to errors
  }, []);

  return (
    <YellowDialog opened={opened} onClose={onRecLater}>
      <div
        className="phone-pop-thanks d-flex flex-column justify-content-around"
        style={{
          paddingInline: "58px",
          height: "100%",
        }}
      >
        <div>
          <h1
            className="font-title2-Osw first-up-elem-spacer"
            style={{ textAlign: "center" }}
          >
            <span className="white-text-background">Grazie</span> per essere tra{" "}
            <br /> le prime e i primi{" "}
            <span className="white-text-background">Tunehers</span>!
          </h1>

          <h2 className="font-gratitude">
            Resta aggiornato e continua a diffondere con noi{" "}
            <br className="no-phone" />
            il <span className="strong600">valore della musica</span> e la{" "}
            <span className="strong600">bellezza del dono</span>
            .
            <br />
            <br className="no-phone" />
          </h2>
          <h2 className="font-gratitude">Buona consegna del tune :)</h2>
        </div>
        <div className="d-flex flex-column justify-content-around phone-thanks-H">
          <br className="desktop" />
          <div className="d-flex flex-row justify-content-center last-down-elem-spacer">
            <Link to="/" style={{ textDecoration: "none" }}>
              <PrimaryButton text="HOMEPAGE" clickHandler={() => {}} />
            </Link>
          </div>
        </div>
      </div>
    </YellowDialog>
  );
}

export default PopThanks;
