import React from "react";
import MiniSongBar from "./miniSongBar";

function PlaylistSongs({
  editorial,
  songPlaying,
  handlePlay,
  handlePause,
  miniPlaylist,
}) {
  return (
    <>
      {editorial.Playlist.map((item, index) => {
        if (miniPlaylist && editorial.Safe_Five.includes(index)) {
          return (
            <MiniSongBar
              key={item.ID_Song}
              chosed={false}
              data={item}
              onPlay={() => handlePlay(item)}
              onPause={() => handlePause()}
              playingSong={songPlaying}
            />
          );
        } else if (!miniPlaylist) {
          return (
            <MiniSongBar
              key={item.ID_Song}
              chosed={false}
              data={item}
              onPlay={() => handlePlay(item)}
              onPause={() => handlePause()}
              playingSong={songPlaying}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default PlaylistSongs;
