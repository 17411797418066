import { Card } from "@mui/material";
import { IoAdd, IoCloseOutline } from "react-icons/io5";

//THIS COMPONENT IS A PRIMITIVE FOR OFFERS THAT DISPLAY THIS TYPE OF INFORMATION
//IN A BIGGER SHAPE
function OfferBig({
  form,
  claim,
  row1,
  row2,
  price,
  saving,
  gifts,
  onClickAdd,
  onClickRmv,
}) {
  return (
    // we recognize classes for "phone" and one "mid" class...for all other sizes defined here, are both for desktop and mid
    <Card
      className="phone-offer mid-offer"
      variant="outlined"
      sx={{
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        padding: "15px",
        borderRadius: "10px",
        alignItems: "center",
      }}
      style={{
        width: "605px",
        height: "156px",
        backgroundColor: "#FFFCEB",
        marginBottom: "25px",
      }}
    >
      <span
        className="phone-tag"
        style={{ marginLeft: "15px", width: "280px" }}
      >
        <h4 className="offer-claim big-offer-claim">{claim}</h4>
        <p
          className="phone-rows"
          style={{
            fontFamily: "Work Sans",
            fontSize: "25px",
            marginBlock: "0px",
            lineHeight: "27px",
          }}
        >
          {row1}
          <br />
          {row2}
        </p>
      </span>
      <span>
        <h6
          className="font-price-gift-value offer-big-font-price-gift-value"
          style={{
            fontFamily: "Oswald",
            fontWeight: "500",
            fontSize: "42px",
            lineHeight: "46px",
            color: "#F98050",
            margin: "4.5px",
          }}
        >
          {price} €
        </h6>
        <hr
          style={{
            height: "1px",
            borderWidth: "1px",
            opacity: "1",
            margin: "0px",
          }}
        />
        <h6
          className="phone-saving"
          style={{
            fontFamily: "Oswald",
            fontWeight: "300",
            fontSize: "16px",
            lineHeight: "24px",
            margin: "0px",
          }}
        >
          (Risparmi {saving} euro)
        </h6>
        <h6
          className="phone-equal"
          style={{
            fontFamily: "Oswald",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "28px",
            color: "#F98050",
            margin: "0px",
          }}
        >
          ={gifts} regali
        </h6>
      </span>
      <button
        className="transparentBtn mt-auto"
        onClick={form !== "selected" ? onClickAdd : onClickRmv}
      >
        <IoAdd
          className="phone-btn-big-as-sm"
          fontSize={40}
          style={
            form !== "selected"
              ? { color: "#F98050", opacity: 0.8 }
              : {
                  color: "#2C2B26",
                  opacity: 0.5,
                  transform: "rotate(45deg)",
                  transition: "0.1s ease",
                }
          }
        />
      </button>
    </Card>
  );
}

export default OfferBig;
