import React, { useState } from "react";
import { Drawer } from "@mantine/core";
import { IoCloseOutline } from "react-icons/io5";

// Inline CSS
const styles = `
  .mantine-71srt6 {
    color: #2C2B26;
    background-color: #F1E8D5;
    border-radius: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    outline: 0;
    padding: 23px 18px 0px 18px;
  }

  .mantine-bsiqi3 {
    z-index: 1500;
  }
`;

const BottomSheet = ({ title, isOpen, onSheet, children }) => {
  return (
    <div>
      <style>{styles}</style>
      <Drawer
        opened={isOpen}
        onClose={() => onSheet("")}
        position="bottom"
        size={546}
        withCloseButton={false}
      >
        <div className="song-tool-header song-sheet-header">
          <IoCloseOutline
            className="song-sheet-menu-icon"
            onClick={() => onSheet("")}
          />
          <span className="song-tool-title song-sheet-title" translate="no">
            {title}
          </span>
        </div>
        <hr className="song-tool-divider" />
        <div>{children}</div>
      </Drawer>
    </div>
  );
};

export default BottomSheet;
