export const SENTIMENT_LIST = [
  { lab: "amore", ita: "#amore", eng: "#love", col: "#F7BFA0" },
  { lab: "amicizia", ita: "#amicizia", eng: "#friendship", col: "#FCF0B8" },
  { lab: "famiglia", ita: "#famiglia", eng: "#family", col: "#C7D697" },
  { lab: "energia", ita: "#energia", eng: "#energy", col: "#AAD3C8" },
  { lab: "felicità", ita: "#felicità", eng: "#happiness", col: "#FCF0B8" },
  { lab: "spensieratezza", ita: "#spensieratezza", eng: "#carefreeness", col: "#E3D1A4" },
  { lab: "relax", ita: "#relax", eng: "#relax", col: "#C7D697" },
  { lab: "dolore", ita: "#dolore", eng: "#pain", col: "#F7BFA0" },
  { lab: "tristezza", ita: "#tristezza", eng: "#sadness", col: "#AAD3C8" },
  { lab: "nostalgia", ita: "#nostalgia", eng: "#nostalgia", col: "#E3D1A4" },
  { lab: "coraggio", ita: "#coraggio", eng: "#courage", col: "#F7BFA0" },
  { lab: "speranza", ita: "#speranza", eng: "#hope", col: "#C7D697" },
  { lab: "rabbia", ita: "#rabbia", eng: "#anger", col: "#E3D1A4" },
];

export const GENRE_LIST = [
  { lab: "indie", ita: "#indie", eng: "#indie", col: "#C7D697" },
  { lab: "pop", ita: "#pop", eng: "#pop", col: "#FCF0B8" },
  { lab: "hip-hop", ita: "#hip-hop", eng: "#hip-hop", col: "#AAD3C8" },
  { lab: "soul", ita: "#soul", eng: "#soul", col: "#E3D1A4" },
  { lab: "rap", ita: "#rap", eng: "#rap", col: "#F7BFA0" },
  { lab: "evergreen", ita: "#evergreen", eng: "#evergreen", col: "#E3D1A4" },
  { lab: "international", ita: "#international", eng: "#international", col: "#C7D697" },
  { lab: "folk", ita: "#folk", eng: "#folk", col: "#F7BFA0" },
  { lab: "rock", ita: "#rock", eng: "#rock", col: "#F7BFA0" },
  { lab: "elettronica", ita: "#elettronica", eng: "#elettronic", col: "#C7D697" },
  { lab: "jazz", ita: "#jazz", eng: "#jazz", col: "#AAD3C8" },
  { lab: "dance", ita: "#dance", eng: "#dance", col: "#E3D1A4" },
  { lab: "classica", ita: "#classica", eng: "#classic", col: "#FCF0B8" },
];