import YellowDialog from "../../layouts/popup800";
import PrimaryButton from "../../components/common/primaryButton";

// THIS POPUP THAT MAKE THE USER CONFIRM THE USE OF A CREDIT
// is very hard styled for desktop, with a lot of midphone (and one phone) overwrite classes
function PopGems({ opened, type, owned, used, onGems, onClose }) {
  var [word1, word2] =
    type === "L"
      ? ["Lifestyle", "Gifter"]
      : type === "E"
      ? ["Gifting", "Explorer"]
      : type === "B"
      ? ["Pioneer's", "Bonus"]
      : type === "M"
      ? ["Artist", "Offer"]
      : ["Service", "Credit"];
  return (
    <YellowDialog opened={opened} onClose={onClose}>
      <div
        className="midphone-pop-gems"
        style={{
          padding: "100px 58px",
        }}
      >
        <h1
          className="font-title1-Osw midphone-boost-ft1o-2 midphone-credit-disclaimer"
          style={{ textAlign: "center", marginBottom: "80px" }}
        >
          Vuoi usare 1 regalo del
          <br />
          pacchetto{" "}
          <span className="green-text-background" style={{ color: "#FFFCEB" }}>
            {word1}
            <br />
            {word2}
          </span>{" "}
          ?
        </h1>

        <h2
          className="phone-credit-confirm"
          style={{
            fontFamily: "Work sans",
            fontWeight: "300",
            fontSize: "45px",
            lineHeight: "29px",
            textAlign: "center",
            marginBottom: "22px",
          }}
        >
          Riscattati:
        </h2>
        <h4
          className="midphone-credit-confirm-value"
          style={{
            fontFamily: "Oswald",
            fontWeight: "600",
            fontSize: "55px",
            lineHeight: "29px",
            color: "#F98050",
            textDecoration: "underline",
            textDecorationColor: "#2C2B26",
            textAlign: "center",
            marginBottom: "110px",
          }}
        >
          {used}/{used + owned}
        </h4>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row justify-content-center">
            <PrimaryButton text="CONFERMA" clickHandler={onGems} />
          </div>
        </div>
      </div>
    </YellowDialog>
  );
}

export default PopGems;
