export function clearSearchBar(song, query) {
  // Extract words starting with '#' for later use
  const hashtagWords = query
    .split(/\s+/)
    .filter((word) => word.startsWith("#"));
  const laterHashtag =
    hashtagWords.length > 0 ? hashtagWords.join(" ") + " " : "";

  // Work with the rest of the query
  const cleanedQuery = query.replace(/#\w+/g, "").replace(/\s+/g, " ").trim();

  const queryToCheck = cleanedQuery.toLowerCase();
  const stageName = song.Stage_Name.toLowerCase();

  // Check if the stage name is at the beginning of the cleaned query (so it's probably an intended search)
  if (
    queryToCheck.startsWith(stageName) ||
    stageName.startsWith(queryToCheck)
  ) {
    // Calculate the length of the intersection and take the shortest part from the original cleaned query
    const intersectionLen = Math.min(stageName.length, queryToCheck.length);
    const result = cleanedQuery.substring(0, intersectionLen);

    // Add hashtag words back at the beginning of the result
    return laterHashtag + result;
  } else {
    // Return an empty string if no intersection at the beginning
    return laterHashtag + "";
  }
}
