import ReactPixel from "../../services/facebookPixel";

function RedeemButton({ text, clickHandler, active = true, inside = false }) {
  const handleClick = () => {
    clickHandler();
    ReactPixel.trackCustom("cta-redeem");
  };

  return (
    <button
      className={`secondaryRedeemBtn ${active ? "active" : ""} ${
        inside ? "inside" : ""
      }`}
      disabled={!active}
      style={!active ? { opacity: "0.4" } : {}}
      onClick={() => {
        handleClick();
      }}
    >
      {text}
    </button>
  );
}

export default RedeemButton;
