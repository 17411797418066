import { useNavigate } from "react-router-dom";
import SizeChoice from "../components/common/sizeChoice";

function SelectSize({ setGiftSize, sceneChange, pageChange }) {
  sceneChange("land");

  const navigate = useNavigate();

  const handleClick = (size) => {
    if (size == "5,99") {
      setGiftSize("S");
    } else setGiftSize("M");
    sceneChange("light");
    navigate("/create");
    pageChange("create/playlist");
  };

  return (
    <div
      className="exact-page-net-header d-flex flex-column justify-content-around align-items-center"
      style={{ backgroundColor: "#FCE868" }}
    >
      <SizeChoice handleClick={handleClick} />
    </div>
  );
}

export default SelectSize;
