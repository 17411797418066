import { useState, useEffect } from "react";
import WheelPrimaryButton from "../components/common/wheelPrimaryButton";
import { getCreatedGifts } from "../services/contentApi";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

import PopGift from "./popups/popGift";
import HERO from "./homepage/hero";
import DemoSection from "./homepage/demo";
import Footer from "../layouts/footer";
import SlidingSection from "./homepage/slidingSection";
import Feedbacks from "./homepage/feedbacks";
import { isMobile } from "../utils/isMobile";
import TopBanner from "../layouts/banner";
import Pricing from "./homepage/pricing";
import FaqSection from "./homepage/faqSection";
import Artists from "./homepage/artists";

const Home = ({
  setName,
  setDedication,
  setMomentID,
  setOriginalMoment,
  setMoment,
  setCollection,
  sceneChange,
  pageChange,
  userId,
  setSongs,
  setIcon,
  setCover,
  setFakeCover,
  setGiftId,
  setFilenameIcon,
  setFilenameCover,
  onSave,
  setGiverName,
  setGiftSize,
}) => {
  const navigate = useNavigate();
  const [giftList, setGiftList] = useState([]);

  const deviceMobile = isMobile();
  const isDisplayedBanner = false;

  useEffect(() => {
    pageChange("");
    sceneChange("light");
  }, []);

  const [openPop, setOpenPop] = useState(false);
  const [openLaterPop, setOpenLaterPop] = useState(
    localStorage.getItem("popList") !== null
      ? localStorage.getItem("popList")
      : false
  ); //used to persist the session but delays the real openPop update

  useEffect(() => {
    localStorage.setItem("popList", openPop);
  }, [openPop]);

  // if user is arriving it wait for it - used opening the process (..or the popup, if arrives!)
  const waitForUserId = async () => {
    if (userId !== "evaluating") {
      return userId;
    } else {
      await new Promise((resolve) => setTimeout(resolve, 250));
      return waitForUserId();
    }
  };

  const handleClickOpen = async () => {
    setGiftId("");
    setIcon("");
    setFilenameIcon("");
    setCover("");
    setFakeCover("");
    setFilenameCover("");

    await waitForUserId();

    if (userId) {
      setOpenPop(true);
      const res = await getCreatedGifts(userId);
      if (res.data.number > 0) {
        setGiftList(res.data.contacts);
      }
    } else {
      setOpenPop(true);
      // localStorage.setItem("popList", true);
      navigate("/create");
      pageChange("create/playlist");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (openLaterPop == "true" && userId !== "null" && userId !== null) {
        handleClickOpen();
      }
    }, 1000); //the popup waits the page
  }, [userId]);

  const handleClose = () => {
    setOpenPop(false);
    setOpenLaterPop(false);
    localStorage.setItem("popList", false);
  };

  const callRedeem = () => {
    navigate("/redeem");
    pageChange("redeem");
    sceneChange("light");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // VISION under SLIDING section ANIMATION
  const handleScroll = () => {
    const slidingSection = document.querySelector(".sliding-section");
    // const intermediateSector = document.querySelector(
    //   ".first-fixed-second-sliding-sector"
    // );
    // const stuntIntermediateSector = document.querySelector(
    //   ".stunt-intermediate-sector"
    // );
    const visionSection = document.querySelector(".vision-section");
    const stuntVisionSection = document.querySelector(".stunt-vision-section");

    if (slidingSection && visionSection) {
      const slidingSectionRect = slidingSection.getBoundingClientRect();
      // const intermediateSectorRect = intermediateSector.getBoundingClientRect();
      // const visionSectionRect = visionSection.getBoundingClientRect();

      if (slidingSectionRect.top < 0 && slidingSectionRect.bottom > 0) {
        // Make the Vision section fixed (adding a "stunt" page to preserve the scrolling normal flow movement)
        visionSection.style.position = "fixed";
        visionSection.style.top = "0";
        stuntVisionSection.style.position = "relative";
        stuntVisionSection.style.top = "0";
      } else {
        // Restore original styles of Vision section and his stunt
        visionSection.style.position = "relative";
        visionSection.style.top = "0";
        stuntVisionSection.style.position = "fixed";
        stuntVisionSection.style.top = "0";
      }
    }
  };

  return (
    <>
      {isDisplayedBanner && <TopBanner />}

      {/* HERO content */}
      <HERO handleClickOpen={handleClickOpen} callRedeem={callRedeem} />

      {/* gift manager */}
      <PopGift
        setName={setName}
        setDedication={setDedication}
        setMomentID={setMomentID}
        setOriginalMoment={setOriginalMoment}
        setMoment={setMoment}
        setCollection={setCollection}
        open={openPop}
        onClose={handleClose}
        pageChange={pageChange}
        sceneChange={sceneChange}
        setSongs={setSongs}
        setIcon={setIcon}
        setCover={setCover}
        setFakeCover={setFakeCover}
        setGiftId={setGiftId}
        setFilenameIcon={setFilenameIcon}
        setFilenameCover={setFilenameCover}
        giftList={giftList}
        setGiftList={setGiftList}
        userId={userId}
        setGiverName={setGiverName}
        setGiftSize={setGiftSize}
      />

      {/* FEEDBACK BAND */}
      <Feedbacks isMobile={deviceMobile} />

      {/* DEMO SECTION */}
      <DemoSection clickHandler={handleClickOpen} customEventName="cta2" />

      {/* SLIDING SECTION */}
      <SlidingSection />

      {/* <div
        className="first-fixed-second-sliding-sector"
        style={{ width: "100%" }}
      >
        <Pricing />
        <FaqSection />
        <Artists />
          </div>
        <div
          className="home-intermediate-stunt-height stunt-intermediate-sector "
          style={{ zIndex: -1000 }} //this section only serves as a scroll background spacer during the time is original (vision) section is removed from the normal flow
          /> */}

      {/* VISION SECTION */}
      <div
        className="cream-background-color exact-page d-flex flex-column justify-content-center align-items-center vision-section"
        align="center"
      >
        <h1 className="font-subtitle2-OswMed">
          {" "}
          Crediamo che un mondo <br /> con più{" "}
          <span className="orange-color"> musica </span> e{" "}
          <span className="orange-color">regali </span> <br /> sia certamente
          più felice{" "}
        </h1>
        <br />
        <br />
        <WheelPrimaryButton
          text="REGALA"
          clickHandler={handleClickOpen}
          customEventName="cta3"
        />
      </div>
      <div
        className="exact-page stunt-vision-section"
        style={{ zIndex: -1000 }} //this section only serves as a scroll background spacer during the time is original (vision) section is removed from the normal flow
      />
      <Footer pageChange={pageChange} sceneChange={sceneChange} />
    </>
  );
};

export default Home;
