export const userFeedbacks = [
  {
    id: 1,
    feedback:
      "“Non conoscevo le canzoni ricevute e per questo le ho ascoltate anche più attentamente, per capire quello che voleva dirmi. È stato speciale.”",
    person: "Alessandro M.",
  },
  {
    id: 2,
    feedback:
      "“Adesso ogni volta che ascolterò quelle canzoni penserò al regalo”",
    person: "Chiara F.",
  },
  {
    id: 3,
    feedback: "“Uhhh uno dei regali più belli mai ricevuti!”",
    person: "Sara Maria R.",
  },
  {
    id: 4,
    feedback:
      "“Regalare delle canzoni lo considero qualcosa di intimo, quindi davvero bello da ricevere”",
    person: "Chiara G.",
  },
  {
    id: 5,
    feedback:
      "“Creare il regalo è stato molto bello, ho scoperto io stessa canzoni nuove in maniera molto diversa dal solito”",
    person: "Sara I.",
  },
];
