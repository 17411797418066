import React, { useEffect, useState } from "react";
import RollingHashtags from "./rollingHashtags";
import SongsTool from "./common/songTool";
import { IoIosArrowDown } from "react-icons/io";
import PlaylistNavigator from "./playlistNavigator";
import "../assets/localStyle/cursor.css";
import { getTopArtist } from "../services/contentApi";
import BottomSheet from "./common/bottomSheet";

function SongToolBox({
  selectedSentiment,
  setSelectedSentiment,
  selectedGenre,
  setSelectedGenre,
  setDebouncedQuery,
  songPlaying,
  handlePlay,
  handlePause,
  setSongs,
  songs,
  giftSize,
  deviceMobile,
  openTool,
  handleTool,
  openSheet,
  setOpenSheet,
  openFilter,
  setOpenFilter,
  hideInvisibleLayer,
}) {
  const [top30, setTop30] = useState(["wait"]);
  useEffect(() => {
    const fetchTopArtists = async () => {
      try {
        const response = await getTopArtist();
        setTop30(response.data); // Assuming the data structure is an array of artists
      } catch (error) {
        console.error(error);
      }
    };

    fetchTopArtists();
  }, []);

  const handleButtonClick = (event) => {
    // Prevent the click event from reaching the parent container
    event.stopPropagation();
  };

  return (
    <>
      <div className="tool-section">
        {/* FIRST */}
        <SongsTool
          title="Filtri"
          isOpen={openTool === "Filtri"}
          onTool={handleTool}
        >
          <>
            <p className="filter-category">
              Sentimenti
              <button
                className="transparentBtn"
                style={{ padding: 0 }}
                onClick={() => {
                  if (openFilter == "sentiment") {
                    setOpenFilter("");
                  } else setOpenFilter("sentiment");
                }}
              >
                <IoIosArrowDown
                  className={`${
                    openFilter == "sentiment"
                      ? "reversed-arrow"
                      : "normal-arrow"
                  } `}
                  color="#F98050"
                  style={{ marginLeft: "10px" }}
                />
              </button>
            </p>
            {openFilter == "sentiment" && (
              <RollingHashtags
                type="sentiments"
                selectedHashtag={selectedSentiment}
                setSelectedHashtag={setSelectedSentiment}
                setDebouncedQuery={setDebouncedQuery}
                otherPart={selectedGenre}
              />
            )}
            <p className="filter-category">
              Generi
              <button
                className="transparentBtn"
                style={{ padding: 0 }}
                onClick={() => {
                  if (openFilter == "genre") {
                    setOpenFilter("");
                  } else setOpenFilter("genre");
                }}
              >
                <IoIosArrowDown
                  className={`${
                    openFilter == "genre" ? "reversed-arrow" : "normal-arrow"
                  } `}
                  color="#F98050"
                  style={{ marginLeft: "10px" }}
                />
              </button>
            </p>
            {openFilter == "genre" && (
              <RollingHashtags
                type="genres"
                selectedHashtag={selectedGenre}
                setSelectedHashtag={setSelectedGenre}
                setDebouncedQuery={setDebouncedQuery}
                otherPart={selectedSentiment}
              />
            )}
          </>
        </SongsTool>

        {/* SECOND */}
        <SongsTool
          title="Artisti"
          isOpen={openTool === "Artisti"}
          onTool={handleTool}
          handleArtistClick={() => {}}
        >
          <div className="artist-tool-layout">
            <p>
              Top 30 sempre aggiornata degli artisti più regalati. <br />
              Clicca per navigare i brani.
            </p>
            <ol className="artist-top30-container">
              {top30.map((artist, index) => (
                <li key={index}>
                  <span
                    className="clickable-artist"
                    onClick={() => {
                      setDebouncedQuery(artist);
                    }}
                  >
                    {artist}
                  </span>
                </li>
              ))}
            </ol>
          </div>
        </SongsTool>

        {/* THIRD */}
        <SongsTool
          title="Playlists"
          isOpen={openTool === "Playlists"}
          onTool={handleTool}
        >
          <PlaylistNavigator
            songs={songs}
            setSongs={setSongs}
            songPlaying={songPlaying}
            handlePlay={handlePlay}
            handlePause={handlePause}
            onSheet={() => {}}
            giftSize={giftSize}
          />
        </SongsTool>
      </div>
      {/* ------------------------- */}
      <div
        id="sheet-section"
        className={`${hideInvisibleLayer ? "" : "sheet-section"}`}
      >
        {/* first */}
        <BottomSheet
          title="Filtri"
          isOpen={openSheet === "Filtri"}
          onSheet={setOpenSheet}
        >
          <div style={{ padding: "16px" }}>
            <p className="filter-category" style={{ color: "#F98050" }}>
              Sentimenti
            </p>
            <RollingHashtags
              type="sentiments"
              selectedHashtag={selectedSentiment}
              setSelectedHashtag={setSelectedSentiment}
              setDebouncedQuery={setDebouncedQuery}
              otherPart={selectedGenre}
            />
            <br />
            <p className="filter-category" style={{ color: "#F98050" }}>
              Generi
            </p>
            <RollingHashtags
              type="genres"
              selectedHashtag={selectedGenre}
              setSelectedHashtag={setSelectedGenre}
              setDebouncedQuery={setDebouncedQuery}
              otherPart={selectedSentiment}
            />
          </div>
        </BottomSheet>

        {/* second */}
        <BottomSheet
          title="Artisti"
          isOpen={openSheet === "Artisti"}
          onSheet={setOpenSheet}
        >
          <div style={{ padding: "16px" }}>
            <div className="artist-tool-layout">
              <p>
                Top 30 sempre aggiornata degli artisti più regalati. Clicca per
                navigare i brani.
              </p>
              <ol className="artist-top30-container">
                {top30.map((artist, index) => (
                  <li key={index}>
                    <span
                      className="clickable-artist"
                      onClick={() => {
                        setDebouncedQuery(artist);
                        setTimeout(() => {
                          setOpenSheet("");
                        }, 200);
                      }}
                    >
                      {artist}
                    </span>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </BottomSheet>

        {/* third */}
        <BottomSheet
          title="Playlists"
          isOpen={openSheet === "Playlists"}
          onSheet={setOpenSheet}
        >
          <PlaylistNavigator
            songs={songs}
            setSongs={setSongs}
            songPlaying={songPlaying}
            handlePlay={handlePlay}
            handlePause={handlePause}
            deviceMobile={deviceMobile}
            onSheet={setOpenSheet}
            giftSize={giftSize}
          />
        </BottomSheet>
      </div>
    </>
  );
}

export default SongToolBox;
