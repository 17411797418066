import React, { useEffect, useState, useRef } from "react";
import SizeChoice from "../../components/common/sizeChoice";

function Pricing() {
  const [highlightedWords, setHighlightedWords] = useState([]);
  const [widenedHighlight, setWidenedHighlight] = useState([]);
  const animationStart = 3000;
  const highlightDelay = 600;

  const intersectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Target element is now visible, trigger your animations here
            highlightWords();
            widenHighlights();
            observer.disconnect(); // Disconnect the observer after triggering animations
          }
        });
      },
      { threshold: 0.9 } // share of the page that have to be visible to trigger the animation
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup when component unmounts
    };
  }, []);

  const highlightWords = () => {
    const words = ["dono", "davvero", "su misura"];
    let delay = animationStart;

    words.forEach((word, index) => {
      setTimeout(() => {
        setHighlightedWords((prev) => [...prev, word]);
      }, delay);

      delay += highlightDelay;
    });
  };

  const widenHighlights = () => {
    const words = ["dono", "davvero", "su misura"];
    let delay = animationStart + 4 * highlightDelay;

    words.forEach((word, index) => {
      setTimeout(() => {
        setWidenedHighlight(words);
      }, delay);
    });
  };

  return (
    <div
      ref={intersectionRef}
      className="exact-page d-flex flex-column justify-content-center align-items-center "
      style={{ backgroundColor: "#FCE868" }}
    >
      <div>
        <h2
          className="font-subtitle2-OswMed "
          style={{ fontWeight: "500", textAlign: "center" }}
        >
          Due opzioni
        </h2>
        <h2
          className="font-subT1-WS home-space-1"
          style={{ fontWeight: "500", textAlign: "center" }}
        >
          per cucire <br className="phone" />
          un{" "}
          <span
            className={`word-appearing-highlight ${
              highlightedWords.includes("dono") && "appearing-highlight"
            } ${widenedHighlight.includes("dono") && "widening-highlight"}`}
          >
            dono
          </span>{" "}
          <span
            className={`word-appearing-highlight ${
              highlightedWords.includes("davvero") && "appearing-highlight"
            } ${widenedHighlight.includes("davvero") && "widening-highlight"}`}
          >
            davvero
          </span>{" "}
          <span
            className={`word-appearing-highlight ${
              highlightedWords.includes("su misura") && "appearing-highlight"
            } ${
              widenedHighlight.includes("su misura") && "widening-highlight"
            }`}
          >
            su misura
          </span>{" "}
        </h2>
      </div>
      <div className="home-space-1">
        <SizeChoice onlyCards={true} />
      </div>
      <div>
        {/* <h2
          className="font-subT1-WS"
          style={{ fontWeight: "500", textAlign: "center" }}
        >
          per cucire <br className="phone" />
          un{" "}
          <span
            className={`word-appearing-highlight ${
              highlightedWords.includes("dono") && "appearing-highlight"
            } ${widenedHighlight.includes("dono") && "widening-highlight"}`}
          >
            dono
          </span>{" "}
          <span
            className={`word-appearing-highlight ${
              highlightedWords.includes("davvero") && "appearing-highlight"
            } ${widenedHighlight.includes("davvero") && "widening-highlight"}`}
          >
            davvero
          </span>{" "}
          <span
            className={`word-appearing-highlight ${
              highlightedWords.includes("su misura") && "appearing-highlight"
            } ${
              widenedHighlight.includes("su misura") && "widening-highlight"
            }`}
          >
            su misura
          </span> 
        </h2>*/}
      </div>
    </div>
  );
}

export default Pricing;
