// OUR SECONDARY BUTTON IS INTERESTING CAUSE IS ALSO A PRIMITIVE FOR OTHER BUTTONS (like recording),
// because is based on Oxygen font, and finally because it has to adapt to the various background colors
function SecondaryButton({
  children,
  text,
  scene = "light",
  clickHandler,
  active = true,
  isBig = false,
  isSaving = false,
  saveFeedback = false,
}) {
  const textColor = scene === "dark" ? "#FFFCEB" : "#2C2B26";
  const sizeClass = isBig ? "bigSecondaryBtn" : "secondaryBtn";
  const saveClass = isSaving ? "saving" : "";
  const confirmSaveClass =
    saveFeedback === "correct"
      ? "saved"
      : saveFeedback === "error"
      ? "not-saved"
      : "";

  return (
    <button
      className={`transparentBtn ${sizeClass} d-flex align-items-center ${saveClass} ${confirmSaveClass}`}
      style={{ WebkitTextStrokeColor: textColor }}
      disabled={active ? false : true}
      onClick={() => {
        clickHandler();
      }}
    >
      {children}
      {text}
    </button>
  );
}

export default SecondaryButton;
