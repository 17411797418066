import { motion, AnimatePresence } from "framer-motion";

function MockupCircle({
  position,
  movement,
  // isReady, //in precedence used to call the render of the motion div
  isActive,
  clickHandler,
  index,
}) {
  const circleVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: [0.3, 1.5, 1.1], // Scale from 0 to 1.5 back to 1
      transition: { duration: 1 }, // One-second duration
    },
  };

  return (
    <motion.div
      className={`mockup-circle ${
        isActive ? "active" : ""
      } mockup-circle-${index}`} // Add the index as part of the class
      style={{
        top: position.y,
        left: position.x,
        translateY: movement,
      }}
      initial="hidden"
      animate={isActive ? "visible" : "hidden"}
      exit="hidden"
      variants={circleVariants}
      onClick={clickHandler}
    />
  );
}

export default MockupCircle;
