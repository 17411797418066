// THE USER BUTTON MAKE THE USER ACCESS TO THE USER MENU
// and FULL MENU DISPLAYING AND INTERACTION FEATURE
//
// the dropdown component is from MANTINE library

import { HiOutlineUser } from "react-icons/hi";
import { Menu } from "@mantine/core";

function UserButton({
  scene = "light",
  clickHandler,
  credits,
  creditMessage,
  email,
}) {
  const color = scene === "dark" ? "#FFFCEB" : "#2C2B26";

  return (
    <Menu
      trigger="hover"
      openDelay={50}
      closeDelay={50}
      withArrow
      offset={4}
      position="bottom-end"
      arrowPosition="center"
      radius={"md"}
      color={"#FFFCEB"}
      zIndex={1100}
    >
      <Menu.Target>
        <button
          className={`transparentBtn d-flex align-items-center`}
          style={{ color: color }}
        >
          <HiOutlineUser className="userBtn" />
        </button>
      </Menu.Target>
      <Menu.Dropdown trigger="hover">
        <p className="font-hd-menu menu-dropdown-label row-margin-block">
          {email}
        </p>
        <hr
          style={{
            borderWidth: "0.5px",
            opacity: "0.6",
            borderColor: "#E4D9C5",
            margin: "0px",
          }}
        />
        {credits && (
          <>
            {creditMessage}
            <hr
              style={{
                borderWidth: "0.5px",
                opacity: "0.6",
                borderColor: "#E4D9C5",
                margin: "0px",
              }}
            />
          </>
        )}
        <button
          className={
            "transparentBtn secondaryBtn d-flex align-items-center row-margin-block"
          }
          style={{
            WebkitTextStrokeColor: "#2B2C26",
          }}
          onClick={() => {
            clickHandler();
          }}
        >
          LOG OUT
        </button>

        {/* Other items ... */}
      </Menu.Dropdown>
    </Menu>
  );
}

export default UserButton;
