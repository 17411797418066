import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaLinkedin,
} from "react-icons/fa";
import { LiaCookieBiteSolid } from "react-icons/lia";

export default function Footer({ pageChange, sceneChange }) {
  let navigate = useNavigate();
  const goFaq = () => {
    navigate("/faq");
    pageChange("faq");
    sceneChange("light");
  };

  const goPP = () => {
    navigate("/privacypolicy");
    pageChange("privacypolicy");
    sceneChange("light");
  };

  const goCP = () => {
    navigate("/cookiepolicy");
    pageChange("cookiepolicy");
    sceneChange("light");
  };

  const goTC = () => {
    navigate("/termsandconditions");
    pageChange("termsandconditions");
    sceneChange("light");
  };

  return (
    // the footer is in his specific tag with flex column (centered) orientation, his own class, and seafom selection style
    <footer className="d-flex flex-column justify-content-center footer black-background-color seafoam-selection-all">
      {/* FIRST ROW, ------------------------------------------------------
      specificity: it wraps, and the APP BADGES appear only in phone setting */}
      <div
        className="d-flex flex-row justify-content-between flex-wrap "
        style={{ marginTop: "auto" }}
      >
        {/* logo */}
        <span className="d-flex flex-row align-items-center brand-footer">
          <img src={Logo} className="logo-footer no-selection" />
          <h1 className="brand-name-footer" translate="no">
            tunehey
          </h1>
        </span>

        {/* APP BADGES [phone] */}
        <span className="phone" style={{ marginBlock: "10px", width: "120px" }}>
          <a href="https://play.google.com/store/apps/details?id=com.tunehey.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Disponibile su Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/it_badge_web_generic.png"
              style={{ width: "120px" }}
              className="no-selection"
            />
          </a>
          <a href="https://apps.apple.com/it/app/tunehey/id1660784630?itsct=apps_box_badge&itscg=30200">
            <img
              alt="Scarica su App Store"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/it-it?size=250x83&releaseDate=1674086400"
              style={{ width: "120px" }}
              className="no-selection"
            />
          </a>
        </span>

        {/* footer menu (with now only an element) but pre-built to grow*/}
        <div className="footer-menu">
          <button className="transparentBtn footer-text-hover" onClick={goFaq}>
            <h4 className="font-ft-menu left">Domande frequenti</h4>
          </button>
        </div>

        {/* official email  */}
        <div className="d-flex flex-column justify-content-center sym-footer">
          <h4 className="font-contacts">Contatti e servizio clienti</h4>
          <h5 className="font-subT-Osw-mail yellow-selection">
            hey@tunehey.com
          </h5>
        </div>
      </div>

      {/* SECOND ROW, ------------------------------------------------------
      specificity: displays link to other platforms, with the APP BAGDES that don't show on phone */}
      <div>
        {/* APP BADGES [no phone] */}
        <div className="d-flex justify-content-between align-items-center">
          <span className="no-phone" style={{ marginBlock: "10px" }}>
            <a href="https://play.google.com/store/apps/details?id=com.tunehey.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Disponibile su Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/it_badge_web_generic.png"
                style={{ width: "120px", marginRight: "6px" }}
                className="no-selection"
              />
            </a>
            <a href="https://apps.apple.com/it/app/tunehey/id1660784630?itsct=apps_box_badge&itscg=30200">
              <img
                alt="Scarica su App Store"
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/it-it?size=250x83&releaseDate=1674086400"
                style={{ height: "40px" }}
                className="no-selection"
              />
            </a>
          </span>
          {/* layout element to fix social buttons to right with flex-between also in phone where there are alone */}
          <span />
          {/* SOCIAL */}
          <div className="d-flex flex-column social">
            <h4 className="font-contacts join-us-on-align">
              Vieni a trovarci su:
            </h4>
            <div>
              <a href="https://www.instagram.com/tunehey/" target="_blank">
                <FaInstagramSquare className="social-icon" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100080499938844"
                target="_blank"
              >
                <FaFacebookSquare className="social-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/tunehey/about/"
                target="_blank"
              >
                <FaLinkedin className="social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* THIRD ROW, ------------------------------------------------------
      specificity: displays all the mandatory legal objects */}
      <div className="d-flex flex-row justify-content-center bottom-footer">
        <h6
          className="rights-footer"
          style={{ paddingRight: "20px", color: "#FFFCEB" }}
        >
          © 2023 - Tunehey srl società benefit - P.I. 12498300966
        </h6>
        <button
          className="transparentBtn low-law footer-text-hover"
          onClick={goTC}
        >
          <h6 className="rights-footer low-law-h6">Termini e Condizioni</h6>
        </button>
        <button
          className="transparentBtn low-law footer-text-hover"
          onClick={goPP}
        >
          <h6 className="rights-footer low-law-h6" translate="no">
            Privacy Policy
          </h6>
        </button>

        <button
          className="transparentBtn low-law footer-text-hover"
          onClick={goCP}
        >
          <h6 className="rights-footer low-law-h6" translate="no">
            Cookie Policy
          </h6>
        </button>
        <button
          class="iubenda-cs-preferences-link"
          // onClick={() => _iub.cs.api.openPreferences()}
          className="transparentBtn low-law cookie-icon-hv iubenda-cs-preferences-link"
          style={{ marginBottom: "8px" }}
        >
          <LiaCookieBiteSolid />
        </button>
      </div>
    </footer>
  );
}
