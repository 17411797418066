import { useEffect, useLayoutEffect, useRef } from "react";

//THIS COMPONENT PROVIDES THE AUDIO FUNCTION IN THE PAGE
//AND MECHANISM TO PLAY/STOP MUSIC BASED ON URL CHANGES
//THAT ARE CONTROLLED BY THE MASTER COMPONENT

function AudioPassivePlayer({ songUrl, songStart, handlePause, type, volume }) {
  const audioRef = useRef(null);

  useLayoutEffect(() => {
    if (songUrl !== "") {
      audioRef.current.currentTime = songStart;
    }
  }, [songUrl]);

  useEffect(() => {
    // checks every second if we have overtaken 30 seconds from songStart
    const intervalId = setInterval(() => {
      if (songUrl !== "") {
        if (!audioRef.current.paused) {
          if (audioRef.current.currentTime > songStart + 30) {
            handlePause();
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [songUrl]);

  useEffect(() => {
    // Set the volume when the 'volume' prop changes
    if (audioRef.current) {
      const volumeValue = (volume / 100) * (65 / 100); //100% is the 65% of the (pc) device
      if (!isNaN(volumeValue) && isFinite(volumeValue)) {
        audioRef.current.volume = volumeValue;
      }
    }
  }, [volume]);

  return (
    <audio
      ref={audioRef}
      src={songUrl}
      type="audio/mp3"
      autoPlay={true} //needed to play when url is changed and so audio tag re-renders
      id={type}
    />
  );
}

export default AudioPassivePlayer;
