import { useLocation, Navigate } from 'react-router-dom';
import { useContext } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserContext } from './userContext';

export function RequireAuth({ children }) {
  const { ID_User } = useContext(UserContext);
  
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  // previously in the condition we checked also "&& !cookies.enrolling"
  if (route !== 'authenticated' && !ID_User) {
    // return <Navigate to="/signin" state={{ from: location }} replace />;
    return <Navigate to="/signin" state={{ from: location }} />;
  } else {
    //ONLY IF ROUTE AUTHENTICATED & WE HAVE OUR TUNEHEY ID USER
    return children;
  }
}