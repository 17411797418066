import React from "react";
import secToMin from "../../utils/secToMin";
import { IoCloseOutline } from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";

function GiftSong({
  data,
  isInteractive,
  removeSong,
  playingSong,
  onPlay,
  onPause,
}) {
  const { Title: title, Stage_Name: artist, Length: length } = data;
  const isPlaying = playingSong === data.Storage_Preview;

  return (
    <div className={`gift-song ${isInteractive ? "interactive" : ""} `}>
      {isInteractive && (
        <>
          <MdDragIndicator
            fontSize={19}
            style={{
              position: "absolute",
              top: "50%",
              left: "28px",
              transform: "translateY(-50%)",
              color: !isPlaying ? "#F98050" : "#FFFDEF",
              // opacity: 0.9,
              cursor: "move",
              zIndex: 50,
            }}
            onClick={() => {}}
          />
          <button
            className="transparentBtn fill-song-with-button"
            style={{
              position: "absolute",
              height: "35px",
              width: "100px",
              top: "10%",
              left: "60px",
              // transform: "translateY(-50%)",
              // color: "#F98050",
              cursor: "pointer",
              zIndex: 50,
            }}
            onClick={() => {
              if (isInteractive) {
                if (isPlaying) {
                  onPause();
                } else {
                  onPlay(data);
                }
              }
            }}
          />
          <IoCloseOutline
            fontSize={21}
            style={{
              position: "absolute",
              top: "50%",
              right: "30px",
              transform: "translateY(-50%)",
              color: !isPlaying ? "#F98050" : "#FFFDEF",
              cursor: "pointer",
              zIndex: 50,
            }}
            onClick={() => removeSong(data)}
          />
        </>
      )}
      <p>{title}</p>
      <p
        style={{
          fontWeight: "200",
          letterSpacing: "0.045em",
        }}
      >
        {artist}
      </p>
      <p
        style={
          isInteractive
            ? {
                fontWeight: "200",
                letterSpacing: "0.06em",
                fontFamily: "Work sans",
                fontSize: "12px",
                position: "absolute",
                top: 26,
                right: 70,
              }
            : {
                fontWeight: "200",
                letterSpacing: "0.06em",
                fontFamily: "Work sans",
                fontSize: "10px",
                position: "absolute",
                top: 36,
                right: 70,
              }
        }
      >
        {secToMin(length)}
      </p>
      <hr
        style={
          isInteractive
            ? {
                border: "none",
                height: "0",
                borderTop: "1px solid #FFFDEF",
                marginTop: "4px",
                marginBottom: "5px",
                opacity: "0.8",
              }
            : {
                border: "none",
                height: "0",
                borderTop: "1px solid #FFFDEF",
                marginTop: "5px",
                marginBottom: "6px",
                opacity: "0.8",
              }
        }
      />
    </div>
  );
}

export default GiftSong;
