import YellowDialog from "../../layouts/popup800";
import PrimaryButton from "../../components/common/primaryButton";
import SecondaryButton from "../../components/common/secondaryButton";

// THIS POPUP IS THE TWIN OF THE DIALOG IN THE ANDROID APP
function PopSelf({ opened, onPreserve, onSelf }) {
  return (
    <YellowDialog opened={opened} onClose={onPreserve}>
      <div
        className="pop-self d-flex flex-column justify-content-between"
        style={{
          paddingInline: "100px",
          paddingTop: "170px",
        }}
      >
        <div>
          <h2
            className="font-subT1-Osw pop-self-space"
            style={{ textAlign: "center", color: "#2C2B26" }}
          >
            Sembra che tu voglia riscattare un codice acquistato... da te! 🤔{" "}
            <br />
            <br />
            <span style={{ fontWeight: "300" }}>
              Se procedi, il codice non sarà più riscattabile da altri. Confermi
              di voler riscattare il regalo per te?
            </span>
          </h2>
          <br />
        </div>
        <div className="mt-auto d-flex flex-column align-items-center">
          <PrimaryButton text="ANNULLA" clickHandler={onPreserve} />
          <span className="phone-pop-less-H" style={{ height: "20px" }} />
          <SecondaryButton text="PROCEDI" clickHandler={onSelf} />
        </div>
      </div>
    </YellowDialog>
  );
}

export default PopSelf;
