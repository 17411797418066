import { motion } from "framer-motion";

function Tooltip({ content, isActive, position, index }) {
  return (
    <motion.div
      className={`mockup-tooltip ${
        isActive ? "active" : ""
      } phone-demo-tooltip-${index}`}
      style={{ top: position }}
    >
      {content}
    </motion.div>
  );
}

export default Tooltip;
