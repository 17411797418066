import { useEffect, useState } from "react";
import "../../assets/localStyle/iubenda.css";

const CookiePolicy = () => {
  // const [policyText, setPolicyText] = useState("");

  // useEffect(() => {
  //   const fetchPolicy = async () => {
  //     const response = await fetch(
  //       "https://www.iubenda.com/api/privacy-policy/47780912/cookie-policy"
  //     );
  //     const data = await response.json();
  //     setPolicyText(data.content);
  //   };
  //   fetchPolicy();
  // }, []);

  return (
    // <div
    //   // className="iubenda-embed iub-body-embed iubenda-nostyle"
    //   className="exact-page-net-header"
    //   dangerouslySetInnerHTML={{ __html: policyText }}
    // />
    <div>
      <h1>Cookie Policy</h1>
      <p>
        Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per
        saperne di più, gli Utenti possono consultare la Cookie Policy.
      </p>
      <h2>Ulteriori informazioni per gli utenti nell'Unione Europea</h2>
      <p>
        Questa sezione si applica a tutti gli utenti dell'Unione Europea, in
        conformità al Regolamento generale sulla protezione dei dati (il "GDPR")
        e, per tali Utenti, sostituisce qualsiasi altra informazione
        eventualmente divergente o in conflitto contenuta nell'informativa sulla
        privacy. Ulteriori dettagli relativi alle categorie di Dati trattati,
        alle finalità del trattamento, alle categorie di destinatari dei Dati
        personali, se presenti, e ulteriori informazioni sui Dati personali sono
        disponibili nella sezione "Informazioni dettagliate sul trattamento dei
        Dati personali" all'interno del presente documento.
      </p>
      <h3>Base giuridica del trattamento</h3>
      <p>
        Il Titolare tratta Dati Personali relativi all’Utente in caso sussista
        una delle seguenti condizioni:
      </p>
      <ul>
        <li>
          L’Utente ha prestato il consenso per una o più finalità specifiche.
        </li>
        <li>
          Il trattamento è necessario all'esecuzione di un contratto con
          l’Utente e/o all'esecuzione di misure precontrattuali;
        </li>
        <li>
          Il trattamento è necessario per adempiere un obbligo legale al quale è
          soggetto il Titolare;
        </li>
        <li>
          Il trattamento è necessario per l'esecuzione di un compito di
          interesse pubblico o per l'esercizio di pubblici poteri di cui è
          investito il Titolare;
        </li>
        <li>
          Il trattamento è necessario per il perseguimento del legittimo
          interesse del Titolare o di terzi.
        </li>
      </ul>
      <p>
        È comunque sempre possibile richiedere al Titolare di chiarire la
        concreta base giuridica di ciascun trattamento ed in particolare di
        specificare se il trattamento sia basato sulla legge, previsto da un
        contratto o necessario per concludere un contratto.
      </p>
      <h3>Ulteriori informazioni sul tempo di conservazione</h3>
      <p>
        Se non diversamente indicato in questo documento, i Dati Personali sono
        trattati e conservati per il tempo richiesto dalla finalità per la quale
        sono stati raccolti e potrebbero essere conservati per un periodo più
        lungo a causa di eventuali obbligazioni legali o sulla base del consenso
        degli Utenti.
      </p>
      <p>
        Al termine del periodo di conservazione i Dati Personali saranno
        cancellati. Pertanto, allo spirare di tale termine il diritto di
        accesso, cancellazione, rettificazione ed il diritto alla portabilità
        dei Dati non potranno più essere esercitati.
      </p>
      <h3>
        Diritti dell’Utente sulla base del Regolamento Generale sulla Protezione
        dei Dati (GDPR)
      </h3>
      <p>
        Gli Utenti possono esercitare determinati diritti con riferimento ai
        Dati trattati dal Titolare.
      </p>
      <ul>
        <li>Revocare il consenso in ogni momento.</li>
        <li>Opporsi al trattamento dei propri Dati.</li>
        <li>Accedere ai propri Dati.</li>
        <li>Verificare e chiedere la rettificazione.</li>
        <li>Ottenere la limitazione del trattamento.</li>
        <li>
          Ottenere la cancellazione o rimozione dei propri Dati Personali.
        </li>
        <li>Ricevere i propri Dati o farli trasferire ad altro titolare.</li>
        <li>Proporre reclamo all’autorità di controllo competente.</li>
      </ul>
      <p>
        Per esercitare i propri diritti, gli Utenti possono indirizzare una
        richiesta ai recapiti del Titolare indicati in questo documento. La
        richiesta è gratuita e il Titolare risponderà nel più breve tempo
        possibile, in ogni caso entro un mese.
      </p>
      <h3>Ulteriori informazioni sul trattamento</h3>
      <p>
        I Dati Personali dell’Utente possono essere utilizzati da parte del
        Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
        instaurazione per la difesa da abusi nell'utilizzo di questa
        Applicazione o dei Servizi connessi da parte dell’Utente. L’Utente
        dichiara di essere consapevole che il Titolare potrebbe essere obbligato
        a rivelare i Dati per ordine delle autorità pubbliche.
      </p>
      <h3>Modifiche a questa privacy policy</h3>
      <p>
        Il Titolare del Trattamento si riserva il diritto di apportare modifiche
        alla presente privacy policy in qualunque momento notificandolo agli
        Utenti su questa pagina e, se possibile, su questa Applicazione nonché,
        qualora tecnicamente e legalmente fattibile, inviando una notifica agli
        Utenti attraverso uno degli estremi di contatto di cui è in possesso.
      </p>
      <p>
        Qualora le modifiche interessino trattamenti la cui base giuridica è il
        consenso, il Titolare provvederà a raccogliere nuovamente il consenso
        dell’Utente, se necessario.
      </p>
    </div>
  );
};

export default CookiePolicy;
