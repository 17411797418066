import { Fragment, memo } from "react";
import { SENTIMENT_LIST } from "../constants";
import { GENRE_LIST } from "../constants";

import ReactPixel from "../services/facebookPixel";

const RollingHashtags = ({
  type = "sentiments",
  selectedHashtag,
  setSelectedHashtag,
  setDebouncedQuery,
  otherPart,
}) => {
  const getList = () => {
    return type === "sentiments" ? SENTIMENT_LIST : GENRE_LIST;
  };

  const hashtagList = getList();

  return (
    <div className="rolling-hashtag">
      {hashtagList.map((hashtag) => (
        <Fragment key={hashtag.ita}>
          <button
            className="cipBtn"
            style={{ paddingInline: "2px" }}
            onClick={() => {
              //if we click on selected (based on S.S.O.T. state) we deselect it (in the S.S.O.T. state) and viceversa
              //note that the state has space as a separator, so we have to consider it in these UI/state commutations
              if (selectedHashtag.includes(hashtag.ita)) {
                setSelectedHashtag(
                  selectedHashtag.replace(hashtag.ita + " ", "")
                );
                ReactPixel.trackCustom("#hashtag-rmv", hashtag);
                if (type === "sentiment") {
                  setDebouncedQuery(
                    selectedHashtag.replace(hashtag.ita + " ", "") + otherPart
                  );
                } else {
                  setDebouncedQuery(
                    otherPart + selectedHashtag.replace(hashtag.ita + " ", "")
                  );
                }
              } else {
                setSelectedHashtag(selectedHashtag + hashtag.ita + " ");
                ReactPixel.trackCustom("#hashtag-add", hashtag);
                if (type === "sentiment") {
                  setDebouncedQuery(
                    selectedHashtag + hashtag.ita + " " + otherPart
                  );
                } else {
                  setDebouncedQuery(
                    otherPart + selectedHashtag + hashtag.ita + " "
                  );
                }
              }
            }}
          >
            <span
              className="hashtag" //includes font, phonesize, and color
              style={
                selectedHashtag.includes(hashtag.ita)
                  ? {
                      backgroundColor: "#D3C3A6",
                      fontWeight: "600",
                      color: "#FFFDEF",
                    }
                  : {}
              }
            >
              {hashtag.lab}
            </span>
          </button>
          <br />
        </Fragment>
      ))}
    </div>
  );
};

export default memo(RollingHashtags);
