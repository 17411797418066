import rec from "../../assets/icons/rec.svg";
import SecondaryButton from "./secondaryButton";
import ReactPixel from "../../services/facebookPixel";

// UNIQUE BUTTON
// note the isBig version
function RecordingButton({ scene, isBig = false }) {
  return (
    <>
      <SecondaryButton
        text="REGISTRATI"
        scene={scene}
        clickHandler={() => {
          ReactPixel.trackCustom("registrazione");
        }}
        isBig={isBig}
      >
        <img
          src={rec}
          className={isBig ? "rec-big" : "rec-std"}
          alt=""
          style={{ marginRight: "1em" }}
        />
      </SecondaryButton>
    </>
  );
}

export default RecordingButton;
