import React, { useState } from "react";
import { DropCrop } from "../../components/dragNCropAvatar";

// With this 3 lines we checked blob existence until we didn't instantly uploaded new images to S3, our good solution for now.
// (this pieces of code/comment are only here to remember we can't persist images using blob!)
// Import: import { imageExists } from "../../utils/checkBlobUrl";
// Here: var result = avatar.startsWith("blob") ? imageExists(avatar) : "altro";
// Render after DropCrop: <h1>{result}</h1>

function Avatar({
  avatar,
  onAvatar,
  giverName,
  onGiverName,
  setOnTopActivity,
}) {
  const [focusOtherAction, setFocusOtherAction] = useState(false);

  const handleFocusActionTop = (bool) => {
    setFocusOtherAction(bool);
    setOnTopActivity(bool);
  };

  return (
    <div className="custom-page-int-up custom-page-int-up-phone-corr custom-page-int-up-process">
      <br className="no-desktop" />
      {!focusOtherAction ? (
        <div className="centered-title-phone centered-process-pd-right">
          <h1 className="font-title0-Osw">
            E infine la <span className="yellow-text-background">tua foto</span>
            <br className="phone" /> e il tuo nome
          </h1>
          <h2 className="font-subT1-Osw font-subT1-Osw-custom-phone">
            Firma più cara non c'è
          </h2>
        </div>
      ) : (
        <div className="top-action">
          {/* <h1 className="font-title0-Osw">
            Infine carica <br className="phone" /> la{" "}
            <span className="yellow-text-background">tua foto</span>.
          </h1> */}
          <h2 className="font-subT1-Osw font-subT1-Osw-custom-phone">
            Ritaglia la foto e clicca conferma
          </h2>
        </div>
      )}
      {/* We could now decomment this senctence, however the user is also soflty informed with the option inside DropCrop */}
      {/* <h5 className="font-subT2-Osw">
        (nota: registrandoti sarà poi il default per i tuoi prossimi regali, ma
        potrai anche cambiarla.)
      </h5> */}
      <br className="no-phone no-mid" />
      <br className="no-phone no-extralowmid no-extralowmidheight" />
      <br className="" />
      <div className="avatar-action-flexprop centered-process-pd-right">
        <div className="dropcrop-avatar-flexprop dropcrop-avatar-outer-container top-action">
          <DropCrop
            shape="round"
            grid={0}
            input="drop"
            cta="Conferma"
            whencrop={onAvatar}
            cropped={avatar}
            spaceRequested={focusOtherAction}
            makeSpaceForMe={handleFocusActionTop}
          />
        </div>
        {!focusOtherAction && (
          <div className="centered-group-phone">
            <div className="your-name-box">
              <p className="your-name-title">Il tuo nome nel tune:</p>
              <input
                type="text"
                placeholder=""
                className="cream-background-color name-form your-name-form centered-name-phone"
                value={giverName}
                onChange={(e) => onGiverName(e.currentTarget.value)}
              />
            </div>
          </div>
        )}
      </div>
      <br />
      <br />
      <br className="phone" />
      <br className="phone" />
    </div>
  );
}

export default React.memo(Avatar);
