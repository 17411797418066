import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import { Auth } from "aws-amplify";
import RecordingButton from "../components/common/recordingButton";
import SecondaryButton from "../components/common/secondaryButton";
import UserButton from "../components/common/userButton";
import {
  TbRosetteNumber1,
  TbRosetteNumber2,
  TbRosetteNumber3,
  TbRosetteNumber4,
  TbRosetteNumber5,
  TbRosetteNumber6,
  TbRosetteNumber7,
  TbRosetteNumber8,
  TbRosetteNumber9,
} from "react-icons/tb";
import { HiOutlineChevronLeft } from "react-icons/hi";
// import { BsSkipBackwardBtn } from "react-icons/bs";

import Logo from "../assets/images/logo.svg";
import { ParseDate } from "../utils/parseDate";
import CartButton from "../components/common/cartButton";
import { isMobile } from "../utils/isMobile";

const Header = (props) => {
  const location = useLocation();
  let navigate = useNavigate();

  const deviceMobile = isMobile();

  const [loggedIn, setLoggedIn] = useState(false);

  const creditMessage = (
    <>
      <p className="font-hd-menu menu-dropdown-label row-margin-block">
        Hai{" "}
        <span style={{ color: "#F98050", fontWeight: "600" }}>
          {props.userCredits} {props.userCredits > 1 ? "CREDITI" : "CREDITO"}
        </span>{" "}
        <br />
        che {props.userCredits > 1 ? "scadono" : "scade"} il{" "}
        <span style={{ color: "#F98050" }}>{ParseDate(props.creditsExp)}</span>{" "}
        <br />
        per regalare{" "}
        <span style={{ color: "#F98050" }}>
          {props.userCredits} {props.creditsSize == "S" ? "tune" : "Maxi tune"}
        </span>
      </p>
    </>
  );

  const creditIcons = [
    null,
    <TbRosetteNumber1 className="orange-color" />,
    <TbRosetteNumber2 className="orange-color" />,
    <TbRosetteNumber3 className="orange-color" />,
    <TbRosetteNumber4 className="orange-color" />,
    <TbRosetteNumber5 className="orange-color" />,
    <TbRosetteNumber6 className="orange-color" />,
    <TbRosetteNumber7 className="orange-color" />,
    <TbRosetteNumber8 className="orange-color" />,
    <TbRosetteNumber9 className="orange-color" />,
  ];

  useEffect(() => {
    setLoggedIn(true);
    // console.log("header triggered refresh cycle");
    //header will resolve a limbo situation after email confirmation
  }, [props.fresh]);

  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then((sess) => {
        console.log("logged in");
        setLoggedIn(true);
      })
      .catch(() => {
        console.log("not logged in");
        setLoggedIn(false);
      });
  };
  useEffect(() => {
    assessLoggedInState(); //for maximum header reactivity & consistence trough user sessions
  }, []);

  const brandNameElement = (
    <h1
      className="brand-name"
      style={
        props.scene === "dark" || props.page === "compose"
          ? { color: "#FFFCEB" }
          : {}
      }
      translate="no"
    >
      tunehey
    </h1>
  );

  //PERFORMS THE LOGOUT (& data reset)
  const signOut = () => {
    try {
      Auth.signOut().then(() => setLoggedIn(false));
      // userContext will reset userId cookie
      // here the header reset all the local storage
      localStorage.setItem("progress", "");
      localStorage.setItem("scene", "");
      localStorage.setItem("giftId", "");
      localStorage.setItem("name", "");
      localStorage.setItem("collection", "");
      localStorage.setItem("selection", "[]");
      localStorage.setItem("dedication", "");
      localStorage.setItem("momentID", "");
      localStorage.setItem("originalMoment", "");
      localStorage.setItem("iconLink", "");
      localStorage.setItem("iconName", "");
      localStorage.setItem("coverLink", "");
      localStorage.setItem("coverName", "");
      localStorage.setItem("chosenEmail", "");
      localStorage.setItem("idBundle", "");
      localStorage.setItem("size", "");
    } catch (error) {
      console.log("error signing out:", error);
    }
  };

  const handleBack = (topage) => {
    const rootPage = topage.split("/")[0];
    props.setPage(topage);
    // Navigate only to the root part
    navigate("/" + rootPage);
  };

  const handleCart = () => {
    props.setPage("email");
    navigate("/email");
  };

  const startSaveLogin = () => {
    props.setPage("createLogin");
    props.setSignPage("forced"); //kind of
    navigate("/signin");
  };

  const [cookies, setCookie] = useCookies();
  const handleBackFromSignin = (topage) => {
    props.setSignPage("");
    props.setScene("light");
    handleBack(topage);
    setCookie("enrolling", false, [
      { path: "/", expires: new Date(Date.now() + 10 * 60 * 1000) },
    ]);
  };

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                color:
                  props.acquisitionMsg === "Accedi per selezionare più brani"
                    ? "#FFFDEF"
                    : "#2B2C26",
                backgroundColor:
                  props.acquisitionMsg === "Accedi per selezionare più brani"
                    ? "#2B2C26"
                    : "#F1E8D5",
              },
            },
          },
        },
      }),
    [props.acquisitionMsg]
  );

  return (
    <header
      className="header"
      style={
        props.scene === "land"
          ? { backgroundColor: "#FCE868" }
          : props.scene === "dark"
          ? { backgroundColor: "#2C2B26" }
          : { backgroundColor: "#FFFCEB" }
      }
    >
      {!["/feelbox-feedback"].includes(location.pathname) ? (
        <>
          <span className="d-flex flex-row align-items-center">
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              onClick={() => handleBackFromSignin("")}
            >
              <img src={Logo} alt="logo" className="logo-header no-selection" />
            </Link>
            {[
              "create/playlist",
              "create/dedication",
              "create/moment",
              "create/cover",
              "create/avatar",
            ].includes(props.page) ? (
              <>
                {!deviceMobile && brandNameElement}
                <button
                  className="transparentBtn back-button"
                  onClick={() =>
                    props.page == "create/playlist"
                      ? handleBack("")
                      : handleBack("create/playlist")
                  }
                >
                  <HiOutlineChevronLeft
                    className="d-flex"
                    style={{ color: "#2C2B26", opacity: "0.8" }}
                  />
                </button>
              </>
            ) : (
              brandNameElement
            )}
          </span>
          <span className="d-flex flex-row align-items-center">
            {loggedIn ? (
              <>
                {[
                  "create/playlist",
                  "create/dedication",
                  "create/moment",
                  "create/cover",
                  "create/avatar",
                ].includes(props.page) ? (
                  <>
                    <SecondaryButton
                      scene={props.scene}
                      clickHandler={props.onSave}
                      isSaving={props.isHandlingSave}
                      saveFeedback={props.saveFeedback}
                    >
                      SALVA
                    </SecondaryButton>
                    <span className="header-save-user-space" />
                  </>
                ) : null}

                {[
                  "",
                  "size",
                  "create/playlist",
                  "create/dedication",
                  "create/moment",
                  "create/cover",
                  "create/avatar",
                ].includes(props.page) ? (
                  <div className="header-user-credit-container">
                    <UserButton
                      scene={props.scene}
                      clickHandler={signOut}
                      credits={props.userCredits}
                      creditMessage={creditMessage}
                      expiration={props.creditsExp}
                      email={props.email}
                    />
                    <Tooltip
                      title={creditMessage}
                      arrow
                      placement="left"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <div className="credit-icon">
                        {creditIcons[props.userCredits]}
                      </div>
                    </Tooltip>
                  </div>
                ) : null}

                {props.canPay && !["cart", "email"].includes(props.page) ? (
                  <>
                    <span className="header-save-user-space" />
                    <CartButton clickHandler={handleCart} />
                  </>
                ) : null}
              </>
            ) : (
              <>
                {["forced"].includes(props.signPage) ? (
                  // if user is unintentionaly sended to signin it's nice it can decide to go back
                  // and specifically when we force it from compose to customize, the destination should be "compose"
                  // this last is the only difference between this two options
                  <SecondaryButton
                    scene={props.scene}
                    clickHandler={() => handleBackFromSignin("create/playlist")}
                  >
                    TORNA INDIETRO
                  </SecondaryButton>
                ) : ["/signin"].includes(location.pathname) ? (
                  <SecondaryButton
                    scene={props.scene}
                    clickHandler={() => handleBackFromSignin("")}
                  >
                    TORNA INDIETRO
                  </SecondaryButton>
                ) : ["/create"].includes(location.pathname) ? (
                  <>
                    <SecondaryButton
                      scene={props.scene}
                      clickHandler={startSaveLogin}
                    >
                      ACCEDI E SALVA
                      <div>
                        <ThemeProvider theme={theme}>
                          <Tooltip
                            open={props.isOpenAcquisitionMsg}
                            onClose={() => props.setIsOpenAcquisitionMsg(false)}
                            title={props.acquisitionMsg}
                            placement="bottom-end"
                          >
                            <div></div>
                          </Tooltip>
                        </ThemeProvider>
                      </div>
                    </SecondaryButton>
                  </>
                ) : (
                  <Link to="/signin" style={{ textDecoration: "none" }}>
                    <RecordingButton scene={props.scene} />
                  </Link>
                )}
              </>
            )}
          </span>
        </>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;

// const darkpages = ["name", "email"];
