function LineTitle({ text }) {
  return (
    <div>
      <h4 className="font-linetitle">{text}</h4>
      <hr
        className="linetitle"
        style={{
          width: "300px",
          borderWidth: "2px",
          opacity: "1",
          // borderColor: "#2C2B26", //inherited
        }}
      />
    </div>
  );
}

export default LineTitle;
