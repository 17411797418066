import React, { useState } from "react";
import { IoEllipsisVertical } from "react-icons/io5";

const SongsTool = ({ title, isOpen, onTool, children }) => {
  const handleClick = (event) => {
    // Stop event propagation
    event.stopPropagation();
    onTool(title);
  };

  return (
    <div
      id={`${title}-tool`}
      className={`song-tool-container ${isOpen ? "song-tool-open" : ""}`}
    >
      <div className="song-tool-header" onClick={handleClick}>
        <span className="song-tool-title" translate="no">
          {title}
        </span>
        <IoEllipsisVertical className="song-tool-menu-icon no-phone" />
      </div>
      {isOpen && (
        <div className="song-tool-content">
          <hr className="song-tool-divider" />
          {children}
        </div>
      )}
    </div>
  );
};

export default SongsTool;
