import YellowDialog from "../../layouts/popup800";

function PopMail({ name, opened, onClose }) {
  // in this component we see here all the desktop styles
  // while all (and only) the MID and PHONE styles are on app.css stylesheet with "ready-midphone" name
  // ("ready" from the title text)
  return (
    <YellowDialog opened={opened} onClose={onClose}>
      <div
        className="pop-mail"
        style={{
          paddingLeft: "58px",
        }}
      >
        <h1
          className="font-ready-midphone first-up-elem-spacer"
          style={{
            color: "#F98050",
            fontFamily: "Oswald",
            fontSize: "62px",
            fontWeight: 400,
          }}
        >
          Il tuo regalo è pronto!
        </h1>

        <div className="d-flex flex-row">
          <h2
            className="font-ready-midphone-2"
            style={{
              width: "384px",
              marginRight: "68px",
              fontFamily: "Oswald",
              fontSize: "34px",
              fontWeight: 400,
              lineHeight: "54px",
              letterSpacing: "0.04em",
            }}
          >
            Dopo l'acquisto manderemo alla{" "}
            <span className="white-text-background">
              <span>tua mail</span>
            </span>{" "}
            un codice per far riscattare il regalo a {name}
          </h2>
          {/* this is the container of the image part */}
          <span
            className="container-ready-midphone-3"
            style={{
              width: "290px",
              height: "410px",
              overflow: "hidden",
              paddingRight: "0",
            }}
          >
            {/* this is the large image positioned behind and shielded by the container  */}
            <img
              className="img-phone-ready-4"
              src={require(`../../assets/images/logo.png`)}
              style={{
                width: "700px",
                height: "700px",
                objectFit: "cover",
                objectPosition: "-60px -230px",
              }}
            />
          </span>
        </div>
      </div>
    </YellowDialog>
  );
}

export default PopMail;
