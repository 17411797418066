import React, { useState, useEffect, useContext, useCallback } from "react";

import { Group, Text, useMantineTheme } from "@mantine/core";
import FileUploader from "./common/hiddenFileUpload";
import { HiOutlineUpload } from "react-icons/hi";
import { FaRegCircleUser } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { MdCenterFocusWeak } from "react-icons/md";

import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";

import Cropper from "react-easy-crop";
import { getCroppedImg } from "./common/cropUtils";
import PrimaryButton from "./common/primaryButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CheckBox from "./common/checkBox";
import { UserContext } from "../contexts/userContext";

//This component is more complex than the Cover version as it manages its input and process but also the output.
//Specifically it displays the output and manages his save as the user default.

export function DropCrop(props) {
  const theme = useMantineTheme();

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(
    props.cropped !== "" ? props.cropped : ""
  );
  const [newOpenToken, setNewOpenToken] = useState(false); //we use it to manage cropper reopen to re-center a fresh-cropped image
  const [rejected, setRejected] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [stdChecked, setStdChecked] = useState(false);
  const { setNewStdAvatar } = useContext(UserContext);

  useEffect(() => {
    if (imageSrc && (!croppedImage || newOpenToken)) {
      props.makeSpaceForMe(true);
    } else {
      if (props.spaceRequested === true) {
        props.makeSpaceForMe(false);
      }
    }
  }, [imageSrc, croppedImage, newOpenToken]);

  const onFileChange = async (files) => {
    setLoadingFile(true);
    if (files && files.length > 0) {
      const file = files[0];
      // console.log(file);
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      if (croppedImage) {
        setNewOpenToken(true);
      }
      setLoadingFile(false);
    }
  };

  //alert: don't remove the apparently unused "croppedArea" otherwise it crops badly
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      const currCroppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      // console.log("done", { currCroppedImage });

      props.whencrop(currCroppedImage);
      setCroppedImage(currCroppedImage);
      setNewOpenToken(false);
      setStdChecked(true);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  //THERE IS NOT AN EXIT STRATEGY - this snippet is a reminder
  //
  // const onClose = useCallback(() => {
  //   setCroppedImage(null);
  // }, []);

  const dropTextSubtitle = (
    <Text
      size="sm"
      color={rejected ? "red" : "dimmed"}
      align="center"
      inline
      mt={7}
    >
      Dimensione massima 5mb
    </Text>
  );

  return (
    <div>
      {props.cropped && !imageSrc ? (
        //Handling the case where the saved avatar is received but there's no original resource to re-center it
        <>
          <div className="containerAvatar">
            <LazyLoadImage
              src={props.cropped}
              className="avatar-image"
              style={{
                borderRadius: props.shape === "round" && "50%",
              }}
              threshold={500}
              alt=""
            />
            <div className="overlayAvatar"></div>
            <div className="buttonAvatarNew">
              <FileUploader
                color="grey"
                handleFile={(files) => {
                  // console.log("accepted files", files);
                  onFileChange([files, ""]);
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {!imageSrc ? (
            <Dropzone
              className="drop-cover-colors"
              loading={loadingFile}
              onDrop={(files) => {
                // console.log("accepted files", files);
                setRejected(false);
                onFileChange(files);
              }}
              onReject={(files) => {
                // console.log("rejected files", files);
                setRejected(true);
              }} //FIXME explain at the user
              maxSize={5 * 1024 ** 2}
              accept={IMAGE_MIME_TYPE}
              sx={() => ({
                position: "relative",
                borderRadius: "16px",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "1px solid #CFBFA2",
                  // boxSizing: "border-box", // include border in width/height calculation
                  borderRadius: "50%",
                  pointerEvents: "none", // ensures the circle does not intercept events
                },
              })}
            >
              <Group
                className="drop-avatar-dim"
                position="center"
                spacing="xl"
                style={{
                  maxwidth: 500,
                  pointerEvents: "none",
                }}
              >
                <Dropzone.Accept>
                  <HiOutlineUpload size={50} color={"#FCE868"} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IoCloseOutline
                    size={50}
                    color={
                      theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                    }
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <FiUpload size={50} color="#2C2B26" />
                </Dropzone.Idle>

                <div className="desktop">
                  <Text size="xl" align="center" inline>
                    Trascina qui la foto <br /> (o clicca per sceglierla)
                  </Text>
                  {dropTextSubtitle}
                </div>

                <div className="midsize">
                  <Text size="lg" align="center" inline>
                    Trascina qui la foto <br /> (o clicca per sceglierla)
                  </Text>
                  {dropTextSubtitle}
                </div>

                <div className="phone">
                  <Text size="md" align="center" inline>
                    Scegli la tua foto
                  </Text>
                  {dropTextSubtitle}
                </div>
              </Group>
            </Dropzone>
          ) : (
            // CASE1: if imageSrc
            <React.Fragment>
              {!croppedImage || newOpenToken ? (
                <div className="cropzone-flex-props">
                  <div
                    className="crop-dimensions"
                    style={{
                      position: "relative",
                      alignSelf: "flex-start",
                      width: 700,
                      height: 400,
                    }}
                  >
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={3 / 3}
                      cropShape={props.shape}
                      showGrid={props.grid}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      // style={{ alignSelf: "flex-end" }}
                    />
                  </div>
                  <div className="crop-confirm-spacing">
                    <PrimaryButton
                      clickHandler={saveCroppedImage}
                      text={props.cta}
                    />
                  </div>
                </div>
              ) : (
                //CASE2: if croppedImage (and imageSrc)
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="containerAvatar">
                      <img
                        src={croppedImage}
                        className="avatar-image"
                        style={{
                          borderRadius: props.shape === "round" && "50%",
                        }}
                        alt=""
                      />
                      <div className="overlayAvatar"></div>
                      <div className="buttonAvatarCrop">
                        <button
                          className="transparentBtn"
                          onClick={() => setNewOpenToken(true)}
                        >
                          <MdCenterFocusWeak color="white" size={60} />
                        </button>
                      </div>
                      <div className="buttonAvatarNew">
                        <FileUploader
                          //TODO className="iconAvatarNew" or similiar to change color onHover
                          color="grey"
                          handleFile={(files) => {
                            // console.log("accepted files", files);
                            onFileChange([files, ""]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="phone-av-ckbx-cntr">
                    <CheckBox
                      label="Salva questa foto per i prossimi regali"
                      id="avatar-default-choice"
                      handler={() => {
                        setNewStdAvatar(!stdChecked);
                        setStdChecked(!stdChecked);
                      }}
                      checked={stdChecked}
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </>
      )}
    </div>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
