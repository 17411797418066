import { createContext, useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { useCookieState } from "use-cookie-state";
import { useNavigate } from 'react-router-dom';

import { getUserInfo, firstLogin, getUserByEmail, connectCognitoWithUser, secondInfos } from "../services/contentApi";

//THIS CONTEXT FINALLY EXPORT & PROVIDE ACCESS TO 
// user, ID_User, userAvatar, setUserAvatar, newStdAvatar, setNewStdAvatar, requestRefresh, userCredits, creditsExp
export const UserContext = createContext()

export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [ID_User, setUserId] = useCookieState('userId', '', {
    path: '/',
    // maxAge: 60 * 60 * 24 * 60, // 60 days in seconds
    expires: new Date(Date.now() + 60 * 60 * 24 * 60 * 1000 * 2), // Two months from now in milliseconds
  });
  const [userAvatar, setUserAvatar] = useState("")
  const [newStdAvatar, setNewStdAvatar] = useState(false);
  const [userCredits, setUserCredits] = useState(null)
  const [creditsExp, setCreditsExp] = useState (null)
  const [creditsSize, setCreditsSize] = useState (null)
  const [requestRefresh, setRequestRefresh] = useState(false);
  
  let navigate = useNavigate();

  //FUNCTION TO ASSESS STATE
  const getUserState = async (username, userdata, allowSignUp = false) => {
    setUserId("evaluating", {
      path: '/',
      expires: new Date(Date.now() + 60 * 60 * 24 * 60 * 1000 * 2), // Two months from now in milliseconds
    });
    // console.log("every time assessment", ID_User)
    const res = await getUserInfo(username);
    // console.log("getUserInfo", username, res)
    let userId, userIcon, userCredits, userCreditsExp, userCreditsSize ;

    if (res.data.number === 0 && (allowSignUp || !userId)) {
        const identikit = await getUserByEmail(userdata.email);
        // console.log("getUserByEmail", username.email, identikit)
        if (identikit.data.number == 0) {
          userId = (await firstLogin(username, userdata.given_name ?? userdata.name, userdata.family_name, userdata.email, false)).data;
          // console.log("first login", username, userdata,userId)
        } else {
          userId = identikit.data.contacts[0].ID_User;
          userIcon = identikit.data.contacts[0].Storage_Icon;
          await connectCognitoWithUser(username, userId);
          // console.log("connectCognitoWithUser", username, userId)
          }
    } else {
      userId = res.data.contacts[0].ID_User;
      userIcon = res.data.contacts[0].Storage_Icon;
      userCredits = res.data.contacts[0].Credits_Owned;
      userCreditsExp = res.data.contacts[0].Expiration_Date;
      userCreditsSize = res.data.contacts[0].Size;
      // console.log("normal user", userId)
      // now we check for user data holes in the DB and we repair it with secondInfos
      if (!res.data.contacts[0].Name && (userdata.given_name ?? userdata.name)) {
        secondInfos(userId, userdata.given_name ?? userdata.name, userdata.family_name)
      }
    }

    setUserId(userId, {
      path: '/',
      expires: new Date(Date.now() + 60 * 60 * 24 * 60 * 1000 * 2), // Two months from now in milliseconds
    });
    setUserAvatar(userIcon);
    setUserCredits(userCredits);
    setCreditsExp(userCreditsExp);
    setCreditsSize(userCreditsSize);

    setUser(userdata);
    // console.log("final assess", userId, userdata)
  }

  //LISTEN FRESH COGNITO USER DATA (AFTER REFRESH, SO EVEN AFTER LOGIN EVENTS) AND SET STATE
  useEffect(() => {
    // console.log("calling getUserState to assess state after REFRESH", ID_User);
      Auth.currentAuthenticatedUser().then((data) => {
        // console.log("actually calling getUserState to assess state after REFRESH", ID_User);
        getUserState(data.username,
           data.attributes)
      },() => {
        setUserId(null, {
          path: '/',
          expires: new Date(Date.now() + 60 * 60 * 24 * 60 * 1000 * 2), // Two months from now in milliseconds
        });
        // console.log("refresh assessment (here case no auth) and set state",ID_User);
        setUserAvatar("");
        setUser(null);
      })
    },[])

  //LISTEN TO AUTH EVENTS AND EVENTUALLY MANAGE  
  useEffect(() => {
    const listener = async data => {
      switch (data.payload.event) {

        case 'signIn': // both email or provider access
        // console.log("calling getUserState to assess state after SIGNIN", ID_User);
        // getUserState(data.payload.data.username, data.payload.data.signInUserSession.idToken.payload, true) //don't need it as we do it via refresh 
        break;
        
        case 'signOut': //managed by currentAuthenticatedUser onReject
          // setUserId(null);
          // setUserAvatar("");
          // setUser(null);
          // console.log('user signed out');
          break;

        case 'confirmSignUp':
          navigate(localStorage.getItem("progress") !== "" ? "/"+localStorage.getItem("progress"): "/")
          // console.log('user confirmation successful and going to: ', localStorage.getItem("progress")); //email confirmation
          setRequestRefresh(true) //to exit a limbo situation, via header
          break;
        
          // LIST OF AUTH EVENTS WE DON'T (SPECIFICALLY) MANAGE (FOR NOW) 
        case 'signUp':
          // console.log('user signed up'); //email registration
          break;
          
        case 'configured':
          // console.log('the Auth module is configured');
          break;
        case 'signIn_failure':
          // console.log('user sign in failed');
          break;
        case 'signUp_failure':
          // console.log('user sign up failed');
          break;
        case 'completeNewPassword_failure':
          // console.log('user did not complete new password flow');
          break;
        case 'autoSignIn':
          // console.log('auto sign in successful');
          break;
        case 'autoSignIn_failure':
          // console.log('auto sign in failed');
          break;
        case 'forgotPassword':
          // console.log('password recovery initiated');
          break;
        case 'forgotPassword_failure':
          // console.log('password recovery failed');
          break;
        case 'forgotPasswordSubmit':
          // console.log('password confirmation successful');
          break;
        case 'forgotPasswordSubmit_failure':
          // console.log('password confirmation failed');
          break;
        case 'tokenRefresh':
          // console.log('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          // console.log('token refresh failed');
          break;
        case 'cognitoHostedUI_failure':
          // console.log('Cognito Hosted UI sign in failed');
          break;
        case 'customOAuthState':
          // console.log('custom state returned from CognitoHosted UI');
          break;
        case 'customState_failure':
          // console.log('custom state failure');
          break;
        case 'parsingCallbackUrl':
          // console.log('Cognito Hosted UI OAuth url parsing initiated');
          break;
        case 'userDeleted':
          // console.log('user deletion successful');
          break;

        default:
          break;
      }
    }
    Hub.listen('auth', (data) => listener(data));
    return () => Hub.remove("auth", data => {
      // console.log("REMOVE AUTH");
      listener(data)
    })
  }, []);

  // // THIS PROGRESSCIRCLE IS A TEMPLATE FOR AN ANIMATION DURING LOGIN PROGRESS
  // 
  // const [progressCircle, setProgressCircle] = useState(true);
  // 
  //   return (
  //     <>
  //       {progressCircle ? (
  //         'Tuning'
  //       ) : (
  //         <UserContext.Provider value={{ user, ID_User, userAvatar, setUserAvatar, newStdAvatar, setNewStdAvatar, requestRefresh}}>
  //           {props.children}
  //         </UserContext.Provider>
  //       )}
  //     </>
  //   )
  // }

  return (
    <UserContext.Provider value={{ user, ID_User, userAvatar, setUserAvatar, newStdAvatar, setNewStdAvatar, requestRefresh, userCredits, setUserCredits, creditsExp, creditsSize}}>
      {props.children}
    </UserContext.Provider>
  )
}