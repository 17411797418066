import React from "react";
import { IoSearch } from "react-icons/io5";

const SearchBar = ({ value, onChange, disabled = false }) => {
  return (
    <div className="search-wrapper search-phone">
      <div>
        <IoSearch className="search-icon" fontSize={35} />
      </div>
      <input
        type="text"
        autoComplete="off" //or appears [object Object] if value="". Another solution is to convert value.toString() in his assignement
        name="query"
        className="search-phone search-input ps-5"
        style={{ backgroundColor: "#FAF2CD" }} //change initial #F8F2CD towards design #FDF5D1 but compromise #F9F2C8 or "#FAF2CD"
        placeholder="Cerca per titolo, #sentimento o @..."
        value={value}
        onChange={({ currentTarget: input }) => onChange(input.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchBar;
