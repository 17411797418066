import { useEffect, useState } from "react";
import "../../assets/localStyle/iubenda.css";

const PrivacyPolicy = ({ sceneChange, pageChange }) => {
  // const [policyText, setPolicyText] = useState("");

  // useEffect(() => {
  //   const fetchPolicy = async () => {
  //     const response = await fetch(
  //       "https://www.iubenda.com/api/privacy-policy/47780912"
  //     );
  //     const data = await response.json();
  //     setPolicyText(data.content);
  //   };
  //   fetchPolicy();
  // }, []);

  return (
    <div style={{ padding: "40px 40px" }}>
      <h1>Privacy Policy di www.tunehey.com</h1>
      <p>
        Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.
      </p>
      <br />
      <p>
        Gli Utenti possono essere soggetti a livelli di protezione diversi.
        Alcuni Utenti godono pertanto di superiore protezione. L'Utente può
        contattare il Titolare per ulteriori informazioni sui livelli di
        protezione. Questo documento può essere stampato utilizzando il comando
        di stampa presente nelle impostazioni di qualsiasi browser.
      </p>
      <h2>Riassunto della policy</h2>
      <br />
      <h3>
        Dati Personali trattati per le seguenti finalità e utilizzando i
        seguenti servizi:
      </h3>
      <p>
        <h4>Accesso agli account su servizi terzi</h4>
        <h5>Accesso all'account Facebook</h5>
        Permessi: Accesso ai dati privati; Email; Email di contatto
        <h5>Accesso all'account Stripe</h5>
        Dati Personali: cognome; Dati comunicati durante l'utilizzo del
        servizio; Dati di utilizzo; email; informazioni di pagamento; nome
        <h4>Contattare l'Utente</h4>
        <h5>Mailing list o newsletter</h5>
        Dati Personali: cognome; data di nascita; email; nome; sesso
        <h4>Gestione dei pagamenti</h4>
        <h5>Stripe</h5>
        Dati Personali: cognome; email; indirizzo di fatturazione; informazioni
        di pagamento; nome; varie tipologie di Dati secondo quanto specificato
        dalla privacy policy del servizio
        <h4>Gestione dei tag</h4>
        <h5>Google Tag Manager</h5>
        Dati Personali: Dati di utilizzo; Strumenti di Tracciamento
        <h4>Gestione della raccolta dati e dei sondaggi online</h4>
        <h5>Typeform</h5>
        Dati Personali: email
        <h4>Hosting ed infrastruttura backend</h4>
        <h5>Amazon Web Services (AWS)</h5>
        Dati Personali: varie tipologie di Dati secondo quanto specificato dalla
        privacy policy del servizio
        <h4>Interazione con social network e piattaforme esterne</h4>
        Pulsante e widget sociali di Linkedin e Pulsante Mi Piace e widget
        sociali di Facebook
        <h5>Dati Personali: Dati di utilizzo; Strumenti di Tracciamento</h5>
        <h4>Pubblicità</h4>
        Monitoraggio conversioni di Meta ads (pixel di Meta) e Monitoraggio
        conversioni di Google Ads
        <h5>Dati Personali: Dati di utilizzo; Strumenti di Tracciamento</h5>
        <h4>Raccolta delle preferenze relative alla privacy</h4>
        <h5>Consent Solution di iubenda</h5>
        Dati Personali: Dati comunicati durante l'utilizzo del servizio;
        Strumenti di Tracciamento
        <h5>Cookie Solution di iubenda</h5>
        Dati Personali: Strumenti di Tracciamento
        <h4>Registrazione ed autenticazione</h4>
        <h5>Auth0</h5>
        Dati Personali: cognome; email; immagine; nome; password; Strumenti di
        Tracciamento; varie tipologie di Dati secondo quanto specificato dalla
        privacy policy del servizio
        <h5>Accedi con Apple</h5>
        Dati Personali: città; cognome; data di nascita; email; nome; regione
        geografica
        <h5>Facebook Authentication</h5>
        Dati Personali: Strumenti di Tracciamento; varie tipologie di Dati
        secondo quanto specificato dalla privacy policy del servizio
        <h5>Google OAuth</h5>
        Dati Personali: varie tipologie di Dati secondo quanto specificato dalla
        privacy policy del servizio
        <h4>
          Registrazione ed autenticazione fornite direttamente da questa
          Applicazione
        </h4>
        <h5>Registrazione diretta</h5>
        Dati Personali: CAP; cognome; data di nascita; email; immagine di
        profilo; lingua; nome; sesso
        <h4>Remarketing e behavioral targeting</h4>
        <h5>
          Facebook Remarketing, Remarketing Google Ads e Remarketing con Google
          Analytics Dati Personali: Dati di utilizzo; Strumenti di Tracciamento
        </h5>
        <h4>Salvataggio e gestione di backup</h4>
        <h5>Amazon Glacier</h5>
        Dati Personali: varie tipologie di Dati secondo quanto specificato dalla
        privacy policy del servizio
        <h4>Statistica</h4>
        <h5>
          Google Analytics, Meta Events Manager e Google Analytics con IP
          anonimizzato
        </h5>
        Dati Personali: Dati di utilizzo; Strumenti di Tracciamento
        <h5>Google Analytics 4</h5>
        Dati Personali: città; Dati di utilizzo; informazioni sul browser;
        informazioni sul dispositivo; latitudine (della città); longitudine
        (della città); numero di Utenti; statistiche delle sessioni; Strumenti
        di Tracciamento
        <h4>Visualizzazione di contenuti da piattaforme esterne</h4>
        <h5>Google Fonts</h5>
        Dati Personali: Dati di utilizzo; Strumenti di Tracciamento
      </p>
      <h3>
        Informazioni su come disattivare gli annunci pubblicitari basati sugli
        interessi
      </h3>
      <p>
        Oltre a qualsiasi funzione di opt-out fornita da uno qualsiasi dei
        servizi elencati in questo documento, gli Utenti possono leggere di più
        su come disattivare gli annunci pubblicitari basati sugli interessi
        nell'apposita sezione della Cookie Policy.
      </p>
      <h3>Ulteriori informazioni sul trattamento dei Dati Personali</h3>
      <p>
        <h4>Dati Personali raccolti attraverso fonti diverse dall'Utente</h4>
        Il Titolare di questa Applicazione potrebbe aver legittimamente raccolto
        Dati Personali relativi all’Utente senza il suo coinvolgimento,
        attingendo a fonti fornite da terze parti, in conformità con le basi
        giuridiche descritte nella sezione relativa alle basi giuridiche del
        trattamento. Qualora il Titolare avesse raccolto Dati Personali in tal
        modo, l’Utente potrà trovare informazioni specifiche riguardo alle fonti
        nelle rispettive sezioni di questo documento o contattando il Titolare.
        <h4>localStorage</h4>
        localStorage permette a questa Applicazione di memorizzare e accedere ai
        dati direttamente dal browser dell’Utente senza una data di scadenza.
        <h4>Analisi dei Dati dell’Utente e previsioni (“profilazione”)</h4>
        Il Titolare potrebbe trattare i dati d’utilizzo raccolti attraverso
        questa Applicazione per creare o aggiornare profili di utenza. Questo
        tipo di trattamento consente al Titolare di valutare scelte, preferenze
        e comportamento dell’Utente per gli scopi specificati nelle rispettive
        sezioni di questo documento. I profili d’utenza possono essere creati
        anche grazie a strumenti automatizzati, come algoritmi, che possono
        anche essere offerti da terze parti. Per ottenere ulteriori informazioni
        sull’attività di profilazione l’Utente può fare riferimento alle
        rispettive sezioni di questo documento. L’Utente ha in ogni momento
        diritto ad opporsi a tale attività di profilazione. Per scoprire di più
        sui diritti dell’Utente e su come esercitarli, l’Utente può fare
        riferimento alla sezione di questo documento relativa ai diritti degli
        Utenti.
      </p>
      <h3>Informazioni di contatto</h3>
      <p>
        <h4>Titolare del Trattamento dei Dati</h4>
        Tunehey srl sb - via Modigliani 23, Senigallia (AN), 60019 Indirizzo
        email del Titolare: hey@tunehey.com
      </p>
      <br />
      <br /> <h2>Policy completa</h2>
      <br />
      <h3>Titolare del Trattamento dei Dati</h3>
      <p>Tunehey srl sb - via Modigliani 23, Senigallia (AN), 60019</p>
      <p>Indirizzo email del Titolare: hey@tunehey.com</p>
      <h3>Tipologie di Dati raccolti</h3>
      <p>
        Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o
        tramite terze parti, ci sono: Dati di utilizzo; nome; cognome; email;
        Dati comunicati durante l'utilizzo del servizio; informazioni di
        pagamento; Strumenti di Tracciamento; sesso; data di nascita; indirizzo
        di fatturazione; password; immagine; regione geografica; città; CAP;
        immagine di profilo; lingua; numero di Utenti; informazioni sul
        dispositivo; statistiche delle sessioni; latitudine (della città);
        longitudine (della città); informazioni sul browser. Dettagli completi
        su ciascuna tipologia di Dati Personali raccolti sono forniti nelle
        sezioni dedicate di questa privacy policy o mediante specifici testi
        informativi visualizzati prima della raccolta dei Dati stessi. I Dati
        Personali possono essere liberamente forniti dall'Utente o, nel caso di
        Dati di Utilizzo, raccolti automaticamente durante l'uso di questa
        Applicazione. Se non diversamente specificato, tutti i Dati richiesti da
        questa Applicazione sono obbligatori. Se l’Utente rifiuta di
        comunicarli, potrebbe essere impossibile per questa Applicazione fornire
        il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati
        come facoltativi, gli Utenti sono liberi di astenersi dal comunicare
        tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità
        del Servizio o sulla sua operatività. Gli Utenti che dovessero avere
        dubbi su quali Dati siano obbligatori sono incoraggiati a contattare il
        Titolare. L’eventuale utilizzo di Cookie - o di altri strumenti di
        tracciamento - da parte di questa Applicazione o dei titolari dei
        servizi terzi utilizzati da questa Applicazione ha la finalità di
        fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità
        descritte nel presente documento e nella Cookie Policy. L'Utente si
        assume la responsabilità dei Dati Personali di terzi ottenuti,
        pubblicati o condivisi mediante questa Applicazione.
      </p>
      <h3>Modalità e luogo del trattamento dei Dati raccolti</h3>
      <h4>Modalità di trattamento</h4>
      <p>
        Il Titolare adotta le opportune misure di sicurezza volte ad impedire
        l’accesso, la divulgazione, la modifica o la distruzione non autorizzate
        dei Dati Personali. Il trattamento viene effettuato mediante strumenti
        informatici e/o telematici, con modalità organizzative e con logiche
        strettamente correlate alle finalità indicate. Oltre al Titolare, in
        alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti
        nell’organizzazione di questa Applicazione (personale amministrativo,
        commerciale, marketing, legali, amministratori di sistema) ovvero
        soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
        postali, hosting provider, società informatiche, agenzie di
        comunicazione) nominati anche, se necessario, Responsabili del
        Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili
        potrà sempre essere richiesto al Titolare del Trattamento.
      </p>
      <h4>Luogo</h4>
      <p>
        I Dati sono trattati presso le sedi operative del Titolare ed in ogni
        altro luogo in cui le parti coinvolte nel trattamento siano localizzate.
        Per ulteriori informazioni, contatta il Titolare. I Dati Personali
        dell’Utente potrebbero essere trasferiti in un paese diverso da quello
        in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo
        del trattamento l’Utente può fare riferimento alla sezione relativa ai
        dettagli sul trattamento dei Dati Personali.
      </p>
      <h4>Periodo di conservazione</h4>
      <p>
        Se non diversamente indicato in questo documento, i Dati Personali sono
        trattati e conservati per il tempo richiesto dalla finalità per la quale
        sono stati raccolti e potrebbero essere conservati per un periodo più
        lungo a causa di eventuali obbligazioni legali o sulla base del consenso
        degli Utenti.
      </p>
      <h3>Finalità del Trattamento dei Dati raccolti</h3>
      <p>
        I Dati dell’Utente sono raccolti per consentire al Titolare di fornire
        il Servizio, adempiere agli obblighi di legge, rispondere a richieste o
        azioni esecutive, tutelare i propri diritti ed interessi (o quelli di
        Utenti o di terze parti), individuare eventuali attività dolose o
        fraudolente, nonché per le seguenti finalità: Accesso agli account su
        servizi terzi, Statistica, Contattare l'Utente, Gestione dei pagamenti,
        Gestione dei tag, Gestione della raccolta dati e dei sondaggi online,
        Hosting ed infrastruttura backend, Interazione con social network e
        piattaforme esterne, Raccolta delle preferenze relative alla privacy,
        Registrazione ed autenticazione, Registrazione ed autenticazione fornite
        direttamente da questa Applicazione, Remarketing e behavioral targeting,
        Salvataggio e gestione di backup, Pubblicità e Visualizzazione di
        contenuti da piattaforme esterne. Per ottenere informazioni dettagliate
        sulle finalità del trattamento e sui Dati Personali trattati per
        ciascuna finalità, l’Utente può fare riferimento alla sezione “Dettagli
        sul trattamento dei Dati Personali”.
      </p>
      <h3>Permessi Facebook richiesti da questa Applicazione</h3>
      <p>
        Questa Applicazione può richiedere alcuni permessi Facebook che le
        consentono di eseguire azioni con l’account Facebook dell’Utente e di
        raccogliere informazioni, inclusi Dati Personali, da esso. Questo
        servizio permette a questa Applicazione di connettersi con l'account
        dell'Utente sul social network Facebook, fornito da Facebook Inc. Per
        maggiori informazioni sui permessi che seguono, fai riferimento alla
        documentazione dei permessi Facebook ed alla privacy policy di Facebook.
        I permessi richiesti sono i seguenti:
      </p>
      <h4>Informazioni di base</h4>
      <p>
        Le informazioni di base dell’Utente registrato su Facebook che
        normalmente includono i seguenti Dati: id, nome, immagine, genere e
        lingua di localizzazione ed, in alcuni casi gli “Amici” di Facebook. Se
        l'Utente ha reso disponibili pubblicamente Dati ulteriori, gli stessi
        saranno disponibili.
      </p>
      <h4>Accesso ai dati privati</h4>
      <p>Permette l'accesso ai dati privati dell'Utente e degli amici.</p>
      <h4>Email</h4>
      <p>Fornisce accesso all'indirizzo email primario dell'Utente.</p>
      <h4>Email di contatto</h4>
      <p>Permette di accedere all'indirizzo email di contatto dell'Utente.</p>
      <h3>Dettagli sul trattamento dei Dati Personali</h3>
      <p>
        I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i
        seguenti servizi:
      </p>
      <p>
        <h4>Accesso agli account su servizi terzi</h4>
        Questo tipo di servizi permettono a questa Applicazione di prelevare
        Dati dai tuoi account su servizi terzi ed eseguire azioni con essi.
        Questi servizi non sono attivati automaticamente, ma richiedono
        l'espressa autorizzazione dell'Utente.
        <h5>Accesso all'account Stripe</h5>
        Questo servizio permette a questa Applicazione di connettersi con
        l'account dell'Utente su Stripe, fornito da Stripe, Inc. Dati Personali
        trattati: cognome; Dati comunicati durante l'utilizzo del servizio; Dati
        di utilizzo; email; informazioni di pagamento; nome. Luogo del
        trattamento: Stati Uniti – Privacy Policy; Unione europea .
        <h5>Accesso all'account Facebook (Meta Platforms, Inc.)</h5>
        Questo servizio permette a questa Applicazione di connettersi con
        l'account dell'Utente sul social network Facebook, fornito da Facebook,
        Inc. Permessi richiesti: Accesso ai dati privati; Email; Email di
        contatto. Luogo del trattamento: Stati Uniti – Privacy Policy.
        <h4>Contattare l'Utente</h4>
        <h5>Mailing list o newsletter (questa Applicazione)</h5>
        Con la registrazione alla mailing list o alla newsletter, l’indirizzo
        email dell’Utente viene automaticamente inserito in una lista di
        contatti a cui potranno essere trasmessi messaggi email contenenti
        informazioni, anche di natura commerciale e promozionale, relative a
        questa Applicazione. L'indirizzo email dell'Utente potrebbe anche essere
        aggiunto a questa lista come risultato della registrazione a questa
        Applicazione o dopo aver effettuato un acquisto. Dati Personali
        trattati: cognome; data di nascita; email; nome; sesso.
        <h4>Gestione dei pagamenti</h4>
        Se non diversamente specificato, questa Applicazione elabora tutti i
        pagamenti con carta di credito, bonifico bancario o altri mezzi tramite
        fornitori esterni di servizi di pagamento. In generale, e salvo diversa
        indicazione, gli Utenti sono pregati di fornire i dettagli di pagamento
        e le informazioni personali direttamente a tali fornitori di servizi di
        pagamento. Questa Applicazione non è coinvolta nella raccolta e
        nell'elaborazione di tali informazioni: riceverà invece solo una
        notifica da parte del fornitore di servizi di pagamento in questione
        circa l'avvenuto pagamento.
        <h5>Stripe</h5>
        Stripe è un servizio di pagamento fornito da Stripe Inc oppure da Stripe
        Technology Europe Ltd, a seconda di come il Titolare gestisce il
        trattamento dei Dati. Dati Personali trattati: cognome; email; indirizzo
        di fatturazione; informazioni di pagamento; nome; varie tipologie di
        Dati secondo quanto specificato dalla privacy policy del servizio. Luogo
        del trattamento: Stati Uniti – Privacy Policy; Irlanda – Privacy Policy.
        <h4>Gestione dei tag</h4>
        Questo tipo di servizi è funzionale alla gestione centralizzata dei tag
        o script utilizzati su questa Applicazione. L'uso di tali servizi
        comporta il fluire dei Dati dell'Utente attraverso gli stessi e, se del
        caso, la loro ritenzione.
        <h5>Google Tag Manager (Google Ireland Limited)</h5>
        Google Tag Manager è un servizio di gestione dei tag fornito da Google
        Ireland Limited. Dati Personali trattati: Dati di utilizzo; Strumenti di
        Tracciamento. Luogo del trattamento: Irlanda – Privacy Policy.
        <h4>Gestione della raccolta dati e dei sondaggi online</h4>
        Questo tipo di servizio permette a questa Applicazione di gestire la
        creazione, l'implementazione, l'amministrazione, la distribuzione e
        l'analisi di moduli e di sondaggi online al fine di raccogliere, salvare
        e riutilizzare i Dati degli Utenti che rispondono. I Dati Personali
        raccolti dipendono dalle informazioni richieste e fornite dagli Utenti
        nel modulo online corrispondente. Questi servizi possono essere
        integrati con un'ampia gamma di servizi di terze parti per consentire al
        Titolare di compiere azioni successive con i Dati trattati - ad esempio,
        gestione dei contatti, invio di messaggi, statistiche, pubblicità ed
        elaborazione dei pagamenti.
        <h5>Typeform (TYPEFORM S.L)</h5>
        Typeform è un generatore di moduli e una piattaforma di raccolta dati
        fornita da TYPEFORM S.L. Dati Personali trattati: email. Luogo del
        trattamento: Spagna – Privacy Policy.
        <h4>Hosting ed infrastruttura backend</h4>
        Questo tipo di servizi ha la funzione di ospitare Dati e file che
        permettono a questa Applicazione di funzionare, ne consentono la
        distribuzione e mettono a disposizione un'infrastruttura pronta all'uso
        per erogare specifiche funzionalità di questa Applicazione. Alcuni
        servizi tra quelli elencati di seguito, se presenti, possono funzionare
        su server geograficamente distribuiti, rendendo difficile determinare
        l'effettiva ubicazione in cui sono conservati i Dati Personali.
        <h5>Amazon Web Services (AWS) (Amazon Web Services, Inc.)</h5>
        Amazon Web Services (AWS) è un servizio di hosting e backend fornito da
        Amazon Web Services, Inc. Dati Personali trattati: varie tipologie di
        Dati secondo quanto specificato dalla privacy policy del servizio. Luogo
        del trattamento: Irlanda – Privacy Policy.
        <h4>Interazione con social network e piattaforme esterne</h4>
        Questo tipo di servizi permette di effettuare interazioni con i social
        network, o con altre piattaforme esterne, direttamente dalle pagine di
        questa Applicazione. Le interazioni e le informazioni acquisite da
        questa Applicazione sono in ogni caso soggette alle impostazioni privacy
        dell’Utente relative ad ogni social network. Questo tipo di servizio
        potrebbe comunque raccogliere dati sul traffico per le pagine dove il
        servizio è installato, anche quando gli Utenti non lo utilizzano. Si
        raccomanda di disconnettersi dai rispettivi servizi per assicurarsi che
        i dati elaborati su questa Applicazione non vengano ricollegati al
        profilo dell'Utente.
        <h5>Pulsante e widget sociali di Linkedin (LinkedIn Corporation)</h5>
        Il pulsante e i widget sociali di LinkedIn sono servizi di interazione
        con il social network Linkedin, forniti da LinkedIn Corporation. Dati
        Personali trattati: Dati di utilizzo; Strumenti di Tracciamento. Luogo
        del trattamento: Stati Uniti – Privacy Policy.
        <h5>
          Pulsante Mi Piace e widget sociali di Facebook (Meta Platforms Ireland
          Limited)
        </h5>
        Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di
        interazione con il social network Facebook, forniti da Meta Platforms
        Ireland Limited Dati Personali trattati: Dati di utilizzo; Strumenti di
        Tracciamento. Luogo del trattamento: Irlanda – Privacy Policy.
        <h4>Pubblicità</h4>
        Alcuni dei servizi di seguito indicati potrebbero utilizzare Strumenti
        di Tracciamento per identificare l’Utente, o utilizzare la tecnica del
        behavioral retargeting, ossia visualizzare annunci pubblicitari
        personalizzati in base agli interessi e al comportamento dell’Utente, o
        misurare le prestazioni degli annunci. Per avere maggiori informazioni
        in merito, ti suggeriamo di verificare le informative privacy dei
        rispettivi servizi. Generalmente i servizi di questo tipo offrono la
        possibilità di disattivare tale tracciamento. Oltre a qualsiasi funzione
        di opt-out fornita da uno qualsiasi dei servizi elencati in questo
        documento, l’Utente può leggere di più su come disattivare gli annunci
        pubblicitari basati sugli interessi nell'apposita sezione "Come
        disattivare la pubblicità basata sugli interessi" in questo documento.
        <h5>
          Monitoraggio conversioni di Meta ads (pixel di Meta) (Meta Platforms
          Ireland Limited)
        </h5>
        Il monitoraggio conversioni di Meta ads (pixel di Meta) è un servizio di
        statistiche fornito da Meta Platforms Ireland Limited che collega i dati
        provenienti dal network di annunci Meta con le azioni compiute
        all'interno di questa Applicazione. Il pixel di Meta monitora le
        conversioni che possono essere attribuite alle inserzioni di Facebook,
        Instagram e Audience Network. Dati Personali trattati: Dati di utilizzo;
        Strumenti di Tracciamento. Luogo del trattamento: Irlanda – Privacy
        Policy – Opt out.
        <h5>Monitoraggio conversioni di Google Ads (Google Ireland Limited)</h5>
        Il monitoraggio conversioni di Google Ads è un servizio di statistiche
        fornito da Google Ireland Limited che collega i dati provenienti dal
        network di annunci Google Ads con le azioni compiute all'interno di
        questa Applicazione. Dati Personali trattati: Dati di utilizzo;
        Strumenti di Tracciamento. Luogo del trattamento: Irlanda – Privacy
        Policy.
        <h4>Raccolta delle preferenze relative alla privacy</h4>
        Questo tipo di servizio permette a questa Applicazione di raccogliere e
        salvare le preferenze degli Utenti relative alla raccolta, all'uso e al
        trattamento delle loro informazioni personali, come richiesto dalla
        legislazione applicabile in materia di privacy.
        <h5>Consent Solution di iubenda (iubenda srl)</h5>
        La Consent Solution di iubenda permette di salvare e recuperare le
        registrazioni del consenso degli Utenti al trattamento dei Dati
        Personali, nonché le informazioni e le preferenze espresse in relazione
        al consenso fornito. A tal fine, fa uso di uno Strumento di Tracciamento
        che memorizza temporaneamente le informazioni in sospeso sul dispositivo
        dell'Utente fino a quando non vengono elaborate dall'API. Lo Strumento
        di Tracciamento (una funzione del browser chiamata localStorage) viene a
        quel punto cancellato. Dati Personali trattati: Dati comunicati durante
        l'utilizzo del servizio; Strumenti di Tracciamento. Luogo del
        trattamento: Italia – Privacy Policy.
        <h5>Cookie Solution di iubenda (iubenda srl)</h5>
        La Cookie Solution di iubenda permette al Titolare di raccogliere e
        salvare le preferenze degli Utenti relative al trattamento dei dati
        personali e in particolare all'utilizzo di Cookie e altri Strumenti di
        Tracciamento su questa Applicazione. Dati Personali trattati: Strumenti
        di Tracciamento. Luogo del trattamento: Italia – Privacy Policy.
        <h4>Registrazione ed autenticazione</h4>
        Con la registrazione o l’autenticazione l’Utente consente a questa
        Applicazione di identificarlo e di dargli accesso a servizi dedicati. A
        seconda di quanto indicato di seguito, i servizi di registrazione e di
        autenticazione potrebbero essere forniti con l’ausilio di terze parti.
        Qualora questo avvenga, questa Applicazione potrà accedere ad alcuni
        Dati conservati dal servizio terzo usato per la registrazione o
        l’identificazione. Alcuni dei servizi di seguito indicati potrebbero
        raccogliere Dati Personali anche per fini di targeting e profilazione;
        per saperne di più, si prega di fare riferimento alla descrizione di
        ciascun servizio.
        <h5>Auth0 (Auth0, Inc)</h5>
        Auth0 è un servizio di registrazione ed autenticazione fornito da Auth0,
        Inc. Per semplificare il processo di registrazione ed autenticazione,
        Auth0 può utilizzare fornitori di identità di terze parti e salvare le
        informazioni sulla propria piattaforma. Dati Personali trattati:
        cognome; email; immagine; nome; password; Strumenti di Tracciamento;
        varie tipologie di Dati secondo quanto specificato dalla privacy policy
        del servizio. Luogo del trattamento: Unione europea – Privacy Policy.
        <h5>Accedi con Apple (Apple Inc.)</h5>
        Accedi con Apple è un servizio di registrazione ed autenticazione
        fornito da Apple Inc. Nei casi in cui all’Utente sia richiesto di
        fornire il proprio indirizzo email, Accedi con Apple potrebbe generare
        un indirizzo privato di riferimento a nome dell’Utente che inoltri
        automaticamente i messaggi al suo indirizzo email personale verificato –
        nascondendo in tal modo il proprio indirizzo email al Titolare. Dati
        Personali trattati: città; cognome; data di nascita; email; nome;
        regione geografica. Luogo del trattamento: Stati Uniti – Privacy Policy.
        <h5>Facebook Authentication (Meta Platforms Ireland Limited)</h5>
        Facebook Authentication è un servizio di registrazione ed autenticazione
        fornito da Meta Platforms Ireland Limited e collegato al social network
        Facebook. Dati Personali trattati: Strumenti di Tracciamento; varie
        tipologie di Dati secondo quanto specificato dalla privacy policy del
        servizio. Luogo del trattamento: Irlanda – Privacy Policy.
        <h5>Google OAuth (Google Ireland Limited)</h5>
        Google OAuth è un servizio di registrazione ed autenticazione fornito da
        Google Ireland Limited e collegato al network Google. Dati Personali
        trattati: varie tipologie di Dati secondo quanto specificato dalla
        privacy policy del servizio. Luogo del trattamento: Irlanda – Privacy
        Policy.
        <h4>
          Registrazione ed autenticazione fornite direttamente da questa
          Applicazione
        </h4>
        Con la registrazione o l’autenticazione l’Utente consente a questa
        Applicazione di identificarlo e di dargli accesso a servizi dedicati. I
        Dati Personali sono raccolti e conservati esclusivamente a scopo di
        registrazione o di identificazione. I Dati raccolti sono solo quelli
        necessari a fornire il servizio richiesto dall’Utente.
        <h5>Registrazione diretta (questa Applicazione)</h5>
        L’Utente si registra compilando il modulo di registrazione e fornendo
        direttamente a questa Applicazione i propri Dati Personali. Dati
        Personali trattati: CAP; cognome; data di nascita; email; immagine di
        profilo; lingua; nome; sesso.
        <h4>Remarketing e behavioral targeting</h4>
        Questo tipo di servizi consente a questa Applicazione ed ai suoi partner
        di comunicare, ottimizzare e servire annunci pubblicitari basati
        sull'utilizzo passato di questa Applicazione da parte dell'Utente.
        Questa attività è facilitata dal tracciamento dei Dati di Utilizzo e
        dall'utilizzo di Strumenti di Tracciamento per raccogliere informazioni
        che vengono poi trasferite ai partner che gestiscono le attività di
        remarketing e di behavioral targeting. Alcuni servizi offrono un'opzione
        di remarketing basata sulle liste di indirizzi email. Generalmente i
        servizi di questo tipo offrono la possibilità di disattivare tale
        tracciamento. Oltre a qualsiasi funzione di opt-out fornita da uno
        qualsiasi dei servizi elencati in questo documento, l’Utente può leggere
        di più su come disattivare gli annunci pubblicitari basati sugli
        interessi nell'apposita sezione "Come disattivare la pubblicità basata
        sugli interessi" in questo documento.
        <h5>Facebook Remarketing (Meta Platforms Ireland Limited)</h5>
        Facebook Remarketing è un servizio di remarketing e behavioral targeting
        fornito da Meta Platforms Ireland Limited che collega l'attività di
        questa Applicazione con il network di advertising Facebook. Dati
        Personali trattati: Dati di utilizzo; Strumenti di Tracciamento. Luogo
        del trattamento: Irlanda – Privacy Policy – Opt Out.
        <h5>Remarketing Google Ads (Google Ireland Limited)</h5>
        Remarketing Google Ads è un servizio di remarketing e behavioral
        targeting fornito da Google Ireland Limited che collega l'attività di
        questa Applicazione con il network di advertising Google Ads ed il
        Cookie DoubleClick. Per una comprensione dell'utilizzo dei Dati da parte
        di Google, si prega di consultare le norme per i partner di Google. Gli
        Utenti possono scegliere di non utilizzare i Strumenti di Tracciamento
        di Google per la personalizzazione degli annunci visitando le
        Impostazioni annunci di Google. Dati Personali trattati: Dati di
        utilizzo; Strumenti di Tracciamento. Luogo del trattamento: Irlanda –
        Privacy Policy – Opt Out.
        <h5>Remarketing con Google Analytics (Google Ireland Limited)</h5>
        Remarketing con Google Analytics è un servizio di remarketing e
        behavioral targeting fornito da Google Ireland Limited che collega
        l'attività di tracciamento effettuata da Google Analytics e dai suoi
        Strumenti di Tracciamento con il network di advertising Google Ads ed il
        Cookie Doubleclick. Dati Personali trattati: Dati di utilizzo; Strumenti
        di Tracciamento. Luogo del trattamento: Irlanda – Privacy Policy – Opt
        OutOpt Out.
        <h4>Salvataggio e gestione di backup</h4>
        Questo tipo di servizi permette il salvataggio e la gestione dei backup
        di questa Applicazione su server esterni gestiti dal fornitore del
        servizio stesso. Questi backup possono comprendere sia il codice
        sorgente ed i contenuti della stessa che i dati forniti a questa
        Applicazione da parte dell'Utente.
        <h5>Amazon Glacier (Amazon Europe )</h5>
        Amazon Glacier è un servizio di salvataggio e gestione di backup fornito
        da Amazon Web Services Inc. Dati Personali trattati: varie tipologie di
        Dati secondo quanto specificato dalla privacy policy del servizio. Luogo
        del trattamento: Lussemburgo – Privacy Policy.
        <h4>Statistica</h4>I servizi contenuti nella presente sezione permettono
        al Titolare del Trattamento di monitorare e analizzare i dati di
        traffico e servono a tener traccia del comportamento dell’Utente.
        <h5>Google Analytics (Google Ireland Limited)</h5>
        Google Analytics è un servizio di analisi web fornito da Google Ireland
        Limited (“Google”). Google utilizza i Dati Personali raccolti allo scopo
        di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare
        report e condividerli con gli altri servizi sviluppati da Google. Google
        potrebbe utilizzare i Dati Personali per contestualizzare e
        personalizzare gli annunci del proprio network pubblicitario. Dati
        Personali trattati: Dati di utilizzo; Strumenti di Tracciamento. Luogo
        del trattamento: Irlanda – Privacy Policy – Opt Out.
        <h5>Google Analytics 4 (Google Ireland Limited)</h5>
        Google Analytics è un servizio di statistica fornito da Google Ireland
        Limited (“Google”). Google utilizza i Dati Personali raccolti allo scopo
        di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare
        report e condividerli con gli altri servizi sviluppati da Google. Google
        potrebbe utilizzare i Dati Personali per contestualizzare e
        personalizzare gli annunci del proprio network pubblicitario. In Google
        Analytics 4, gli indirizzi IP vengono utilizzati al momento della
        raccolta e poi eliminati prima che i dati vengano registrati in
        qualsiasi data center o server. Per saperne di più, è possibile
        consultare la documentazione ufficiale di Google. Dati Personali
        trattati: città; Dati di utilizzo; informazioni sul browser;
        informazioni sul dispositivo; latitudine (della città); longitudine
        (della città); numero di Utenti; statistiche delle sessioni; Strumenti
        di Tracciamento. Luogo del trattamento: Irlanda – Privacy Policy – Opt
        Out.
        <h5>Meta Events Manager (Meta Platforms Ireland Limited)</h5>
        Meta Events Manager è un servizio di statistica fornito da Meta
        Platforms Ireland Limited. Integrando il pixel di Meta, Meta Events
        Manager può dare al Titolare delle informazioni sul traffico e sulle
        interazioni su questa Applicazione. Dati Personali trattati: Dati di
        utilizzo; Strumenti di Tracciamento. Luogo del trattamento: Irlanda –
        Privacy Policy.
        <h5>Google Analytics con IP anonimizzato (Google Ireland Limited)</h5>
        Google Analytics è un servizio di analisi web fornito da Google Ireland
        Limited (“Google”). Google utilizza i Dati Personali raccolti allo scopo
        di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare
        report e condividerli con gli altri servizi sviluppati da Google. Google
        potrebbe utilizzare i Dati Personali per contestualizzare e
        personalizzare gli annunci del proprio network pubblicitario. Questa
        integrazione di Google Analytics rende anonimo il tuo indirizzo IP.
        L'anonimizzazione funziona abbreviando entro i confini degli stati
        membri dell'Unione Europea o in altri Paesi aderenti all'accordo sullo
        Spazio Economico Europeo l'indirizzo IP degli Utenti. Solo in casi
        eccezionali, l'indirizzo IP sarà inviato ai server di Google ed
        abbreviato all'interno degli Stati Uniti. Dati Personali trattati: Dati
        di utilizzo; Strumenti di Tracciamento. Luogo del trattamento: Irlanda –
        Privacy Policy – Opt Out.
        <h4>Visualizzazione di contenuti da piattaforme esterne</h4>
        Questo tipo di servizi permette di visualizzare contenuti ospitati su
        piattaforme esterne direttamente dalle pagine di questa Applicazione e
        di interagire con essi. Questo tipo di servizio potrebbe comunque
        raccogliere dati sul traffico web relativo alle pagine dove il servizio
        è installato, anche quando gli utenti non lo utilizzano.
        <h5>Google Fonts (Google Ireland Limited)</h5>
        Google Fonts è un servizio di visualizzazione di stili di carattere
        gestito da Google Ireland Limited che permette a questa Applicazione di
        integrare tali contenuti all’interno delle proprie pagine. Dati
        Personali trattati: Dati di utilizzo; Strumenti di Tracciamento. Luogo
        del trattamento: Irlanda – Privacy Policy.
      </p>
      <h3>
        Informazioni su come disattivare gli annunci pubblicitari basati sugli
        interessi
      </h3>
      <p>
        Oltre a qualsiasi funzione di opt-out fornita da uno qualsiasi dei
        servizi elencati in questo documento, gli Utenti possono leggere di più
        su come disattivare gli annunci pubblicitari basati sugli interessi
        nell'apposita sezione della Cookie Policy.
      </p>
      <h4>Ulteriori informazioni sul trattamento dei Dati Personali</h4>
      <p>
        <h5>Dati Personali raccolti attraverso fonti diverse dall'Utente</h5>
        Il Titolare di questa Applicazione potrebbe aver legittimamente raccolto
        Dati Personali relativi all’Utente senza il suo coinvolgimento,
        attingendo a fonti fornite da terze parti, in conformità con le basi
        giuridiche descritte nella sezione relativa alle basi giuridiche del
        trattamento. Qualora il Titolare avesse raccolto Dati Personali in tal
        modo, l’Utente potrà trovare informazioni specifiche riguardo alle fonti
        nelle rispettive sezioni di questo documento o contattando il Titolare.
        <h5>localStorage</h5>
        localStorage permette a questa Applicazione di memorizzare e accedere ai
        dati direttamente dal browser dell’Utente senza una data di scadenza.
        <h5>Analisi dei Dati dell’Utente e previsioni (“profilazione”)</h5>
        Il Titolare potrebbe trattare i dati d’utilizzo raccolti attraverso
        questa Applicazione per creare o aggiornare profili di utenza. Questo
        tipo di trattamento consente al Titolare di valutare scelte, preferenze
        e comportamento dell’Utente per gli scopi specificati nelle rispettive
        sezioni di questo documento. I profili d’utenza possono essere creati
        anche grazie a strumenti automatizzati, come algoritmi, che possono
        anche essere offerti da terze parti. Per ottenere ulteriori informazioni
        sull’attività di profilazione l’Utente può fare riferimento alle
        rispettive sezioni di questo documento. L’Utente ha in ogni momento
        diritto ad opporsi a tale attività di profilazione. Per scoprire di più
        sui diritti dell’Utente e su come esercitarli, l’Utente può fare
        riferimento alla sezione di questo documento relativa ai diritti degli
        Utenti.
      </p>
      <h4>Cookie Policy</h4>
      <p>
        Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per
        saperne di più, gli Utenti possono consultare la Cookie Policy.
      </p>
      <h4>Ulteriori informazioni per gli utenti nell'Unione Europea</h4>
      <p>
        Questa sezione si applica a tutti gli utenti dell'Unione Europea, in
        conformità al Regolamento generale sulla protezione dei dati (il "GDPR")
        e, per tali Utenti, sostituisce qualsiasi altra informazione
        eventualmente divergente o in conflitto contenuta nell'informativa sulla
        privacy. Ulteriori dettagli relativi alle categorie di Dati trattati,
        alle finalità del trattamento, alle categorie di destinatari dei Dati
        personali, se presenti, e ulteriori informazioni sui Dati personali sono
        disponibili nella sezione "Informazioni dettagliate sul trattamento dei
        Dati personali" all'interno del presente documento.
      </p>
      <h5>Base giuridica del trattamento</h5>
      <p>
        Il Titolare tratta Dati Personali relativi all’Utente in caso sussista
        una delle seguenti condizioni:
        <ul>
          <li>
            l’Utente ha prestato il consenso per una o più finalità specifiche.
          </li>
          <li>
            il trattamento è necessario all'esecuzione di un contratto con
            l’Utente e/o all'esecuzione di misure precontrattuali;
          </li>
          <li>
            il trattamento è necessario per adempiere un obbligo legale al quale
            è soggetto il Titolare;
          </li>
          <li>
            il trattamento è necessario per l'esecuzione di un compito di
            interesse pubblico o per l'esercizio di pubblici poteri di cui è
            investito il Titolare;
          </li>
          <li>
            il trattamento è necessario per il perseguimento del legittimo
            interesse del Titolare o di terzi.
          </li>
        </ul>
        È comunque sempre possibile richiedere al Titolare di chiarire la
        concreta base giuridica di ciascun trattamento ed in particolare di
        specificare se il trattamento sia basato sulla legge, previsto da un
        contratto o necessario per concludere un contratto.
      </p>
      <h5>Ulteriori informazioni sul tempo di conservazione</h5>
      <p>
        Se non diversamente indicato in questo documento, i Dati Personali sono
        trattati e conservati per il tempo richiesto dalla finalità per la quale
        sono stati raccolti e potrebbero essere conservati per un periodo più
        lungo a causa di eventuali obbligazioni legali o sulla base del consenso
        degli Utenti. Pertanto:
        <ul>
          <li>
            I Dati Personali raccolti per scopi collegati all’esecuzione di un
            contratto tra il Titolare e l’Utente saranno trattenuti sino a
            quando sia completata l’esecuzione di tale contratto.
          </li>
          <li>
            I Dati Personali raccolti per finalità riconducibili all’interesse
            legittimo del Titolare saranno trattenuti sino al soddisfacimento di
            tale interesse. L’Utente può ottenere ulteriori informazioni in
            merito all’interesse legittimo perseguito dal Titolare nelle
            relative sezioni di questo documento o contattando il Titolare.
          </li>
        </ul>
        Quando il trattamento è basato sul consenso dell’Utente, il Titolare può
        conservare i Dati Personali più a lungo sino a quando detto consenso non
        venga revocato. Inoltre, il Titolare potrebbe essere obbligato a
        conservare i Dati Personali per un periodo più lungo per adempiere ad un
        obbligo di legge o per ordine di un’autorità. Al termine del periodo di
        conservazione i Dati Personali saranno cancellati. Pertanto, allo
        spirare di tale termine il diritto di accesso, cancellazione,
        rettificazione ed il diritto alla portabilità dei Dati non potranno più
        essere esercitati.
      </p>
      <h5>
        Diritti dell’Utente sulla base del Regolamento Generale sulla Protezione
        dei Dati (GDPR)
      </h5>
      <p>
        Gli Utenti possono esercitare determinati diritti con riferimento ai
        Dati trattati dal Titolare. In particolare, nei limiti previsti dalla
        legge, l’Utente ha il diritto di:
        <ul>
          <li>
            <strong>revocare il consenso in ogni momento.</strong> L’Utente può
            revocare il consenso al trattamento dei propri Dati Personali
            precedentemente espresso.
          </li>
          <li>
            <strong>opporsi al trattamento dei propri Dati.</strong> L’Utente
            può opporsi al trattamento dei propri Dati quando esso avviene in
            virtù di una base giuridica diversa dal consenso.
          </li>
          <li>
            <strong>accedere ai propri Dati.</strong> L’Utente ha diritto ad
            ottenere informazioni sui Dati trattati dal Titolare, su determinati
            aspetti del trattamento ed a ricevere una copia dei Dati trattati.
          </li>
          <li>
            <strong>verificare e chiedere la rettificazione.</strong> L’Utente
            può verificare la correttezza dei propri Dati e richiederne
            l’aggiornamento o la correzione.
          </li>
          <li>
            <strong>ottenere la limitazione del trattamento.</strong> L’Utente
            può richiedere la limitazione del trattamento dei propri Dati. In
            tal caso il Titolare non tratterà i Dati per alcun altro scopo se
            non la loro conservazione.
          </li>
          <li>
            <strong>
              ottenere la cancellazione o rimozione dei propri Dati Personali.
            </strong>{" "}
            L’Utente può richiedere la cancellazione dei propri Dati da parte
            del Titolare.
          </li>
          <li>
            <strong>
              ricevere i propri Dati o farli trasferire ad altro titolare.
            </strong>{" "}
            L’Utente ha diritto di ricevere i propri Dati in formato
            strutturato, di uso comune e leggibile da dispositivo automatico e,
            ove tecnicamente fattibile, di ottenerne il trasferimento senza
            ostacoli ad un altro titolare.
          </li>
          <li>
            <strong>proporre reclamo.</strong> L’Utente può proporre un reclamo
            all’autorità di controllo della protezione dei dati personali
            competente o agire in sede giudiziale.
          </li>
        </ul>
        Gli Utenti hanno diritto di ottenere informazioni in merito alla base
        giuridica per il trasferimento di Dati all'estero incluso verso
        qualsiasi organizzazione internazionale regolata dal diritto
        internazionale o costituita da due o più paesi, come ad esempio l’ONU,
        nonché in merito alle misure di sicurezza adottate dal Titolare per
        proteggere i loro Dati.
      </p>
      <h5>Dettagli sul diritto di opposizione</h5>
      <p>
        <strong>
          Quando i Dati Personali sono trattati nell’interesse pubblico,
          nell’esercizio di pubblici poteri di cui è investito il Titolare
          oppure per perseguire un interesse legittimo del Titolare, gli Utenti
          hanno diritto ad opporsi al trattamento per motivi connessi alla loro
          situazione particolare. Si fa presente agli Utenti che, ove i loro
          Dati fossero trattati con finalità di marketing diretto, possono
          opporsi al trattamento in qualsiasi momento, gratuitamente e senza
          fornire alcuna motivazione. Qualora gli Utenti si oppongano al
          trattamento per finalità di marketing diretto, i Dati Personali non
          sono più oggetto di trattamento per tali finalità. Per scoprire se il
          Titolare tratti Dati con finalità di marketing diretto gli Utenti
          possono fare riferimento alle rispettive sezioni di questo documento.
        </strong>
      </p>
      <h5>Come esercitare i diritti</h5>
      <p>
        Per esercitare i propri diritti, gli Utenti possono indirizzare una
        richiesta ai recapiti del Titolare indicati in questo documento. La
        richiesta è gratuita e il Titolare risponderà nel più breve tempo
        possibile, in ogni caso entro un mese, fornendo all’Utente tutte le
        informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o
        limitazioni del trattamento saranno comunicate dal Titolare a ciascuno
        dei destinatari, se esistenti, a cui sono stati trasmessi i Dati
        Personali, salvo che ciò si riveli impossibile o implichi uno sforzo
        sproporzionato. Il Titolare comunica all'Utente tali destinatari qualora
        egli lo richieda.
      </p>
      <h3>Ulteriori informazioni sul trattamento</h3>
      <p>
        <h4>Difesa in giudizio</h4>I Dati Personali dell’Utente possono essere
        utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie
        alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di
        questa Applicazione o dei Servizi connessi da parte dell’Utente.
        L’Utente dichiara di essere consapevole che il Titolare potrebbe essere
        obbligato a rivelare i Dati per ordine delle autorità pubbliche.
        <h4>Informative specifiche</h4>
        Su richiesta dell’Utente, in aggiunta alle informazioni contenute in
        questa privacy policy, questa Applicazione potrebbe fornire all'Utente
        delle informative aggiuntive e contestuali riguardanti Servizi
        specifici, o la raccolta ed il trattamento di Dati Personali.
        <h4>Log di sistema e manutenzione</h4>
        Per necessità legate al funzionamento ed alla manutenzione, questa
        Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero
        raccogliere log di sistema, ossia file che registrano le interazioni e
        che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.
        Informazioni non contenute in questa policy Ulteriori informazioni in
        relazione al trattamento dei Dati Personali potranno essere richieste in
        qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di
        contatto.
        <h4>Modifiche a questa privacy policy</h4>
        Il Titolare del Trattamento si riserva il diritto di apportare modifiche
        alla presente privacy policy in qualunque momento notificandolo agli
        Utenti su questa pagina e, se possibile, su questa Applicazione nonché,
        qualora tecnicamente e legalmente fattibile, inviando una notifica agli
        Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si
        prega dunque di consultare con frequenza questa pagina, facendo
        riferimento alla data di ultima modifica indicata in fondo. Qualora le
        modifiche interessino trattamenti la cui base giuridica è il consenso,
        il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente,
        se necessario.
      </p>
      <h4>Definizioni e riferimenti legali</h4>
      <hr></hr>
      <p>
        <h5>Dati Personali (o Dati)</h5>
        Costituisce dato personale qualunque informazione che, direttamente o
        indirettamente, anche in collegamento con qualsiasi altra informazione,
        ivi compreso un numero di identificazione personale, renda identificata
        o identificabile una persona fisica.
        <h5>Dati di Utilizzo</h5>
        Sono le informazioni raccolte automaticamente attraverso questa
        Applicazione (anche da applicazioni di parti terze integrate in questa
        Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer
        utilizzati dall’Utente che si connette con questa Applicazione, gli
        indirizzi in notazione URI (Uniform Resource Identifier), l’orario della
        richiesta, il metodo utilizzato nell’inoltrare la richiesta al server,
        la dimensione del file ottenuto in risposta, il codice numerico
        indicante lo stato della risposta dal server (buon fine, errore, ecc.)
        il paese di provenienza, le caratteristiche del browser e del sistema
        operativo utilizzati dal visitatore, le varie connotazioni temporali
        della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i
        dettagli relativi all’itinerario seguito all’interno dell’Applicazione,
        con particolare riferimento alla sequenza delle pagine consultate, ai
        parametri relativi al sistema operativo e all’ambiente informatico
        dell’Utente.
        <h5>Utente</h5>
        L'individuo che utilizza questa Applicazione che, salvo ove diversamente
        specificato, coincide con l'Interessato.
        <h5>Interessato</h5>
        La persona fisica cui si riferiscono i Dati Personali.
        <h5>Responsabile del Trattamento (o Responsabile)</h5>
        La persona fisica, giuridica, la pubblica amministrazione e qualsiasi
        altro ente che tratta dati personali per conto del Titolare, secondo
        quanto esposto nella presente privacy policy.
        <h5>Titolare del Trattamento (o Titolare)</h5>
        La persona fisica o giuridica, l'autorità pubblica, il servizio o altro
        organismo che, singolarmente o insieme ad altri, determina le finalità e
        i mezzi del trattamento di dati personali e gli strumenti adottati, ivi
        comprese le misure di sicurezza relative al funzionamento ed alla
        fruizione di questa Applicazione. Il Titolare del Trattamento, salvo
        quanto diversamente specificato, è il titolare di questa Applicazione.
        <h5>Questa Applicazione</h5>
        Lo strumento hardware o software mediante il quale sono raccolti e
        trattati i Dati Personali degli Utenti.
        <h5>Servizio</h5>
        Il Servizio fornito da questa Applicazione così come definito nei
        relativi termini (se presenti) su questo sito/applicazione.
        <h5>Unione Europea (o UE)</h5>
        Salvo ove diversamente specificato, ogni riferimento all’Unione Europea
        contenuto in questo documento si intende esteso a tutti gli attuali
        stati membri dell’Unione Europea e dello Spazio Economico Europeo.
        <h5>Cookie</h5>I Cookie sono Strumenti di Tracciamento che consistono in
        piccole porzioni di dati conservate all'interno del browser dell'Utente.
        <h5>Strumento di Tracciamento</h5>
        Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es.
        Cookie, identificativi univoci, web beacons, script integrati, e-tag e
        fingerprinting - che consenta di tracciare gli Utenti, per esempio
        raccogliendo o salvando informazioni sul dispositivo dell’Utente.
        <hr></hr>
        <h5>Riferimenti legali</h5>
        Ove non diversamente specificato, questa informativa privacy riguarda
        esclusivamente questa Applicazione.
      </p>
      <br />
      <br />
      <p>Ultima modifica: 8 marzo 2024</p>
    </div>
  );
};

export default PrivacyPolicy;
