import React from "react";
import { useTransform } from "framer-motion";
import MockupCircle from "./mockupCircles";
import Tooltip from "./mockupTooltip";
import Mockup from "./mockup";
import {
  giverSample,
  momentSample,
  dedicationSample,
  songsSample,
} from "./fixture";

export function MockupTour({
  scrollY,
  wRoomS,
  wRoomSE,
  wTuneA,
  dur,
  wMessC,
  Ldur,
  wTuneS,
  mockupCirclePositions,
  readyCircles,
  activeCircleIndex,
  tooltipsData,
  activeTooltipIndex,
}) {
  const roomTranslateY = useTransform(scrollY, [wRoomS, wRoomSE], [0, -340]); //to scroll the content1 (room) inside the mockup
  const roomOpacity = useTransform(scrollY, [wTuneA, wTuneA + dur], [1, 0]); //to transition between the two contents we remove the room
  //to transition between the two dedications
  const dedicationOpacity = useTransform(
    scrollY,
    [wTuneA, wTuneA + dur, wMessC, wMessC + Ldur],
    [0, 1, 1, 0]
  );
  const momentOpacity = useTransform(scrollY, [wMessC, wMessC + Ldur], [0, 1]);
  const dedicationScale = useTransform(
    scrollY,
    [wMessC, wMessC + Ldur],
    [1, 0]
  );
  const momentScale = useTransform(scrollY, [wMessC, wMessC + Ldur], [0, 1]);
  //to scroll the content1 (tune) inside the mockup
  const tuneTranslateY = useTransform(
    scrollY,
    [wTuneS, wTuneS + 150],
    [0, -290]
  );
  return (
    <>
      {mockupCirclePositions.map((position, index) => (
        <MockupCircle
          key={index}
          position={position}
          movement={
            index === 5
              ? 0
              : index > 0
              ? tuneTranslateY // the Y translation values
              : roomTranslateY //
          }
          isReady={readyCircles[index]}
          isActive={activeCircleIndex === index}
          clickHandler={() => {
            if (tooltipsData[index]) {
              window.scrollTo(0, tooltipsData[index].activeRange[0] + 10); //without 10 security pixel there are problems
            }
          }}
          index={index}
        />
      ))}

      <Mockup
        roomOpacity={roomOpacity}
        dedicationOpacity={dedicationOpacity}
        momentOpacity={momentOpacity}
        dedicationScale={dedicationScale}
        momentScale={momentScale}
        roomTranslateY={roomTranslateY}
        tuneTranslateY={tuneTranslateY}
        giverName={giverSample}
        moment={momentSample}
        dedication={dedicationSample}
        avatar={"mockup-avatar.png"}
        cover={"mockup-cover.png"}
        songs={songsSample}
      />

      {tooltipsData.map((tooltip, index) => (
        <Tooltip
          key={index}
          content={tooltip.content}
          isActive={activeTooltipIndex === index}
          position={tooltip.position}
          index={index}
        />
      ))}
    </>
  );
}
