// note that in this file we have a void item at the end, but it don't render
// and also it is not mandatory to render until the previous one, so we can omit it
export const faqFile = {
  categories: [
    "",
    "Come funziona",
    "Pagamento, offerte, rimborso",
    "Scelta delle canzoni",
  ],
  contents: [
    {
      question: "Cos’è un Tune?",
      answer: (
        <span>
          Un Tune è un{" "}
          <strong className="strong600">regalo personalizzato</strong> composto
          da musica, una dedica, un consiglio per un momento speciale di
          ascolto, una copertina personalizzata e un'immagine del mittente come
          firma del regalo.
        </span>
      ),
      category: "",
      places: [],
    },
    {
      question: "Perché pagare per un Tune?",
      answer: (
        <span>
          Per dare <strong className="strong600">valore alla musica</strong>,
          sia economicamente tramite il sostegno al lavoro degli{" "}
          <strong className="strong600">artisti</strong>, sia relazionalmente,
          garantendo con Tunehey un{" "}
          <strong className="strong600">posto sicuro</strong> dove ascoltare e
          vivere il tuo regalo, senza che venga perso, e al riparo da altre
          distrazioni.
        </span>
      ),
      category: "",
      places: ["landing"],
    },
    {
      question: "Come si regala un Tune?",
      answer: (
        <span>
          Vai nella Home Page del sito e clicca “Regala”! Inizierai ad esplorare
          le varie fasi di creazione del regalo, dalla scelta delle canzoni e
          personalizzazione dei restanti elementi fino al pagamento. Ricorda che
          per salvare i progressi e ascoltare le anteprime dei brani è
          necessario registrarsi anche prima della pagina di pagamento. Puoi
          però esplorare il processo anche senza registrarti.
        </span>
      ),
      category: "Come funziona",
      places: [],
    },
    {
      question: "Posso regalare una sola canzone?",
      answer: (
        <span>
          No, pensiamo al regalo di un Tune come un regalo di un momento che
          l’altra persona può prendersi, per questo il numero minimo di canzoni
          è 3.
        </span>
      ),
      category: "Come funziona",
      places: [],
    },
    {
      question: "Come si consegna un Tune?",
      answer: (
        <span>
          Dopo aver completato il pagamento
          <strong className="strong600">
            riceverai per mail le istruzioni e il codice da comunicare al
            destinatario
          </strong>{" "}
          e a questo punto puoi farlo in due modi. Il primo modo è quello di
          stampare il documento presente in allegato nella mail, scriverci il
          codice e consegnarlo personalmente al destinatario. Il secondo modo
          (utile se il destinatario è lontano da noi) è quello di inviargli per
          messaggio (whatsapp, mail, ecc) le istruzioni e il codice per
          riscattare il regalo.
        </span>
      ),
      category: "Come funziona",
      places: [],
    },
    {
      question: "Devo scaricare un'APP per vedere il regalo ricevuto?",
      answer: (
        <span>
          Sì, tutti i regali ricevuti si possono riscattare nell’App Tunehey,
          disponibile per iOS e Android.
        </span>
      ),
      category: "Come funziona",
      places: [],
    },
    {
      question: "Quanto costa regalare un Tune?",
      answer: (
        <span>
          Il prezzo dipende dalla tipologia di regalo: Tune e Maxi Tune.
          <br />
          Entrambi contengono tutte le personalizzazioni (dedica, consiglio per
          un momento speciale di ascolto, immagine di copertina, immagine e nome
          del mittente) ma differiscono per il numero di brani musicali e per il
          prezzo.
          <br />
          Nel <strong className="strong600">Tune</strong>, dove puoi inserire da
          3 a 5 brani, il prezzo è di{" "}
          <strong className="strong600">5,99€</strong>.<br />
          Nel <strong className="strong600">Maxi Tune</strong>, dove invece puoi
          inserire da 6 a 10 brani, il prezzo è di{" "}
          <strong className="strong600">9,99€</strong>.
        </span>
      ),
      category: "Pagamento, offerte, rimborso",
      places: [],
    },
    {
      question: "Ci sono delle offerte?",
      answer: (
        <span>
          Sì, per permetterti di ripetere l’esperienza nei mesi successivi, o
          fare anche subito più regali, ci sono due offerte apposite:
          <br />- <strong className="strong600">GIFTING EXPLORER</strong>: 2
          regali a 10,99€ (Tune) o 16,99€ (maxi Tune)
          <br />- <strong className="strong600">LIFESTYLE GIFTER:</strong> 4
          regali a 19,99€ (Tune) o 29,99€ (maxi Tune).
        </span>
      ),
      category: "Pagamento, offerte, rimborso",
      places: ["landing"],
    },
    {
      question: "Dall’APP Tunehey posso anche regalare?",
      answer: (
        <span>
          No, al momento nell’APP potrai solo farti coccolare dai regali
          ricevuti, custoditi nello scaffale digitale. Per creare un regalo si
          parte sempre da www.tunehey.com
        </span>
      ),
      category: "Come funziona",
      places: ["landing"],
    },
    {
      question:
        "Ho acquistato un pacchetto da 2 o 4 regali, come posso utilizzarlo?",
      answer: (
        <span>
          Dopo aver personalizzato un nuovo regalo, al momento del pagamento,
          potrai selezionare l’opzione “Utilizza credito”. Puoi sempre
          controllare il numero di crediti a tua disposizione cliccando
          nell’icona utente in alto a destra.
        </span>
      ),
      category: "Pagamento, offerte, rimborso",
      places: [],
    },
    {
      question: "Posso ascoltare nell’APP il regalo che ho fatto?",
      answer: (
        <span>
          No, ogni Tune può essere riscattato da una sola persona. Questo lo
          rende un vero dono, e un regalo realmente unico.
        </span>
      ),
      category: "Come funziona",
      places: [],
    },
    {
      question: "Quanto riceve l'artista?",
      answer: (
        <span>
          Per ogni regalo l'industria musicale e gli artisti coinvolti nello
          stesso ricevono circa{" "}
          <strong className="strong600">la metà del nostro ricavo netto</strong>
          . Prendendo ad esempio un Maxi Tune da 9,99€ il loro ricavo sarà
          4,10€, una cifra{" "}
          <strong className="strong600">
            equivalente a più di 1300 stream
          </strong>{" "}
          nelle piattaforme tradizionali.
        </span>
      ),
      category: "Come funziona",
      places: ["landing"],
    },
    {
      question:
        "Ho acquistato un regalo ma non so se consegnarlo o non può essere riscattato da chi l'ha ricevuto. Posso ricevere un rimborso?",
      answer: (
        <span>
          Dal momento che il regalo acquistato non è stato ancora aperto, puoi
          chiedere per qualsiasi motivo entro 14 giorni dall'acquisto di
          ricevere un rimborso. Per farlo invia una richiesta con oggetto
          "Richiesta rimborso" all'indirizzo hey@Tunehey.com includendo nel
          testo il codice del regalo che hai ricevuto nell'email di conferma. Se
          sono invece passati più di 14 giorni ma il tuo regalo non può essere
          aperto dal destinatario e vuoi cambiarlo, siamo ben lieti di riportare
          il tuo regalo allo stato di bozza in modo che tu possa gratuitamente
          modificarlo per regalarlo a qualcuno che possa effettivamente
          riceverlo. Scrivi "Richiesta cambio regalo" nell'oggetto, includendo
          anche in questo caso nel testo il codice ricevuto alla conferma.
        </span>
      ),
      category: "Pagamento, offerte, rimborso",
      places: [],
    },
    {
      question: "Perché non trovo la musica che vorrei regalare?",
      answer: (
        <span>
          Lavoriamo per allargare costantemente il nostro catalogo, e più
          sostegno riceviamo più sarà un processo veloce. Abbiamo infatti
          bisogno di dimostrare ai grandi fornitori musicali che c’è interesse
          per la nostra soluzione al problema di regalare musica oggi. Per
          questo, a partire dal nostro catalogo dove puoi già trovare canzoni di
          qualità che puoi navigare in funzione del regalo che vuoi fare (vedi
          le playlist o la ricerca per hashtag), ti invitiamo a sostenerci
          iniziando da subito a utilizzare Tunehey per i tuoi regali. Grazie!
        </span>
      ),
      category: "Scelta delle canzoni",
      places: [],
    },
    {
      question: "Come si cercano le canzoni? ",
      answer: (
        <span>
          Durante il processo di personalizzazione potrai 1) cercare nella{" "}
          <strong className="strong600">barra di ricerca</strong> scrivendo il
          nome della canzone o dell’artista, 2) utilizzare i{" "}
          <strong className="strong600">filtri sentimento o genere</strong> e 3)
          consultare la <strong className="strong600">sezione playlist</strong>{" "}
          per scoprire brani che probabilmente non conoscevi ma adatti al tuo
          regalo.
        </span>
      ),
      category: "Scelta delle canzoni",
      places: [],
    },
    {
      question: "Posso regalare una playlist già composta?",
      answer: (
        <span>
          Sì, abbiamo creato delle playlist editoriali che trovi nella pagina di
          scelta delle canzoni. Aprendo la sezione “Playlists” puoi consultarle
          e aggiungere al tuo regalo la playlist per intero o prendere
          ispirazione per aggiungere singole canzoni.
        </span>
      ),
      category: "Scelta delle canzoni",
      places: [],
    },
    {
      question:
        "Mi è stata consigliata una playlist ma non è tra quelle editoriali, dove la trovo? ",
      answer: (
        <span>
          Se ti è stata consigliata con una parola chiave, cerca @parolachiave
          nella barra di ricerca delle canzoni.
        </span>
      ),
      category: "Scelta delle canzoni",
      places: [],
    },
    {
      question: "A cosa servono gli #?",
      answer: (
        <span>
          Con gli Hashtag (#) puoi filtrare il catalogo per genere musicale e/o
          cercare le canzoni sulla base del sentimento che vuoi suscitare. Ad
          esempio, se vuoi regalare canzoni dal sound “felice” o che trasmettano
          felicità, scrivi #felicità nella barra di ricerca oppure seleziona
          l’hashtag dalla lista nella sezione “Filtri”
        </span>
      ),
      category: "Scelta delle canzoni",
      places: [],
    },
    {
      question: "",
      answer: "",
      category: "",
      places: [],
    },
  ],
};
