import React, { useEffect, useState, useContext, useRef } from "react";
import { Tooltip } from "@mui/material";
import "intro.js/introjs.css";
import { Steps, Hints } from "intro.js-react";

import SongList from "../../components/songList";
import MusicControl from "../../components/musicControl";
import AudioPassivePlayer from "../../components/common/audioPassivePlayer";
import SongToolBox from "../../components/songToolBox";
import PopSwitchSize from "../popups/popSwitchSize";
import { UserContext } from "../../contexts/userContext";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineSwitchAccessShortcut } from "react-icons/md";
import { isMobile } from "../../utils/isMobile";

const Compose = ({
  sceneChange,
  songs,
  setSongs,
  setJustAddedSong,
  currentSong,
  volume,
  setVolume,
  songUrl,
  songPlaying,
  songStart,
  handlePlay,
  handlePause,
  giftSize,
  setGiftSize,
  selectMin,
  selectMax,
  showAcquisitionMsg,
  setAcquisitionMsg,
  tuTourEd,
  setTuTourEd,
}) => {
  useEffect(() => {
    sceneChange("light");
  }, []);

  const { user } = useContext(UserContext);

  const [requestPopSwitchSize, setRequestPopSwitchSize] = useState(false); // manages the popup to switch from S to M gift size

  const [tooltipState, setTooltipState] = useState(false);
  const handleTooltip = () => {
    if (giftSize == "S") {
      setRequestPopSwitchSize(true);
    } else {
      let prev = tooltipState;
      setTooltipState(!tooltipState);
      if (prev == false) {
        setTimeout(() => {
          setTooltipState(false);
        }, 5400);
      }
    }
  };

  const [isSixth, setIsSixth] = useState(false);

  const tooltipRef = useRef(null);

  const handleSwitchDown = () => {
    if (giftSize == "M") {
      if (songs.length <= 5) {
        setGiftSize("S");
        handleTooltip();
      } else {
        tooltipRef.current.classList.add("vibrate");
        setTimeout(() => {
          tooltipRef.current.classList.remove("vibrate");
        }, 500);
      }
    }
  };

  //the handling of these local states must be at this level
  const [selectedSentiment, setSelectedSentiment] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");

  function handleSelect(song) {
    if (songs.length < selectMax) {
      var selected = [...songs, song];
      setSongs(selected);
      setJustAddedSong(true);
    } else if (selectMax == 5) {
      if (user !== null) {
        setRequestPopSwitchSize(true);
      } else {
        setAcquisitionMsg("Accedi per selezionare più brani");
        showAcquisitionMsg(true);
      }
    }
  }

  function handleRemove(song) {
    var selected = songs.filter((m) => m.ID_Song !== song.ID_Song);
    setSongs(selected);
  }

  const [debouncedQuery, setDebouncedQuery] = useState("");
  // This state variable collects queries that occur within a certain
  // number of milliseconds and "bounces them off the screen" instead
  // of passing them to the API. This improves performance while not
  // reducing the user experience.

  // TOOLBOX MANAGEMENT
  const deviceMobile = isMobile();

  const [openTool, setOpenTool] = useState(null);
  const [openSheet, setOpenSheet] = useState(null);
  const [openFilter, setOpenFilter] = useState("");

  const handleTool = (title, action = "both") => {
    if (action == "both") {
      !deviceMobile
        ? setOpenTool(openTool === title ? null : title)
        : setOpenSheet(title);
    } else if (action == "open") {
      !deviceMobile ? setOpenTool(title) : setOpenSheet(title);
    } else {
      !deviceMobile ? setOpenTool(null) : setOpenSheet(null);
    }
  };

  useEffect(() => {
    if (deviceMobile) setOpenTool(null);
  }, [deviceMobile]);

  // PAGE TOUR

  // Function to be executed when changing to a new step
  const handleChange = (stepIndex) => {
    console.log(`Changed to step with index: ${stepIndex}`);
    if (stepIndex === 1) {
      handleTool("Playlists", "open");
    } else if (stepIndex === 0 || stepIndex === 2) {
      handleTool("Playlists", "close");
    }
    if (stepIndex === 3) {
      {
        handleTool("Filtri", "open");
        setOpenFilter("sentiment");
      }
    } else if (stepIndex === 2) {
      handleTool("Filtri", "close");
    }
  };

  const handleComplete = () => {
    setTuTourEd(true);
  };

  const steps = [
    {
      element: "#Playlists-tool",
      intro: "Per S. Valentino abbiamo preparato delle playlist speciali 🧡",
      // position: "left",
    },
    {
      element: !deviceMobile ? "#Playlists-tool" : "#sheet-section",
      intro: "Prendi ispirazione o aggiungila per intero! 🎶",
      position: "right",
    },
    {
      element: "#Filtri-tool",
      intro:
        "Qui puoi affinare la ricerca 🔎, non solo per genere musicale ma...",
      position: "right",
    },
    {
      element: !deviceMobile ? "#Filtri-tool" : "#sheet-section",
      intro:
        " ..un filtro sentimento per scovare canzoni d'amore (o altri mood che vuoi suscitare) ✨",
      position: "right",
    },
  ];

  const options = {
    showProgress: true,
    showBullets: false,
    exitOnOverlayClick: false,
    exitOnEsc: false,
    nextLabel: "Avanti",
    prevLabel: "Indietro",
    // skipLabel: "Salta",
    hidePrev: true,
    doneLabel: "Fine",
    overlayOpacity: 0.5,
    overlayColor: "#FCE868",
    showStepNumbers: true,
    keyboardNavigation: true,
    scrollToElement: true,
    helperElementPadding: 10,
    showButtons: true,
    autoPosition: true,
    // positionPrecedence: ["left", "bottom", "top", "right"],
  };

  const [tourActive, setTourActive] = useState(false);
  //manages song-sheet layer for intro.js disactivation to enable active user songs search
  // serves also as a non-repeating anchor for the tour if skipped but not completed (next tour at page refresh)

  return (
    <>
      <div className="custom-page-songs">
        <div className="custom-row-compose">
          <div className="custom-row-compose-limits" />
          <div className="d-flex flex-column justify-content-between">
            <div className="first-column-top-margin ">
              <div className="d-flex flex-row align-items-center centered-title-phone">
                <h1 className="font-title3-Osw margin0 ">
                  Scegli da {selectMin} a {selectMax} canzoni{" "}
                </h1>
                {user !== null && (
                  <Tooltip
                    ref={tooltipRef}
                    title={
                      giftSize == "M" ? (
                        <p className="tooltip-text">
                          Se vuoi passare al tune da massimo 5 brani,
                          <br className="phone" /> rimuovi le eventuali canzoni
                          in più e poi
                          <button
                            className="transparentBtn"
                            onClick={() => handleSwitchDown()}
                            style={{ color: "#F98050" }}
                          >
                            clicca qui
                          </button>
                        </p>
                      ) : (
                        <></>
                      )
                    }
                    placement="bottom-end"
                    open={tooltipState}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#2C2B26",
                          borderRadius: "40px",
                          color: "#FFFDEF",
                          maxWidth: "400px",
                        },
                      },
                    }}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [10, -12],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <button className="transparentBtn" onClick={handleTooltip}>
                      <MdOutlineSwitchAccessShortcut
                        className={`switch-icon ${
                          giftSize == "M" ? "half-rotation" : "vertical-reverse"
                        }`}
                      />
                    </button>
                  </Tooltip>
                )}
              </div>

              <h2
                className="font-subT1-Osw font-subT1-Osw-custom-phone centered-title-phone"
                style={{ marginBottom: "14px" }}
              >
                per dar suono al tuo regalo
              </h2>
            </div>
            <Steps
              enabled={!tuTourEd && tourActive}
              steps={steps}
              options={options}
              initialStep={0}
              onBeforeChange={(targetElement) => handleChange(targetElement)}
              onComplete={handleComplete}
              onStart={() => {}}
              onExit={() => {
                setTourActive(false);
              }}
            />
            {/* <Hints enabled={true} hints={hints} /> */}
            <SongToolBox
              selectedSentiment={selectedSentiment}
              setSelectedSentiment={setSelectedSentiment}
              selectedGenre={selectedGenre}
              setSelectedGenre={setSelectedGenre}
              setDebouncedQuery={setDebouncedQuery}
              songPlaying={songPlaying}
              handlePlay={handlePlay}
              handlePause={handlePause}
              setSongs={setSongs}
              songs={songs}
              giftSize={giftSize}
              deviceMobile={deviceMobile}
              openTool={openTool}
              handleTool={handleTool}
              openSheet={openSheet}
              setOpenSheet={setOpenSheet}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              hideInvisibleLayer={!tourActive}
            />

            <div></div>
          </div>

          <div
            className="compose-central phone-compose-central d-flex flex-column justify-content-start "
            style={{ maxWidth: "1000px" }}
          >
            <SongList
              songs={songs}
              setSongs={setSongs}
              selectMax={selectMax}
              setIsSixth={setIsSixth}
              setRequestPopSwitchSize={setRequestPopSwitchSize}
              setAcquisitionMsg={setAcquisitionMsg}
              showAcquisitionMsg={showAcquisitionMsg}
              setJustAddedSong={setJustAddedSong}
              selectedSentiment={selectedSentiment}
              setSelectedSentiment={setSelectedSentiment}
              selectedGenre={selectedGenre}
              setSelectedGenre={setSelectedGenre}
              debouncedQuery={debouncedQuery}
              setDebouncedQuery={setDebouncedQuery}
              songPlaying={songPlaying}
              handlePlay={handlePlay}
              handlePause={handlePause}
            />

            <MusicControl
              currentSong={currentSong}
              isPlaying={songUrl !== ""}
              isChosed={songs.includes(currentSong)}
              onPlay={handlePlay}
              onPause={handlePause}
              volume={volume}
              setVolume={setVolume}
              onSelect={handleSelect}
              onRemove={handleRemove}
            />
            <AudioPassivePlayer
              songUrl={songUrl}
              songStart={songStart}
              handlePause={handlePause}
              type={"previewPlayer"}
              volume={volume}
            />
          </div>

          <div className="no-phone ">
            <h1
              className="font-title3-Osw no-selection"
              style={{ color: "#FFFDEF" }}
            >
              <span className="nowordsright">Scegli</span> da 6 a 10 canzoni{" "}
            </h1>
          </div>
          <div className="custom-row-compose-limits" />
        </div>
        {requestPopSwitchSize && (
          <PopSwitchSize
            setGiftSize={setGiftSize}
            requestPopSwitchSize={requestPopSwitchSize}
            setRequestPopSwitchSize={setRequestPopSwitchSize}
            sixth={isSixth}
            setIsSixth={setIsSixth}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(Compose);
