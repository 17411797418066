import whiteWheel from "../../assets/icons/whiteWheel.svg";
import ReactPixel from "../../services/facebookPixel";

// THIS BUTTON IS USED ONLY ON THE LANDING PAGE CTAs
function WheelPrimaryButton({
  text,
  clickHandler,
  active = true,
  darkTheme = false,
  customEventName = "unregistered-wheelPrimary",
}) {
  const handleClick = () => {
    clickHandler();
    if (customEventName !== "unregistered-wheelPrimary") {
      ReactPixel.trackCustom(customEventName);
    }
  };

  const buttonClasses = `wheelBtn ${
    active ? "wheelPrimary" : "wheelPrimaryPre"
  } d-flex align-items-center ${darkTheme ? "lightShadow" : ""}`;

  return (
    <button
      className={buttonClasses}
      disabled={active ? false : true}
      onClick={handleClick}
    >
      {/* <img
        className={wheelClasses}
        src={whiteWheel}
        alt=""
        style={{ marginRight: "1em" }}
      /> */}
      <svg
        width="73"
        height="74"
        viewBox="0 0 73 74"
        xmlns="http://www.w3.org/2000/svg"
        className="wheel"
        alt=""
        style={{ marginRight: "1em" }}
      >
        <path
          d="M36.51 73.02C16.53 73.02 0.279999 56.77 0.279999 36.79C0.279999 16.81 16.53 0.559998 36.51 0.559998C56.49 0.559998 72.74 16.81 72.74 36.79C72.74 56.77 56.49 73.02 36.51 73.02ZM36.51 6.98999C20.08 6.98999 6.71 20.36 6.71 36.79C6.71 53.22 20.08 66.59 36.51 66.59C52.94 66.59 66.31 53.22 66.31 36.79C66.31 20.36 52.94 6.98999 36.51 6.98999Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M16.0665 11.7955L11.6047 16.2573L16.0665 20.7192L20.5284 16.2573L16.0665 11.7955Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M16.07 21.62L10.7 16.25L16.07 10.88L21.44 16.25L16.07 21.62ZM12.52 16.25L16.07 19.8L19.62 16.25L16.07 12.7L12.52 16.25Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M56.1091 11.7929L51.6472 16.2548L56.1091 20.7166L60.5709 16.2548L56.1091 11.7929Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M56.1 21.62L50.73 16.25L56.1 10.88L61.47 16.25L56.1 21.62ZM52.55 16.25L56.1 19.8L59.65 16.25L56.1 12.7L52.55 16.25Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M51.6384 56.2854L56.1003 60.7473L60.5621 56.2854L56.1003 51.8236L51.6384 56.2854Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M56.1 61.66L50.73 56.29L56.1 50.92L61.47 56.29L56.1 61.66ZM52.55 56.29L56.1 59.84L59.65 56.29L56.1 52.74L52.55 56.29Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M16.062 51.831L11.6002 56.2928L16.062 60.7547L20.5239 56.2928L16.062 51.831Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M16.07 61.66L10.7 56.29L16.07 50.92L21.44 56.29L16.07 61.66ZM12.52 56.29L16.07 59.84L19.62 56.29L16.07 52.74L12.52 56.29Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M11.02 33.63H4.71002V39.94H11.02V33.63Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M11.67 40.59H4.07001V32.99H11.67V40.59ZM5.36001 39.3H10.38V34.28H5.36001V39.3Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M66.88 33.63H60.57V39.94H66.88V33.63Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M67.52 40.59H59.92V32.99H67.52V40.59ZM61.21 39.3H66.23V34.28H61.21V39.3Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M38.8 60.75H32.49V67.06H38.8V60.75Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M39.44 67.7H31.84V60.1H39.44V67.7ZM33.13 66.41H38.15V61.39H33.13V66.41Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M38.8 5.94H32.49V12.25H38.8V5.94Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
        <path
          d="M39.44 12.89H31.84V5.28998H39.44V12.89ZM33.13 11.6H38.15V6.58002H33.13V11.6Z"
          fill={active ? "#FFFCEB" : "#F98050"}
        />
      </svg>

      {text}
    </button>
  );
}

export default WheelPrimaryButton;
