import axios from "axios";
import { EncodeLineBreak } from './../utils/manageLines';

const testUrl = "https://develop-functions.azurewebsites.net/api/";
// const testUrl = "https://develop-functions-api.azure-api.net/";
const prodUrl = "https://tunehey-functions.azurewebsites.net/api/";
// const prodUrl = "https://tunehey-functions-api.azure-api.net/";

const url = (window.location.hostname == "www.tunehey.com" || window.location.hostname == "tunehey.com")  ? prodUrl : testUrl;

/**
 * Requests information about songs related to a textual or parameter based search
 * 
 * @param {string} query - The text of the search to be performed.
 * @param {string} feature - The text of the features to filter.
 * @param {string} genre - The text of the genres to filter.
 * @param {string} code - The text of the code of the playlist to be searched.
 * @returns {Object} - A JSON object containing the following information 
 *   - number: the number of songs returned, and the lenght of the following array
 *   - contacts: an array of objects containing the following informations about the songs matched
 *      - ID_Song (number): the ID number
 *      - Title (string): the title
 *      - Length (string): a text containing the number of seconds of lenght
 *      - Storage_Preview (string): the filename of the entire song
 *      - Stage_Name (string): the Artist name
 *      - Preview_Start (number): the starting second for the preview
 */
export function fetchData(query, feature, genre, code) {
  try {
    const result = axios.post(url + `searchSongs?text=${query}&feature=${feature}&genre=${genre}&playlist=${code}`);
    return result
  } catch (error) {
    console.error(error);
  }
}

/**
 * Creates a new collection
 * 
 * @param {string} songs - a list of song IDs to add to the collection, in text format and separated by commas
 * @returns {number} - a number corresponding to the ID of the new collection
 */
export function newCollection(songs) {
  try {
    const result = axios.post(url + `newCollection`, {idSongs: songs});
    return result
  } catch (error) {
    console.error(error);
  }
}

/**
 * Modifies an existing collection
 * 
 * @param {number} collection - the ID of the collection to modify
 * @param {string} songs - the new list of song IDs to overwrite the collection, in text format and separated by commas
 * @returns {number} - a number corresponding to the ID of the new collection
 */
export function modifyCollection(collection, songs) {
  try {
    return axios.post(url + `modifyCollection`, {idCollection: collection, idSongs: songs});
  } catch (error) {
    console.error(error);
  }
}

/**
 * Creates a new gift
 * 
 * @param {number} user - the ID of the user who is creating the gift
 * @param {string} person - the name of the receiver that identifies the gift
 * @param {number} collection - the ID of the collection created
 * @param {string} size - the code identifying the commercial size of the gift
 * @param {string} moment - the text of moment suggested for the gift
 * @param {string} dedication - a message to be included with the gift
 * @param {string} cover - the filename of the cover image
 * @param {string} avatar - the filename of the avatar image
 * @param {string} contactEmail - the email address the user want to be contacted
 * @param {boolean} newStdAvatar - a flag indicating whether the avatar image should update existing default avatar for the user
 * @returns {number} - a number corresponding to the ID of the new gift
 */
 export function newGift(user, person, collection, size, moment, dedication, cover, avatar, giverName, contactEmail, newStdAvatar) {
  try {
    return axios.post(url + `newGift`,{
      name: EncodeLineBreak(person), 
      moment: EncodeLineBreak(moment), 
      dedication: EncodeLineBreak(dedication), 
      avatar: avatar, 
      cover: cover, 
      giverName: giverName, 
      idGiver: user, 
      idCollection: collection, 
      size: size,
      contactEmail: contactEmail, 
      default: newStdAvatar});
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Modifies an existing gift
 * 
 * @param {number} gift - the ID of the gift to be modified
 * @param {number} userId - the ID of the user who is modifying the gift
 * @param {string} person - the name of the receiver that identifies the gift
 * @param {number} collection - the ID of the collection associated with the gift
 * @param {string} size - the code identifying the commercial size of the gift
 * @param {string} moment - the text of moment suggested for the gift
 * @param {string} dedication - a message to be included with the gift
 * @param {string} cover - the filename of the cover image
 * @param {string} avatar - the filename of the avatar image
 * @param {string} contactEmail - the email address the user wants to be contacted
 * @param {boolean} newStdAvatar - a flag indicating whether the avatar image should update existing default avatar for the user
 *  @returns {Object} an object with the following properties:
 *  - contacts: an object containing information about the affected rows
 *      - fieldCount: the number of columns in the result set
 *      - affectedRows: the number of affected rows
 *      - insertId: the ID of the inserted row if applicable, 0 otherwise
 *      - info: information about the query execution
 *      - serverStatus: the status code of the server
 *      - warningStatus: the status code of the warnings, if any
 *      - changedRows: the number of changed rows
 */
export function modifyGift(gift, userId, person, collection, size, moment, dedication, cover, avatar, giverName, contactEmail, newStdAvatar) {
  try { 
    return axios.post(url + `modifyGift`, {
      idGift: gift, 
      idUser: userId, 
      name: EncodeLineBreak(person), 
      moment: EncodeLineBreak(moment),
      dedication: EncodeLineBreak(dedication),
      avatar: avatar,
      cover: cover, 
      giverName: giverName,
      idCollection: collection,
      size: size,
      contactEmail: contactEmail, 
      default: newStdAvatar});
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Retrieves all the initialized and not completed (so just "Activated") gift of a given user
 * 
 * @param {number} user - the ID of the user who is modifying the gift
 * @returns {Object} an object with the following properties:
 *  - number: the number of gift that user have in state Active, and also the lenght of the following array
 *  - contacts: an array of Object with the following properties
 *      - ID_Gift (number): the ID of the gift
 *      - Name (string): the name associated with the gift
 *      - Modification_Date (string): the date and time the gift was last modified in ISO format
 *      - Filename_Coverart (string): the filename of the cover art associated with the gift
 *      - Filename_Avatar (string): the filename of the avatar associated with the gift
 *      - Size (string): the code identifying the commercial size of the gift
 *      - Moment (string): a message associated with the gift
 *      - Dedication (string): a dedication message associated with the gift
 *      - ID_Collection (number): the ID of the collection to which the gift belongs.
 */
export function getCreatedGifts(user) {
  try {
    return axios.post(url + `getCreatedGifts`, {id : user});
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Retrieves the user information
 * 
 * @param {string} cognitoId - the Cognito ID of the authenticated user
 * @returns {Object} a JSON object with the following properties:
 *  - number: 1 if there is a user, 0 if not
 *  - contacts: an array of Object with the following properties
 *      - ID_User (number): the Tunehey ID of the user
 *      - Name (string): the first name of the user
 *      - Storage_Icon (string): the filename of the user default avatar
 *      - Contact_Email (string): the email address the user wants to be contacted || UNUSED
 *      - Size (string): the size of the credits owned
 *      - Credits_Owned (number): the number of credit the user can spend
 *      - Expiration_Date (string): the expiration date of the bundle in ISO 8601 format
 * 
 */
export function getUserInfo(cognitoId) {
  try {
    return axios.post(url + `getUserInfo`, {cognitoId: cognitoId});
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Creates a new user and 
 * and |NEW| adds a credit (if addCredit true) 
 * 
 * @param {string} cognitoId - the Cognito ID of the authenticated user
 * @param {string} name - the name of the user provided by Cognito
 * @param {string} surname - the surname of the user provided by Cognito
 * @param {string} email - the email of the Cognito user
 * @param {boolean} addCredit - a boolean flag indicating whether to add credit or not
 * @returns {number} the Tunehey ID of the new created user
 */
export function firstLogin(cognitoId, name, surname, email, addCredit = false) {
  try {
    return axios.post(url + `firstLogin?cognitoId=${cognitoId}&name=${name}&surname=${surname}&email=${email}&role=G&addCredit=${addCredit}`);
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Adds previously unknown information to the user
 * 
 * @param {string} idUser - The ID of the user we want to associate
 * @param {string} name - the name of the user provided by Cognito
 * @param {string} surname - the surname of the user provided by Cognito
 * @returns {string} a status message "Information added" (FIXME currently only if no error!)
 */
export function secondInfos(idUser, name, surname) {
  try {
    return axios.post(url + `secondInfos`, { idUser: idUser, name: name, surname: surname});
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the songs informations gived the ID of a collection
 * 
 * @param {number} collection - The ID of the collection
 * @returns {Object} - A JSON object containing the following information 
 *   - number: the number of songs in the collection, and the lenght of the following array
 *   - contacts: an array of objects containing the following informations about the songs
 *      - ID_Song (number): the ID number
 *      - Title (string): the title
 *      - Length (string): a text containing the number of seconds of lenght
 *      - Storage_Preview (string): the filename of the entire song
 *      - Stage_Name (string): the Artist name
 *      - Preview_Start (number): the starting second for the preview
 */
export function getSongsData(collection) {
  try {
    return axios.post(url + `getSongsData?id=${collection}`);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Search if an email reference already exist in our DB and possibly returns the user associated info
 * 
 * @param {string} email - The email of the Cognito authenticated user
 * @returns {Object} a JSON object with the following properties:
 *  - number: 1 if there is a user, 0 if not
 *  - contacts: an array of Object with the following properties
 *      - ID_User (number): the Tunehey ID of the user
 *      - Storage_Icon (string): the filename of the user default avatar
 *      - Contact_Email (string): the email address the user wants to be contacted || UNUSED
 */
export function getUserByEmail(email) {
  try {
    return axios.post(url + `getUserByEmail?email=${email}`);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a new reference between a new authentication method for the same email, and the user ID associated with this same user
 * 
 * @param {string} cognitoId - The Cognito ID of the authenticated user
 * @param {string} userId - The ID of the user we want to associate
 * @returns {Object} an object with the following properties:
 *  - contacts: an object containing information about the affected rows
 *      - fieldCount: the number of columns in the result set
 *      - affectedRows: the number of affected rows
 *      - insertId: the ID of the inserted row if applicable, 0 otherwise
 *      - info: information about the query execution
 *      - serverStatus: the status code of the server
 *      - warningStatus: the status code of the warnings, if any
 */
export function connectCognitoWithUser(cognitoId, userId) {
  try {
    return axios.post(url + `connectCognitoWithUser?cognitoId=${cognitoId}&userId=${userId}`);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Sets a gift in state A to the state C (Completed)
 * increase the gift-counter of the chosed songs
 * and returns the code to redeem the gift
 * 
 * @param {number} idGift - The Id of the Gift to purchase
 * @param {number} price - The entire price paid with . decimal separator
 * @param {string} currency - The currency of the payment, "EUR" for €
 * @param {number} userId - (optional) the user to add the bundle to
 * @param {string} type - (optional) the type of the purchased bundle, "" = no bundle
 * @param {string} size - (optional) the size of credits in the purchased bundle
 * @param {string} idSongs - A list of song IDs to add to the collection, in text format and separated by commas
 * @returns {string} the code to redeem the purchased gift
 */
export function purchaseGift(idGift, price, currency, userId, type, size, idSongs) {
  try {
    return axios.post(url + `purchaseGift`, {
      idGift: idGift, 
      price: price, 
      currency: currency, 
      userId: userId, 
      type: type, 
      size: size,
      idSongs: idSongs});
  } catch (error) {
    console.error(error);
    return "error"
  }
}

/**
 * Retrieves or creates a user for the sended email
 * and adds a credit of type Type to that user
 * 
 * @param {string} email - the email of the web user (not necessarily registered)
 * @param {string} type - (optional) the type of the purchased bundle, "" = no bundle
 * @param {string} size - (optional) the size of credits in the purchased bundle
 * @returns {string} "credit added" message if ok
 */
export function purchaseCredit(email, type, size) {
  try {
    return axios.post(url + `purchaseCredit`, {email: email, type: type, size: size});
  } catch (error) {
    console.error(error);
    return "error"
  }
}

/**
 * Check bundle existence
 * sets a gift in state A to the state C (Completed)
 * decrease the bundle capacity
 * increase the gift-counter of the chosed songs
 * and returns the code to redeem the gift
 * 
 * @param {number} idGift - The Id of the Gift to purchase
 * @param {number} userId - The user to check the bundle
 * @param {number} idBundle - The id of the Bundle to check and associate to the gift for price ammortization
 * @param {string} size - The size of the gift to purchase
 * @param {string} idSongs - A list of song IDs to add to the collection, in text format and separated by commas
 * @returns {string} the code to redeem the purchased gift
 */
export function purchaseGiftWithCredit(idGift, userId, idBundle, size, idSongs) {
  try {
    return axios.post(url + `purchaseGiftWithCredit`, {idGift: idGift, userId: userId, idBundle: idBundle, size: size, idSongs: idSongs});
  } catch (error) {
    console.error(error);
    return "error"
  }
}

/**
 * Request informations about credits possibly owned by the user
 * 
 * @param {number} userId - The user to investigate
 * @returns {Object} a JSON object with the following properties:
 *  - number: the number of Active credits of the user, and lenght of the following array
 *  - contacts: an array of Object with the following properties about bundles and credits
 *      - ID_Bundle (number): the ID of the bundle
 *      - Type (string): the code identifying the bundle type
 *      - Size (string): the size of the credits owned
 *      - Credits_Owned (number): the number of credit the user can spend
 *      - Credits_Used (number): the number of credit the user has already spent from the same bundle
 *      - Expiration_Date (string): the expiration date of the bundle in ISO 8601 format
 */
export function getUserCredits(userId) {
  try {
    return axios.post(url + `getUserCredits`, {id: userId});
  } catch (error) {
    console.error(error);
  }
}

/**
 * Tries to redeem a gift code for a user
 * 
 * @param {number} userId - The user that want to redeem a code
 * @param {string} code - The user that want to redeem a code
 * @param {boolean} self - (optional) a flag that indicates if the user want to redeem the gift that he himself made
 * @returns {Object} an object with the following properties:
 *  - contacts: an object containing information about the affected rows
 *      - fieldCount: the number of columns in the result set
 *      - affectedRows: the number of affected rows
 *      - insertId: the ID of the inserted row if applicable, 0 otherwise
 *      - info: information about the query execution
 *      - serverStatus: the status code of the server
 *      - warningStatus: the status code of the warnings, if any
 */
export function redeemCode(userId, code, self) {
  try {
    return axios.post(url + `redeemCode?id=${userId}&code=${code}&self=${self}`);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the current top30 of most gifted artists
 * 
 * @returns {string} an array of maximum 30 elements
 */
export function getTopArtist() {
  try {
    return axios.post(url + `getTopArtist`);
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Modifies the Name in an existing gift
 * 
 * @param {number} gift - the ID of the gift to be modified
 * @param {number} userId - the ID of the user who is modifying the gift
 * @param {string} person - the name of the receiver that identifies the gift
 *  @returns {Object} an object with the following properties:
 *  - contacts: an object containing information about the affected rows
 *      - fieldCount: the number of columns in the result set
 *      - affectedRows: the number of affected rows
 *      - insertId: the ID of the inserted row if applicable, 0 otherwise
 *      - info: information about the query execution
 *      - serverStatus: the status code of the server
 *      - warningStatus: the status code of the warnings, if any
 *      - changedRows: the number of changed rows
 */
export function modifyGiftName(gift, userId, person) {
  try { 
    return axios.post(url + `modifyGiftName`, {idGift: gift, idUser: userId, name: EncodeLineBreak(person)});
  } catch (error) {
    console.error(error);
  }
}

/**
 * 
 * Put in the Deleted state a gift in process (created)
 * 
 * @param {number} gift - the ID of the gift to be deleted
 * @param {number} userId - the ID of the user who own the gift
 * @returns {string} "Gift successfully deleted" message if ok
 */
export function deleteCreatedGift(gift, userId) {
  try { 
    return axios.post(url + `deleteCreatedGift`, {idGift: gift, userId: userId});
  } catch (error) {
    console.error(error);
  }
}