import SizeCard from "../../components/common/sizeCard";
import PrimaryButton from "../../components/common/primaryButton";
import SecondaryButton from "../../components/common/secondaryButton";
import { useState } from "react";
import YellowDialog from "../../layouts/popup800";

function PopSwitchSize({
  setGiftSize,
  requestPopSwitchSize,
  setRequestPopSwitchSize,
  sixth = true, //introduces use-case divergencies
  setIsSixth,
}) {
  const [open, setOpen] = useState(requestPopSwitchSize);
  const handleClose = () => {
    setOpen(false);
    setRequestPopSwitchSize(false);
    if (sixth === true) {
      setIsSixth(false);
    }
  };

  const [selected, setSelected] = useState(false);

  return (
    // we recognize classes for "phone", "mid", "midphone" corrections, or "all" when the style is declined in the stylesheet for all sizes
    <YellowDialog opened={open} onClose={handleClose}>
      <div
        className="phone-pop-size-small mid-pop-offer-small d-flex flex-column align-items-center flex-grow"
        style={{
          paddingTop: "54px",
        }}
      >
        <h1 className="font-title0-Osw less-margin-1">
          {sixth ? "Hai messo la sesta!" : "Musica maestro!"}
          <span className="no-phone"> 🎵</span>
        </h1>
        <h2
          className="font-subT1-Osw all-offer-text1-space"
          style={{ textAlign: "center" }}
        >
          Vuoi passare al regalo con più canzoni?
        </h2>

        <div className="d-flex flex-row flex-wrap">
          <SizeCard
            tuneType="Maxi"
            subtitle=""
            infosize="da 6 a 10 canzoni"
            price="9,99"
            clickHandler={() => setSelected(!selected)}
          />
        </div>

        <div className="d-flex flex-column flex-end align-items-center">
          <span
            className="phone-popup-less-margin"
            style={{ marginTop: "25px" }}
          />
          <PrimaryButton
            text="CONFERMA"
            clickHandler={() => {
              handleClose();
              setGiftSize("M");
            }}
            active={selected}
          />
          <span className="phone-pop-less-H" style={{ height: "20px" }} />
          <SecondaryButton
            text="NON VOGLIO CAMBIARE"
            clickHandler={() => {
              handleClose();
            }}
          />
          <p className="font-subtitle3-WS">
            Mantengo un massimo di 5 canzoni per 5.99 €
          </p>
        </div>
      </div>
    </YellowDialog>
  );
}

export default PopSwitchSize;
