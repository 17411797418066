import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import awsExports from './aws-exports';
import Amplify from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { UserContextProvider } from './contexts/userContext';
// import "./normalize.css";

// //Check if you are in localhost
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)
// const signInURI = "http://localhost:3000/"
// const signOutURI = "http://localhost:3000/"

// if (isLocalhost) {
//   console.log('Configuring for localhost')
//   awsExports.oauth.redirectSignIn = signInURI
//   awsExports.oauth.redirectSignOut = signOutURI

awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;
awsExports.oauth.domain = "signin.tunehey.com";


// console.log(awsExports)
// }

Amplify.configure(awsExports)

// console.log("Full page reload");

// console.log("Domain ",window.location.hostname);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <AmplifyProvider>
          <Authenticator.Provider>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </Authenticator.Provider>
        </AmplifyProvider>
      </BrowserRouter>
    </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
