export const giverSample = "Gloria";

export const dedicationSample =
  "A te e alla nostra amicizia.\nGrazie, perché riesci sempre a strapparmi un sorriso e a tirare fuori il meglio di me.\nLe canzoni non hanno bisogno \ndi spiegazioni...tu sai!\nTi voglio bene";

export const momentSample =
  "Voglio regalarti del tempo che puoi dedicare a te stessa, tu che per me e per gli altri ci sei sempre. Rilassati, ascolta, e se ti scrivo non rispondermi!";

export const songsSample = [
  { Title: "La musica non c'è", Stage_Name: "Coez", Length: "223" },
  { Title: "Je so accusì", Stage_Name: "Serena Brancale", Length: "202" },
  { Title: "Per sempre", Stage_Name: "Ligabue", Length: "236" },
  { Title: "Amarena", Stage_Name: "Frah Quintale", Length: "214" },
  { Title: "Un motivo maledetto", Stage_Name: "Irene Grandi, Levante", Length: "241" },
  { Title: "Duemilacredici", Stage_Name: "Caterina", Length: "215" },
  {
    Title: "Finisce che sto bene",
    Stage_Name: "Street Clerks",
    Length: "236",
  },
];