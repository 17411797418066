import {
  Card,
  CardMedia,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import { coverArtOrdered as CoverNames } from "../../pages/customize/cover";
import { IoEllipsisVertical } from "react-icons/io5";
import { useState } from "react";
import { modifyGiftName, deleteCreatedGift } from "../../services/contentApi";

// A PRIMITIVE TO DISPLAY GIFTS TO RESUME AND COMPLETE
// NTH: some element that indicates progress in the gift, some other info
function ResumeCard({
  name,
  date,
  thumb,
  userId,
  giftId,
  giftListIndex,
  handleRemoveGift,
  size,
}) {
  let img = "";
  if (CoverNames.includes(thumb)) {
    img = require("../../assets/cover/" + thumb);
  } else if (thumb) {
    img = "https://d1ytrwzciudool.cloudfront.net/Coverart/" + thumb;
  } else {
    img = require("../../assets/icons/photo.png"); //it's a placeholder
  }

  // menu part
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMobile, setAnchorElMobile] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickMobile = (event) => {
    event.stopPropagation();
    setAnchorElMobile(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElMobile(null);
  };
  // ----------------

  const [editable, setEditable] = useState(false);
  const [editedName, setEditedName] = useState(name);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const handleNameSave = async () => {
    setEditable(false);
    try {
      await modifyGiftName(giftId, userId, editedName);
    } catch (error) {
      // console.error("Error saving new name:", error);
    }
  };

  const handleDeleteGift = async () => {
    try {
      await deleteCreatedGift(giftId, userId);
      handleRemoveGift(giftListIndex);
      handleClose();
    } catch (error) {
      // console.error("Error deleting gift:", error);
    }
  };

  return (
    <>
      <span className="no-phone">
        <Card
          sx={{
            display: "flex",
            boxShadow: "none",
            direction: "",
            width: "498px",
            height: "87px",
            padding: "15px",
            borderRadius: "10px",
            alignItems: "center",
          }}
          style={{ backgroundColor: "#FFFCEB", marginBottom: "9px" }}
        >
          <CardMedia
            component="img"
            sx={{ height: 60, width: 60, borderRadius: "5px" }}
            image={img}
          />
          <div
            style={{ textAlign: "start", marginLeft: "16px", width: "100%" }}
          >
            <div className="d-flex flex-row justify-content-between">
              <h4 className="resume-card-header conditionalHv">
                Regalo per{" "}
                {editable ? (
                  <input
                    type="text"
                    value={editedName}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleNameChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleNameSave();
                      }
                      if (e.key === " ") {
                        e.preventDefault();
                        setEditedName((editedName) => editedName + " ");
                      }
                    }}
                    onBlur={handleNameSave}
                    className="editable-name"
                  />
                ) : (
                  <span className="resume-card-name">{editedName}</span>
                )}
              </h4>
              <div>
                {size == "M" && (
                  <Tooltip
                    title={
                      <p
                        style={{
                          fontFamily: "Work sans",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Maxi tune - da 6 a 10 canzoni
                      </p>
                    }
                    arrow
                    placement="top"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -7],
                            },
                          },
                        ],
                      },
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span
                      style={{
                        color: "#F98050",
                        border: "1px solid #F98050",
                        borderRadius: "4px",
                        padding: "2px",
                        maxHeight: "30px",
                        marginRight: "4px",
                      }}
                    >
                      Mx
                    </span>
                  </Tooltip>
                )}
                <IconButton onClick={handleClick} disableTouchRipple={true}>
                  <IoEllipsisVertical fontSize={14} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onClick={(e) => e.stopPropagation()}
                  PaperProps={{
                    style: {
                      background: "#FFFDEF",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <MenuList dense>
                    <MenuItem onClick={handleEditClick}>
                      <Typography style={{ fontFamily: "Work sans" }}>
                        Cambia Nome
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleDeleteGift}>
                      <Typography style={{ fontFamily: "Work sans" }}>
                        Elimina
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <p className="resume-card-subtitle conditionalHv">
              Completa il regalo modificato il <span>{date}</span>
            </p>
          </div>
        </Card>
      </span>

      <span className="phone">
        <Card
          sx={{
            display: "flex",
            boxShadow: "none",
            direction: "",
            width: "250px",
            height: "45px",
            padding: "8px",
            borderRadius: "5px",
            alignItems: "center",
            marginTop: "6px",
          }}
          style={{ backgroundColor: "#FFFCEB" }}
        >
          <CardMedia
            component="img"
            className="thumb-up-gift"
            sx={{ height: 25, width: 25, borderRadius: "5px" }}
            image={img}
          />
          <div style={{ textAlign: "start", marginLeft: "8px", width: "100%" }}>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h4 className="resume-card-header">
                Regalo per{" "}
                {editable ? (
                  <input
                    type="text"
                    value={editedName}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleNameChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleNameSave();
                      }
                    }}
                    onBlur={handleNameSave}
                    className="editable-name"
                  />
                ) : (
                  <span className="resume-card-name">{editedName}</span>
                )}
              </h4>
              <div>
                {size == "M" && (
                  <Tooltip
                    title={
                      <p
                        style={{
                          fontSize: "10px",
                          fontFamily: "Work sans",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Maxi tune - da 6 a 10 canzoni
                      </p>
                    }
                    arrow
                    placement="top"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -7],
                            },
                          },
                        ],
                      },
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span
                      style={{
                        color: "#F98050",
                        border: "1px solid #F98050",
                        borderRadius: "4px",
                        padding: "2px",
                        maxHeight: "20px",
                        marginRight: "4px",
                        fontSize: "0.5rem",
                      }}
                    >
                      Mx
                    </span>
                  </Tooltip>
                )}
                <IconButton
                  onClick={handleClickMobile}
                  disableTouchRipple={true}
                >
                  <IoEllipsisVertical fontSize={10} />
                </IconButton>
                <Menu
                  anchorEl={anchorElMobile}
                  open={Boolean(anchorElMobile)}
                  onClose={handleClose}
                  onClick={(e) => e.stopPropagation()}
                  PaperProps={{
                    style: {
                      background: "#FFFDEF",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <MenuList dense>
                    <MenuItem onClick={handleEditClick}>
                      <Typography
                        style={{ fontFamily: "Work sans", fontSize: "11px" }}
                      >
                        Cambia Nome
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleDeleteGift}>
                      <Typography
                        style={{ fontFamily: "Work sans", fontSize: "11px" }}
                      >
                        Elimina
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <p className="resume-card-subtitle">
              Completa il regalo modificato il <span>{date}</span>
            </p>
          </div>
        </Card>
      </span>
    </>
  );
}

export default ResumeCard;
