import { useState, useEffect } from "react";
import { useScroll } from "framer-motion";

import WheelPrimaryButton from "../../components/common/wheelPrimaryButton";
import { MockupTour } from "../../components/mockup/mockupTour";
import VerticalProgressBar from "../../components/common/verticalProgressBar";

function DemoSection({ clickHandler, customEventName }) {
  const { scrollY } = useScroll();

  const sectionsBefore = 1.5;

  // calculate viewport height based on --vh custom property (multiplied by 100)
  const wpH =
    parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue("--vh")
    ) *
    100 *
    sectionsBefore;

  // const sDur = 50; //shortTransitionDuration
  const dur = 50; //normalTransitionDuration
  const Ldur = 100; //longTransitionDuration

  const wPSB = wpH; //where page scrool blocks
  const wT1A = wpH + 100; //where tooltip1 (room) appears
  const wRoomS = wpH + 275; //where room scrolls
  const wRoomSE = wpH + 400; //where room scroll ends
  const wTuneA = wpH + 450; //where tune appears (+duration)75
  const wT2A = wpH + 550; //where tooltip2 (avatar) appears
  const wT3A = wpH + 850; //where tooltip3 (dedication) appears
  const wMessC = wpH + 1000; //where message changes (+duration)
  const wT4A = wpH + 1050; //where tooltip4 (moment) appears
  const wT5A = wpH + 1350; //where tooltip5 (cover) appears
  const wTuneS = wpH + 1600; //where tune scrolls
  const wT6A = wpH + 1750; //where tooltip6 (music) appears

  const mockupCirclePositions = [
    { x: 245, y: 130, in: wPSB - 500, out: wRoomS + 25 }, //room
    { x: 205, y: 67, in: wTuneA + 150, out: wTuneS }, //title
    { x: 34, y: 165, in: wTuneA + 150, out: wTuneS + 25 }, //dedication
    { x: 300, y: 210, in: wT3A, out: wTuneS + 50 }, //moment
    { x: 290, y: 405, in: wTuneA + 150, out: wTuneS + 350 }, //image
    { x: 300, y: 525, in: wTuneA + 150, out: wT6A + 350 }, //songs
  ];

  const tooltipsData = [
    {
      activeRange: [wT1A, wRoomSE],
      content: (
        <span>Sarà conservato qui, nello scaffale del destinatario</span>
      ),
      position: "19.5%",
    },
    {
      activeRange: [wT2A, wT2A + 200],
      content: (
        <span>
          Sarai presente con la tua
          <strong className="strong600"> foto</strong> e il tuo nome
        </span>
      ),
      position: "11%",
    },
    {
      activeRange: [wT3A, wT3A + 200],
      content: (
        <span>
          Poi subito la tua <strong className="strong600">dedica</strong>: il
          cuore del regalo{" "}
        </span>
      ),
      position: "24.5%",
    },
    {
      activeRange: [wT4A, wT4A + 200],
      content: (
        <span>
          In più, consiglia un{" "}
          <strong className="strong600">momento speciale</strong> da vivere
        </span>
      ),
      position: "30.5%",
    },
    {
      activeRange: [wT5A, wT5A + 200],
      content: (
        <span>
          Scegli un'
          <strong className="strong600">immagine di copertina</strong>{" "}
          significativa
        </span>
      ),
      position: "57.3%",
    },
    {
      activeRange: [wT6A, wT6A + 200],
      content: (
        <span>
          Ed eccoci alla <strong className="strong600">musica</strong>!
          Seleziona fino a 10 canzoni ascoltabili senza alcun limite
        </span>
      ),
      position: "74%",
    },
  ];

  const [isFixed, setIsFixed] = useState(false);
  const [readyCircles, setReadyCircles] = useState(Array(6).fill(false));
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);
  const [activeCircleIndex, setActiveCircleIndex] = useState(null);
  const [isMobileDemoTime, setIsMobileDemoTime] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [prevScrollTime, setPrevScrollTime] = useState(0);
  const [scrollingHelperGoing, setScrollingHelperGoing] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = scrollY.current; // Extract the scroll position here

      if (scrollPosition >= wPSB && scrollPosition <= wT6A + 500 + wpH) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

      if (scrollPosition > wT1A - 50) {
        setIsMobileDemoTime(true);
      } else {
        setIsMobileDemoTime(false);
      }

      // Find the index of the tooltip that should be active
      const newActiveIndex = tooltipsData.findIndex(
        (tooltip) =>
          scrollPosition >= tooltip.activeRange[0] &&
          scrollPosition <= tooltip.activeRange[1]
      );
      setActiveTooltipIndex(newActiveIndex === -1 ? null : newActiveIndex);

      // Find the index of the circle that should be active (same things, considering an exception)
      let isException1 = scrollPosition >= wT1A && scrollPosition <= wT1A + 175;
      setActiveCircleIndex(
        isException1 === true
          ? 0
          : newActiveIndex < 1 //or it's -1 or zero (tooltip active and circle not active)
          ? null
          : newActiveIndex
      );

      // functional update form to generate component re-renders
      setReadyCircles((prevReadyCircles) =>
        prevReadyCircles.map((_, index) => {
          const value = mockupCirclePositions[index];
          return scrollPosition >= value.in && scrollPosition < value.out;
        })
      );

      // Calculate the time elapsed since the previous scroll event
      const currentTime = performance.now();
      const deltaTime = currentTime - prevScrollTime;
      // Ensure deltaTime is greater than zero to avoid division by zero
      if (deltaTime > 0) {
        // Calculate scroll velocity as pixels per millisecond
        const scrollVelocity =
          (scrollPosition - prevScrollPosition) / deltaTime;
        // console.log(scrollVelocity);
        // Define the threshold for scroll velocity
        const scrollVelocityThreshold = 3;
        const pixelAttractionDomain = 100;
        const attendTime = 100;
        const secureMarginPxDown = 60;
        const secureMarginPxUp = 30;

        // Check if the current scroll position is within 100px of the first extreme
        // of the next tooltip or the last extreme of the previous one
        if (
          newActiveIndex === -1 &&
          Math.abs(scrollVelocity) < scrollVelocityThreshold &&
          !scrollingHelperGoing // Check if scrolling helper is not already going
        ) {
          for (let i = 0; i < tooltipsData.length - 1; i++) {
            const nextTooltip = tooltipsData[i + 1];
            const previousTooltip = tooltipsData[i];

            if (nextTooltip && previousTooltip) {
              // Check next tooltip's first extreme
              if (
                scrollVelocity > 0 &&
                scrollPosition > previousTooltip.activeRange[1] &&
                scrollPosition < nextTooltip.activeRange[0] &&
                scrollPosition + pixelAttractionDomain >=
                  nextTooltip.activeRange[0]
              ) {
                setScrollingHelperGoing(true);
                // Stop the scroll
                // console.log("Hey, stop the scroll 1");
                window.scrollTo(
                  0,
                  nextTooltip.activeRange[0] + secureMarginPxDown
                );

                setTimeout(() => {
                  setScrollingHelperGoing(false);
                }, attendTime); // Remove the flag after a brief delay to allow for smooth scrolling
                return; // Exit the function to prevent further scrolling
              }

              // Check previous tooltip's last extreme when scrolling up
              if (
                scrollVelocity < 0 &&
                scrollPosition > previousTooltip.activeRange[1] &&
                scrollPosition < nextTooltip.activeRange[0] &&
                scrollPosition - pixelAttractionDomain <=
                  previousTooltip.activeRange[1]
              ) {
                setScrollingHelperGoing(true);
                // Stop the scroll
                // console.log("Hey, stop the scroll 2");
                window.scrollTo(
                  0,
                  previousTooltip.activeRange[1] - secureMarginPxUp
                );

                setTimeout(() => {
                  setScrollingHelperGoing(false);
                }, attendTime); // Remove the flag after a brief delay to allow for smooth scrolling
                return; // Exit the function to prevent further scrolling
              }
            }
          }
        }
        // Update the previous scroll position
        setPrevScrollPosition(scrollPosition);
        setPrevScrollTime(currentTime);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY, prevScrollPosition, prevScrollTime, scrollingHelperGoing]);

  // useEffect(() => {
  //   const handleScrollLog = () => {
  //     console.log("scrollY:", scrollY.current);
  //     console.log("tuneOpacity:", tuneOpacity.current);
  //   };

  //   window.addEventListener("scroll", handleScrollLog);
  //   return () => {
  //     window.removeEventListener("scroll", handleScrollLog);
  //   };
  // }, [scrollY, tuneOpacity]);

  const [scrollProgress, setScrollProgress] = useState(0);
  useEffect(() => {
    let requestId;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Ensure the progress bar updates only within the desired range
      if (currentScrollY >= wpH && currentScrollY <= wpH + 2020) {
        const scrollDelta = currentScrollY - wpH;
        const newProgress = (scrollDelta / 2020) * 100; // Calculate progress percentage
        setScrollProgress(Math.max(0, Math.min(100, newProgress))); // Clamp between 0 and 100
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      cancelAnimationFrame(requestId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollProgress]);

  return (
    <div className="black-background-color">
      <div className="super-scrolling-page demo-page">
        <div className={`${isFixed ? "fixed-demo-section" : ""}`}>
          <VerticalProgressBar progress={scrollProgress} isFixed={isFixed} />
          <div
            className={"demo-section-container"}
            style={{ height: "fit-content" }}
          >
            <div>
              <h1
                className="cream-color font-title1-Osw midphone-boost-ft1o-2"
                style={{ fontWeight: "500" }}
              >
                QUESTO È UN <span className="orange-color">TUNE</span>
                <br className="midsize phone" />
              </h1>
              <h2
                className={`cream-color font-subT1-WS home-space-1 ${
                  isMobileDemoTime ? "text-disappear" : ""
                }`}
              >
                Lo amerai, <br className="phone" />
                e sorprenderai <br className="phone" />
              </h2>
              <div className="no-phone">
                <WheelPrimaryButton
                  text="CREA"
                  darkTheme={true}
                  clickHandler={clickHandler}
                  customEventName={customEventName}
                />
              </div>
            </div>

            {/* the section of the interactive demo, useful to group and position all of the layers inside */}
            <div className="mockup-section">
              <MockupTour
                scrollY={scrollY}
                wRoomS={wRoomS}
                wRoomSE={wRoomSE}
                wTuneA={wTuneA}
                dur={dur}
                wMessC={wMessC}
                Ldur={Ldur}
                wTuneS={wTuneS}
                mockupCirclePositions={mockupCirclePositions}
                readyCircles={readyCircles}
                activeCircleIndex={activeCircleIndex}
                tooltipsData={tooltipsData}
                activeTooltipIndex={activeTooltipIndex}
              />
            </div>
            <div className="no-phone" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemoSection;
