import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "../assets/localStyle/boxCassetteNav.css";
import "../assets/localStyle/cursor.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPlusCircle } from "react-icons/bs";
import { editorials } from "../data/editorials";

import "swiper/css/navigation";
import PlaylistSongs from "./playlistSongs";
// import "swiper/css/pagination";
// import ReactPixel from "../services/facebookPixel";

function PlaylistNavigator({
  songs,
  setSongs,
  songPlaying,
  handlePlay,
  handlePause,
  deviceMobile = false,
  onSheet,
  giftSize,
}) {
  const handlePlaylistSelect = (playlistObject) => {
    if (giftSize == "S") {
      let safeSongsArray = playlistObject.Playlist.filter((_, index) =>
        playlistObject.Safe_Five.includes(index)
      );
      setSongs(safeSongsArray);
    } else {
      setSongs(playlistObject.Playlist);
    }
  };

  return (
    <div className="">
      <Swiper
        className="no-phone"
        slidesPerView={1}
        spaceBetween={2}
        centeredSlidesBounds={true}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
      >
        {editorials.map((editorial) => (
          <SwiperSlide key={editorial.ID_Editorial}>
            <div
              key={editorial.ID_Editorial}
              className="ed-playlist-card d-flex flex-row"
            >
              <div className="ed-playlist-img-container">
                <img
                  className="ed-playlist-img"
                  src={require(`../assets/editorial/${editorial.Storage_Artwork}`)}
                  alt=""
                />

                <button
                  className={`transparentBtn ed-playlist-full-btn`}
                  onClick={() => {
                    handlePlaylistSelect(editorial);
                    setTimeout(() => onSheet(""), 1600);
                  }}
                >
                  <BsPlusCircle
                    fontSize={"30px"}
                    style={
                      (giftSize === "S" &&
                        JSON.stringify(songs) ===
                          JSON.stringify(
                            editorial.Playlist.filter((_, index) =>
                              editorial.Safe_Five.includes(index)
                            )
                          )) ||
                      (giftSize !== "S" &&
                        JSON.stringify(songs) ===
                          JSON.stringify(editorial.Playlist))
                        ? { color: "#FCE868" }
                        : {}
                    }
                  />
                </button>
              </div>
              <div className="d-flex flex-column align-items-start">
                <p className="ed-playlist-title">{editorial.Title}</p>
                <div className="ed-playlist-song-container">
                  <PlaylistSongs
                    editorial={editorial}
                    songPlaying={songPlaying}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                    miniPlaylist={giftSize == "S"}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <button className="transparentBtn">
          <IoIosArrowBack className="playlist-swiper swiper-button-prev" />
        </button>
        <button className="transparentBtn">
          <IoIosArrowForward className="playlist-swiper swiper-button-next" />
        </button>
      </Swiper>

      {deviceMobile && (
        <div className="playlist-phone-viewer">
          {editorials.map((editorial) => (
            <div
              key={editorial.ID_Editorial}
              className="ed-playlist-card d-flex flex-row"
              style={{ marginBottom: "50px" }}
            >
              <div className="ed-playlist-img-container">
                <img
                  className="ed-playlist-img"
                  src={require(`../assets/editorial/${editorial.Storage_Artwork}`)}
                  alt=""
                />

                <button
                  className={`transparentBtn ed-playlist-full-btn`}
                  onClick={() => {
                    handlePlaylistSelect(editorial);
                    setTimeout(() => onSheet(""), 200);
                  }}
                >
                  <BsPlusCircle
                    fontSize={"30px"}
                    style={
                      songs == editorial.Playlist ? { color: "#FCE868" } : {}
                    }
                  />
                </button>
              </div>
              <div className="d-flex flex-column align-items-start">
                <p className="ed-playlist-title">{editorial.Title}</p>
                <div className="ed-playlist-song-container">
                  <PlaylistSongs
                    editorial={editorial}
                    songPlaying={songPlaying}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                    miniPlaylist={giftSize == "S"}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PlaylistNavigator;
