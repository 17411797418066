import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import PopMail from "./popups/popMail";
import PrimaryButton from "../components/common/primaryButton";
import SecondaryButton from "../components/common/secondaryButton";

const Email = ({
  sceneChange,
  name,
  chosenEmail,
  setChosenEmail,
  pageChange,
  onNext,
}) => {
  useEffect(() => {
    sceneChange("dark");
  }, []);

  const [openPop, setOpenPop] = useState(false);
  const handleClose = () => {
    setOpenPop(false);
  };

  let navigate = useNavigate();
  const handleClickFront = () => {
    onNext();
    sceneChange("light");
    pageChange("cart");
    navigate("/cart");
  };

  const handleClickBack = () => {
    sceneChange("light");
    pageChange("create/playlist");
    navigate("/create");
  };

  const emailRef = useRef(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const [error, setError] = useState(null);
  const handleInput = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Inserisci una email valida");
    } else {
      setError(null);
    }
  };

  return (
    <div className="exact-page-net-header d-flex flex-column justify-content-center align-items-center black-background-color">
      <PopMail name={name} opened={openPop} onClose={handleClose} />
      <div className="d-flex flex-column align-items-center cream-color">
        <h2 className="font-subT4-Osw" align="center" style={{}}>
          Ci siamo quasi!
        </h2>
        <h3 className="font-subT5-Osw-WS" align="center" style={{}}>
          A quale tua mail vuoi ricevere
          <br className="phone" />
          le info per consegnare il regalo?
          <br />
        </h3>
        <br />

        <div className="mail-box-position">
          <div className="mail-box-disposition">
            {/* <h5 className="your-mail">
              <span className="font-subT1-WS" style={{ color: "#F9E847" }}>
                *
              </span>
              La tua <br className="no-phone" />
              email
            </h5> */}
            <input
              ref={emailRef}
              type="text"
              className="cream-background-color mail-form input-mail"
              value={chosenEmail} //S.S.O.T.
              onChange={(e) => {
                setChosenEmail(e.currentTarget.value); //updated at every change
                handleInput(e);
              }}
            />
          </div>
          {error && <h5 className="email-error">{error}</h5>}
        </div>
      </div>
      <br />
      <br />
      <br className="no-phone" />
      <PrimaryButton
        text="CONTINUA"
        clickHandler={handleClickFront}
        active={error === null}
      />
      <br />
      <div style={{ paddingBottom: "50px" }}>
        <SecondaryButton
          text="TORNA INDIETRO"
          scene="dark"
          clickHandler={handleClickBack}
        />
      </div>
    </div>
  );
};

export default Email;
