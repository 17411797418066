import { useState } from "react";
import PopThanks from "./popups/popThanks";

const Thanks = () => {
  const [openPop, setOpenPop] = useState(true);

  const onClose = () => {
    setOpenPop(true);
  };

  return (
    <div className="exact-page-net-header cream-background-color">
      <PopThanks opened={openPop} onClose={onClose} />
    </div>
  );
};

export default Thanks;
