import React, { useState, useEffect } from "react";
import { PiMusicNotesDuotone } from "react-icons/pi";
import { TbMessageHeart } from "react-icons/tb";
import { TbClockPause } from "react-icons/tb";
import { HiOutlinePhotograph } from "react-icons/hi";
import { FaRegCircleUser } from "react-icons/fa6";

const buttonsData = [
  {
    id: 1,
    label: "Playlist",
    icon: <PiMusicNotesDuotone />,
    route: "playlist",
  },
  { id: 2, label: "Dedica", icon: <TbMessageHeart />, route: "dedication" },
  { id: 3, label: "Consiglio", icon: <TbClockPause />, route: "moment" },
  { id: 4, label: "Cover", icon: <HiOutlinePhotograph />, route: "cover" },
  { id: 5, label: "Avatar", icon: <FaRegCircleUser />, route: "avatar" },
];

const NavCheckBar = (props) => {
  const [activeButton, setActiveButton] = useState(1);
  const [completedButtons, setCompletedButtons] = useState([]);

  useEffect(() => {
    // Fetch completed buttons from the server or local storage and update the state
    // For demonstration purposes, we'll set the completed state based on the steps prop
    const initialCompletedButtons = props.steps.reduce((acc, step, index) => {
      if (step) {
        return [...acc, index + 1]; // Buttons are 1-indexed
      }
      return acc;
    }, []);

    setCompletedButtons(initialCompletedButtons);
  }, [props.steps]);

  const handleButtonClick = (buttonId, route) => {
    // Handle button click to navigate and update active button
    setActiveButton(buttonId);
    props.handleNavBar(route);
    if (buttonId == 1) {
      props.playlistScroll();
      props.setDedicationOpacity(0);
      props.setMomentOpacity(0);
    } else if (buttonId == 2) {
      props.setTuneTranslateY(0);
      props.setDedicationOpacity(1);
      props.setMomentOpacity(0);
      props.setDedicationScale(1);
      props.setMomentScale(0);
    } else if (buttonId == 3) {
      props.setTuneTranslateY(0);
      props.setDedicationOpacity(0);
      props.setMomentOpacity(1);
      props.setDedicationScale(0);
      props.setMomentScale(1);
    } else if (buttonId == 4) {
      props.setTuneTranslateY(0);
      props.setDedicationOpacity(1);
      props.setMomentOpacity(0);
      props.setDedicationScale(1);
      props.setMomentScale(0);
    } else if (buttonId == 5) {
      props.setTuneTranslateY(0);
      props.setDedicationOpacity(1);
      props.setMomentOpacity(0);
      props.setDedicationScale(1);
      props.setMomentScale(0);
    }
  };

  // const handleTaskCompleted = (buttonId) => {
  //   // Handle task completion and update completed buttons
  //   setCompletedButtons((prevCompletedButtons) => [
  //     ...prevCompletedButtons,
  //     buttonId,
  //   ]);
  // };

  return (
    <div className="navigation-bar">
      {buttonsData.map((button) => (
        <div
          key={button.id}
          className={`nav-button ${
            completedButtons.includes(button.id) ? "completed" : ""
          } ${activeButton === button.id ? "active" : ""}`}
          onClick={() => handleButtonClick(button.id, button.route)}
        >
          <div className="n-icon">{button.icon}</div>
          <div
            className="n-label seafoam-selection seafoam-selection-all"
            translate="no"
          >
            {button.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NavCheckBar;
