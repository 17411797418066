import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { Card, CardMedia } from "@mui/material";
import PopOffer from "./popups/popOffer";
import PopGems from "./popups/popGems";

import Footer from "../layouts/footer";
import LineTitle from "../components/common/lineTitle";
import PrimaryButton from "../components/common/primaryButton";
import Offer from "../components/common/offer";
import Carta from "../assets/icons/carta.svg";
import GooglePay from "../assets/icons/googlepay.svg";
import ApplePay from "../assets/icons/applepay.svg";
import Satispay from "../assets/icons/satispay.svg";
import SecondaryButton from "../components/common/secondaryButton";

import { ParseDate } from "../utils/parseDate";
import { FindArtistSale, FindArtistCredit } from "../utils/findSale";
import stripePL from "./../services/stripeLinks";
import { getUserCredits } from "./../services/contentApi";

function Purchase({
  progress,
  sceneChange,
  name,
  thumbnail,
  user, //the id
  pageChange,
  setIdBundle,
  gift,
  possibleDiscountedPrice,
  giftSize,
  chosenEmail,
}) {
  let navigate = useNavigate();
  //it's important to don't give access to cart at the wrong moment
  useEffect(() => {
    if (progress !== "cart") {
      navigate("/" + progress);
    }
  }, []);

  // OFFERS MANAGEMENT
  const specialSale = false;
  const specialPrice = possibleDiscountedPrice.toString().replace(".", ",");
  const displayOffers = true;

  const [cookies, setCookies] = useCookies();
  useEffect(() => {
    if (user === "") {
      user = cookies.userId; //figured out it helps to find user credits with reactivity (case: first navigation here)
    }
    // console.log("USER", user);
    sceneChange("light");
  }, []);

  //this is the blank credits state, to provide homogeneity in data access
  const [trueCredits, setTrueCredits] = useState({
    Type: "Dummy",
    Credits_Owned: 0,
    Credits_Used: 0,
    Size: "",
    Expiration_Date: null,
  });

  //we want to initialize the method to Standart
  //but to Credits at the moment we detect them
  const [method, setMethod] = useState("Standard");
  useEffect(() => {
    if (trueCredits.Credits_Owned > 0) {
      setMethod("Credits");
    }
  }, [trueCredits]);

  const [basePrice, setBasePrice] = useState(giftSize == "S" ? "5,99" : "9,99");
  const [total, setTotal] = useState(giftSize == "S" ? "5,99" : "9,99");
  const [offer, setOffer] = useState("");

  useEffect(() => {
    if ((gift && FindArtistSale(gift.songs)) || specialSale) {
      setBasePrice(specialPrice);
      setTotal(specialPrice);
    }
  }, [gift]);

  // START OF THE PURCHASE MATRIX
  //
  // THE MOLTIPLICATION OF A PRICE INTEGER AND A METHOD INTEGER WILL PROVIDE
  // THE UNIQUE URL WHERE TO REDIRECT THE USER TO PAY THE RIGHT SUM
  //
  // this maybe helps keep more secure the page logic
  //
  const [idP, setIdP] = useState(2);
  useEffect(() => {
    switch (total) {
      case "9,99":
        setIdP(2);
        break;
      case "16,99":
        setIdP(3);
        break;
      case "29,99":
        setIdP(5);
        break;
      case "5,99":
        setIdP(2.1);
        break;
      case "10,99":
        setIdP(3.1);
        break;
      case "19,99":
        setIdP(5.1);
        break;
      case "6,99":
        setIdP(0.7);
        break;
      case "7,99":
        setIdP(0.8);
        break;
      default:
        setIdP(99);
    }
  }, [total]);

  const [idM, setIdM] = useState(1);
  useEffect(() => {
    switch (method) {
      case "Credits":
        setIdM(0);
        break;
      case "Standard":
        setIdM(1);
        break;
      case "Satispay":
        setIdM(2);
        break;
      default:
        setIdM(99);
    }
  }, [method]);

  const [urid, setUrid] = useState("2"); //the default for standar price & method
  useEffect(() => {
    setUrid(idP * idM);
  }, [idP, idM]);
  //
  //END OF THE MATRIX
  //
  //NOW WE SET THE URL
  const [url, setUrl] = useState("https://www.tunehey.com");
  useEffect(() => {
    switch (urid) {
      case 0:
        setUrl("intercept");
        break;
      case 0.7:
        setUrl(stripePL.links[0].sale7);
        break;
      case 0.8:
        setUrl(stripePL.links[0].sale8);
        break;
      case 2:
        setUrl(stripePL.links[0].one);
        break;
      case 3:
        setUrl(stripePL.links[0].explorer);
        break;
      case 5:
        setUrl(stripePL.links[0].lifestyle);
        break;
      case 2.1:
        setUrl(stripePL.links[0].minione);
        break;
      case 3.1:
        setUrl(stripePL.links[0].miniexplorer);
        break;
      case 5.1:
        setUrl(stripePL.links[0].minilifestyle);
        break;
      // case 4:
      //   setUrl("https://"); //SATISPAY
      //   break;
      // case 6:
      //   setUrl("https://"); //SATISPAY
      //   break;
      // case 10:
      //   setUrl("https://"); //SATISPAY
      //   break;
    }
  }, [urid]);

  //CREDIT DETERMINATION, is all here (exept for one of the first useEffects to load user id from cookie, if necessary)
  useEffect(() => {
    // console.log("user is", user);
    getUserCredits(user).then((res) => {
      // console.log("res is", res);
      if (res.data.number > 0) {
        const contacts = res.data.contacts;

        // //ARTIST CREDIT VALIDATION
        // const mCreditIndex = contacts.findIndex(
        //   (credit) => credit.Type === "M"
        // );
        // if (mCreditIndex !== -1) {
        //   const hasValidArtistCredit = FindArtistCredit(gift.songs);
        //   if (!hasValidArtistCredit) {
        //     // Remove the credit of type "M" if FindArtistCredit returns false
        //     contacts.splice(mCreditIndex, 1);
        //     console.log("contacts are ", contacts);
        //   }
        // }

        if (contacts.length > 0) {
          setIdBundle(contacts[0].ID_Bundle); //we need to update the SSOT so then in the credit payment we can update credits in the DB
          setTrueCredits(contacts[0]);
        } else {
          setTrueCredits({
            Type: "No credit",
            Credits_Owned: 0,
            Credits_Used: 0,
            Size: "",
            Expiration_Date: null,
          });
        }
      } else {
        setTrueCredits({
          Type: "No credit",
          Credits_Owned: 0,
          Credits_Used: 0,
          Size: "",
          Expiration_Date: null,
        });
      }
    });
  }, [user]);

  const [openGems, setOpenGems] = useState(false);

  const onGems = () => {
    navigate({
      pathname: "/thankspage/C",
    });
  };

  const handleClose = () => {
    setOpenGems(false);
  };

  const handleClickBack = () => {
    pageChange("create/playlist");
    navigate("/create");
  };

  return (
    <>
      {/* CONDITIONAL POP OFFER RENDERING */}
      {displayOffers &&
      trueCredits.Type !== "Dummy" &&
      (trueCredits.Credits_Owned === 0 || trueCredits.Size !== giftSize) ? (
        <PopOffer
          giftSize={giftSize}
          onExplore={() => {
            setOffer("E");
            setTotal(giftSize == "M" ? "16,99" : "10,99");
          }}
          onLifestyle={() => {
            setOffer("L");
            setTotal(giftSize == "M" ? "29,99" : "19,99");
          }}
          onThanks={() => {
            setOffer("");
            setTotal(basePrice);
          }}
        />
      ) : null}
      <div className="exact-page-net-header purchase-page cream-background-color">
        {/* TITLE */}
        <div className="cart-title-box">
          <h2 className="font-title0-Osw">Ottimo lavoro!</h2>
          <h2
            className="font-subT2-Osw purchase-aftertitle"
            style={{ lineHeight: "1.3em" }}
          >
            Dopo l'acquisto riceverai tutte le info
            <br className="phone" />
            utili alla mail {chosenEmail}
          </h2>
        </div>
        {/* CONTENT */}
        {/* CART CARD */}
        <div className="d-flex flex-column cart-card-yellow">
          <LineTitle text="Carrello" />

          <Card
            variant="outlined"
            className="cart-gift cart-cards-bottom"
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              padding: "15px",
              borderRadius: "10px",
              alignItems: "center",
            }}
            style={{ backgroundColor: "#FFFCEB" }}
          >
            <div className="d-flex flex-row align-items-center">
              <CardMedia
                component="img"
                sx={{ height: 50, width: 50, borderRadius: "5px" }}
                image={
                  thumbnail.startsWith("blob") ||
                  thumbnail.startsWith("http") ||
                  thumbnail === ""
                    ? thumbnail
                    : require(`../assets/cover/${thumbnail}`)
                }
              />
              <h4 className="gift-font">
                Regalo per <br />
                <span className="gift-name-font"> {name} </span>
              </h4>
            </div>
            {offer === "" ? (
              <span className="mt-auto">
                <h6
                  className="phone-font-price-gift"
                  style={{
                    fontFamily: "Oswald",
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#F98050",
                    margin: "0px",
                  }}
                >
                  Prezzo:
                </h6>
                <hr
                  style={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: "#F98050",
                    opacity: "1",
                    margin: "0px",
                  }}
                />
                <h6
                  className="phone-font-price-gift-value"
                  style={{
                    fontFamily: "Oswald",
                    fontWeight: "500",
                    fontSize: "24px",
                    lineHeight: "33px",
                    color: "#F98050",
                    margin: "0px",
                  }}
                >
                  {basePrice} €
                </h6>
              </span>
            ) : null}
          </Card>
          {/*SELECTED OFFER */}
          {offer === "E" ? (
            <Offer
              form="selected"
              claim="GIFTING EXPLORER"
              row1="Un altro regalo nei"
              row2="prossimi 2 mesi"
              price={giftSize == "M" ? "16,99" : "10,99"}
              saving={giftSize == "M" ? "3" : "1"}
              gifts="2"
              onClick={() => {
                setOffer("");
                setTotal(basePrice);
              }}
            />
          ) : null}
          {offer === "L" ? (
            <Offer
              form="selected"
              claim="LIFESTYLE GIFTER"
              row1="Altri 3 regali nei"
              row2="prossimi 4 mesi"
              price={giftSize == "M" ? "29,99" : "19,99"}
              saving={giftSize == "M" ? "10" : "4"}
              gifts="4"
              onClick={() => {
                setOffer("");
                setTotal(basePrice);
              }}
            />
          ) : null}

          {trueCredits.Type !== "Dummy" &&
          (trueCredits.Credits_Owned === 0 || trueCredits.Size !== giftSize) ? (
            <>
              <LineTitle text="Aggiungi pacchetto" />
              {/* SELECTABLE OFFERS */}
              {offer !== "E" ? (
                <Offer
                  form="free"
                  claim="GIFTING EXPLORER"
                  row1="Un altro regalo nei"
                  row2="prossimi 2 mesi"
                  price={giftSize == "M" ? "16,99" : "10,99"}
                  saving={giftSize == "M" ? "3" : "1"}
                  gifts="2"
                  onClick={() => {
                    setOffer("E");
                    setTotal(giftSize == "M" ? "16,99" : "10,99");
                  }}
                />
              ) : null}
              {offer !== "L" ? (
                <Offer
                  form="free"
                  claim="LIFESTYLE GIFTER"
                  row1="Altri 3 regali nei"
                  row2="prossimi 4 mesi"
                  price={giftSize == "M" ? "29,99" : "19,99"}
                  saving={giftSize == "M" ? "10" : "4"}
                  gifts="4"
                  onClick={() => {
                    setOffer("L");
                    setTotal(giftSize == "M" ? "29,99" : "19,99");
                  }}
                />
              ) : null}
            </>
          ) : null}

          <span className="mt-auto" style={{ alignSelf: "flex-end" }}>
            <h4
              className="phone-total-card"
              style={{
                fontFamily: "Oswald",
                fontWeight: "500",
                fontSize: "35px",
              }}
            >
              TOTALE: {total} €
            </h4>
          </span>
        </div>
        {/* end of Cart Card */}

        {/* PAYMENT METHODS CARD */}
        <div className="d-flex flex-column cart-card-beije">
          <LineTitle text="Pagamento" />
          {trueCredits.Credits_Owned > 0 && trueCredits.Size == giftSize ? (
            <button className="methodBtn" onClick={() => setMethod("Credits")}>
              <Card
                className="cardBtn cart-cards-bottom payment-methods-card"
                variant="outlined"
                style={{ backgroundColor: "#FFFDEF" }}
              >
                <h4
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "500",
                    fontSize: "26px",
                    lineHeight: "28px",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  Utilizza credito
                </h4>
              </Card>
            </button>
          ) : null}

          <button className="methodBtn" onClick={() => setMethod("Standard")}>
            <Card
              className="cardBtn cart-cards-bottom payment-methods-card"
              variant="outlined"
              style={{ backgroundColor: "#84CDC4", paddingTop: "25px" }}
            >
              <figure className="methodIcon phone-card-icon">
                <img src={Carta} />
                <figcaption className="methodCaption">Carta</figcaption>
              </figure>

              <figure className="methodIcon phone-card-icon">
                <img src={ApplePay} />
                <figcaption className="methodCaption">Apple Pay</figcaption>
              </figure>

              <figure className="methodIcon phone-card-icon">
                <img src={GooglePay} />
                <figcaption className="methodCaption">Google Pay</figcaption>
              </figure>
            </Card>
          </button>

          {/* TODO SATISPAY */}
          {/* <button
            className="methodBtn"
            onClick={() => setMethod("Satispay")}
            >
            <Card
              className="cardBtn"
              variant="outlined"
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "space-between",
                width: "370.85px",
                height: "72.53px",
                padding: "15px",
                borderRadius: "10px",
                alignItems: "center",
              }}
              style={{ backgroundColor: "#84CDC4", marginBottom: "25px" }}
            >
              <h4
                align="center"
                style={{
                  textAlign: "center",
                  marginTop: "25px",
                  marginLeft: "113px",
                }}
              >
                <figure class="methodIcon">
                  <img src={Satispay} />
                  <figcaption className="methodCaption">Satispay</figcaption>
                </figure>
              </h4>
            </Card>
            </button> */}

          <span className="mt-auto" style={{ alignSelf: "center" }}>
            <div className="mt-auto d-flex flex-column align-items-center">
              {trueCredits.Credits_Owned > 0 && trueCredits.Size == giftSize ? (
                <p
                  fontSize="14px"
                  style={{
                    width: "250px",
                    textAlign: "center",
                    marginBottom: "30px",
                    lineHeight: "20px",
                  }}
                >
                  <span style={{ color: "#F98050", fontSize: "25px" }}>*</span>{" "}
                  Ricorda che hai {trueCredits.Credits_Owned}{" "}
                  {trueCredits.Credits_Owned > 1 ? "crediti" : "credito"} da
                  utilizzare entro il {ParseDate(trueCredits.Expiration_Date)}
                </p>
              ) : (
                trueCredits.Credits_Owned > 0 && (
                  <p
                    fontSize="14px"
                    style={{
                      width: "250px",
                      textAlign: "center",
                      marginBottom: "30px",
                      lineHeight: "20px",
                    }}
                  >
                    <span style={{ color: "#F98050", fontSize: "25px" }}>
                      *
                    </span>{" "}
                    Ricorda che hai {trueCredits.Credits_Owned}{" "}
                    {trueCredits.Credits_Owned > 1 ? "crediti" : "credito"} da
                    utilizzare entro il {ParseDate(trueCredits.Expiration_Date)}{" "}
                    (ma puoi usarli solo per l'acquisto di tune dell'altra
                    misura)
                  </p>
                )
              )}
            </div>
            <div className="d-flex flex-column align-items-center">
              {gift.songs.length > 0 &&
              gift.dedication !== "" &&
              gift.moment !== "" &&
              gift.cover !== "" &&
              gift.avatar !== "" &&
              gift.id !== "" ? (
                <>
                  <div className="d-flex flex-row justify-content-center">
                    {url.startsWith("intercept") ? (
                      <PrimaryButton
                        text="PROCEDI"
                        clickHandler={() => setOpenGems(true)}
                      />
                    ) : (
                      <a
                        href={url}
                        // target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <PrimaryButton text="PROCEDI" clickHandler={() => {}} />
                      </a>
                    )}
                  </div>
                  <div style={{ height: "15px" }} />
                  <SecondaryButton
                    text="TORNA INDIETRO"
                    clickHandler={handleClickBack}
                  />
                </>
              ) : (
                // use the TEST_HELPER in Gift to test this error part
                <>
                  <h2
                    className="secondaryBtn"
                    style={{
                      color: "#F98050",
                      WebkitTextFillColor: "#F98050",
                      textAlign: "center",
                    }}
                  >
                    OPS, <br />
                    CI MANCA QUALCOSA...
                  </h2>
                  <div className="d-flex flex-row justify-content-center">
                    <SecondaryButton
                      text="CONTROLLA CI SIA TUTTO"
                      clickHandler={handleClickBack}
                    />
                  </div>
                </>
              )}
            </div>
            {/* THE CREDITS POPUP */}
            <PopGems
              opened={openGems}
              type={trueCredits.Type}
              owned={trueCredits.Credits_Owned}
              used={trueCredits.Credits_Used}
              onGems={onGems}
              onClose={handleClose}
            />
          </span>
        </div>
        {/* end of Payment Method Card */}
      </div>
      {/* end of Content */}
      {/* end of Page */}
      <Footer />
    </>
  );
}

export default Purchase;
