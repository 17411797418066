import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { UserContext } from "../contexts/userContext";
import PopSelf from "./popups/popSelf";
import RedeemButtonP from "./../components/common/redeemButton";
import Sofa from "../assets/images/sofa.svg";
import Footer from "../layouts/footer";
import { redeemCode } from "../services/contentApi";

// THIS PAGE IS IN THE RECOMMENDED FLOW FOR iOS USERS
function Redeem({ setScene }) {
  const { ID_User } = useContext(UserContext);

  //important if we immediately follow login (redirect from root scene "land")
  useEffect(() => {
    setScene("light");
  }, []);

  //THIS PART of code was developed iterating with chatGPT to manage the 6 code boxes UI/UX and data
  //
  const inputRefs = useRef([]);
  const [code, setCode] = useState(Array(6).fill(""));
  const [complete, setComplete] = useState(false);

  //update the complete state to true if the length of the code string is equal to 6
  useEffect(() => {
    if (code.join("").length === 6) {
      setComplete(true);
    } else setComplete(false);
  }, [code]);

  const handlePaste = useCallback((e, index) => {
    e.preventDefault(); // prevent the default behavior of pasting into the input element

    const pasteData = e.clipboardData.getData("text/plain"); // extract the pasted content
    const pasteChars = pasteData.toUpperCase().split(""); // split the pasted content into an array of characters
    setCode((prevState) => {
      const newCode = [...prevState];

      // the index limit of the paste operation considering start, lenght & template
      const pasteIndexLimit = Math.min(
        index + pasteChars.length,
        newCode.length
      );

      // update the code state with the pasted characters
      for (let i = index, j = 0; i < pasteIndexLimit; i++, j++) {
        newCode[i] = pasteChars[j];
      }

      return newCode.slice(0, 6);
    });

    // move the focus to the next input element if there are more characters to paste
    if (inputRefs.current[index + pasteChars.length]) {
      inputRefs.current[index + pasteChars.length].focus();
    } else {
      inputRefs.current[5].focus();
    }
  }, []);

  // update the code state with the new input value
  const handleChange = useCallback(
    (event, index) => {
      const newCode = [...code];
      newCode[index] = event.target.value.toUpperCase();
      setCode(newCode);

      // move the focus to the next input element if the current input has a value
      if (event.target.value && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    },
    [code]
  );

  // move the focus to the previous input element if the current input is empty and the backspace key is pressed
  const handleKeyDown = (event, index) => {
    if (
      !event.target.value &&
      event.key === "Backspace" &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };
  // ----------------------

  // in this part we manage different feedbacks to the user
  const [message, setMessage] = useState({ text: "", color: "" });
  const [needConfirm, setNeedConfirm] = useState(false); //we need confirmation if the user wants to redeem a gift he also made
  const handleSend = (id, code, self = false) => {
    redeemCode(id, code, self)
      .then((res) => {
        setCode(Array(6).fill(""));
        setMessage({
          text: "Apri o scarica l'app. C'è un regalo che ti attende!",
          color: "#81C6BF",
        });
      })
      .catch((res) => {
        switch (res.response.data) {
          case "Wrong code":
            setMessage({
              text: "Controlla che il codice sia corretto o contatta l'assistenza",
              color: "#2C363F",
            });
            break;
          case "Param error":
            setMessage({
              text: "Controlla che il codice sia corretto o contatta l'assistenza",
              color: "2C363F",
            });
            break;
          case "Needs confirmation":
            setNeedConfirm(true);
            break;
          default:
            setMessage("");
        }
      });
  };

  const handleClose = () => {
    setCode(Array(6).fill(""));
    setNeedConfirm(false);
  };

  const handleConfirm = () => {
    handleSend(ID_User, code.join(""), true);
    setNeedConfirm(false);
  };

  // TODO maybe we can add an option to clear the code? useful in "wrong code" case

  return (
    <>
      <div
        className="exact-page-net-header-footer d-flex flex-column justify-content-between align-items-center cream-background-color"
        style={{ paddingInline: "30px" }}
      >
        <div>
          <span className="phone" style={{ height: "30px" }} />
          <h2
            className="font-title0-Osw"
            style={{ textAlign: "center", marginBottom: "10px" }}
          >
            Riscatta qui il regalo che hai ricevuto!
          </h2>
          <h2
            className="font-subT1-Osw font-subT1-Osw-custom-phone"
            style={{ textAlign: "center" }}
          >
            Troverai poi il regalo{" "}
            <span style={{ color: "#D3C3A6" }}>nel mobile</span>
            <br />
            dell'<span style={{ color: "#D3C3A6" }}>app mobile</span>
          </h2>
        </div>

        <div className="d-flex flex-column align-items-center">
          <div>
            {/* render six input elements with a maxLength of 1 and store each input element in the ref  */}
            {code.map((c, i) => (
              <input
                className="codeBlock"
                key={i}
                ref={(el) => (inputRefs.current[i] = el)}
                type="text"
                maxLength={1}
                value={c}
                onChange={(e) => handleChange(e, i)}
                onPaste={(e) => handlePaste(e, i)}
                onKeyDown={(e) => handleKeyDown(e, i)}
                autoComplete="new-password" //works better than "off"
              />
            ))}
          </div>
          <div className="shelf" />
          <span
            className="font-subT2-Osw"
            style={{ color: message.color, textAlign: "center" }}
          >
            {message.text}
          </span>
        </div>
        <RedeemButtonP
          text="RISCATTA"
          active={complete}
          clickHandler={() => handleSend(ID_User, code.join(""))}
          inside={true}
        />
        <div>
          <img className="sofa" src={Sofa} />
        </div>
      </div>
      <PopSelf
        opened={needConfirm}
        onPreserve={handleClose}
        onSelf={handleConfirm}
      />
      <Footer />
    </>
  );
}

export default Redeem;
