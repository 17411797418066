import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Frame from "../../assets/images/mockup/mockup-frame.svg";
import Content1 from "../../assets/images/mockup/your-room.png";
import Content2 from "../../assets/images/mockup/gloriastune.png";
import Footer1 from "../../assets/images/mockup/footer1.png";
import Footer2 from "../../assets/images/mockup/footer2.png";
import Header from "../../assets/images/mockup/header.png";
import GiftSong from "./giftSong";
import { isURL } from "../../utils/isURL";
import Tooltip from "@mui/material/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function Mockup({
  roomOpacity,
  dedicationOpacity,
  momentOpacity,
  dedicationScale,
  momentScale,
  roomTranslateY,
  tuneTranslateY,
  giverName,
  dedication,
  moment,
  avatar,
  cover,
  songs,
  setSongs,
  removeSong,
  isInteractive = false,
  smoothTargets = false,
  tuneFooterVisible = true,
  isFrameHidden = false,
  playingSong = "Nice Track of Tunehey",
  onPlay = () => {},
  onPause = () => {},
}) {
  const transitionDuration = 0.5; // FIXME not working

  // const elementRef = useRef(null);
  // const [isEllipsed, setIsEllipsed] = useState(false);

  // useEffect(() => {
  //   const element = elementRef.current;
  //   if (element) {
  //     // Check if the text is ellipsed
  //     const isTextEllipsed =
  //       element.scrollWidth > element.clientWidth ||
  //       element.scrollHeight > element.clientHeight;

  //     setIsEllipsed(isTextEllipsed);
  //   }
  // }, [giverName]);

  const dedicationParagraphs = splitParagraphs(dedication);
  const momentParagraphs = splitParagraphs(moment);

  const renderAvatar = () => {
    const commonProps = {
      alt: "Mockup avatar",
      className: "mockup-avatar",
    };
    if (avatar === "") {
      return (
        <span
          className="mockup-avatar"
          style={{ backgroundColor: "#E2D6BE" }}
        />
      );
    } else if (isURL(avatar)) {
      return <motion.img src={avatar} {...commonProps} />;
    } else if (avatar.startsWith("blob")) {
      <motion.img src={avatar} {...commonProps} />;
    } else {
      return (
        <motion.img
          src={require(`../../assets/images/mockup/${avatar}`)}
          {...commonProps}
        />
      );
    }
  };

  const renderCover = () => {
    const commonProps = {
      alt: "Mockup cover",
      className: "mockup-cover",
      style: { translateY: tuneTranslateY },
    };
    if (cover === "") {
      return (
        <motion.div
          className="mockup-cover"
          style={{
            translateY: tuneTranslateY,
            backgroundColor: "#E2D6BE",
          }}
        />
      );
    } else if (isURL(cover)) {
      return <motion.img src={cover} {...commonProps} />;
    } else if (cover.startsWith("mockup")) {
      return (
        <motion.img
          src={require(`../../assets/images/mockup/${cover}`)}
          {...commonProps}
        />
      );
    } else if (cover.startsWith("blob")) {
      return <motion.img src={cover} {...commonProps} />;
    } else {
      return (
        <motion.img
          src={require(`../../assets/cover/${cover}`)}
          {...commonProps}
        />
      );
    }
  };

  //DRAG AND DROP
  // function to update list on drop
  const handleDrop = (droppedItem) => {
    try {
      // ignore drop outside droppable container
      if (!droppedItem.destination) return;
      var updatedList = [...songs];
      // remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      // add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      // update State
      setSongs(updatedList);
    } catch (error) {
      // console.error("Error during drag:", error);
    }
  };

  return (
    <>
      {/* the over layer is the mobile frame */}
      <img
        src={Frame}
        className={`frame ${isFrameHidden ? "frame-hidden" : ""}`}
      />
      {/* the second layer is a viewport in wich the content scroll becoming visible only inside it */}
      <div className="mockup-content-viewport">
        {/* fixed content ahead (app footer) */}
        <motion.img
          src={Footer1}
          className="content-footer"
          style={{ opacity: roomOpacity, pointerEvents: "none" }}
        />
        {/* scrolling content behind */}
        <motion.img
          src={Content1}
          alt="Your Room"
          className="content-image room-image"
          style={{
            translateY: roomTranslateY,
            opacity: roomOpacity,
            pointerEvents: "none",
          }}
        />
        {tuneFooterVisible && (
          <motion.img
            src={Footer2}
            alt="Gift Footer"
            className="content-footer"
            style={{
              zIndex: 6,
              left: 0,
            }}
          />
        )}

        <motion.div
          alt="Gift Background"
          className="content-image content2-image"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#2C2B26",
          }}
          transition={{
            opacity: { duration: smoothTargets ? transitionDuration : 0 },
          }}
        />
        {renderCover()}
        <motion.p
          customname="dedication"
          className="cream-color dedication-demo "
          style={{
            whiteSpace: "pre-line",
            opacity: dedicationOpacity,
            scale: dedicationScale,
            translateY: tuneTranslateY,
          }}
          transition={{
            opacity: { duration: smoothTargets ? transitionDuration : 0 },
            scale: { duration: smoothTargets ? transitionDuration : 0 },
            translateY: { duration: smoothTargets ? transitionDuration : 0 },
          }}
        >
          {dedicationParagraphs}
        </motion.p>
        <motion.p
          customname="moment"
          className="moment-demo"
          style={{
            opacity: momentOpacity,
            scale: momentScale,
            translateY: tuneTranslateY,
          }}
          transition={{
            opacity: { duration: smoothTargets ? transitionDuration : 0 },
            scale: { duration: smoothTargets ? transitionDuration : 0 },
            translateY: { duration: smoothTargets ? transitionDuration : 0 },
          }}
        >
          <span style={{ fontWeight: "500", lineHeight: "20px" }}>
            Per un ascolto speciale:
          </span>
          <br />
          {momentParagraphs}
        </motion.p>
        <motion.div
          alt="Gift header content wrap"
          className="mockup-header d-flex flex-row justify-content-between"
        >
          <motion.p
            // ref={elementRef}
            className="mockup-name"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            translate="no"
          >
            {giverName}'s tune
          </motion.p>
          {renderAvatar()}
        </motion.div>
        <motion.img
          src={Header}
          alt="Gift header background"
          className="content-image content2-image content3-image"
          style={{
            zIndex: 5,
          }}
        />
        <motion.div
          className="mockup-songs"
          style={{ translateY: tuneTranslateY }}
        >
          {/* ------------------------------------------ */}
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="selected-container">
              {(provided) => (
                <div
                  className="d-grid border-0 w-auto"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {!Array.isArray(songs)
                    ? null
                    : songs.map((item, index) => (
                        <Draggable
                          key={item.ID_Song}
                          draggableId={String(item.ID_Song)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={index}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                opacity: snapshot.isDragging ? 0 : 1,
                              }}
                            >
                              <GiftSong
                                key={index}
                                data={item}
                                isInteractive={isInteractive}
                                removeSong={removeSong}
                                playingSong={playingSong}
                                onPlay={onPlay}
                                onPause={onPause}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* ------------------------------------------ */}
        </motion.div>
      </div>
    </>
  );
}

function splitParagraphs(text) {
  return text.split("\n").map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph} <br />
    </React.Fragment>
  ));
}
