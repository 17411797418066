import ReactPixel from "../../services/facebookPixel";

function PrimaryButton({
  text,
  clickHandler,
  active = true,
  customEventName = "unregistered-primarybtn",
  customData = [],
}) {
  const handleClick = () => {
    clickHandler();
    if (customEventName !== "unregistered-primarybtn") {
      ReactPixel.trackCustom(customEventName, customData);
    }
  };

  return (
    <button
      className={`primaryBtnUncolored ${
        active ? "primaryBtnColor" : "primaryBtnColorInactive"
      }`}
      disabled={active ? false : true}
      onClick={() => {
        handleClick();
      }}
    >
      {text}
    </button>
  );
}

export default PrimaryButton;
