import { motion } from "framer-motion";

function VerticalProgressBar({ progress, isFixed }) {
  const progressBarVariants = {
    initial: {
      height: "0%",
    },
    animate: {
      height: `${progress}%`,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 30,
        restDelta: 0.001,
      },
    },
  };

  return (
    <div className={`${isFixed ? "vertical-progress-bar" : ""}`}>
      <motion.div
        className="progress"
        variants={progressBarVariants}
        initial="initial"
        animate="animate"
      ></motion.div>
    </div>
  );
}

export default VerticalProgressBar;
