import { React } from "react";
import { IoPlayOutline, IoCloseOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { CiPause1 } from "react-icons/ci";
import { MdDragIndicator } from "react-icons/md";
import secToMin from "./../utils/secToMin";

const SongBar = ({
  data,
  onSelect,
  chosed,
  onPlay,
  onPause,
  playingSong,
  playlistChosed,
}) => {
  const {
    Title: title,
    Stage_Name: artist,
    Length: length,
    // ID_Song: iD_Song, //not needed
  } = data;

  const TextPart = () => {
    return (
      <div
        className="songbar-text-space"
        style={{
          width: "420px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <span className="songbar-text" translate="no">
          {title}
        </span>
        <span className="songbar-text" style={{ opacity: 0.7 }} translate="no">
          <span className="no-phone">{" - "}</span>
          {artist}
        </span>
      </div>
    );
  };

  const ButtonsPart = () => {
    // Play/Pause and Remove if chosed
    // Play/Pause and Add if not chosed
    // Play if chosed via playlist
    const handleButtonClick = (event) => {
      // Prevent the click event from reaching the parent container
      event.stopPropagation();
    };

    return (
      <div>
        {/* <span
          className="no-phone"
          style={{ opacity: 0.7, paddingInlineEnd: 25, color: "#2C2B26" }}
        >
          {secToMin(length)}
        </span> */}

        {!playlistChosed ? (
          //if we are not in a playlist we can modify it
          //TODO handle (via SongList) that in that case we can modify it but simply the Playlist bar disappears (as we are changing it)
          //or that removing the playlist bar songs don't disappear (until user confirmation like "do you want to edit it or restart from scratch?")
          <>
            {chosed ? (
              <button
                className="transparentBtn btnFixPadding"
                onClick={(event) => {
                  handleButtonClick(event);
                  onSelect(data);
                }}
              >
                <IoCloseOutline
                  fontSize={"24px"}
                  style={{ opacity: 0.5 }}
                  color="#000000"
                />
              </button>
            ) : (
              <button
                className="transparentBtn btnFixPadding"
                onClick={(event) => {
                  handleButtonClick(event);
                  onSelect(data);
                }}
              >
                <FaPlus fontSize={"20px"} color="#F98050" />
              </button>
            )}
          </>
        ) : null}
      </div>
    );
  };

  const barBackgroundColor = chosed ? "#FCE868" : "#F1E8D5";
  const resultBarShadow =
    !chosed && playingSong === data.Storage_Preview ? "#FCE868" : "transparent";

  return (
    <div>
      <a
        //based on bootstrap list-group...maybe unnecessary
        className="songbar list-group-item list-group-item-action px-3 rounded-3 border-0 d-flex align-items-center justify-content-between"
        style={{
          backgroundColor: barBackgroundColor,
          boxShadow: `0 0 0 3px ${resultBarShadow}`,
          height: "40px",
          // width: "660px",
        }}
        onClick={() => {
          if (playingSong === data.Storage_Preview) {
            onPause();
          } else {
            onPlay(data);
          }
        }}
      >
        <div className="d-flex align-items-center">
          {!playlistChosed ? (
            <>
              {chosed ? (
                <MdDragIndicator fontSize={18} style={{ marginRight: 18 }} />
              ) : null}
              {/* note that user can't reorder curated playlists */}
            </>
          ) : null}
          <TextPart />
        </div>
        <ButtonsPart />
      </a>
    </div>
  );
};

export default SongBar;
