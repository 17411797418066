function Artists() {
  return (
    <div
      className="yellow-band-section d-flex flex-column justify-content-start align-items-center"
      style={{ position: "relative" }}
    >
      <h1 className="font-subT-OswReg title-artist">
        VALORIZZIAMO INSIEME <br className="phone " />
        IL LAVORO DEGLI ARTISTI
      </h1>
      <h2 className="text-artist">
        Per ogni regalo l'industria <br className="phone" /> musicale e{" "}
        <span className="white-text-background">gli artisti</span> ricevono un{" "}
        <br />
        <span className="text-artist-strong-italic">compenso</span> equivalente
        a quello
        <br className="phone" /> prodotto da{" "}
        <span className="text-artist-strong-italic">
          più di <br className="phone" /> 1300 stream
        </span>
      </h2>
    </div>
  );
}

export default Artists;
