import { useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  Authenticator,
  // Button, //import all this for the commented UI implementation from scratch
  // Text,
  // Heading,
  // useTheme,
  // View,
  // useAuthenticator,
} from "@aws-amplify/ui-react";
import "../assets/localStyle/auth.css";
// IN THIS "MANAGED" COMPONENT WE CAN CONFIGURE IT CTA'S (actually not every one)
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);
I18n.setLanguage("it");

function SignIn({ sceneChange, position }) {
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    sceneChange("light");
    setCookie("enrolling", true, [
      { path: "/", expires: new Date(Date.now() + 10 * 60 * 1000) },
    ]);
    //ten minutes of time to complete the process and let Gift redirect the user to previous task
  }, []);

  const rootPosition = position.split("/")[0];

  const messages = [
    {
      id: "redeem",
      message: (
        <>
          Mettiti comodo. A breve potrai
          <br />
          <span className="orange-color">scartare</span> il tuo regalo 🎶
        </>
      ),
    },
    {
      id: "createLogin",
      message: (
        <>
          Via le limitazioni e
          <br /> pronti a{" "}
          <span style={{ textDecoration: "line-through" }}>salpare</span>{" "}
          <span className="orange-color">salvare</span>
        </>
      ),
    },
    {
      id: "generic",
      message: (
        <>
          Meglio dare o ricevere? In ogni caso
          <br />
          ciò per cui sei qui,{" "}
          <span className="orange-color">è bello di sicuro</span> ✨
        </>
      ),
    },
  ];

  return (
    <div className="yellow-gradient-background exact-page-net-header">
      <h2
        className="font-subT1-Osw font-subT1-Osw-custom-phone centered-title-phone"
        style={{ marginBottom: "14px", textAlign: "center" }}
      >
        {messages.find((msg) => msg.id == rootPosition)?.message ||
          messages.find((msg) => msg.id == "generic").message}
      </h2>
      <br />
      <Authenticator />
    </div>
  );
}

export default SignIn;

//ALTERNATIVE VERSION OF SIGNIN, FROM SCRATCH, NOT DEVELOPED YET

// import { useEffect } from "react";

// import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";

// import { useNavigate, useLocation } from "react-router-dom";

// export function Login() {
//   const { authStatus } = useAuthenticator((context) => [context.authStatus]);
//   console.log("i'm ", authStatus);
//   const location = useLocation();
//   const navigate = useNavigate();
//   let from = location.pathname || "/out";
//   console.log(from);
//   useEffect(() => {
//     if (authStatus === "authenticated") {
//       console.log("i'm here");
//       navigate(from, { replace: true });
//     }
//   }, [authStatus, navigate, from]);
//   return (
//     <View className="auth-wrapper">
//       <Authenticator></Authenticator>
//     </View>
//   );
// }
