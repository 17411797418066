import React from "react";
import { motion } from "framer-motion";
import { IoPlay } from "react-icons/io5";
import { PiPauseFill } from "react-icons/pi";
import { BsPlusCircle } from "react-icons/bs";
import VolumeControl from "./common/volumeControl";

const MusicControl = ({
  currentSong,
  isChosed,
  isPlaying,
  onPlay,
  onPause,
  volume,
  setVolume,
  onSelect,
  onRemove,
}) => {
  const handleButtonClick = () => {
    if (isChosed) {
      // If isChosed is true, trigger onRemove
      onRemove(currentSong);
    } else {
      // If isChosed is false, trigger onSelect
      onSelect(currentSong);
    }
  };

  const helper = () => {
    return (
      <motion.div
        className="music-control-container mc-variant"
        animate={{ opacity: currentSong ? 0 : 1 }}
        transition={{ duration: 3, ease: "easeInOut" }} //FIXME
        style={{ backgroundColor: "#FCE868" }}
      >
        <p className="font-subT5-WS" style={{ margin: "0px 0px 0px 0px" }}>
          Cerca dalla searchbar{" "}
          <span className="no-phone">o con le sezioni di sinistra.</span>
          <span className="phone">o dai 3 menù.</span>
        </p>
        <h2
          className="font-subT2-Osw-mc-variant"
          style={{ lineHeight: "32px", margin: "0px 0px 0px 0px" }}
        >
          Clicca un brano per ascoltare l'anteprima.
        </h2>
      </motion.div>
    );
  };

  return currentSong ? (
    <motion.div
      className="music-control-container"
      style={{ border: "none" }}
      animate={{ opacity: currentSong ? 1 : 0 }}
      transition={{ duration: 3, ease: "easeInOut" }} //FIXME
    >
      <button
        className="mc-play-pause-btn"
        onClick={isPlaying ? onPause : () => onPlay(currentSong)}
      >
        <div className={`${isPlaying ? "mc-pause-icon" : "mc-play-icon"}`}>
          {isPlaying ? <PiPauseFill /> : <IoPlay />}
        </div>
      </button>
      <div className="mc-track-info">
        <p
          className="mc-title"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {currentSong.Title}
        </p>
        <p className="mc-artist">{currentSong.Stage_Name}</p>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div
          className={`mc-add-button ${isChosed ? "chosed" : ""}`}
          onClick={handleButtonClick}
        >
          <BsPlusCircle fontSize={"30px"} />
        </div>
        <VolumeControl volume={volume} setVolume={setVolume} />
      </div>
    </motion.div>
  ) : (
    helper()
  );
};

export default React.memo(MusicControl);
