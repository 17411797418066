//TWIN of CheckBox
const RadioButton = ({
  label,
  id,
  handler,
  checked,
  specialClassName = "",
}) => {
  return (
    <div className={`form-check font-subT6-WS radio-list ${specialClassName}`}>
      <input
        className="form-check-input check"
        style={{ backgroundColor: "#F1E8D5" }}
        type="radio"
        name="momentCraft"
        id={id}
        onChange={handler}
        checked={checked}
        value={checked}
      />
      <label className="" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
