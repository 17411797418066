import React, { useState, useEffect } from "react";
import RadioButton from "../../components/common/radioButton";
import { isMobile } from "../../utils/isMobile";

function Moment({
  onMoment,
  momentID,
  onMomentID,
  originalMoment,
  onOriginalMoment,
}) {
  const deviceMobile = isMobile();

  //local state for managing the open option (the "original" moment) in the case when is written but not radio-selected
  let [localMom, setLocalMom] = useState("");

  useEffect(() => {
    if (momentID === "Custom") {
      onMoment(originalMoment);
    } else {
      moments.forEach((element) => {
        if (momentID === element.id) {
          onMoment(element.text);
        }
      });
    }
  }, [momentID]);

  //FIXME THIS COMPONENENT COMPROMISES WITH A BUG about different size of the moment list trough desktop or phone setting:
  //the persistence of state is assured, but we don't always view it (the state)
  //if we are using a different device category from the one used selecting a standard moment.
  //There are no problem with custom moment. It's not a problem at all if the user the same device for his gift customizing.
  //If the user sees it, can choose again a standard/custom moment, but even if he doesn't notice, he's able to conclude the process.
  return (
    <div className="custom-page-int-up custom-page-int-up-phone-corr custom-page-int-up-process">
      <br className="no-desktop" />
      <div className="centered-title-phone centered-process-pd-right">
        <h1 className="font-title0-Osw">
          Consiglia un{" "}
          <span className="yellow-text-background">
            momento
            <br className="phone" /> speciale
          </span>{" "}
          da vivere
        </h1>
        <h2 className="font-subT1-Osw font-subT1-Osw-custom-phone">
          Regalerai una pausa rigenerante
          <br className="no-phone" />
        </h2>
      </div>
      <br className="desktop" />
      <br className="desktop midsize" />
      <span className="phone" style={{ height: "10px" }} />
      {/* ACTION */}
      <div
        className="no-padding centered-process-pd-right"
        style={{ paddingLeft: "70px" }}
      >
        {/* ORIGINAL */}
        <div className="form-check font-subT6-WS" style={{ marginTop: "5px" }}>
          <input
            className="form-check-input check original-check"
            style={{ backgroundColor: "#F1E8D5" }}
            type="radio"
            name="momentCraft"
            id="Custom"
            onChange={() => {
              onMomentID("Custom");
              onMoment(localMom);
            }}
            checked={"Custom" === momentID}
            value={"Custom" === momentID}
          />
          <textarea
            className="form-control moment original-moment"
            style={{
              backgroundColor: "#F1E8D5",
              resize: "none",
              borderColor: "transparent",
              Maxwidth: "200px",
              minHeight: "50px",
              fontFamily: "Work Sans",
              // fontStyle: "Italic",
              fontWeight: "400",
              fontSize: "30px",
              lineHeight: "40px",
            }}
            htmlFor="Custom"
            rows={deviceMobile ? "4" : "2"}
            maxLength={160}
            placeholder="Scrivi qui il tuo invito ✏️"
            onSelect={(e) => {
              onMomentID("Custom");
              setLocalMom(e.currentTarget.value);
              onMoment(e.currentTarget.value);
            }}
            onChange={(e) => {
              onOriginalMoment(e.currentTarget.value);
            }}
            value={originalMoment}
          />
        </div>

        {/* REFERENCES */}
        {moments.slice(0, 4).map((item) => (
          <RadioButton
            label={item.text}
            id={item.id}
            key={item.id}
            handler={(e) => {
              onMomentID(e.currentTarget.id);
              onMoment(item.text);
            }}
            checked={item.id === momentID}
            specialClassName={"radio-more-spacing label-less-fontSize"}
          />
        ))}
      </div>
      <br />
      <br />
    </div>
  );
}

export default React.memo(Moment);

// we identify moments with id and text
// "Custom" is the standard id for all original moment
export const moments = [
  {
    text: "Prenditi una serata per te sul divano con una tisana!",
    id: "Tisana",
  },
  {
    text: "Ascoltalo mentre fai una passeggiata",
    id: "Passeggiata",
  },
  {
    text: "Blocca lo schermo e guarda le stelle! ✨",
    id: "Stelle",
  },
  {
    text: "Vai nel tuo belvedere preferito!",
    id: "Panorama",
  },
  // {
  //   text: "Vai a guardare il mare",
  //   id: "Mare",
  // },
  // {
  //   text: "Immergiti nella natura con queste canzoni",
  //   id: "Natura",
  // },
  // RIPETIZIONE CON KEY DIVERSI PER BUG CLICK RIPETIZIONE PHONE
  // REPETITION WITH DIFFERENT KEY FOR DIFFERENT PHONE CLICK RENDER
  // {
  //   text: "Prenditi una serata per te sul divano con una tisana!",
  //   id: "Tisana2",
  // },
  // {
  //   text: "Ascoltalo mentre fai una passeggiata",
  //   id: "Passeggiata2",
  // },
  // {
  //   text: "Blocca lo schermo e guarda le stelle! ✨",
  //   id: "Stelle2",
  // },
  // {
  //   text: "Vai nel tuo belvedere preferito!",
  //   id: "Panorama2",
  // },
];
