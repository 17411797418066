import { useEffect } from "react";
import Accordion from "../components/common/accordion";
import Footer from "../layouts/footer";
import ScrollToTop from "../utils/scrollToTop";
import { faqFile } from "../data/faqList";

function Faq({ setScene }) {
  useEffect(() => {
    setScene("light");
  }, []);

  ScrollToTop();

  // Group FAQs by category
  const faqsByCategory = faqFile.categories.map((category) => ({
    category,
    faqs: faqFile.contents.filter((faq) => faq.category === category),
  }));

  return (
    <>
      <div
        className="exact-page-net-header-footer d-flex flex-column align-items-center cream-background-color"
        style={{ paddingInline: "30px" }}
      >
        <h1
          className="font-title0-Osw seafoam-selection"
          style={{ textAlign: "center" }}
        >
          F.A.Q.
        </h1>
        <br />
        {faqsByCategory.map((categoryFaqs) => (
          <div
            key={categoryFaqs.category}
            className="bigger-faq"
            style={{
              maxWidth: "700px",
              width: "100%",
              paddingBottom: "30px",
            }}
          >
            <h2 className="font-category-title">{categoryFaqs.category}</h2>
            <Accordion file={categoryFaqs.faqs} />
          </div>
        ))}
      </div>
      <h3
        className="font-subtitle-OswMed seafoam-selection"
        style={{ textAlign: "center" }}
      >
        Per altre domande non esitare a scriverci sui nostri social o
        all'indirizzo <span className="yellow-selection">hey@tunehey.com</span>
      </h3>
      <br />
      <Footer />
    </>
  );
}

export default Faq;
