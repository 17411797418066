import React, { useEffect } from "react";

import { FileInput } from "@mantine/core";
import { HiOutlinePhotograph } from "react-icons/hi";

import { IMAGE_MIME_TYPE } from "@mantine/dropzone";

import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./common/cropUtils";
import SecondaryButton from "./common/secondaryButton";
import PrimaryButton from "./common/primaryButton";

export function DropCrop(props) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState([]);
  const [newOpenToken, setNewOpenToken] = useState(false); //we use it to manage cropper reopen to re-center a fresh-cropped image
  const [rejected, setRejected] = useState(false); //easy-crop-option we could use, as in Avatar
  const [loadingFile, setLoadingFile] = useState(false); //easy-crop-option we could use, as in Avatar

  const onFileChange = async (files) => {
    setLoadingFile(true);
    if (files && files.length > 0) {
      const file = files[0];
      // console.log(file);
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      if (croppedImage) {
        setNewOpenToken(true);
      }
      setLoadingFile(false);
    }
  };

  //alert: don't remove the apparently unused "croppedArea" otherwise it crops badly
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      const currCroppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      // console.log("done", { currCroppedImage });

      props.onCrop1([currCroppedImage].concat(croppedImage));
      setCroppedImage([currCroppedImage]);
      props.onCrop2(currCroppedImage); //selects in Gift the just cropped image
      props.onCrop3(currCroppedImage); //selects in Gift the just cropped image, in the copy that remains "fake" BLOB
      setNewOpenToken(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  //THERE IS NOT AN EXIT STRATEGY - this snippet is a reminder
  //
  // const onClose = useCallback(() => {
  //   setCroppedImage(null);
  // }, []);

  useEffect(() => {
    if (imageSrc && (!croppedImage || newOpenToken)) {
      props.makeSpaceForMe(true);
    } else if (croppedImage) {
      props.makeSpaceForMe(false);
    }
  }, [imageSrc, newOpenToken]);

  return (
    <div>
      {!imageSrc ? (
        // CASE1: no src
        <div className="cover-file-input">
          <FileInput
            accept={IMAGE_MIME_TYPE}
            onChange={(files) => {
              // console.log("accepted files", files);
              onFileChange([files, ""]);
            }}
            variant="filled"
            size="md"
            placeholder="Carica dalla galleria"
            icon={<HiOutlinePhotograph size={22} />}
          />
        </div>
      ) : (
        // CASE2: no cropped but src or newtoken
        <React.Fragment>
          <div className="d-flex align-items-center justify-content-start">
            {!croppedImage || newOpenToken ? (
              <div className="cropzone-flex-props">
                <div
                  className="crop-dimensions cover-cropper"
                  style={{
                    position: "relative",
                    alignSelf: "flex-start",
                    width: 700,
                    height: 400,
                  }}
                >
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={3 / 3}
                    cropShape={props.shape}
                    showGrid={props.grid}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // style={{ alignSelf: "flex-end" }}
                  />
                </div>
                <div className="crop-confirm-spacing">
                  <PrimaryButton
                    clickHandler={saveCroppedImage}
                    text={props.cta}
                  />
                </div>
              </div>
            ) : (
              // CASE3: if croppedImage (and imageSrc)
              <>
                <div className="cover-file-input">
                  {/*note that mR: auto overwrites Justify-Content-Center */}
                  <FileInput
                    accept={IMAGE_MIME_TYPE}
                    onChange={(files) => {
                      onFileChange([files, ""]);
                    }}
                    variant="filled"
                    size="md"
                    placeholder="Carica un'immagine di copertina"
                    icon={<HiOutlinePhotograph size={20} />}
                  />
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
