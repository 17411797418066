export const editorials = [
  {
    ID_Editorial: "6",
    Storage_Artwork: "crush.png",
    // Description: "Il mondo è mio e sembra un sogno accanto a te, con un po' di follia e di magia le stelle brillano per te!",
    Title: "Per la tua crush",
    Description:
      "Che sia da 1 mese o da 40 anni...non è mai troppo presto o troppo tardi per dedicare una canzone!",
    ID_Collection: "6",
    Safe_Five: [0, 2, 3, 4, 7],
    Playlist: [
      {
        ID_Song: 686,
        Title: "La musica non c'è",
        Length: "223",
        Storage_Preview: "L000686",
        Stage_Name: "Coez",
        Preview_Start: 45,
      },
      {
        ID_Song: 1056,
        Title: "Rosé",
        Length: "239",
        Storage_Preview: "R0001056",
        Stage_Name: "Tatum Rush",
        Preview_Start: 45,
      },
      {
        ID_Song: 1105,
        Title: "Like a melody",
        Length: "139",
        Storage_Preview: "L0001105",
        Stage_Name: "Serena Brancale, Roshelle",
        Preview_Start: 45,
      },
      {
        ID_Song: 77,
        Title: "Robin Hood",
        Length: "177",
        Storage_Preview: "R00077",
        Stage_Name: "La Chance su Marte",
        Preview_Start: 47,
      },
      {
        ID_Song: 1970,
        Title: "L'amore conta - Live",
        Length: "288",
        Storage_Preview: "L0001970",
        Stage_Name: "Ligabue",
        Preview_Start: 182,
      },
      {
        ID_Song: 346,
        Title: "Il tuo nome per sempre",
        Length: "172",
        Storage_Preview: "I000346",
        Stage_Name: "Davide Scavolini",
        Preview_Start: 33,
      },
      {
        ID_Song: 1116,
        Title: "Quel raggio nella notte",
        Length: "230",
        Storage_Preview: "Q0001116",
        Stage_Name: "Irene Grandi",
        Preview_Start: 62,
      },
      {
        ID_Song: 1136,
        Title: "Quando",
        Length: "232",
        Storage_Preview: "Q0001136",
        Stage_Name: "Caterina",
        Preview_Start: 58,
      },
      {
        ID_Song: 15,
        Title: "Il tuo vestito migliore",
        Length: "210",
        Storage_Preview: "I00015",
        Stage_Name: "Shampisti",
        Preview_Start: 57,
      },
      {
        ID_Song: 879,
        Title: "Piccola stella senza cielo - Live",
        Length: "353",
        Storage_Preview: "P000879",
        Stage_Name: "Ligabue",
        Preview_Start: 50,
      },
    ],
  },
  {
    ID_Editorial: "5",
    Storage_Artwork: "tiamo.png",
    Title: "Per dire ti amo",
    // Description: 'Per dirle "Vorrei che ti vedessi speciale come ti vedo io"',
    ID_Collection: "5",
    Safe_Five: [0, 1, 2, 3, 4],
    Playlist: [
      {
        ID_Song: 1252,
        Title: "E se domani",
        Length: "186",
        Storage_Preview: "E0001252",
        Stage_Name: "Mina",
        Preview_Start: 68,
      },
      {
        ID_Song: 34,
        Title: "Beverly Hills",
        Length: "274",
        Storage_Preview: "B00034",
        Stage_Name: "Carolina Bubbico",
        Preview_Start: 126,
      },
      {
        ID_Song: 828,
        Title: "Tu sei lei",
        Length: "263",
        Storage_Preview: "T000828",
        Stage_Name: "Ligabue",
        Preview_Start: 52,
      },
      {
        ID_Song: 1156,
        Title: "Supereroi",
        Length: "252",
        Storage_Preview: "S0001156",
        Stage_Name: "Giorgieness",
        Preview_Start: 59,
      },
      {
        ID_Song: 62,
        Title: "Tutto Più Semplice",
        Length: "208",
        Storage_Preview: "T00062",
        Stage_Name: "Il Diavolo e l'Acqua Santa",
        Preview_Start: 50,
      },
      {
        ID_Song: 654,
        Title: "Abbraccio",
        Length: "214",
        Storage_Preview: "A000654",
        Stage_Name: "Christian Mascetta",
        Preview_Start: 137,
      },
      {
        ID_Song: 2170,
        Title: "Eterni",
        Length: "197",
        Storage_Preview: "E0002170",
        Stage_Name: "Davide Scavolini",
        Preview_Start: 40,
      },
      {
        ID_Song: 2171,
        Title: "Milano",
        Length: "212",
        Storage_Preview: "M0002171",
        Stage_Name: "Mani",
        Preview_Start: 135,
      },
      {
        ID_Song: 25,
        Title: "Luna",
        Length: "192",
        Storage_Preview: "L00025",
        Stage_Name: "Ghiaccio",
        Preview_Start: 113,
      },
    ],
  },
  {
    ID_Editorial: "1",
    Storage_Artwork: "coraggio.png",
    Title: "Per dare coraggio",
    Description: 'Alcuni brani per dire "Non sei solo, ci sono io con te"',
    ID_Collection: "1",
    Safe_Five: [1, 2, 3, 5, 8],
    Playlist: [
      {
        ID_Song: 690,
        Title: "Un sorso d'Ipa",
        Length: "211",
        Storage_Preview: "U000690",
        Stage_Name: "Coez",
        Preview_Start: 67,
      },
      {
        ID_Song: 1159,
        Title: "Cose piccole",
        Length: "205",
        Storage_Preview: "C0001159",
        Stage_Name: "Giorgieness",
        Preview_Start: 45,
      },
      {
        ID_Song: 832,
        Title: "Sono sempre i sogni a dare forma al mondo",
        Length: "274",
        Storage_Preview: "S000832",
        Stage_Name: "Ligabue",
        Preview_Start: 89,
      },
      {
        ID_Song: 695,
        Title: "Tornado",
        Length: "180",
        Storage_Preview: "T000695",
        Stage_Name: "Frah Quintale",
        Preview_Start: 45,
      },
      {
        ID_Song: 1243,
        Title: "Anna",
        Length: "234",
        Storage_Preview: "A0001243",
        Stage_Name: "Madame",
        Preview_Start: 110,
      },
      {
        ID_Song: 12,
        Title: "Come stelle",
        Length: "199",
        Storage_Preview: "C00012",
        Stage_Name: "Davide Scavolini",
        Preview_Start: 80,
      },
      {
        ID_Song: 703,
        Title: "Contento",
        Length: "205",
        Storage_Preview: "C000703",
        Stage_Name: "Frah Quintale",
        Preview_Start: 45,
      },
      {
        ID_Song: 1515,
        Title: "Tomorrow",
        Length: "180",
        Storage_Preview: "T0001515",
        Stage_Name: "The Kingdom Choir",
        Preview_Start: 57,
      },
      {
        ID_Song: 102,
        Title: "Fino in fondo",
        Length: "149",
        Storage_Preview: "F000102",
        Stage_Name: "Moscardi",
        Preview_Start: 68,
      },
      {
        ID_Song: 96,
        Title: "La Cartolina",
        Length: "241",
        Storage_Preview: "L00096",
        Stage_Name: "Michele De Martiis",
        Preview_Start: 85,
      },
    ],
  },
  {
    ID_Editorial: "2",
    Storage_Artwork: "tristi.png",
    Title: "Per chi ama le canzoni tristi",
    Description: "Siamo sicuri che anche tu ne conosci almeno uno",
    ID_Collection: "2",
    Safe_Five: [0, 3, 4, 5, 8],
    Playlist: [
      {
        ID_Song: 1030,
        Title: "Hey",
        Length: "224",
        Storage_Preview: "H0001030",
        Stage_Name: "Street Clerks",
        Preview_Start: 42,
      },
      {
        ID_Song: 1149,
        Title: "Che cosa resta",
        Length: "153",
        Storage_Preview: "C0001149",
        Stage_Name: "Giorgieness",
        Preview_Start: 45,
      },
      {
        ID_Song: 1234,
        Title: "SGUARDO LUME",
        Length: "175",
        Storage_Preview: "S0001234",
        Stage_Name: "roadelli",
        Preview_Start: 45,
      },
      {
        ID_Song: 1246,
        Title: "Città vuota",
        Length: "158",
        Storage_Preview: "C0001246",
        Stage_Name: "Mina",
        Preview_Start: 61,
      },
      // {
      //  ID_Song: 45,
      //  Title: "Pensiamoci",
      //  Length: "200",
      //  Storage_Preview: "G00045",
      //  Stage_Name: "G sepe",
      //  Preview_Start: 81,
      //  },
      {
        ID_Song: 151,
        Title: "I Gol Di Chiesa In Nazionale",
        Length: "171",
        Storage_Preview: "I000151",
        Stage_Name: "Legno",
        Preview_Start: 40,
      },
      {
        ID_Song: 61,
        Title: "Prima Di Partire",
        Length: "137",
        Storage_Preview: "P00061",
        Stage_Name: "Il Diavolo e l'Acqua Santa",
        Preview_Start: 46,
      },
      {
        ID_Song: 24,
        Title: "Grandi Mai",
        Length: "258",
        Storage_Preview: "G00024",
        Stage_Name: "Elisa Erin Bonomo",
        Preview_Start: 74,
      },
      {
        ID_Song: 86,
        Title: "Sincero (Acoustic)",
        Length: "266",
        Storage_Preview: "S00086",
        Stage_Name: "Mani",
        Preview_Start: 126,
      },
      {
        ID_Song: 1197,
        Title: "Solo questo mi è rimasto",
        Length: "212",
        Storage_Preview: "S0001197",
        Stage_Name: "Giulia Molino",
        Preview_Start: 45,
      },
      {
        ID_Song: 31,
        Title: "Adieu, ma Fluer",
        Length: "299",
        Storage_Preview: "A00031",
        Stage_Name: "WABEESABEE",
        Preview_Start: 117,
      },
    ],
  },
  {
    ID_Editorial: "8",
    Storage_Artwork: "buonumore.png",
    Title: "Buonumore",
    Description: "Ricorda a qualcuno di non prendersi troppo sul serio",
    ID_Collection: "8",
    Safe_Five: [0, 2, 4, 5, 8],
    Playlist: [
      {
        ID_Song: 46,
        Title: "Mutande",
        Length: "167",
        Storage_Preview: "M00046",
        Stage_Name: "Ginnasio",
        Preview_Start: 37,
      },
      {
        ID_Song: 1021,
        Title: "Londra",
        Length: "218",
        Storage_Preview: "L0001021",
        Stage_Name: "Street Clerks",
        Preview_Start: 45,
      },
      {
        ID_Song: 1137,
        Title: "Fiorellino",
        Length: "229",
        Storage_Preview: "F0001137",
        Stage_Name: "Caterina",
        Preview_Start: 59,
      },
      {
        ID_Song: 1303,
        Title: "Draghi",
        Length: "212",
        Storage_Preview: "D0001303",
        Stage_Name: "ISIDE",
        Preview_Start: 45,
      },
      {
        ID_Song: 1401,
        Title: "Bello bello",
        Length: "208",
        Storage_Preview: "B0001401",
        Stage_Name: "MARTELLI",
        Preview_Start: 64,
      },
      {
        ID_Song: 1027,
        Title: "Il ritorno di Beethoven",
        Length: "198",
        Storage_Preview: "I0001027",
        Stage_Name: "Street Clerks",
        Preview_Start: 23,
      },
      {
        ID_Song: 1499,
        Title: "Bill Murray",
        Length: "184",
        Storage_Preview: "B0001499",
        Stage_Name: "I Giocattoli",
        Preview_Start: 40,
      },
      {
        ID_Song: 1514,
        Title: "Thinkin 'Bout You",
        Length: "180",
        Storage_Preview: "T0001514",
        Stage_Name: "Jake Isaac",
        Preview_Start: 71,
      },
      {
        ID_Song: 1540,
        Title: "Superstition",
        Length: "110",
        Storage_Preview: "S0001540",
        Stage_Name: "Carolina Bubbico",
        Preview_Start: 45,
      },
      {
        ID_Song: 1589,
        Title: "Qualunque cosa sia",
        Length: "203",
        Storage_Preview: "Q0001589",
        Stage_Name: "I Segreti",
        Preview_Start: 45,
      },
    ],
  },
  {
    ID_Editorial: "9",
    Storage_Artwork: "doccia.png",
    Title: "Per chi canta sotto la doccia",
    Description:
      "Il 75% degli italiani canta sotto la doccia, aiuta i tuoi amici ad esprimere il loro potenziale!",
    ID_Collection: "9",
    Safe_Five: [0, 3, 6, 7, 8],
    Playlist: [
      {
        ID_Song: 898,
        Title: "Si, ah",
        Length: "173",
        Storage_Preview: "S000898",
        Stage_Name: "Frah Quintale",
        Preview_Start: 45,
      },
      {
        ID_Song: 1024,
        Title: "La vitamina",
        Length: "170",
        Storage_Preview: "L0001024",
        Stage_Name: "Street Clerks",
        Preview_Start: 25,
      },
      {
        ID_Song: 1143,
        Title: "Duemilacredici",
        Length: "205",
        Storage_Preview: "D0001143",
        Stage_Name: "Caterina",
        Preview_Start: 35,
      },
      {
        ID_Song: 1179,
        Title: "Mathilda e Futura",
        Length: "190",
        Storage_Preview: "M0001179",
        Stage_Name: "I miei migliori complimenti",
        Preview_Start: 67,
      },
      {
        ID_Song: 1427,
        Title: "Groupie - Radio Edit",
        Length: "185",
        Storage_Preview: "G0001427",
        Stage_Name: "Bob Sinclair",
        Preview_Start: 32,
      },
      {
        ID_Song: 1465,
        Title: "Cuore matto",
        Length: "169",
        Storage_Preview: "C0001465",
        Stage_Name: "Fausto Leali",
        Preview_Start: 45,
      },
      {
        ID_Song: 1497,
        Title: "Il Mondo - Gira il Mondo gira",
        Length: "202",
        Storage_Preview: "I0001497",
        Stage_Name: "Finley",
        Preview_Start: 45,
      },
      {
        ID_Song: 145,
        Title: "Che Sarà Mai",
        Length: "185",
        Storage_Preview: "C000145",
        Stage_Name: "Legno",
        Preview_Start: 36,
      },
      {
        ID_Song: 17,
        Title: "Uno Che Ti Ama Più Di Me",
        Length: "179",
        Storage_Preview: "U00017",
        Stage_Name: "Shampisti",
        Preview_Start: 45,
      },
      {
        ID_Song: 60,
        Title: "Non Vedi Che (Pensarti Mi Ammazza)",
        Length: "193",
        Storage_Preview: "N00060",
        Stage_Name: "Il Diavolo e l'Acqua Santa",
        Preview_Start: 44,
      },
    ],
  },
  {
    ID_Editorial: "4",
    Storage_Artwork: "relax.png",
    Title: "Per rilassarsi",
    Description:
      "Ogni tanto tutto ciò che ci vuole è un pò di relax. Regalane un pò a qualcuno con questa playlist!",
    ID_Collection: "4",
    Safe_Five: [0, 1, 3, 4, 9],
    Playlist: [
      {
        ID_Song: 1785,
        Title: "Nuvole bianche",
        Length: "263",
        Storage_Preview: "N0001785",
        Stage_Name: "Paolo Fresu, Ludovico Einaudi",
        Preview_Start: 45,
      },
      {
        ID_Song: 1330,
        Title: "MARTEDÌ",
        Length: "197",
        Storage_Preview: "M0001330",
        Stage_Name: "BLUEM",
        Preview_Start: 45,
      },
      {
        ID_Song: 1374,
        Title: "Gymnasion",
        Length: "217",
        Storage_Preview: "G0001374",
        Stage_Name: "HÅN",
        Preview_Start: 45,
      },
      {
        ID_Song: 1395,
        Title: "Forest",
        Length: "281",
        Storage_Preview: "F0001395",
        Stage_Name: "GINEVRA",
        Preview_Start: 45,
      },
      {
        ID_Song: 1513,
        Title: "New York",
        Length: "202",
        Storage_Preview: "N0001513",
        Stage_Name: "Jake Isaac",
        Preview_Start: 45,
      },
      {
        ID_Song: 1525,
        Title: "Controvento",
        Length: "217",
        Storage_Preview: "C0001525",
        Stage_Name: "Carolina Bubbico",
        Preview_Start: 48,
      },
      {
        ID_Song: 1737,
        Title: "The Silence of Your Heart",
        Length: "261",
        Storage_Preview: "T0001737",
        Stage_Name: "Paolo Fresu",
        Preview_Start: 0,
      },
      {
        ID_Song: 38,
        Title: "Limbo",
        Length: "233",
        Storage_Preview: "L00038",
        Stage_Name: "Lauryyn",
        Preview_Start: 139,
      },
      {
        ID_Song: 41,
        Title: "Salvami",
        Length: "198",
        Storage_Preview: "S00041",
        Stage_Name: "Yorker",
        Preview_Start: 42,
      },
      {
        ID_Song: 39,
        Title: "Polvere di stelle",
        Length: "226",
        Storage_Preview: "P00039",
        Stage_Name: "Margherita Zerbini",
        Preview_Start: 44,
      },
    ],
  },

  {
    ID_Editorial: "7",
    Storage_Artwork: "dose.png",
    Title: "Una dose di serotonina",
    Description:
      "Con questa playlist tra il pop e il rock, regalerai una buona dose di spensieratezza",
    ID_Collection: "7",
    Safe_Five: [0, 3, 5, 6, 9],
    Playlist: [
      {
        ID_Song: 692,
        Title: "Barceloneta",
        Length: "243",
        Storage_Preview: "B000692",
        Stage_Name: "Carl Brave x Franco126",
        Preview_Start: 45,
      },
      {
        ID_Song: 1022,
        Title: "Il mio desiderio di fuggire",
        Length: "182",
        Storage_Preview: "I0001022",
        Stage_Name: "Street Clerks",
        Preview_Start: 42,
      },
      {
        ID_Song: 705,
        Title: "Le cose sbagliate",
        Length: "103",
        Storage_Preview: "L000705",
        Stage_Name: "Frah Quintale",
        Preview_Start: 45,
      },
      {
        ID_Song: 1240,
        Title: "Papaya",
        Length: "140",
        Storage_Preview: "P0001240",
        Stage_Name: "VillaBanks",
        Preview_Start: 45,
      },
      {
        ID_Song: 1319,
        Title: "Friendly",
        Length: "180",
        Storage_Preview: "F0001319",
        Stage_Name: "Masamasa",
        Preview_Start: 45,
      },
      {
        ID_Song: 1042,
        Title: "Bravi tutti",
        Length: "175",
        Storage_Preview: "B0001042",
        Stage_Name: "Dutch Nazari",
        Preview_Start: 45,
      },
      {
        ID_Song: 1181,
        Title: "Alle fragole",
        Length: "199",
        Storage_Preview: "A0001181",
        Stage_Name: "I miei migliori complimenti",
        Preview_Start: 45,
      },
      {
        ID_Song: 1816,
        Title: "2004",
        Length: "193",
        Storage_Preview: "D0001816",
        Stage_Name: "Frah Quintale",
        Preview_Start: 78,
      },
      {
        ID_Song: 1498,
        Title: "SANTA CLAUSTROFOBIA",
        Length: "177",
        Storage_Preview: "S0001498",
        Stage_Name: "Finley",
        Preview_Start: 45,
      },
      {
        ID_Song: 704,
        Title: "Amarena",
        Length: "214",
        Storage_Preview: "A000704",
        Stage_Name: "Frah Quintale",
        Preview_Start: 44,
      },
    ],
  },
  {
    ID_Editorial: "3",
    Storage_Artwork: "lacrime.png",
    Title: "Per affogare nelle proprie lacrime",
    Description:
      "Ti sembrerà strano, ma a volte per risollevare l'umore di un amico bastano solo alcune canzoni tristi",
    ID_Collection: "3",
    Safe_Five: [0, 2, 4, 6, 7],
    Playlist: [
      {
        ID_Song: 888,
        Title: "8 miliardi di persone",
        Length: "223",
        Storage_Preview: "8000888",
        Stage_Name: "Frah Quintale",
        Preview_Start: 74,
      },
      {
        ID_Song: 151,
        Title: "I Gol Di Chiesa In Nazionale",
        Length: "171",
        Storage_Preview: "I000151",
        Stage_Name: "Legno",
        Preview_Start: 40,
      },
      {
        ID_Song: 1154,
        Title: "Il giardino del torto",
        Length: "181",
        Storage_Preview: "I0001154",
        Stage_Name: "Giorgieness",
        Preview_Start: 45,
      },
      {
        ID_Song: 872,
        Title: "Ciò che rimane di noi - Live",
        Length: "277",
        Storage_Preview: "C000872",
        Stage_Name: "Ligabue",
        Preview_Start: 60,
      },
      {
        ID_Song: 1453,
        Title: "A chi (Hurt) - Remastered",
        Length: "202",
        Storage_Preview: "A0001453",
        Stage_Name: "Fausto Leali",
        Preview_Start: 45,
      },
      {
        ID_Song: 903,
        Title: "Gli occhi",
        Length: "142",
        Storage_Preview: "G000903",
        Stage_Name: "Frah Quintale",
        Preview_Start: 45,
      },
      {
        ID_Song: 239,
        Title: "In (gin) Di Vita",
        Length: "191",
        Storage_Preview: "I000239",
        Stage_Name: "Legno",
        Preview_Start: 54,
      },
      {
        ID_Song: 240,
        Title: "Parentesi",
        Length: "219",
        Storage_Preview: "P000240",
        Stage_Name: "Erbe Officinali",
        Preview_Start: 48,
      },
      {
        ID_Song: 714,
        Title: "Gabbiani",
        Length: "204",
        Storage_Preview: "G000714",
        Stage_Name: "Frah Quintale",
        Preview_Start: 45,
      },
      {
        ID_Song: 238,
        Title: "Affogare",
        Length: "152",
        Storage_Preview: "A000238",
        Stage_Name: "Legno",
        Preview_Start: 25,
      },
    ],
  },
  {
    ID_Editorial: "10",
    Storage_Artwork: "tvb.png",
    Title: "Per dire ti voglio bene",
    Description: 'Con questa playlist "Ti voglio bene" avrà un suono speciale',
    ID_Collection: "10",
    Safe_Five: [0, 1, 3, 4, 7],
    Playlist: [
      {
        ID_Song: 689,
        Title: "Le luci della città",
        Length: "177",
        Storage_Preview: "L000689",
        Stage_Name: "Coez",
        Preview_Start: 45,
      },
      {
        ID_Song: 1116,
        Title: "Quel raggio nella notte",
        Length: "230",
        Storage_Preview: "Q0001116",
        Stage_Name: "Irene Grandi",
        Preview_Start: 45,
      },
      {
        ID_Song: 30,
        Title: "Speciale",
        Length: "245",
        Storage_Preview: "S00030",
        Stage_Name: "N.A.N.O",
        Preview_Start: 71,
      },
      {
        ID_Song: 2,
        Title: "Arterie",
        Length: "186",
        Storage_Preview: "A00002",
        Stage_Name: "Daniele Montesi",
        Preview_Start: 28,
      },
      {
        ID_Song: 12,
        Title: "Come stelle",
        Length: "199",
        Storage_Preview: "C00012",
        Stage_Name: "Davide Scavolini",
        Preview_Start: 80,
      },
      {
        ID_Song: 96,
        Title: "La Cartolina",
        Length: "241",
        Storage_Preview: "L00096",
        Stage_Name: "Michele De Martiis",
        Preview_Start: 85,
      },
      {
        ID_Song: 105,
        Title: "Happiness",
        Length: "151",
        Storage_Preview: "H000105",
        Stage_Name: "Moscardi",
        Preview_Start: 40,
      },
      {
        ID_Song: 77,
        Title: "Robin Hood",
        Length: "177",
        Storage_Preview: "R00077",
        Stage_Name: "La chance su Marte",
        Preview_Start: 47,
      },
    ],
  },
  {
    ID_Editorial: "12",
    Storage_Artwork: "energia.png",
    Title: "Un concentrato di energia",
    Description:
      "Una playlist adrenalinica e vivace, consigliatissima per chi ama allenarsi e perfetta per ricaricare le energie!",
    ID_Collection: "12",
    Safe_Five: [1, 2, 5, 6, 7],
    Playlist: [
      {
        ID_Song: 868,
        Title: "Con la scusa del rock'n'roll - Live",
        Length: "247",
        Storage_Preview: "C000868",
        Stage_Name: "Ligabue",
        Preview_Start: 45,
      },
      {
        ID_Song: 1145,
        Title: "Avete tutti ragione",
        Length: "163",
        Storage_Preview: "A0001145",
        Stage_Name: "Giorgieness",
        Preview_Start: 96,
      },
      {
        ID_Song: 1422,
        Title: "Rock This Party - Everybody Dance Now",
        Length: "245",
        Storage_Preview: "R0001422",
        Stage_Name: "Bob Sinclar",
        Preview_Start: 45,
      },
      {
        ID_Song: 16,
        Title: "Zizzania",
        Length: "191",
        Storage_Preview: "Z00016",
        Stage_Name: "Shampisti",
        Preview_Start: 47,
      },
      {
        ID_Song: 60,
        Title: "Non Vedi Che (Pensarti Mi Ammazza)",
        Length: "193",
        Storage_Preview: "N00060",
        Stage_Name: "Il Diavolo e l'Acqua Santa",
        Preview_Start: 44,
      },
      {
        ID_Song: 33,
        Title: "Baby",
        Length: "202",
        Storage_Preview: "B00033",
        Stage_Name: "Carolina Bubbico",
        Preview_Start: 45,
      },
      {
        ID_Song: 122,
        Title: "Cyberia ReEvolution",
        Length: "254",
        Storage_Preview: "C000122",
        Stage_Name: "Hotel Monroe",
        Preview_Start: 45,
      },
      {
        ID_Song: 17,
        Title: "Uno Che Ti Ama Più Di Me",
        Length: "179",
        Storage_Preview: "U00017",
        Stage_Name: "Shampisti",
        Preview_Start: 45,
      },
      {
        ID_Song: 35,
        Title: "Bimba",
        Length: "192",
        Storage_Preview: "B00035",
        Stage_Name: "Carolina Bubbico",
        Preview_Start: 54,
      },
      {
        ID_Song: 15,
        Title: "Il tuo Vestito Migliore",
        Length: "210",
        Storage_Preview: "I00015",
        Stage_Name: "Shampisti",
        Preview_Start: 57,
      },
    ],
  },
  // {
  //   ID_Editorial: "11",
  //   Storage_Artwork: "LoFi.png",
  //   Title: "LoFi",
  //   Description:
  //     "Una playlist versatile, adatta sia per il relax che per concentrarsi durante lo studio o il lavoro",
  //   ID_Collection: "11",
  //   Playlist: [
  //     {
  //       ID_Song: 51,
  //       Title: "Safe",
  //       Length: "94",
  //       Storage_Preview: "S00051",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //     {
  //       ID_Song: 52,
  //       Title: "Blue Sky",
  //       Length: "90",
  //       Storage_Preview: "B00052",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //     {
  //       ID_Song: 53,
  //       Title: "Homesick",
  //       Length: "108",
  //       Storage_Preview: "H00053",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //     {
  //       ID_Song: 54,
  //       Title: "This Way",
  //       Length: "91",
  //       Storage_Preview: "T00054",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //     {
  //       ID_Song: 55,
  //       Title: "Comfort Zone",
  //       Length: "95",
  //       Storage_Preview: "C00055",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //     {
  //       ID_Song: 56,
  //       Title: "Melancholy",
  //       Length: "125",
  //       Storage_Preview: "M00056",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //     {
  //       ID_Song: 57,
  //       Title: "Beside me",
  //       Length: "96",
  //       Storage_Preview: "B00057",
  //       Stage_Name: "[ggm]",
  //       Preview_Start: 45,
  //     },
  //   ],
  // },
  {
    ID_Editorial: "13",
    Storage_Artwork: "chilly-christmas.png",
    Title: "A very chilly Christmas",
    Description: 'Per riscaldare in questo Natale"',
    ID_Collection: "13",
    Safe_Five: [0, 1, 2, 3, 4],
    Playlist: [
      {
        ID_Song: 1062,
        Title: "The Banister Bough",
        Length: "250",
        Storage_Preview: "T0001062",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 8,
      },
      {
        ID_Song: 1064,
        Title: "All I Want For Christmas Is You",
        Length: "100",
        Storage_Preview: "A0001064",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 41,
      },
      {
        ID_Song: 1061,
        Title: "Last Christmas",
        Length: "145",
        Storage_Preview: "L0001061",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 19,
      },
      {
        ID_Song: 1066,
        Title: "Snow Is Falling In Manhattan",
        Length: "246",
        Storage_Preview: "S0001066",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 59,
      },
      {
        ID_Song: 1067,
        Title: "O Tannenbaum",
        Length: "99",
        Storage_Preview: "O0001067",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 0,
      },
      {
        ID_Song: 1065,
        Title: "In The Bleak Midwinter",
        Length: "132",
        Storage_Preview: "I0001065",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 38,
      },
      {
        ID_Song: 1060,
        Title: "God Rest Ye Merry Gentlemen",
        Length: "147",
        Storage_Preview: "G0001060",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 37,
      },
      {
        ID_Song: 1058,
        Title: "Good King Wenceslas",
        Length: "121",
        Storage_Preview: "G0001058",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 0,
      },
      {
        ID_Song: 1057,
        Title: "Silent Night",
        Length: "165",
        Storage_Preview: "S0001057",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 54,
      },
      {
        ID_Song: 1059,
        Title: "Silver Bells",
        Length: "159",
        Storage_Preview: "S0001059",
        Stage_Name: "Chilly Gonzales",
        Preview_Start: 43,
      },
    ],
  },
  {
    ID_Editorial: "14",
    Storage_Artwork: "restart.png",
    Title: "Anno nuovo, vita nuova!",
    Description:
      "A chi ha avuto un anno sfortunato e vuoi ricordare che il 2024 andrà certamente meglio!",
    ID_Collection: "14",
    Safe_Five: [0, 1, 2, 6, 7],
    Playlist: [
      {
        ID_Song: 1083,
        Title: "Non dimenticare",
        Length: "189",
        Storage_Preview: "N0001083",
        Stage_Name: "Nesli",
        Preview_Start: 17,
      },
      {
        ID_Song: 1474,
        Title: "C'e' una strada",
        Length: "190",
        Storage_Preview: "C0001474",
        Stage_Name: "Finley",
        Preview_Start: 55,
      },
      {
        ID_Song: 1566,
        Title: "Per essere felici",
        Length: "193",
        Storage_Preview: "P0001566",
        Stage_Name: "Marina Rei",
        Preview_Start: 102,
      },
      {
        ID_Song: 1821,
        Title: "Forte",
        Length: "207",
        Storage_Preview: "F0001821",
        Stage_Name: "Gemitaiz",
        Preview_Start: 9,
      },
      {
        ID_Song: 901,
        Title: "Stupefacente",
        Length: "187",
        Storage_Preview: "S000901",
        Stage_Name: "Frah Quintale",
        Preview_Start: 42,
      },
      {
        ID_Song: 2020,
        Title: "Supermassiccio",
        Length: "322",
        Storage_Preview: "S0002020",
        Stage_Name: "Elio e le storie tese",
        Preview_Start: 24,
      },
      {
        ID_Song: 1865,
        Title: "Buona fortuna",
        Length: "223",
        Storage_Preview: "B0001865",
        Stage_Name: "Finley",
        Preview_Start: 24,
      },
      {
        ID_Song: 1798,
        Title: "T.V.U.M.D.B.",
        Length: "327",
        Storage_Preview: "T0001798",
        Stage_Name: "Elio e le storie tese",
        Preview_Start: 52,
      },
      {
        ID_Song: 831,
        Title: "Per sempre",
        Length: "236",
        Storage_Preview: "P000831",
        Stage_Name: "Ligabue",
        Preview_Start: 50,
      },
    ],
  },
  {
    ID_Editorial: "15",
    Storage_Artwork: "grandimai.png",
    Title: "Grandi nel duemilacredici",
    Description:
      "A quella persona con cui continuerai a fare ca**ate nel 2024!",
    ID_Collection: "15",
    Safe_Five: [0, 1, 2, 3, 5],
    Playlist: [
      {
        ID_Song: 1647,
        Title: "Gli anni che verranno",
        Length: "191",
        Storage_Preview: "G0001647",
        Stage_Name: "Giorgio Gaber",
        Preview_Start: 40,
      },
      {
        ID_Song: 683,
        Title: "Faccio un casino",
        Length: "194",
        Storage_Preview: "F000683",
        Stage_Name: "Coez",
        Preview_Start: 7,
      },
      {
        ID_Song: 145,
        Title: "Che sarà mai",
        Length: "185",
        Storage_Preview: "C000145",
        Stage_Name: "Legno",
        Preview_Start: 36,
      },
      {
        ID_Song: 1456,
        Title: "È solo un gioco - Remastered",
        Length: "189",
        Storage_Preview: "E0001456",
        Stage_Name: "Fausto Leali",
        Preview_Start: 30,
      },
      {
        ID_Song: 233,
        Title: "Lo so, lo so",
        Length: "266",
        Storage_Preview: "L000233",
        Stage_Name: "Il peggio è passato",
        Preview_Start: 59,
      },
      {
        ID_Song: 80,
        Title: "Non cresciamo mai",
        Length: "157",
        Storage_Preview: "N00080",
        Stage_Name: "Mani",
        Preview_Start: 58,
      },
      {
        ID_Song: 1930,
        Title: "Va bene così",
        Length: "195",
        Storage_Preview: "V0001930",
        Stage_Name: "Gemitaiz",
        Preview_Start: 12,
      },
      {
        ID_Song: 833,
        Title: "Non è tempo per noi - Live",
        Length: "344",
        Storage_Preview: "N000833",
        Stage_Name: "Ligabue",
        Preview_Start: 65,
      },
    ],
  },
  {
    ID_Editorial: "16",
    Storage_Artwork: "buonipropositi.png",
    Title: "Dai che è l'anno buono!",
    Description:
      "Per dare una spinta all'amico con sempre un sacco di buoni propositi ma poca forza di volontà",
    ID_Collection: "16",
    Safe_Five: [0, 1, 2, 7, 8],
    Playlist: [
      {
        ID_Song: 81,
        Title: "Facciamo finta",
        Length: "199",
        Storage_Preview: "F00081",
        Stage_Name: "Mani",
        Preview_Start: 37,
      },
      {
        ID_Song: 1909,
        Title: "Cambio le regole",
        Length: "226",
        Storage_Preview: "C0001909",
        Stage_Name: "Gemitaiz",
        Preview_Start: 75,
      },
      {
        ID_Song: 1072,
        Title: "Vado via",
        Length: "133",
        Storage_Preview: "V0001072",
        Stage_Name: "Drupi",
        Preview_Start: 18,
      },
      {
        ID_Song: 9,
        Title: "Lontano da qua",
        Length: "193",
        Storage_Preview: "L00009",
        Stage_Name: "Davide Scavolini",
        Preview_Start: 38,
      },
      {
        ID_Song: 198,
        Title: "Vado a vivere in Brasile",
        Length: "327",
        Storage_Preview: "V000198",
        Stage_Name: "Don Rodriguez",
        Preview_Start: 72,
      },
      {
        ID_Song: 148,
        Title: "Spaccotutto",
        Length: "171",
        Storage_Preview: "S000148",
        Stage_Name: "Legno",
        Preview_Start: 31,
      },
      {
        ID_Song: 1153,
        Title: "Come se non ci fosse un domani",
        Length: "202",
        Storage_Preview: "C0001153",
        Stage_Name: "Giorgieness",
        Preview_Start: 60,
      },
      {
        ID_Song: 905,
        Title: "Hai visto mai",
        Length: "208",
        Storage_Preview: "H000905",
        Stage_Name: "Frah Quintale",
        Preview_Start: 24,
      },
      {
        ID_Song: 1026,
        Title: "Finisce che sto bene",
        Length: "237",
        Storage_Preview: "F0001026",
        Stage_Name: "Street Clerks",
        Preview_Start: 47,
      },
    ],
  },
];
